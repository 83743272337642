import React from 'react';

const AppContext = React.createContext({
  user: null,
  loading: false,
  collapsed: false,
  search: 'test',
  categories: [],
  objectives: [],
  partners: [],
  empresas: [],
  specials: [],
  listWaiting: [],
  searchFilter: 'test',
  searchFilterTypeOfAssign: 'test',
  initialDate: '',
  finalDate: '',
  setAppState() {},
});

export const useAppContext = () => React.useContext(AppContext);

export default AppContext;
