/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {minutesLabels, secondsLabels} from '../../core/timeHelpers';
import {useFileContext} from '../singletons/FileContext';
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Grid,
  Avatar,
  Switch,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import {string, object, array, number} from 'yup'; // for only what you need
import {Formik} from 'formik';
import axios from 'axios';
import {toDataURL} from '../../core/toDataURL';
import ReturnButton from '../ReturnButton';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import RenderInput from './RenderInput';
import SearchIcon from '@material-ui/icons/Search';
import {
  ModalEditThumbnail,
  ModalEditVideo,
  ModalEditAudio,
} from './ModalEditMidia';
import {
  toggleCheckbox,
  ModalPartner,
  ListKeywords,
  ListCategories,
  postMidiaUpload,
  putVideoMidiaUpload,
  putThumbnailUpload,
  ListObjectives,
  stringGen,
} from '../../core/formHelpers';
import moment from 'moment';

import Confirm from '../Confirm';
import {convertSecondsToDuration} from '../../core/mediaTypeHelpers';
import ButtonSubmit from './ButtonSubmit';
import UploadContext from '../singletons/UploadContext';
import {useSnackbar} from 'notistack';
import api from '../../core/api';
import {useInputsFixedContext} from '../singletons/InputsFixedContext';
import {DatePicker} from '@material-ui/pickers';
import PublicDatePicker from '../PublicDatePicker';
import InputsFixed from './InputsFixed';
import {v4} from 'uuid';

const useStyles = makeStyles(() => ({
  avatar: {
    paddingTop: 0,
    height: '40vh',
    width: '60vw',
    borderRadius: '20px',
    backgroundColor: '#61b7c3',
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  contentMidia: {
    height: 200,
    width: 200,
    backgroundColor: '#61b7c3',
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  iconButton: {
    borderRadius: 0,
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  imageInput: {
    border: '3px dashed #000000a1',
    borderRadius: '0.5em',
    padding: '10px',
  },
  label: {
    padding: '15px 0',
  },
  invalidLabel: {
    color: 'red',
  },
}));

const MultimidiaForm = ({
  midia = {},
  type = '',
  size = null,
  history,
  categories = [],
  objectives = [],
  partners = [],
}) => {
  const classes = useStyles();
  const editing = !!midia.id;
  const {src, crop, blobFile, croppedImageUrl, setFileState} = useFileContext();
  const [uploadState, setUploadState] = useState({
    videoFile: [],
    thumbnailFile: [],
    uploading: false,
    uploadProgress: 0,
    successfullUploaded: false,
  });
  const [open, setOpen] = useState('');
  const {premium, setInputState} = useInputsFixedContext();
  const {enqueueSnackbar} = useSnackbar();
  const [isPresentationVideo, setIsPresentationVideo] = useState(false);

  const handleClickOpen = view => {
    setOpen(view);
  };
  const handleClose = () => {
    setOpen('');
  };

  const infoMidiaInputs = [
    {name: 'title', label: 'Título', type: 'text'},
    {name: 'description', label: 'Descrição', type: 'description'},
    {
      name: 'author',
      label: 'Autor',
      type: 'text',
      leftIcon: (
        <IconButton onClick={() => handleClickOpen('partner')}>
          <SearchIcon />
        </IconButton>
      ),
      onSearch: () => void 0,
    },
    {
      name: 'duration',
      label: 'Minutos',
      type: 'time',
      values: {minutes: minutesLabels, seconds: secondsLabels},
    },
  ];

  const validateForm = values => {
    const errors = {};
    let errorMessage = '';

    if (!values.thumbnail.length && !values.thumbnail.name) {
      errors.thumbnail = 'Imagem inválida';
      errorMessage += 'Imagem\n';
    }

    if (!values.partner.id) {
      errors.author = 'Autor inválido';
      errorMessage += 'Autor\n';
    }

    if (!isPresentationVideo) {
      if (!values.title) {
        errors.title = 'Título inválido';
        errorMessage += 'Título\n';
      }

      if (!values.description) {
        errors.description = 'Descrição inválido';
        errorMessage += 'Descrição\n';
      }
      if (!values.selected_categories.length) {
        errors.selected_categories = 'Categoria inválida';
        errorMessage += 'Categoria\n';
      }
      if (!values.objectives.length) {
        errors.objectives = 'Objetivo inválido';
        errorMessage += 'Objetivo\n';
      }
    }

    if (Object.keys(errors).length) {
      enqueueSnackbar(
        'Formulário incompleto, campos a serem preenchidos:\n' + errorMessage,
        {
          preventDuplicate: true,
          variant: 'error',
          style: {whiteSpace: 'pre-line'},
        },
      );
    }

    return errors;
  };

  let defaultValues = editing
    ? {
        ...midia,
        filename: midia.original_filename
          ? midia.original_filename
          : midia.filename,
        duration_seconds: midia.duration_seconds,
        selected_categories: midia.categories,
        duration: JSON.parse(midia.duration),
        sub_category_ids: JSON.parse(midia.sub_category_ids),
        key_word_ids: JSON.parse(midia.key_word_ids),
        objectives: midia.objectives ? JSON.parse(midia.objectives) : [],
        checkbox: midia.publication_date ? true : false,
        publication_date: midia.publication_date
          ? moment.utc(midia.publication_date)
          : null,
        audio: midia.isPartnerPresentationVideo
          ? 'presentationVideo'
          : midia.audio
          ? 'audio'
          : 'video',
      }
    : {
        title: '',
        filename: midia.filename,
        description: '',
        thumbnail: {},
        premium: false,
        promo: false,
        audio: midia.type,
        video: [],
        publication_date: null,
        sub_category_ids: [],
        key_word_ids: [],
        partnerId: '',
        duration_seconds: midia.duration_seconds,
        duration: midia.duration_seconds
          ? convertSecondsToDuration(midia.duration_seconds)
          : {minutes: '0', seconds: '0'},
        selected_categories: [],
        partner: {
          id: '',
          thumbnailURL: '',
          artisticName: '',
        },
        objectives: [],
        isPartnerPresentationVideo: false,
      };

  useEffect(() => {
    if (editing) {
      if (defaultValues.thumbnail) {
        toDataURL(
          `${defaultValues.thumbnail}?random=${Math.random()
            .toString(36)
            .substring(7)}`,
          function(dataUrl) {
            console.log('RESULT:', dataUrl)
            setFileState({src: dataUrl});
          },
          'image/jpeg',
        );
      }
      if (defaultValues.isPartnerPresentationVideo) {
        setIsPresentationVideo(true);
      }
    }
    setInputState({premium: defaultValues.premium});
  }, []);

  const onSaveApresentationVideo = async (values, setSubmitting) => {
    values.title = `Video de Apresentação - ${values.partner.authorName ||
      values.partner.artisticName}`;
    values.description = `Video de Apresentação`;
    values.sub_category_ids = [];
    values.objectives = [];
    values.key_word_ids = [];

    //Verify is parter has apresenteation video
    const response = await api.get(
      `/partners/presentation-video/${values.partnerId}`,
    );

    if (response.data.id) {
      if (editing && response.data.id === midia.id) {
        onSubmit(values);
      } else {
        const question = await Confirm(
          'Parceiro já possui um vídeo de apresentação, deseja deletar o video antigo?',
        );
        if (question) {
          try {
            enqueueSnackbar('Salvando');
            await api.delete(`/videos/${response.data.id}`);
            await onSubmit(values);
          } catch (e) {
            console.log(e);
          }
        } else {
          enqueueSnackbar('Cancelado.');
          setSubmitting(false);
        }
      }
    } else {
      await onSubmit(values);
    }
  };

  const onSubmit = async values => {
    let form = {
      ...values,
      audio: values.audio == 'audio' ? true : false,
      isPartnerPresentationVideo:
        values.audio == 'presentationVideo' ? true : false,
      duration_seconds: defaultValues.duration_seconds,
      size: size,
      partnerId: values.partner.id,
      categories: values.selected_categories.toString(),
      duration: JSON.stringify(values.duration),
      sub_category_ids: JSON.stringify(values.sub_category_ids),
      publication_date: values.checkbox
        ? values.publication_date
          ? moment.utc(values.publication_date).format('YYYY-MM-DD')
          : null
        : null,
      objectives: JSON.stringify(values.objectives),
    };

    let filteredKeywords = [];

    if (values.key_word_ids.length) {
      //Look and remove the keywords that not includes in selected categories
      const validKeywords = [];
      categories.map(category => {
        const validCategorys = values.selected_categories.includes(category.id);
        if (validCategorys) {
          category.keywords.map(keyword => {
            if (validKeywords.indexOf(keyword.id) === -1)
              validKeywords.push(keyword.id);
          });
        }
      });

      //Filtered list of included keywords of selected categories
      filteredKeywords = values.key_word_ids.filter(id =>
        validKeywords.includes(id),
      );

      form.key_word_ids = JSON.stringify(filteredKeywords);
    } else {
      form.key_word_ids = '[]';
    }

    if (editing) {
      if (
        values.thumbnail.name &&
        values.thumbnail.name.toLowerCase().match(/.png|.jpg|.jpeg/)
      ) {

        const filename = midia.thumbnail.split('/').pop();
      
        await putThumbnailUpload(values.thumbnail, 'thumbnails', filename);
      }
      delete form.duration_seconds;
      delete form.size;
      delete form.thumbnail;
      delete form.filename;
      await api.put(`/videos/${values.id}`, form).then(() => {
        enqueueSnackbar('Conteúdo editado com sucesso!');
      });
      history.replace('/medias');
    } else {
      if (values.filename) {
        const rndString = v4().replace(/-/g, '');
        form.thumbnail = `upload_${rndString}`;

        try {
          const response = await api.post('/videos', form).then(async res => {
            if (res.status === 200) {
              await putThumbnailUpload(
                values.thumbnail,
                'thumbnails',
                form.thumbnail,
              );
              enqueueSnackbar('Conteúdo salvo com sucesso!');
            }
          });
        } catch (e) {
          console.log({e});
        }
        history.push(`/medias`);
      } else {
        alert('Erro ao enviar');
      }
    }
  };

  const onChangeContentType = (event, formikProps) => {
    formikProps.setFieldValue('audio', event.target.value);
    if (event.target.value === 'presentationVideo') {
      setIsPresentationVideo(true);
    } else {
      setIsPresentationVideo(false);
    }
  };

  return midia.filename ? (
    <UploadContext.Provider value={{...uploadState, setUploadState}}>
      <Formik
        initialValues={defaultValues}
        validate={validateForm}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, {setSubmitting}) => {
          if (values.audio == 'presentationVideo') {
            onSaveApresentationVideo(values, setSubmitting);
          } else {
            onSubmit(values);
          }
        }}>
        {formikProps => {
          return (
            <div>
              <div
                className="flex-row center-b"
                style={{
                  justifyContent: 'space-between',
                  padding: '0 20px',
                }}>
                <ModalEditThumbnail
                  formikProps={formikProps}
                  handleClose={handleClose}
                  open={open}
                />
                <ModalEditAudio
                  formikProps={formikProps}
                  handleClose={handleClose}
                  open={open}
                />
                <ModalEditVideo
                  formikProps={formikProps}
                  handleClose={handleClose}
                  open={open}
                />
                <ModalPartner
                  formikProps={formikProps}
                  partners={partners}
                  open={open}
                  handleClose={handleClose}
                />

                <div className="flex-row center-b">
                  <ReturnButton />
                  <Typography component="h1" variant="h5">
                    {!editing ? 'Adicionar Conteúdo' : 'Editar Conteúdo'}
                  </Typography>
                </div>
                <InputsFixed formikProps={formikProps} />
              </div>

              <Grid container xs={12}>
                <Grid xs={12} item style={{padding: '20px'}}>
                  <div className="flex-col center-a center-b">
                    <IconButton
                      className={classes.avatar}
                      onClick={() => handleClickOpen('thumbnail')}>
                      <Avatar
                        className={classes.avatar}
                        src={croppedImageUrl || src || null}>
                        {!croppedImageUrl && !src && (
                          <AddAPhotoIcon style={{fontSize: 60}} />
                        )}
                      </Avatar>
                    </IconButton>

                    <p>Selecione uma imagem de capa para este conteúdo.</p>
                    {formikProps.errors.thumbnail && (
                      <span className={classes.invalidLabel}>
                        Imagem inválida
                      </span>
                    )}
                  </div>

                  {src && (
                    <Grid xs={12} container justify="center">
                      <Button onClick={() => handleClickOpen('thumbnail')}>
                        Editar Imagem
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <div
                  style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                  <Typography variant="h6">
                    {formikProps.values.filename}
                  </Typography>
                </div>
                <Grid xs={12} item>
                  <form
                    style={{padding: '20px'}}
                    onSubmit={formikProps.handleSubmit}>
                    <div>
                      <Typography variant="body1">Tipo do conteúdo</Typography>
                      <RadioGroup
                        value={formikProps.values.audio}
                        onChange={event =>
                          onChangeContentType(event, formikProps)
                        }>
                        <FormControlLabel
                          value="audio"
                          control={<Radio />}
                          label="Áudio"
                        />
                        <FormControlLabel
                          value="video"
                          control={<Radio />}
                          label="Vídeo"
                        />
                        <FormControlLabel
                          value="presentationVideo"
                          control={<Radio />}
                          label="Vídeo de Apresentação"
                        />
                      </RadioGroup>
                    </div>
                    <RenderInput
                      inputs={infoMidiaInputs}
                      formikProps={formikProps}
                      isPresentationVideo={isPresentationVideo}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 50,
                      }}></div>
                    {isPresentationVideo ? null : (
                      <>
                        <Grid xs={12}>
                          <Typography component="h5" variant="h5">
                            Objetivos
                          </Typography>
                          {formikProps.errors.objectives && (
                            <span className={classes.invalidLabel}>
                              Objetivo inválido
                            </span>
                          )}
                          <ListObjectives
                            objectives={objectives}
                            formikProps={formikProps}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <Typography component="h5" variant="h5">
                            Categorias
                          </Typography>
                          {formikProps.errors.selected_categories && (
                            <span className={classes.invalidLabel}>
                              Categoria inválida
                            </span>
                          )}
                          <ListCategories
                            categories={categories}
                            formikProps={formikProps}
                            regValues={/cursos|matérias|promoções/}
                          />
                        </Grid>

                        <Grid xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleClickOpen('keyword')}>
                            {!editing
                              ? 'Adicionar Palavra Chave'
                              : 'Editar Palavra Chave'}
                          </Button>
                          <ListKeywords
                            categories={categories}
                            formikProps={formikProps}
                            open={open}
                            handleClose={handleClose}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid alignItems="center">
                      <ButtonSubmit
                        disabled={formikProps.isSubmitting}
                        style={{marginTop: 20}}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary">
                        {formikProps.isSubmitting ? (
                          <CircularProgress size={40} />
                        ) : (
                          'Salvar'
                        )}
                      </ButtonSubmit>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </div>
          );
        }}
      </Formik>
    </UploadContext.Provider>
  ) : null;
};

export default MultimidiaForm;
