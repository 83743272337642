import React, {useState, useEffect} from 'react';
import {
  Grid,
  Avatar,
  Typography,
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Popover,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import {Link} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/MoreVert';
import api from '../core/api';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {useSnackbar} from 'notistack';
import Confirm from './Confirm';
import {useAppContext} from './singletons/AppContext';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const PartnerItem = ({user}) => {
  const partner = {...user};
  const {enqueueSnackbar} = useSnackbar();
  const {onReloadData} = useAppContext();

  const onDeletePartner = async () => {
    const res = await Confirm('Deseja realmente remover este parceiro?');
    if (res)
      try {
        await api.delete(`/partners/${partner.id}`);
        onReloadData();
        enqueueSnackbar('Deletado com Sucesso!');
      } catch (e) {
        console.log(e);
      }
  };

  return (
    <React.Fragment>
      <ListItem alignItems="center">
        <ListItemAvatar>
          <Avatar src={partner.thumbnail} alt="Remy Sharp">
            {!partner.thumbnail && (partner.artisticName || '')[0]}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={partner.artisticName || partner.name}
          secondary={
            <ListItemIcon>
              <EmailIcon />
              <Typography
                style={{marginLeft: 8}}
                variant="body2"
                className="flex-row center-b"
                color="textPrimary">
                {partner.email}
              </Typography>
            </ListItemIcon>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            component={Link}
            to={
              partner.candidate_aproved !== true
                ? `/candidates/edit/${partner.id}`
                : `/partners/edit/${partner.id}`
            }
            edge="end">
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDeletePartner} edge="end">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
export default PartnerItem;
