import React, {useEffect, useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import api from '../../core/api';
import SpecialForm from '../../components/forms/SpecialForm';

const EditSpecial = ({history, match}) => {
  const [special, setSpecial] = useState({});
  console.log('aq');

  const fetchSpecial = async () => {
    try {
      const {data: special} = await api.get(
        `/specials/${match.params.specialId}`,
      );
      console.log(special);
      setSpecial(special);
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    fetchSpecial();
  }, []);

  return (
    <Dashboard>
      {special.id && <SpecialForm history={history} special={special} />}
    </Dashboard>
  );
};

export default EditSpecial;
