import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import api from '../../core/api'
import { useAppContext } from '../singletons/AppContext'

export default function ArticlesContainer({ key, children }) {
	const [ state, setState ] = useState({
		articles: [],
		loading: true,
		error: null
	})
	const { setAppState } = useAppContext()

	async function fetchData() {
		setState({ loading: true, error: null })
		try {
			// Estudos sobre error
			// if (!window.abc) {
			// 	throw new Error("erro")
			// 	window.abc = true
			// }
			const { data: articles } = await api.get(`/articles`)

			const newArticles = articles.filter((item) => {
				return !item.course
			})

			setState({ ...state, articles: newArticles, loading: false })
		} catch (e) {
			if (e.response)
				setState({
					...state,
					error: {
						payload: e.response.data
					}
				})
			else setState({ ...state, error: {} })
		}
	}

	useEffect(() => {
		fetchData()
		setAppState({ onReloadData: () => fetchData() })
	}, [])

	if (state.error) {
		// for (let i = 0; i < 10; i++) {
		// 	onDelete(i)
		// }
		return (
			<div>
				<p style={{ textAlign: 'center' }}>Ocorreu um erro ao obter os dados.</p>
				<p style={{ textAlign: 'center' }}>
					<a href="javascript:void(0)" onClick={fetchData}>
						Tentar novamente
					</a>
				</p>
			</div>
		)
	}

	if (state.loading) {
		return (
			<div className="flex-col center-a center-b" style={{ height: '50vh' }}>
				<span style={{ paddingTop: '10em' }}>
					<CircularProgress size={100} />
					<p> Carregando... </p>
				</span>
			</div>
		)
	}

	return (
		<Grid container xs={12}>
			{children({
				articles: state.articles.sort((a, b) => {
					if (a.name > b.name) {
						return 1
					}
					if (a.name < b.name) {
						return -1
					}
					return 0
				}),
				fetchData
			})}
		</Grid>
	)
}
