import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(styles => ({
  uploadsScreen: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fileInput: {
    display: 'none',
  },
}));

export default useStyles;
