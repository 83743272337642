import React, {useEffect, useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import {
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {read, utils, writeFile} from 'xlsx';

import UploadIcon from '@material-ui/icons/CloudUpload';
import useStyles from './styles';
import ButtonSubmit from '../../components/forms/ButtonSubmit';

import {CloudDownload, Edit, Save} from '@material-ui/icons';
import EditListItem from './editList';
import Specials from '../../models/Specials';
import Empresas from '../../models/Empresas';
import {useSnackbar} from 'notistack';
import api from '../../core/api';
import Confirm from '../../components/Confirm';

const ListWaitingPage = ({history}) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersOk, setUsersOk] = useState([]);

  const [getEmpresas, setEmpresas] = useState([]);
  const [getSpecial, setSpecial] = useState([]);
  const [selectEmpresa, setSelectEmpresa] = useState('');
  const [selectSpecial, setSelectSpecial] = useState('');
  const idEmpresa = JSON.parse(localStorage.getItem('empresa_id'));
  const adminGestor = JSON.parse(localStorage.getItem('adminGestor'));
  const fetchSpecial = async () => {
    try {
      const allSpecials = await Specials.findAll();
      setSpecial(allSpecials);
    } catch (e) {
      // console.log({e});
    }
  };

  const fetchEmpresa = async () => {
    try {
      const allEmpresas = await Empresas.findAll();
      setEmpresas(allEmpresas);
    } catch (e) {
      // console.log({e});
    }
  };

  useEffect(() => {
    fetchEmpresa();
    fetchSpecial();

    if (!adminGestor) {
      setSelectEmpresa(idEmpresa);
    }
  }, []);

  const handleImport = $event => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async event => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setUsers(rows);
          // verificarDuplicados();
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleExport = () => {
    const headings = [['Email', 'Nome', 'Apelido', 'CPF']];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, users, {origin: 'A2', skipHeader: true});
    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, 'Movie Report.xlsx');
  };

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  const validateCpf = cpf => {
    return String(cpf)
      .toLowerCase()
      .match(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  };

  async function onSubmit() {
    if (editable) {
      setEditable(false);
      return;
    }
    if (!selectEmpresa) {
      enqueueSnackbar('Selecione uma empresa.', {
        variant: 'error',
      });
      return;
    }
    if (!selectSpecial) {
      enqueueSnackbar('Selecione uma categoria.', {
        variant: 'error',
      });
      return;
    }
    let statusOk = true;
    const payload = users.map(user => {
      if (statusOk) {
        if (!user.Email) {
          enqueueSnackbar('Campo email não pode ser nulo.', {
            variant: 'error',
          });
          statusOk = false;
          return;
        }

        if (!validateEmail(user.Email)) {
          enqueueSnackbar(`Email ${user.Email} inválido.`, {
            variant: 'error',
          });
          statusOk = false;
          return;
        }

        if (!user.Nome) {
          enqueueSnackbar('Campo nome não pode ser nulo.', {
            variant: 'error',
          });
          statusOk = false;
          return;
        }
        if (!user.NickName) {
          enqueueSnackbar('Campo apelido não pode ser nulo.', {
            variant: 'error',
          });
          statusOk = false;
          return;
        }
        if (!user.CPF) {
          enqueueSnackbar('Campo CPF não pode ser nulo.', {
            variant: 'error',
          });
          statusOk = false;
          return;
        }
        // if (!validateCpf(user.CPF)) {
        //   enqueueSnackbar(`CPF ${user.CPF} inválido.`, {
        //     variant: 'error',
        //   });
        //   statusOk = false;
        //   return;
        // }
        return {
          email: user.Email.trim(),
          name: user.Nome,
          nickName: user.NickName,
          cpf: user.CPF,
          empresa_id: selectEmpresa,
          special_id: selectSpecial,
        };
      }
    });

    if (statusOk) {
      // console.log(payload);
      const res = await Confirm('Deseja realmente salvar esses usuários?');

      if (res) {
        setIsLoading(true);
        try {
          await api.post(`/waitinglist/mult`, payload).then(res => {
            // console.log(res);
            if (res.status === 200) {
              enqueueSnackbar('Dados inseridos com sucesso!');
              history.push('/listwaiting');
            }
          });
        } catch ({response}) {
          enqueueSnackbar(response.data?.payload.message, {
            variant: 'error',
          });
        } finally {
          setIsLoading(false);
        }
      }
    }
    // console.log(payload);
  }

  useEffect(() => console.log(selectEmpresa, selectSpecial), [
    selectEmpresa,
    selectSpecial,
  ]);

  return (
    <Dashboard>
      <div className={classes.uploadsScreen}>
        <div className={classes.titleContainer}>
          <div className={classes.uploadBar}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <InputLabel>Baixar exemplo</InputLabel>
              <IconButton
                variant="contained"
                component="label"
                onClick={handleExport}>
                <CloudDownload />
              </IconButton>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 20,
              }}>
              <InputLabel>Subir excel</InputLabel>
              <IconButton variant="contained" component="label">
                <UploadIcon />
                <input
                  type="file"
                  className={classes.fileInput}
                  required
                  onChange={handleImport}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </IconButton>
            </div>

            {users.length ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: 20,
                }}>
                <InputLabel>{`${
                  editable ? 'Ver' : 'Editar'
                } campos`}</InputLabel>
                <IconButton
                  variant="contained"
                  component="label"
                  onClick={() => setEditable(!editable)}>
                  {editable ? <Save /> : <Edit />}
                </IconButton>
              </div>
            ) : null}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 20,
              }}>
              <InputLabel>Adicionar</InputLabel>
              <IconButton
                variant="contained"
                component="label"
                onClick={() => {
                  const newUser = [
                    ...users,
                    {
                      Email: '',
                      Nome: '',
                      NickName: '',
                      CPF: '',
                    },
                  ];
                  setUsers(newUser);
                  setEditable(true);
                }}>
                <AddIcon />
              </IconButton>
            </div>
          </div>

          {users.length ? (
            <div className={classes.uploadBar}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '200px',
                  height: '100px',
                  marginRight: 50,
                }}>
                <InputLabel>Selecione a empresa</InputLabel>

                <Select
                  variant="outlined"
                  value={selectEmpresa}
                  label="Selecione a empresa"
                  disabled={!adminGestor}
                  onChange={e => setSelectEmpresa(e.target.value)}>
                  {getEmpresas &&
                    getEmpresas.map(e => {
                      return <MenuItem value={e.id}>{e.nomeFantasia}</MenuItem>;
                    })}
                </Select>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '200px',
                  marginRight: 50,
                }}>
                <InputLabel>Selecione a categoria</InputLabel>
                <Select
                  variant="outlined"
                  value={selectSpecial}
                  label="Selecione a categoria"
                  onChange={e => setSelectSpecial(e.target.value)}>
                  {getSpecial &&
                    getSpecial.map(e => {
                      return <MenuItem value={e.id}>{e.nome}</MenuItem>;
                    })}
                </Select>
              </div>
            </div>
          ) : null}
        </div>

        <div className={classes.mediaItems}>
          {!isLoading ? (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>Apelido</TableCell>
                    <TableCell>CPF</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {users && users.length > 0 && (
                  <EditListItem
                    users={users}
                    editable={editable}
                    setUsers={setUsers}
                  />
                )}
              </Table>
              {users.length ? (
                <ButtonSubmit
                  loadingArea={isLoading}
                  style={{marginTop: 20}}
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onSubmit}>
                  Salvar
                </ButtonSubmit>
              ) : null}
            </>
          ) : (
            <div
              className="flex-col center-a center-b"
              style={{height: '50vh'}}>
              <span style={{paddingTop: '10em', justifyContent: 'center'}}>
                <CircularProgress size={100} />
                <p> Salvando dados... </p>
              </span>
            </div>
          )}
        </div>
      </div>
    </Dashboard>
  );
};

export default ListWaitingPage;
