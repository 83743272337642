import React from 'react';
import {
  Avatar,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Divider,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import api from '../core/api';
import Confirm from './Confirm';
import {useSnackbar} from 'notistack';
import {useAppContext} from './singletons/AppContext';
const adminGestor = JSON.parse(localStorage.getItem('adminGestor'));

const ListWaitingItem = ({user, history, fetchUsers}) => {
  const {enqueueSnackbar} = useSnackbar();
  const {onReloadData} = useAppContext();

  const onDeleteUser = async () => {
    const res = await Confirm(
      'Deseja realmente remover esta Categoria Special?',
    );
    if (res) {
      try {
        await api.delete(`/waitinglist/${user.id}`);
        fetchUsers();
        onReloadData();
        enqueueSnackbar('Usuário deletado da lista de espera!');
      } catch (e) {
        console.log(e);
      }
    }
  };

  const initialLetter = user.name || '';

  return (
    <>
      <TableRow>
        <TableCell>
          <Avatar alt="Remy Sharp" style={{textTransform: 'uppercase'}}>
            {initialLetter[0]}
          </Avatar>
        </TableCell>

        <TableCell>
          <Typography variant="body2" color="textPrimary">
            {user.name}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2" color="textPrimary">
            {user.email}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2" color="textPrimary">
            {user.cpf}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2" color="textPrimary">
            {user?.empresa?.nomeFantasia}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2" color="textPrimary">
            {user?.special?.nome}
          </Typography>
        </TableCell>

        {adminGestor && (
          <TableCell>
            <Typography variant="body2" color="textPrimary">
              {user.onboarding ? 'Sim' : 'Não'}
            </Typography>
          </TableCell>
        )}
        <TableCell>
          <IconButton onClick={onDeleteUser}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ListWaitingItem;
