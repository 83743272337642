import React from 'react';
import {
  TextField,
  withStyles,
  FormControl,
  FormHelperText,
  InputAdornment,
  FormLabel,
} from '@material-ui/core';
import '../../layout/css/InputStyle.css';
import AnimatedView from '../AnimatedView';
import MaskedInput from 'react-text-mask';

const StyledInput = withStyles({
  borderRadius: '40px',
  height: 50,
  border: 'solid 1px #d3d3d3',
  padding: '0 20px',
})(MaskedInput);

const InputMask = ({label, errorMessage, leftIcon, rigthElement, ...props}) => (
  <FormControl fullWidth error={errorMessage}>
    <AnimatedView>
      <FormLabel>{label}</FormLabel>
    </AnimatedView>

    <StyledInput
      style={{
        borderRadius: '40px',
        height: 50,
        border: 'solid 1px #d3d3d3',
        padding: '0 20px',
        marginTop: 10,
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholder={label}
      autoComplete={`${label.includes('E-mail') ? 'email' : 'off'}`}
      {...props}
    />

    {errorMessage && (
      <FormHelperText id="component-error-text">{errorMessage}</FormHelperText>
    )}
  </FormControl>
);

export default InputMask;
