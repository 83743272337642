import React, {useEffect, useState} from 'react';
import NotificationIcon from '@material-ui/icons/Notifications';
import api from '../core/api';
import {useAppContext} from '../components/singletons/AppContext';
import PeopleAltIcon from '@material-ui/icons/People';

const notyIconStyle = {
  position: 'relative',
  display: 'inline',
};
const notyNumStyle = {
  position: 'absolute',
  right: '0',
  backgroundColor: '#c0392b',
  fontSize: '7px',
  color: 'white',
  display: 'inline',
  padding: '3px 5px',
  borderRadius: '20px',
};
export default function Noty({width, color, count}) {
  const [candidates, setCandidates] = React.useState([]);
  const [state, setState] = useState({
    loading: true,
  });

  const {loading, setAppState} = useAppContext();

  const getCandidates = async () => {
    setState({
      loading: true,
    });
    try {
      const {data: partners} = await api.get(`/partners`);
      const candidates = partners.filter(
        partner => partner.candidate_aproved !== true,
      );
      setCandidates(candidates);
      setState({
        loading: false,
      });
    } catch (e) {
      console.log('Ocorreu um erro');
    }
  };

  useEffect(() => {
    getCandidates();
  }, []);

  return (
    <div>
      <div style={notyIconStyle}>
        {count > 0 ? <div style={notyNumStyle}>{count}</div> : null}

        <svg
          viewBox="0 0 24 24"
          className="r-hkyrab r-4qtqp9 r-yyyyoo r-lwhw9o r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
          width={width}
          fill={color}>
          <g>
            {' '}
            <PeopleAltIcon />
          </g>
        </svg>
      </div>
    </div>
  );
}
