import React from 'react'
import Dashboard from '../../layout/Dashboard'
import SubCategoryForm from '../../components/forms/SubCategoryForm'

function SubCategoryAdd({ match }) {
	return (
		<Dashboard>
			<SubCategoryForm />
		</Dashboard>
	)
}

export default SubCategoryAdd
