import api from '../core/api';

const findAll = async () => {
  const result = await api.get('/empresas');
  const empresas = result.data;
  // console.log(Empresas);
  // const sortedEmpresas = empresas.sort((a, b) => {
  //   if (a.nomeFantasia > b.nomeFantasia) {
  //     return 1;
  //   }
  //   if (a.nomeFantasia < b.nomeFantasia) {
  //     return -1;
  //   }
  //   return 0;
  // });
  return empresas;
};

export default {findAll};
