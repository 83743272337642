/* eslint-disable */
import React from 'react';
import InputText from './InputText';
import {
  FormControl,
  Checkbox,
  Grid,
  FormLabel,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import request from 'superagent';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AnimatedView from '../AnimatedView';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const animate = {
  fadeIn(iterations = 1) {
    const keyframes = [
      {opacity: '0', offset: 0},
      {opacity: '1', offset: 1},
    ];
    const timing = {duration: 400, iterations};
    return {keyframes, timing};
  },
};

const getviaCepUrl = (postal_code = '', handleFormikValues) => {
  if (postal_code.length === 8) {
    let next_address = request.get(
      `https://viacep.com.br/ws/${postal_code}/json`,
    );
    next_address.end((err, response) => {
      if (err) {
        console.log(err);
      } else {
        const {logradouro, cep, localidade, uf} = response.body;
        handleFormikValues.setFieldValue('postal_code', cep);
        handleFormikValues.setFieldValue('street', `${logradouro}`);
        handleFormikValues.setFieldValue('state', localidade);
        handleFormikValues.setFieldValue('city', uf);
      }
    });
  }
};

const RenderInput = ({
  inputs = [],
  formikProps,
  isPresentationVideo = false,
}) => {
  return inputs.map((input, i) => {
    switch (input.type) {
      case 'text':
        return isPresentationVideo && input.name !== 'author' ? null : (
          <InputText
            key={`#${i}`}
            name={input.name}
            label={input.label}
            type={input.type}
            leftIcon={input.leftIcon}
            value={formikProps.values[input.name]}
            errorMessage={formikProps.errors[input.name]}
            onChange={e => {
              formikProps.errors[input.name] = null;
              formikProps.setFieldValue(`${input.name}`, e.target.value);
            }}
            rigthElement={
              <React.Fragment>
                {input.name.match(/email|url_callback/) && (
                  <Grid container direction="row" alignItems="center" xs={12}>
                    <Grid item xs={6}>
                      {formikProps.values[`${input.show}`] ? (
                        <VisibilityIcon
                          color="primary"
                          fontSize="default"
                          style={{paddingTop: 5}}
                        />
                      ) : (
                        <VisibilityOffIcon
                          fontSize="default"
                          style={{paddingTop: 5}}
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={formikProps.values[`${input.show}`]}
                        onChange={e =>
                          formikProps.setFieldValue(
                            `${input.show}`,
                            e.target.checked,
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            }
          />
        );
      case 'radio':
        return (
          <Grid xs={12} container>
            <FormControl
              component="fieldset"
              key={`#${i}`}
              fullWidth
              error={
                formikProps.touched[input.name] &&
                formikProps.errors[input.name]
              }>
              {formikProps.values[input.name].type ? (
                <AnimatedView>
                  <FormLabel component="legend">
                    Escolha um tipo da Máteria
                  </FormLabel>
                </AnimatedView>
              ) : (
                <span style={{heigth: 20}}>&nbsp;</span>
              )}
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={formikProps.values[input.name]}
                onChange={e =>
                  formikProps.setFieldValue(`${input.name}`, e.target.value)
                }>
                {input.values.map((label, i) => (
                  <FormControlLabel
                    value={label}
                    control={<Radio />}
                    label={label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        );

      case 'radio-horizontal':
        return (
          <Grid xs={12} container>
            <FormControl
              component="fieldset"
              key={`#${i}`}
              fullWidth
              error={
                formikProps.touched[input.name] &&
                formikProps.errors[input.name]
              }>
              <FormLabel style={{marginTop: 25, marginBottom: 15}}>
                Alinhamento da chamada
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                row
                value={formikProps.values[input.name]}
                onChange={e => {
                  formikProps.setFieldValue(`${input.name}`, e.target.value);
                }}>
                {input.values.map(({label, value}, i) => (
                  <FormControlLabel
                    value={value}
                    control={<Radio />}
                    label={label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        );

      case 'ckeditor':
        return (
          <div>
            <CKEditor
              editor={ClassicEditor}
              data={formikProps.values[input.name]}
              onInit={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                formikProps.setFieldValue(`${input.name}`, data);
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
          </div>
        );
      case 'number':
        return (
          <InputText
            key={`#${i}`}
            label={input.label}
            value={formikProps.values[input.name]}
            onChange={e => {
              const value = (e.target.value.toString().match(/\d/g) || []).join(
                '',
              );

              if (input.name === 'postal_code') {
                getviaCepUrl(value, formikProps);
              }
              formikProps.setFieldValue(`${input.name}`, value);
            }}
            errorMessage={
              formikProps.touched[input.name] && formikProps.errors[input.name]
            }
            rigthElement={
              <React.Fragment>
                {input.name.match(/phone_first|phone_second/) && (
                  <Grid container direction="row" alignItems="center" xs={12}>
                    <Grid item xs={6}>
                      {formikProps.values[`show_${input.name}`] ? (
                        <VisibilityIcon
                          color="primary"
                          fontSize="default"
                          style={{paddingTop: 5}}
                        />
                      ) : (
                        <VisibilityOffIcon
                          fontSize="default"
                          style={{paddingTop: 5}}
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={formikProps.values[`show_${input.name}`]}
                        onChange={e =>
                          formikProps.setFieldValue(
                            `show_${input.name}`,
                            e.target.checked,
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            }
          />
        );
      case 'description':
        return isPresentationVideo ? null : (
          <InputText
            key={`#${i}`}
            id="description"
            name={input.name}
            label={input.label}
            type={input.type}
            value={formikProps.values[input.name]}
            errorMessage={
              formikProps.touched[input.name] && formikProps.errors[input.name]
            }
            onChange={e => {
              formikProps.errors[input.name] = null;
              formikProps.setFieldValue(`${input.name}`, e.target.value);
            }}
            multiline
            rows="5"
          />
        );
      case 'select':
        return (
          <Grid container xs={12} spacing={2}>
            <Grid xs={4} item>
              <FormControl
                key={`#${i}`}
                fullWidth
                error={
                  formikProps.touched[input.name] &&
                  formikProps.errors[input.name]
                }>
                {formikProps.values[input.name].type ? (
                  <AnimatedView>
                    <FormLabel>Tipo</FormLabel>
                  </AnimatedView>
                ) : (
                  <span style={{heigth: 20}}>&nbsp;</span>
                )}
                <Select
                  style={{
                    borderRadius: '40px',
                    height: 50,
                    border: 'solid 1px #d3d3d3',
                    padding: '0 20px',
                  }}
                  placeholder="ABC"
                  fullWidth
                  onChange={e =>
                    formikProps.setFieldValue(`${input.name}`, {
                      number: formikProps.values[input.name].number,
                      type: e.target.value,
                    })
                  }
                  label={'Documento Geral'}
                  value={formikProps.values[input.name].type}>
                  {input.values.map((label, i) => (
                    <MenuItem key={`#${i}`} value={label}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={8} item>
              <InputText
                key={`#${i}`}
                label="Número"
                value={formikProps.values[input.name].number}
                errorMessage={
                  formikProps.touched[input.name] &&
                  formikProps.errors[input.name]
                }
                onChange={e =>
                  formikProps.setFieldValue(`${input.name}`, {
                    type: formikProps.values[input.name].type,
                    number: (e.target.value.toString().match(/\d/g) || []).join(
                      '',
                    ),
                  })
                }
              />
            </Grid>
          </Grid>
        );
      case 'banner':
        return (
          <Grid container xs={9} spacing={2} style={{marginBottom: '50px'}}>
            <Grid xs={4} item>
              <FormControl
                key={`#${i}`}
                fullWidth
                error={
                  formikProps.touched[input.name] &&
                  formikProps.errors[input.name]
                }>
                {formikProps.values[input.name].screen ? (
                  <AnimatedView>
                    <FormLabel>{input.label}</FormLabel>
                  </AnimatedView>
                ) : (
                  <span style={{heigth: 20}}>&nbsp;</span>
                )}
                <Select
                  style={{
                    borderRadius: '40px',
                    height: 50,
                    border: 'solid 1px #d3d3d3',
                    padding: '0 20px',
                  }}
                  fullWidth
                  onChange={e =>
                    formikProps.setFieldValue(`${input.name}`, {
                      id: formikProps.values[input.name].id,
                      screen: e.target.value,
                    })
                  }
                  label={'Tela'}
                  value={formikProps.values[input.name].screen}>
                  {input.values.map((label, i) => (
                    <MenuItem key={`#${i}`} value={label}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid xs={8} item>
							<InputText
								key={`#${i}`}
								label="Id da Tela"
								value={formikProps.values[input.name].id}
								errorMessage={formikProps.touched[input.name] && formikProps.errors[input.name]}
								onChange={(e) =>
									formikProps.setFieldValue(`${input.name}`, {
										screen: formikProps.values[input.name].screen,
										id: (e.target.value.toString().match(/\d/g) || []).join('')
									})}
							/>
						</Grid> */}
          </Grid>
        );

      case 'checkbox':
        return (
          <FormControl
            key={`#${i}`}
            fullWidth
            error={
              formikProps.touched[input.name] && formikProps.errors[input.name]
            }>
            <FormControlLabel
              control={<Checkbox />}
              checked={formikProps.values[input.name]}
              onChange={e =>
                formikProps.setFieldValue(`${input.name}`, e.target.checked)
              }
              label={input.label}
            />
          </FormControl>
        );
      case 'social':
        return (
          <InputText
            key={`#${i}`}
            name={input.name}
            label={input.label}
            type={input.type}
            value={formikProps.values[input.name]}
            errorMessage={
              formikProps.touched[input.name] && formikProps.errors[input.name]
            }
            onChange={e =>
              formikProps.setFieldValue(`${input.name}`, e.target.value)
            }
            leftIcon={input.leftIcon}
            rigthElement={
              <Grid container direction="row" alignItems="center" xs={12}>
                <Grid item xs={6}>
                  {formikProps.values[`show_${input.name}`] ? (
                    <VisibilityIcon
                      color="primary"
                      fontSize="default"
                      style={{paddingTop: 5}}
                    />
                  ) : (
                    <VisibilityOffIcon
                      fontSize="default"
                      style={{paddingTop: 5}}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={formikProps.values[`show_${input.name}`]}
                    onChange={e =>
                      formikProps.setFieldValue(
                        `show_${input.name}`,
                        e.target.checked,
                      )
                    }
                    // label="Mostrar"
                  />
                </Grid>
              </Grid>
            }
          />
        );
      case 'time':
        return isPresentationVideo ? null : (
          <Grid container xs={12}>
            <Grid xs={6} item style={{paddingRight: 10}}>
              <FormControl
                key={`#${i}`}
                fullWidth
                error={
                  formikProps.touched[input.name] &&
                  formikProps.errors[input.name]
                }>
                {formikProps.values[input.name].minutes ? (
                  <AnimatedView>
                    <FormLabel>Minutos</FormLabel>
                  </AnimatedView>
                ) : (
                  <div id="box">&nbsp;</div>
                )}
                <Select
                  style={{
                    borderRadius: '40px',
                    height: 50,
                    border: 'solid 1px #d3d3d3',
                    padding: '0 20px',
                  }}
                  fullWidth
                  onChange={e =>
                    formikProps.setFieldValue(`${input.name}`, {
                      seconds: formikProps.values[input.name].seconds,
                      minutes: e.target.value,
                    })
                  }
                  label={'Minutos'}
                  value={formikProps.values[input.name].minutes}>
                  {input.values.minutes.map((label, i) => (
                    <MenuItem key={`#${i}`} value={label}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={6} item style={{paddingLeft: 10}}>
              <FormControl
                key={`#${i}`}
                fullWidth
                error={
                  formikProps.touched[input.name] &&
                  formikProps.errors[input.name]
                }>
                {formikProps.values[input.name].seconds ? (
                  <AnimatedView
                    keyframes={animate.fadeIn().keyframes}
                    timing={animate.fadeIn().timing}
                    id="box">
                    <FormLabel>Segundos</FormLabel>
                  </AnimatedView>
                ) : (
                  <div id="box">&nbsp;</div>
                )}
                <Select
                  style={{
                    borderRadius: '40px',
                    height: 50,
                    border: 'solid 1px #d3d3d3',
                    padding: '0 20px',
                  }}
                  fullWidth
                  onChange={e =>
                    formikProps.setFieldValue(`${input.name}`, {
                      minutes: formikProps.values[input.name].minutes,
                      seconds: e.target.value,
                    })
                  }
                  label={'Segundos'}
                  value={formikProps.values[input.name].seconds}>
                  {input.values.seconds.map((label, i) => (
                    <MenuItem key={`#${i}`} value={label}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );

      default:
        return '';
    }
  });
};

export default RenderInput;
