import React, {useState} from 'react';
import Dashboard from '../../../layout/Dashboard';
import {Grid, List} from '@material-ui/core';
import VideoListItem from '../../../components/VideoListItem';
import {withRouter} from 'react-router-dom';
import VideosContainer from '../../../components/containers/VideosContainer';
import {useAppContext} from '../../../components/singletons/AppContext';
import Confirm from '../../../components/Confirm';
import api from '../../../core/api';
import {useSnackbar} from 'notistack';

import moment from 'moment';
import {RemoveRedEye, Delete} from '@material-ui/icons';
import MaterialTable from 'material-table';
import MomentUTCUtils from '../../../utils/MomentUTCUtils';

import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

const MediasList = ({history}) => {
  const {search, setAppState, onReloadData} = useAppContext();
  const {enqueueSnackbar} = useSnackbar();

  const columns = [
    {
      title: 'Tipo',
      field: 'audio',
      lookup: {true: 'Áudio', false: 'Vídeo'},
    },
    {title: 'Titulo', field: 'title', width: 150},
    {
      title: 'Descrição',
      field: 'description',
      editable: false,
      render: row => {
        let description = row['description'];
        if (description.length > 60) {
          description = description.slice(0, 60);
          description += '...';
        }
        return <span>{description}</span>;
      },
    },
    {title: 'Autor', field: 'partnerName', editable: false},
    {
      title: 'Data publicação',
      type: 'date',
      field: 'publication_date',
      sorter: 'date',
      customSort: (a, b) => {
        return new Date(a.publication_date) - new Date(b.publication_date);
      },
      render: row => {
        return (
          <span>
            {moment.utc(row['publication_date']).format('DD-MM-YYYY')}
          </span>
        );
      },
      editComponent: props => {
        console.log(props);
        console.log(moment.utc(props.value).format());
        return (
          <MuiPickersUtilsProvider
            utils={MomentUTCUtils}
            locale={'pt-br'}
            moment={moment}>
            <DatePicker
              format="DD/MM/YYYY"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              InputProps={{
                style: {
                  fontSize: 13,
                },
              }}
            />
          </MuiPickersUtilsProvider>
        );
      },
    },
    {
      title: 'Premium',
      field: 'premium',
      lookup: {true: 'Sim', false: 'Não'},
    },
    {
      title: 'Promo',
      field: 'promo',
      lookup: {true: 'Sim', false: 'Não'},
    },
  ];

  const onDelete = async id => {
    const res = await Confirm('Deseja realmente excluir este conteúdo');
    if (res) {
      try {
        await api.delete(`/videos/${id}`).then(res => {
          if (res.status === 200) {
            setAppState({loading: false});
            return onReloadData();
          }
        });
      } catch (e) {
        onReloadData();
        console.log('Upload Error');
        console.dir(e);
      }
    }
  };

  const onSave = async (newData, oldData) => {
    try {
      if (
        newData.audio != oldData.audio ||
        newData.title != oldData.title ||
        newData.publication_date != oldData.publication_date ||
        newData.premium != oldData.premium
      ) {
        delete newData.thumbnail;
        await api.put(`/videos/${newData.id}`, newData).then(() => {
          enqueueSnackbar('Conteúdo editado com sucesso!');
        });
      }
      return true;
    } catch (err) {
      enqueueSnackbar('Erro ao editar conteúdo!');
      console.error(err);
      return false;
    }
  };

  return (
    <Dashboard>
      <VideosContainer>
        {({updateData, videos}) => {
          if (videos)
            return (
              <MaterialTable
                title=""
                columns={columns}
                data={videos}
                localization={{
                  header: {
                    actions: '',
                  },
                  pagination: {
                    labelRowsSelect: 'linhas',
                    labelDisplayedRows: '{from}-{to} de {count}',
                    nextTooltip: 'Próxima Página',
                    firstTooltip: 'Primeira Página',
                    lastTooltip: 'Última Página',
                    previousTooltip: 'Página Anterior',
                  },
                  toolbar: {
                    searchPlaceholder: 'Procurar',
                    searchTooltip: 'Procurar',
                  },
                  body: {
                    editTooltip: 'Editar',
                    deleteTooltip: 'Deletar',
                    emptyDataSourceMessage: 'Nenhum registro encontrado',
                    editRow: {
                      saveTooltip: 'Salvar',
                      cancelTooltip: 'Cancelar',
                    },
                  },
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Adicionar conteúdo',
                    onClick: (event, rowData) => {
                      history.push('/uploads?startUpload=true');
                    },
                    isFreeAction: true,
                  },
                  {
                    icon: () => <RemoveRedEye />,
                    tooltip: 'Visualizar',
                    onClick: (event, rowData) => {
                      history.push(`/medias/edit/${rowData.id}`);
                    },
                  },
                  {
                    icon: () => <Delete />,
                    tooltip: 'Deletar',
                    onClick: (event, rowData) => {
                      onDelete(rowData.id);
                    },
                  },
                ]}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      console.log(newData, 'Entrou');
                      setTimeout(async () => {
                        {
                          const result = await onSave(newData, oldData);
                          console.log(result);
                          if (result) {
                            const dataUpdate = [...videos];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            updateData([...dataUpdate]);
                          }
                          result ? resolve() : reject();
                        }
                      }, 1000);
                    }),
                }}
                options={{
                  pageSize: 20,
                  pageSizeOptions: [20, 50, 100],
                }}
              />
            );
        }}
      </VideosContainer>
    </Dashboard>
  );

  /*  */
};

export default withRouter(MediasList);
