import api from '../core/api';

const findUserByPartnerId = async partnerId => {
  const result = await api.get('/users');
  const users = result.data;
  //console.log(users);
  const usersByPartnerId = users.filter(user => user.partner_id === partnerId);
  // console.log(usersByPartnerId);

  return usersByPartnerId;
};

export default {findUserByPartnerId};
