import React from 'react';
import {
  Grid,
  Avatar,
  Typography,
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import api from '../core/api';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {useSnackbar} from 'notistack';
import Confirm from './Confirm';
import {useAppContext} from './singletons/AppContext';

const CampaingItem = ({campaignItem}) => {
  const campaign = {...campaignItem};
  const {enqueueSnackbar} = useSnackbar();
  const {onReloadData} = useAppContext();

  const onDeletecampaign = async () => {
    const res = await Confirm('Deseja realmente remover esta Campanha?');
    if (res)
      try {
        await api.delete(`/campaign/${campaign.id}`);
        onReloadData();
        enqueueSnackbar('Deletada com Sucesso!');
      } catch (e) {
        console.log(e);
      }
  };

  console.log('c', campaign);
  return (
    <React.Fragment>
      <ListItem alignItems="center">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" style={{textTransform: 'uppercase'}}>
            {(campaign.title || '')[0].toUpperCase()}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={campaign.title}
          secondary={
            <Grid container>
              <Grid item xs={5}>
                <ListItemIcon>
                  <Typography
                    variant="body1"
                    className="flex-row center-b"
                    color="textPrimary">
                    <>
                      {campaign.usersType.length > 0 &&
                        campaign.usersType.map(c => (
                          <Typography
                            variant="body2"
                            className="flex-row center-b"
                            color="textPrimary">{` | Plano ${ReturnTypePremium(
                            c.usersType,
                          )} `}</Typography>
                        ))}
                    </>
                  </Typography>
                </ListItemIcon>
              </Grid>
              <Grid item xs={2}>
                <ListItemIcon>
                  <Typography
                    style={{marginLeft: 8}}
                    variant="body2"
                    className="flex-row center-b"
                    color="textPrimary">
                    Status: {campaign.status === 'Active' ? 'Ativo' : 'Inativo'}
                  </Typography>
                </ListItemIcon>
              </Grid>
            </Grid>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            component={Link}
            to={`/campaign/edit/${campaign.id}`}
            edge="end">
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDeletecampaign} edge="end">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
export default CampaingItem;

export function ReturnTypePremium(premium) {
  switch (premium) {
    case 'F':
      return 'Free';
    case 'N':
      return 'New';
    case 'M':
      return 'Mensal';
    case 'Y':
      return 'Anual';
    default:
      return 'Indefinido';
  }
}
