import React, { useState, useEffect } from 'react';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import MusicIcon from '@material-ui/icons/MusicNote';
import CloseIcon from '@material-ui/icons/Close';

import VideoIcon from '@material-ui/icons/PlayArrow';
import EditIcon from '@material-ui/icons/Edit';
import moment  from 'moment';

import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { useAppContext } from './singletons/AppContext';
import AuditCard from './AuditCard';



const useStyles = makeStyles((theme) => ({
  paperRoot: {
    transition: 'width 1s, height 1s, opacity 1s 1s',
    padding: 20,
  },
}));

const AuditListItem = ({ audit, partners, objectives, categories, users, history }) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  
  const { onReloadData, loading, setAppState } = useAppContext();
  const classes = useStyles();
  
  const type = audit.alter_type == 'I' ? 'Criação' : 'Atualização';
  const date = moment(audit.createdAt).format("DD-MM-YYYY HH:mm:ss");

  const handleClose = () => {
    setOpen(false);
  };
  
  //close dialog modal
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(()=>{
    const alterUser = users.find(user => user.id === audit.user_id);
    setUser(alterUser);
  },[])

  return (
    <React.Fragment>
      <ListItem
        alignItems='center'
        button
        onClick={handleOpen}
      >
        <ListItemAvatar>
          <Avatar>{audit.audio_new ? <MusicIcon /> : <VideoIcon />}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Typography component='h2'>{audit.title_new}</Typography>}
          secondary={
            <Typography
              component='h4'
              variant='body2'
              color='textSecondary'
              style={{ maxWidth: '90%' }}
            >
              Usuário: {user.name} | 
              Data de {type}: {date}
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            onClick={handleOpen}
            edge='end'
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      <Dialog
        PaperProps={{
          classes: {
            root: classes.paperRoot,
          },
        }}
        onClose={handleClose}
        aria-labelledby='simple-dialog-title'
        open={open}
      >
        <DialogTitle id='simple-dialog-title'>
          <div
            className='flex-row center-b'
            style={{ justifyContent: 'space-between' }}
          >
            <Typography variant="h4">Auditoria de {type}</Typography>

            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
          <AuditCard 
            audit={audit} 
            partners={partners}
            categories={categories} 
            objectives={objectives}
            users={users}
            date={date}
          />
      </Dialog>
    </React.Fragment>
  );
};

export default AuditListItem;
