import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from '../core/api';
import useReduxState from '../core/useReduxState';
import { useAppContext } from '../components/singletons/AppContext';
import { withRouter } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { string, object } from 'yup'; // for only what you need
import { Formik } from 'formik';
import InputText from '../components/forms/InputText';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import ButtonMedita from '../components/ButtonMedita';

import authService from '../services/auth-service';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'. Built with '}
      <Link color="inherit" href="https://material-ui.com/">
        Material-UI.
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(4, 0, 2), // validation
  },
}));

const Login = withRouter(({ history }) => {
  const { setAppState } = useAppContext();
  const [getForm, setForm, updateFormField] = useReduxState({
    email: '',
    password: '',
    loading: false,
  });
  const [getError, setErrorInput] = useReduxState({
    email: false,
    password: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = variant => () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar('This is a warning message!', variant);
  };

  async function authenticate(form) {
    setForm({ loading: true });
    try {
      await authService.authenticate(form);
      const response = await api.get('/partners/');
      setAppState({
        partners: response.data
      })
      history.push('/');
    } catch (e) {
      enqueueSnackbar('Email ou Senha Inválidos');
      setForm({ loading: false });
    }
  }

  const classes = useStyles();

  const validationSchema = () =>
    object().shape({
      email: string()
        .email('Digite um e-mail válido')
        .required('Preencha o campo de e-mail'),
      password: string().required('Preencha o campo de senha'),
    });

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={values => {
        authenticate(values);
      }}>
      {formikProps => {
        return (
          <div
            className="container-login-wallpaper"
            style={{
              position: 'absolute',
              height: '100vh',
              width: '100vw',
              top: 0,
              left: 0,
            }}>
            <Container
              component="main"
              maxWidth="xs"
              style={{
                backgroundColor: '#f8f8f8',
                borderRadius: '20px',
                boxShadow: '14px 9px 18px -9px rgba(0,0,0,0.75)',
              }}>
              <div className={classes.paper}>
                <img
                  style={{ paddingTop: 20 }}
                  src={require('../assets/images/medita_logo_horizontal.png')}
                  width="200px"
                />

                <Grid
                  container
                  style={{
                    paddingTop: 10,
                  }}>
                  <form onSubmit={formikProps.handleSubmit}>
                    <InputText
                      leftIcon={<EmailIcon />}
                      errorMessage={
                        formikProps.touched.email && formikProps.errors.email
                      }
                      id="email"
                      label="Seu Email"
                      name="email"
                      value={formikProps.values.email}
                      autoComplete="email"
                      onChange={e =>
                        formikProps.setFieldValue('email', e.target.value)
                      }
                      autoFocus
                    />
                    <InputText
                      leftIcon={<LockIcon />}
                      errorMessage={
                        formikProps.touched.password &&
                        formikProps.errors.password
                      }
                      name="password"
                      label="Sua Senha"
                      type="password"
                      value={formikProps.values.password}
                      id="password"
                      autoComplete="current-password"
                      onChange={e =>
                        formikProps.setFieldValue('password', e.target.value)
                      }
                    />
                    <ButtonMedita
                      disabled={getForm().loading}
                      type="submit"
                      className={classes.submit}>
                      Entrar
                    </ButtonMedita>
                  </form>
                </Grid>
                {/* <FormControlLabel
										control={
											<Checkbox
												value="remember"
												color="primary"
											/>
										}
										label="Lembrar me"
									/> */}
                {/*<Grid container className={classes.submit}>
									<Link href="/forgot" variant="body2">
										Esqueceu sua senha?
									</Link>
								</Grid> */}
              </div>
              {/* <Box mt={8}>
								<Copyright />
							</Box> */}
            </Container>
          </div>
        );
      }}
    </Formik>
  );
});

export default Login;
