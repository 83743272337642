import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    height: '100%',
  }

}));

export default useStyles;
