import React from 'react';
import {
  Grid,
  IconButton
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useAppContext } from './singletons/AppContext';

const AddNewUser = ({ }) => {

  const { history } = useAppContext();
  
  return (
        <Grid item style={{ paddingTop: 20 }}>
          {!window.location.pathname.match(/candidates/) && (
            <IconButton
              onClick={() =>
                history.push(
                  window.location.pathname.includes('medias')
                    ? '/uploads?startUpload=true'  
                    : `${window.location.pathname}/add`
                )
              }
            >
            <AddIcon />
            </IconButton>
          )}
        </Grid>
  );
};

export default AddNewUser;
