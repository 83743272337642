import React from 'react';
import Dashboard from '../../layout/Dashboard';
import {Grid, Button} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CampaingContainer from '../../components/containers/CampaingContainer';
import CampaingItem from '../../components/CampaingItem';

const CampaignsPage = ({history}) => {
  return (
    <Dashboard>
      <Grid container justify="flex-end">
        {!window.location.pathname.match(/candidates/) && (
          <Button
            sx={{borderRadius: 10}}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => history.push(`${window.location.pathname}/add`)}>
            Nova Campanha
          </Button>
        )}
      </Grid>
      <CampaingContainer>
        {campaign =>
          campaign.map(c => {
            return <CampaingItem history={history} campaignItem={c} />;
          })
        }
      </CampaingContainer>
    </Dashboard>
  );
};

export default CampaignsPage;
