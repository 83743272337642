import React from 'react';
import ReactDOM from 'react-dom';
import {
  DialogContentText,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core';

const DIALOG_CONTAINER_ID = `dialog-${parseInt(new Date())}`;

const Confirm = (message = '', dialogProps = {}) =>
  new Promise(resolve => {
    let react_app_root = document.querySelector('#' + DIALOG_CONTAINER_ID);

    if (!react_app_root) {
      react_app_root = document.createElement('div');
      react_app_root.setAttribute('id', DIALOG_CONTAINER_ID);
      document.body.appendChild(react_app_root);
    }

    class DialogInstance extends React.Component {
      state = {open: true};

      render() {
        const beforeResolve = res => {
          ReactDOM.unmountComponentAtNode(react_app_root);
          resolve(res);
        };
        return (
          <Dialog
            {...dialogProps}
            onClose={() => beforeResolve(false)}
            open={this.state.open}>
            <DialogTitle id="simple-dialog-title" style={{textAlign: 'center'}}>
              Mensagem de Confirmação
            </DialogTitle>
            <DialogContent>
              <DialogContentText align="center">{message}</DialogContentText>
            </DialogContent>
            <DialogActions style={{justifyContent: 'space-between'}}>
              <Button
                style={{borderRadius: '8px'}}
                autoFocus
                onClick={() => beforeResolve(false)}
                color="secondary">
                Cancelar
              </Button>
              {/* <Button
								style={{ backgroundColor: '#61b7c3', borderRadius: '8px', color: '#fff' }}
								onClick={() => beforeResolve({reset: true})}
								// color="primary"
							>
								Enviar nova senha
							</Button> */}
              <Button
                style={{
                  backgroundColor: '#61b7c3',
                  borderRadius: '8px',
                  color: '#fff',
                }}
                onClick={() => beforeResolve(true)}
                // color="primary"
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        );
      }
    }

    ReactDOM.render(<DialogInstance />, react_app_root);
  });

export default Confirm;
