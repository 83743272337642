import React, { useState, useEffect } from "react"
import Dashboard from "../../layout/Dashboard"
import BannerForm from "../../components/forms/BannerForm"
import useReduxState from "../../core/useReduxState"
import FileContext from "../../components/singletons/FileContext"
import api from "../../core/api"

function BannerEdit({ match, history }) {
	const [getFileState, setFileState] = useReduxState({
		src: null,
		crop: { unit: "px", width: 100, aspect: 320 / 120 },
		imageRef: null,
		croppedImageUrl: "",
		blobFile: ""
	})
	const [state, setState] = useState({})

	const fetchBanner = async () => {
		try {
			await api.get(`/banners/${match.params.id}`).then(res => {
				const { data: banner } = res
				setState({
					...banner,
					checkbox: banner.publication_date ? true : false
				})				
			})
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		fetchBanner()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<FileContext.Provider value={{ ...getFileState(), setFileState }}>
			<Dashboard>
				<BannerForm banner={state} history={history} />
			</Dashboard>
		</FileContext.Provider>
	)
}

export default BannerEdit
