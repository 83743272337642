import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((styles) => ({
  uploadsScreen: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  uploadBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fileInput: {
    display: 'none',
  },
  loadingArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  warningText: {
    color: '#B00020',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 20,
    padding: 5,
    marginLeft: '10vw',
  }
}));

export default useStyles;
