/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {useFileContext} from '../singletons/FileContext';
import {useSnackbar} from 'notistack';
import {toDataURL} from '../../core/toDataURL';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  Grid,
  Avatar,
} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import Dropzone from 'react-dropzone';
import ImageCroper from '../ImageCroper';
import ReturnButton from '../ReturnButton';
import {string, object, boolean} from 'yup'; // for only what you need
import ButtonSubmit from './ButtonSubmit';
import RenderInput from './RenderInput';
import InputText from './InputText';
import {Formik} from 'formik';
import api from '../../core/api';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import {ModalEditThumbnail} from './ModalEditMidia';
import {FormControl, withStyles} from '@material-ui/core';
import AnimatedView from '../AnimatedView';
import {putThumbnailUpload} from '../../core/formHelpers';
import {v4} from 'uuid';

const useStyles = makeStyles(() => ({
  avatar: {
    height: '40vh',
    width: '50vw',
    borderRadius: '20px',
    backgroundColor: '#61b7c3',
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  contentMidia: {
    height: 200,
    width: 200,
    backgroundColor: '#61b7c3',
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  iconButton: {
    paddingTop: 0,

    borderRadius: '20px',
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  imageInput: {
    border: '3px dashed #000000a1',
    borderRadius: '0.5em',
    padding: '10px',
  },
  label: {
    padding: '15px 0',
  },
  previewTitle: {
    marginTop: '5vh',
    marginBottom: '5vh',
  },
  previewAvatar: {
    height: '120px',
    width: '320px',
    borderRadius: '20px',
    backgroundColor: '#61b7c3',
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
}));

const StyledFormControl = withStyles({
  root: {
    borderRadius: '40px',
    height: 50,
    border: 'solid 1px #d3d3d3',
    padding: '0 20px',
    width: '100%',
  },
})(FormControl);

const BannerForm = ({banner = {}, history}) => {
  const classes = useStyles();

  const editing = !!banner.id;
  const {src, crop, blobFile, croppedImageUrl, setFileState} = useFileContext();
  const [open, setOpen] = useState('');
  const [error, setError] = useState(false);

  const {enqueueSnackbar} = useSnackbar();

  const handleClickOpen = view => {
    setOpen(view);
  };
  const handleClose = () => {
    setOpen('');
  };

  const onSaveThumbnail = async () => {
    try {
      if (blobFile) {
        const reader = new FileReader();
        await reader.addEventListener('load', () =>
          setFileState({src: reader.result}),
        );
        reader.readAsDataURL(blobFile);
      }
    } catch (e) {
      enqueueSnackbar('Erro ao carregar imagem.');
    }
    setOpen(false);
  };

  const onSubmit = async (values = {}) => {
    try {
      // alert(editing)
      const form = {
        ...values,
        link: JSON.stringify(values.link),
        publication_date: values.publication_date
          ? Date.parse(values.publication_date)
          : null,
      };

      if (editing) {
        if (
          !(typeof values.thumbnail === 'string') &&
          values.thumbnail.name &&
          values.thumbnail.name.toLowerCase().match(/.png|.jpg|.jpeg|.svg/)
        ) {
          const formData = new FormData();

          formData.append('thumbnail', values.thumbnail);

          await api
            .post(`/banners/edit-thumbnail/${banner.id}`, formData, {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(async response => {
              console.log(response);
            })
            .catch(function(error) {
              console.log('err', error);
            });
        }

        delete form.thumbnail;
        await api.put(`/banners/${banner.id}`, form);
        enqueueSnackbar('Dados Alterados com Sucesso!');
      } else {
        const rndString = v4().replace(/-/g, '');
        form.thumbnail = `upload_${rndString}`;
        await api.post(`/banners`, form).then(async res => {
          if (res.status === 200) {
            await putThumbnailUpload(
              values.thumbnail,
              'banners',
              form.thumbnail,
            );
            enqueueSnackbar('Banner salvo com sucesso!');
          }
        });
      }
      history.push('/banners');
    } catch (e) {
      console.log({e});
      if (e.response.status === 413) {
        enqueueSnackbar(
          'Imagem muito grande, por favor diminua a resolução para 320pixels de largura.',
          {
            autoHideDuration: 8000,
          },
        );
      }
      if (e.response.status !== 413) {
        enqueueSnackbar('Error ao criar banner, por favor tente novamente.');
      }
      enqueueSnackbar('Ocorreu um erro de conexão, tente novamente!');
      return 'error';
    }
  };

  const bannerInputs = [
    {
      name: 'link',
      label: 'Tela de Exibição',
      type: 'banner',
      values: ['HOME'],
    },
    {name: 'description', label: 'callLabel', type: 'ckeditor'},
    {
      name: 'align',
      label: 'Alinhamento da chamada',
      type: 'radio-horizontal',

      values: [
        {label: 'Esquerda', value: 'left'},
        {label: 'Direita', value: 'right'},
        {label: 'Centralizado', value: 'center'},
      ],
    },
  ];

  const validationSchema = () =>
    object().shape({
      title: string().required('Preencha o campo, Título do banner'),
      //link: object().required('Preencha o campo'),
      thumbnail: string().required('Preencha o campo, Imagem'),
      // description: string().required('Preencha o campo, Descrição'),
    });

  const defaultValues = editing
    ? {
        title: banner.title,
        link: JSON.parse(banner.link),
        thumbnail: banner.thumbnail,
        description: banner.description,
        publication_date: banner.publication_date
          ? new Date(Number(banner.publication_date))
          : null,
        align: banner.align || 'left',
      }
    : {
        title: '',
        link: {screen: 'HOME', id: ''},
        thumbnail: '',
        description: '',
        align: 'left',
      };

  useEffect(() => {
    if (
      editing &&
      defaultValues.thumbnail &&
      defaultValues.thumbnail.includes('https')
    ) {
      toDataURL(
        `${defaultValues.thumbnail}`,
        function(dataUrl) {
          //   console.log('RESULT:', dataUrl);
          setFileState({src: dataUrl});
        },
        'image/jpeg',
      );
    } else {
      setFileState({src: ''});
    }
  }, [defaultValues.thumbnail]);

  const validateForm = values => {
    const errors = {};
    let errorMessage = '';

    if (!values.thumbnail.length && !values.thumbnail.name) {
      errors.thumbnail = 'Imagem inválida';
      errorMessage += 'Imagem\n';
    }

    if (!values.title) {
      errors.title = 'Título inválido';
      errorMessage += 'Título\n';
    }

    if (Object.keys(errors).length) {
      enqueueSnackbar(
        'Formulário incompleto, campos a serem preenchidos:\n' + errorMessage,
        {
          preventDuplicate: true,
          variant: 'error',
          style: {whiteSpace: 'pre-line'},
        },
      );
    }

    return errors;
  };

  return (
    <Formik
      initialValues={defaultValues}
      enableReinitialize
      validate={validateForm}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        onSubmit(values).then(
          err => err && err.includes('error') && setError(true),
        );
      }}>
      {formikProps => (
        <div>
          <div className="flex-row center-b">
            <ModalEditThumbnail
              notEditabled
              formikProps={formikProps}
              handleClose={handleClose}
              open={open}
            />
            <ReturnButton />
            <Typography component="h1" variant="h5">
              {!editing ? 'Adicionar Banner' : 'Editar Banner'}
            </Typography>
          </div>
          <Grid container xs={12}>
            <Grid item xs={12} style={{padding: '20px'}}>
              <div className="flex-col center-a center-b">
                <IconButton
                  className={classes.iconButton}
                  onClick={() => handleClickOpen('thumbnail')}>
                  <Avatar
                    className={classes.avatar}
                    src={croppedImageUrl || src || null}>
                    {!croppedImageUrl && !src && (
                      <AddAPhotoIcon
                        style={{fontSize: 60}}
                        // fontSize="large"
                      />
                    )}
                  </Avatar>
                </IconButton>

                <p
                  style={{
                    color:
                      formikProps.touched.thumbnail &&
                      formikProps.errors.thumbnail &&
                      'red',
                  }}>
                  Selecione uma imagem para o Banner{' '}
                  <strong>(320 x 120)</strong>
                </p>
              </div>

              {src && (
                <Grid xs={12} container justify="center">
                  <Button onClick={() => handleClickOpen('thumbnail')}>
                    Editar Imagem
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid xs={12}>
              <form
                style={{padding: '20px'}}
                onSubmit={formikProps.handleSubmit}>
                <Typography
                  // className={classes.label}
                  component="h5"
                  variant="h5">
                  Dados Banner
                </Typography>
                <Grid container xs={12} justify={'space-between'}>
                  <Grid item xs={8}>
                    <InputText
                      name={'title'}
                      label={'Nome do Banner'}
                      type={'text'}
                      value={formikProps.values['title']}
                      errorMessage={
                        formikProps.touched['title'] &&
                        formikProps.errors['title']
                      }
                      onChange={e =>
                        formikProps.setFieldValue('title', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid container xs={3} alignItems="flex-end">
                    <StyledFormControl
                      key={'datePicker'}
                      error={
                        formikProps.touched['publication_date'] &&
                        formikProps.errors['publication_date']
                      }>
                      <KeyboardDatePicker
                        clearable
                        value={formikProps.values['publication_date'] || null}
                        placeholder="Data de Publicação"
                        minDateMessage=""
                        onChange={date =>
                          formikProps.setFieldValue('publication_date', date)
                        }
                        minDate={new Date()}
                        format="DD/MM/YYYY"
                      />
                    </StyledFormControl>
                  </Grid>
                </Grid>
                <RenderInput inputs={bannerInputs} formikProps={formikProps} />
                {/* <Typography component="h5" variant="h5" className={classes.previewTitle}>
									Pré-Visualização
								</Typography>
								<Grid alignItems="center" >
									<div className="flex-col center-a center-b">
										<Avatar className={classes.previewAvatar} src={croppedImageUrl || src || null}>
												{!croppedImageUrl &&
												!src && (
													<AddAPhotoIcon
														style={{ fontSize: 60 }}
														// fontSize="large"
													/>
												)}
										</Avatar>
									</div>
								</Grid> */}
                <Grid alignItems="center">
                  <ButtonSubmit
                    disabled={formikProps.isSubmitting && !error}
                    style={{marginTop: 20}}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary">
                    Salvar
                  </ButtonSubmit>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </div>
      )}
    </Formik>
  );
};

export default BannerForm;
