import React, {useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import Grid from '@material-ui/core/Grid';
import PartnerItem from '../../components/PartnerItem';
import PartnerContainer from '../../components/containers/PartnerContainer';

import {useAppContext} from '../../components/singletons/AppContext';

const PartnersPage = ({history}) => {
  const {user, search} = useAppContext();

  return (
    <Dashboard>
      <PartnerContainer>
        {({partners, fetchData}) =>
          partners
            .filter(partner => partner.candidate_aproved === true)
            .map((partner, i) => {
              // console.log(partner);
              if (
                partner.artisticName
                  ?.toLowerCase()
                  .includes(search.toLowerCase()) ||
                partner.name?.toLowerCase().includes(search.toLowerCase())
              ) {
                return (
                  <PartnerItem
                    history={history}
                    user={partner}
                    fetchData={fetchData}
                  />
                );
              }
            })
        }
      </PartnerContainer>
    </Dashboard>
  );
};

export default PartnersPage;
