import React, {useState, useEffect} from 'react';
import api from '../../core/api';
import {Grid, List, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useAppContext} from '../singletons/AppContext';
import {CSVLink, CSVDownload} from 'react-csv';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));
export default function UsersContainer({key, children}) {
  const [state, setState] = useState({
    users: [],
    loading: true,
    error: null,
  });

  const {setAppState} = useAppContext();
  const idEmpresa = parseInt(localStorage.getItem('empresa_id'));
  const adminGestor = JSON.parse(localStorage.getItem('adminGestor'));

  const classes = useStyles();

  /**
   *
   * @param {import('axios').AxiosInstance} api
   */
  async function fetchData() {
    setState({
      users: [],
      loading: true,
      error: null,
    });
    try {
      const {data: users} = await api.get(`/users`);
      // const users = [
      //   {
      //     id: 1017,
      //     email: 'testenovo2@teste.com',
      //     name: 'teste1',
      //     nickName: 'teste1',
      //     playlist: null,
      //     favorites: '[]',
      //     objectives: null,
      //     password:
      //       '$2b$10$MfOeVnRJUK4YOqneVgImmeblTrBrleg2cDwPGLCeJNXasltGqRhoi',
      //     password_reset_token: null,
      //     password_reset_expires: null,
      //     isAdmin: false,
      //     adminMaster: false,
      //     receiveNewsletter: false,
      //     facebook_id: null,
      //     premium: 'N',
      //     profile_picture: null,
      //     partner_id: null,
      //     version_device: null,
      //     version_app: null,
      //     assas_client_id: null,
      //     subscription_assas_id: null,
      //     is_active: true,
      //     special_id: 3,
      //     empresa_id: 6,
      //     first_access: null,
      //     first_access_date: null,
      //     createdAt: '2023-05-05T18:38:53.000Z',
      //     updatedAt: '2023-05-12T03:25:33.000Z',
      //   },
      // ];
      const filteredUsers = users.filter(user => !user.isAdmin);
      filteredUsers.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setState({...state, users: filteredUsers, loading: false});
    } catch (e) {
      if (e.response)
        setState({
          ...state,
          error: {
            payload: e.response.data,
          },
        });
      else setState({...state, error: {}});
    }
  }

  useEffect(() => {
    fetchData();
    setAppState({onReloadData: () => fetchData()});
  }, [key]);

  if (state.error) {
    return (
      <div>
        <p style={{textAlign: 'center'}}>Ocorreu um erro ao obter os dados.</p>
        <p style={{textAlign: 'center'}}>
          <a href="javascript:void(0)" onClick={fetchData}>
            Tentar novamente
          </a>
        </p>
      </div>
    );
  }

  if (state.loading) {
    return (
      <div className="flex-col center-a center-b" style={{height: '50vh'}}>
        <span style={{paddingTop: '10em'}}>
          <CircularProgress size={100} />
          <p> Carregando... </p>
        </span>
      </div>
    );
  }

  function getData() {
    const data = state.users.map(user => {
      let userFormatted = {
        id: user.id,
        email: user.email,
        name: user.name,
        version_app: user.version_app ? user.version_app : '-',
        version_device: user.version_device ? user.version_device : '-',
        receive_newsletter: user.receiveNewsletter ? 'Sim' : 'Não',
        premium: user.premium,
        empresa_id: user.empresa_id,
        createdAt: moment.utc(user.createdAt).format('DD/MM/YYYY'),
      };
      return Object.values(userFormatted);
    });

    return [
      [
        'id',
        'email',
        'nome',
        'versão app',
        'versão device',
        'receive newsletter',
        'assinatura',
        'empresa',
        'criado em',
      ],
      ...data,
    ];
  }

  return (
    <>
      <CSVLink filename={`users_${new Date()}`} data={getData()}>
        Exportar usuários
      </CSVLink>
      <List className={classes.root}>{children(state.users)}</List>
    </>
  );
}
