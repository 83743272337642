import React from 'react'
import Dashboard from '../../layout/Dashboard'
import CategoriesContainer from '../../components/containers/CategoriesContainer'
import { List, Fab, Grid } from '@material-ui/core'
import CategoryItem from '../../components/CategoryItem'
import { useAppContext } from '../../components/singletons/AppContext'

export default function CategoriesPage({ history }) {
	const { search } = useAppContext()
	return (
		<Dashboard>
			<CategoriesContainer>
				{({ fetchData, categories }) => (
					<Grid item xs={12}>
						<List>
							{categories.map((category) => {
								if (category.name.toLowerCase().includes(search)) {
									return <CategoryItem item={category} onDelete={fetchData} />
								}
							})}
						</List>
					</Grid>
				)}
			</CategoriesContainer>
		</Dashboard>
	)
}
