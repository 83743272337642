import React, { useEffect } from "react"
import { IconButton, Grid, Typography, makeStyles } from "@material-ui/core"

import Dropzone from "react-dropzone"

import TrashIcon from "@material-ui/icons/Delete"
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled"
import VideoLabelIcon from "@material-ui/icons/VideoLabel"
import UploadContext, { useUploadContext } from "./singletons/UploadContext"

const useStyles = makeStyles(theme => ({
	form: {
		minHeight: window.innerHeight - 300,
		height: "60vh",
		overflowY: "auto",
		overflowX: "hidden"
	},
	fab: {
		margin: 4
	},
	extendedIcon: {
		marginRight: 4
	},
	section: {
		marginTop: 10
	},
	selectInput: {
		paddingTop: "2.5vh"
	},
	imageInput: {
		border: "3px dashed #000000a1",
		borderRadius: "0.5em",
		padding: "10px",
		height: 308
	},
	selectInputMargin: { marginRight: 10 },
	adornment: {
		opacity: "0.3"
	},
	avatar: {
		height: 200,
		width: 200,
		boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
	}
}))
const UploadContent = ({ editing, getVideoURL = "", getThumbnailURL = "" }) => {
	const classes = useStyles()

	const {
		video,
		thumbnail,
		videoURL,
		thumbnailURL,
		setUploadState
	} = useUploadContext()

	useEffect(() => {
		if (editing) {
			setUploadState({
				videoURL: getVideoURL,
				thumbnailURL: getThumbnailURL
			})
		}
	}, [])

	return (
		<Grid spacing={4} container alignItems="center" justify="space-between">
			<React.Fragment>
				{thumbnailURL && thumbnailURL.length > 0 ? (
					<Grid item xs={6}>
						<div
							className="flex-row flex-end"
							style={{
								position: "relative",
								top: 90,
								right: 10
							}}>
							<IconButton
								onClick={() =>
									setUploadState({
										thumbnailURL: "",
										thumbnail: {}
									})
								}>
								<TrashIcon
									style={{ color: "red" }}
									fontSize="large"
								/>
							</IconButton>
						</div>
						<div>
							<Typography>Capa:</Typography>
						</div>
						<div className={classes.imageInput}>
							<img
								onError={res => {
									if (res) {
										setUploadState({
											thumbnail: {
												error:
													"não carregou ainda, verificar o acesso público, para está imagem."
											}
										})
									}
								}}
								style={{
									width: "100%",
									maxHeight: "100%"
								}}
								src={thumbnailURL}
								alt=""
							/>
							{thumbnail && thumbnail.error && (
								<div
									style={{
										display: "flex",
										height: "100%",
										justifyContent: "flex-end",
										flexDirection: "column",
										alignContent: "end"
									}}>
									<p
										style={{
											textAlign: "justify",
											color: "red"
										}}>
										{thumbnail.error}
									</p>
								</div>
							)}
						</div>
					</Grid>
				) : (
					<Grid item xs={6}>
						<Dropzone
							onDrop={acceptedFiles => {
								setUploadState({
									thumbnail: acceptedFiles[0]
								})
							}}>
							{({ getRootProps, getInputProps }) => {
								return (
									<div
										{...getRootProps()}
										className={classes.imageInput}>
										<div className="flex-col center-a center-b">
											<input {...getInputProps()} />
											<IconButton>
												<VideoLabelIcon fontSize="large" />
											</IconButton>
											{thumbnail && thumbnail.name && (
												<ul>
													<li>{thumbnail.name}</li>
												</ul>
											)}
											<p>Insira a imagem de capa </p>
										</div>
									</div>
								)
							}}
						</Dropzone>
					</Grid>
				)}
				{videoURL && videoURL.length > 0 ? (
					<Grid item xs={6}>
						<div
							className="flex-row flex-end"
							style={{
								position: "relative",
								top: 90,
								right: 10,
								zIndex: 99999
							}}>
							<IconButton
								onClick={() =>
									setUploadState({ videoURL: "", video: {} })
								}>
								<TrashIcon
									style={{ color: "red" }}
									fontSize="large"
								/>
							</IconButton>
						</div>
						<div>
							<Typography>Video:</Typography>
						</div>
						<div className={classes.imageInput}>
							{video && video.error ? (
								<div
									style={{
										display: "flex",
										height: "100%",
										justifyContent: "flex-end",
										flexDirection: "column",
										alignContent: "end"
									}}>
									<p
										style={{
											textAlign: "justify",
											color: "red"
										}}>
										{video.error}
									</p>
								</div>
							) : (
								<video
									onError={res => {
										if (res) {
											setUploadState({
												video: {
													error: videoURL
														? "não carregou ainda, verificar o acesso público, para este vídeo."
														: "Está conteúdo não possui vídeo."
												}
											})
										}
									}}
									style={{
										width: "100%",
										maxHeight: "100%"
									}}
									src={`${videoURL}`}
									// src={`https://medita-prod.s3-sa-east-1.amazonaws.com/videos/${video.filename}`}
									controls
								/>
							)}
						</div>
					</Grid>
				) : (
					<Grid item xs={6}>
						<Dropzone
							onDrop={acceptedFiles => {
								setUploadState({
									video: acceptedFiles[0]
								})
							}}>
							{({ getRootProps, getInputProps }) => {
								return (
									<div
										{...getRootProps()}
										className={classes.imageInput}>
										<div className="flex-col center-a center-b">
											<input {...getInputProps()} />
											<IconButton>
												<PlayCircleFilledIcon fontSize="large" />
											</IconButton>
											{video && video.name && (
												<ul>
													<li>{video.name}</li>
												</ul>
											)}
											<p>
												Arraste o arquivo até aqui ou
												clique para selecionar.
											</p>
										</div>
									</div>
								)
							}}
						</Dropzone>
					</Grid>
				)}
			</React.Fragment>
		</Grid>
	)
}

export default UploadContent
