import React, {useState, useEffect} from 'react';
import Dashboard from '../../layout/Dashboard';
import ArticleForm from '../../components/forms/ArticleForm';
import useReduxState from '../../core/useReduxState';
import api from '../../core/api';
import FileContext from '../../components/singletons/FileContext';

const ArticleAdd = ({history}) => {
  const [getFileState, setFileState] = useReduxState({
    src: null,
    crop: {unit: '%', width: 100, aspect: 3 / 3},
    imageRef: null,
    croppedImageUrl: '',
    blobFile: '',
  });
  const [categories, setCategories] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [partners, setPartners] = useState([]);

  const fetchApi = async () => {
    try {
      await api.get('/categories').then(({data: categories}) => {
        setCategories(categories);
      });

      await api.get('/objectives').then(({data: objectives}) => {
        setObjectives(objectives);
      });

      await api.get('/partners').then(({data: partners}) => {
        const newPartners = partners.sort((a, b) => {
          if (a.artisticName > b.artisticName) {
            return 1;
          }
          if (a.artisticName < b.artisticName) {
            return -1;
          }
          return 0;
        });

        setPartners(newPartners);
      });
    } catch (e) {
      console.log({e});
    }
  };

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <FileContext.Provider value={{...getFileState(), setFileState}}>
      <Dashboard>
        <ArticleForm
          history={history}
          partners={partners}
          objectives={objectives}
          categories={categories}
        />
      </Dashboard>
    </FileContext.Provider>
  );
};

export default ArticleAdd;
