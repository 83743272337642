export const getMediaType = (name) => {
  const audios = ['mp3','aac','m4a'];

  const audioCheck = audios.some(type => name.includes(type));

  if(audioCheck){
    return 'audio';
  }else{
    return 'video';
  }
}


export const convertSecondsToDuration = (time) => {
  if(!time)
    return {minutes: '0', seconds: '0'}

  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return {minutes: minutes.toString(),seconds: seconds.toString()}
}