export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 3,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};

export const responsiveMedium = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 3,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 2,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};

export const responsiveCards = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 10,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 8,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 4,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 2,
  },
};

export const days = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
];

export const months = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEZ',
];

export const monthsTable = [
  {name: 'JAN', colSpan: 2},
  {name: 'FEV', colSpan: 2},
  {name: 'MAR', colSpan: 2},
  {name: 'ABR', colSpan: 2},
  {name: 'MAI', colSpan: 2},
  {name: 'JUN', colSpan: 2},
  {name: 'JUL', colSpan: 2},
  {name: 'AGO', colSpan: 2},
  {name: 'SET', colSpan: 2},
  {name: 'OUT', colSpan: 2},
  {name: 'NOV', colSpan: 2},
  {name: 'DEZ', colSpan: 2},
];

export const monthsName = [
  'JANEIRO',
  'FEVEREIRO',
  'MARÇO',
  'ABRIL',
  'MAIO',
  'JUNHO',
  'JULHO',
  'AGOSTO',
  'SETEMBRO',
  'OUTUBRO',
  'NOVEMBRO',
  'DEZEMBRO',
];

export const premiuns = ['New', 'Free', 'Mensal', 'Anual', 'Gift', 'Especial'];

export function getMonthName(num) {
  switch (num) {
    case 1:
      return 'JAN';
    case 2:
      return 'FEV';
    case 3:
      return 'MAR';
    case 4:
      return 'ABR';
    case 5:
      return 'MAI';
    case 6:
      return 'JUN';
    case 7:
      return 'JUL';
    case 8:
      return 'AGO';
    case 9:
      return 'SET';
    case 10:
      return 'OUT';
    case 11:
      return 'NOV';
    case 12:
      return 'DEZ';
  }
}

export function getMonthNameAndYear(num) {
  switch (num) {
    case 0:
      return 'JAN';
    case 1:
      return 'FEV';
    case 2:
      return 'MAR';
    case 3:
      return 'ABR';
    case 4:
      return 'MAI';
    case 5:
      return 'JUN';
    case 6:
      return 'JUL';
    case 7:
      return 'AGO';
    case 8:
      return 'SET';
    case 9:
      return 'OUT';
    case 10:
      return 'NOV';
    case 11:
      return 'DEZ';
  }
}

export function getPlanName(plan) {
  switch (plan) {
    case 'N':
      return 'New';
    case 'F':
      return 'Free';
    case 'M':
      return 'Mensal';
    case 'Y':
      return 'Anual';
    case 'G':
      return 'Gift';
    case 'S':
      return 'Especial';
  }
}

export function getPlanColor(plan) {
  switch (plan) {
    case 'N':
      return '#4472C4';
    case 'F':
      return '#ED7D31';
    case 'M':
      return '#A5A5A5';
    case 'Y':
      return '#FFC000';
    case 'G':
      return '#5B9BD5';
    case 'S':
      return '#70AD47';
  }
}

export const returnValuesTest = data => {
  return months.map(cat => {
    const ca = data?.filter(d => d.categories === cat);

    if (ca.length > 0) {
      return (
        ca.reduce(function(accumulator, value) {
          return accumulator + value.count;
        }, 0) || 0
      );
    }

    return null;
  });
};

export const returnValues = data => {
  return months.map(cat => {
    const ca = data?.filter(d => d.categories === cat)[0];
    if (ca) {
      return ca.count;
    }
    return null;
  });
};

export const returnValuesTotal = data => {
  return months.map(cat => {
    const ca = data?.filter(d => d.categories === cat);
    if (ca && ca.length > 0) {
      return (
        ca.reduce(function(accumulator, value) {
          return accumulator + value.count;
        }, 0) || 0
      );
    }
    return null;
  });
};

export const returnValuesBykeys = (data, keys) => {
  return keys.map(day => {
    const ca = data?.filter(d => d.day.toString() === day)[0];
    if (ca) {
      return ca.count;
    }
    return null;
  });
};

export const returnValuesTotalBykeys = (data, keys) => {
  return keys.map(cat => {
    const ca = data?.filter(d => d.day.toString() === cat);
    if (ca && ca?.length > 0) {
      return ca.length;
    }
    return null;
  });
};

export const returnTotalValues = data => {
  return months.map(cat => {
    const ca = data?.filter(d => d.categories === cat);
    if (ca?.length > 0) {
      return ca.length;
    }
    return null;
  });
};

export const returnValuesPremium = data => {
  return premiuns.map(cat => {
    const ca = data?.filter(d => d.categories === cat)[0];
    if (ca) {
      return ca.count;
    }
    return 0;
  });
};

export const returnValuesPremiumTotal = data => {
  return premiuns.map(cat => {
    const ca = data?.filter(d => d.categories === cat);
    if (ca && ca.length > 0) {
      return (
        ca.reduce(function(accumulator, value) {
          return accumulator + value.count;
        }, 0) || 0
      );
    }
    return null;
  });
};

export const returnValuesTotalPremium = data => {
  return premiuns.map(cat => {
    const ca = data?.filter(d => d.categories === cat);
    if (ca && ca?.length > 0) {
      return ca.length;
    }
    return null;
  });
};

export const returnTotalValuesPremium = data => {
  return premiuns.map(cat => {
    const ca = data[cat];
    if (ca && ca?.length > 0) {
      return ca.length;
    }
    return 0;
  });
};

export const AnnotationsMonth = item => {
  const points = months.map((m, index) => {
    let sum = 0;
    item.map(i => {
      if (i.data[index]) sum = sum + i.data[index];
    });

    return {
      x: m,
      y: sum,
      marker: {
        size: 0,
        fillColor: '#fff',
      },
      label: {
        text: sum,
        offsetY: -5,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
    };
  });

  return points;
};

export const AnnotationsPremiums = item => {
  const points = premiuns.map((m, index) => {
    let sum = 0;
    item.map(i => {
      if (i.data[index]) sum = sum + i.data[index];
    });

    return {
      x: m,
      y: sum,
      marker: {
        size: 0,
        fillColor: '#fff',
      },
      label: {
        text: sum,
        offsetY: -5,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
    };
  });

  return points;
};
