import React from 'react';
import Dashboard from '../../layout/Dashboard';
import PlansContainer from '../../components/containers/PlansContainer';
import PlanItem from '../../components/PlanItem';

const PlansPage = ({history}) => {
  return (
    <Dashboard>
      <PlansContainer>
        {plans =>
          plans.map(plan => {
            return <PlanItem history={history} plan={plan} />;
          })
        }
      </PlansContainer>
    </Dashboard>
  );
};

export default PlansPage;
