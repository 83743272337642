import React from "react"

const FileContext = React.createContext({
	src: {},
	setFileState() {}
})

export const useFileContext = () => React.useContext(FileContext)

export default FileContext
