import React, {useState} from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import useReduxState from '../../core/useReduxState';
import api from '../../core/api';
import ReturnButton from '../ReturnButton';
import {Formik} from 'formik';
import InputText from './InputText';
import ButtonSubmit from './ButtonSubmit';
import InputPrice from './InputPrice';

const PlanForm = ({
  plan = {
    description: '',
    value: '',
  },
  history,
}) => {
  const editing = !!plan.id;
  const [getUser, setUser] = useReduxState({
    loading: false,
  });

  const {enqueueSnackbar} = useSnackbar();

  const onSubmit = async form => {
    setUser({loading: true});

    try {
      if (editing) {
        await api.put(`/plans/${form.id}`, form).then(res => {
          // console.log(res);
          if (res.status === 200) {
            enqueueSnackbar('Plano editado com sucesso!');
            history.push('/plans');
          }
        });
      } else {
        await api.post(`/plans`, form).then(res => {
          if (res.status === 200) {
            enqueueSnackbar('Novo Plano cadastrado com sucesso!');
            history.push('/plans');
          }
        });
      }
    } catch ({response}) {
      enqueueSnackbar(
        response.data.message || 'Ocorreu um erro. Tente novamente.',
      );
      setUser({loading: false});
    }
  };

  const validateForm = values => {
    const errors = {};
    let errorMessage = '';

    if (!values.description) {
      errors.description = 'Digite o nome do Plano';
      errorMessage += 'Nome do Plano\n';
    }

    if (!values.value) {
      errors.value = 'Digite o preço do Plano';
      errorMessage += 'Preço do Plano\n';
    }

    if (Object.keys(errors)?.length) {
      enqueueSnackbar(
        'Formulário incompleto, campos a serem preenchidos:\n' + errorMessage,
        {
          preventDuplicate: true,
          variant: 'error',
          style: {whiteSpace: 'pre-line'},
        },
      );
    }

    return errors;
  };

  return (
    <Formik
      initialValues={plan}
      validate={validateForm}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        onSubmit({
          ...values,
          value: parseFloat(values.value.replace(',', '.')),
        });
      }}>
      {formikProps => (
        <Grid container xs={12}>
          <Grid item xs={12} alignContent="center">
            <Typography component="h1" variant="h5">
              <ReturnButton />
              {editing ? 'Editar Plano' : 'Cadastrar Novo Plano'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            {!getUser().loading ? (
              <form onSubmit={formikProps.handleSubmit}>
                <InputText
                  errorMessage={
                    formikProps.touched.description &&
                    formikProps.errors.description
                  }
                  id="description"
                  label="Nome do Plano"
                  name="description"
                  value={formikProps.values.description}
                  autoComplete="description"
                  onChange={e => {
                    formikProps.setFieldValue('description', e.target.value);
                    formikProps.errors['description'] = null;
                  }}
                  disabled
                />

                <InputPrice
                  fullWidth
                  errorMessage={
                    formikProps.touched.value && formikProps.errors.value
                  }
                  id="value"
                  label="Preço do Plano"
                  name="value"
                  value={formikProps.values.value}
                  autoComplete="value"
                  onChange={e =>
                    formikProps.setFieldValue('value', e.target.value)
                  }
                />

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <ButtonSubmit
                    style={{
                      marginTop: 20,
                      width: '20vw',
                      alignSelf: 'center',
                    }}
                    type="submit"
                    variant="contained"
                    color="primary">
                    {editing ? 'Salvar' : 'Cadastrar'}
                  </ButtonSubmit>
                </div>
              </form>
            ) : (
              <div
                className="flex-col center-a center-b"
                style={{height: '50vh'}}>
                <CircularProgress size={100} />
                <p> Carregando... </p>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default PlanForm;
