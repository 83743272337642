import React, {useState, useEffect} from 'react';
import Chart from 'react-apexcharts';
import Title from '../../../components/Title';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {CircularProgress, Grid, Typography} from '@material-ui/core';
import DataCard from './DataCard';
import {
  getMonthName,
  months,
  responsive,
  responsiveCards,
  responsiveMedium,
  returnTotalValues,
  returnValuesTotalBykeys,
} from '../service/utils';
import {KeyboardDatePicker} from '@material-ui/pickers';
import dashboardService from '../service';
import DrawTableDash from '../../../components/DrawTableDash';

const styles = {
  margin: {
    marginTop: 15,
    marginBottom: 15,
  },
  viewCharts: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: 15,
  },
  containerChart: {
    minWidth: '45%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    margin: '0 10px 10px 0',
  },
  containerChartLine: {
    width: '75%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    marginTop: 20,
  },
  containerChartTable: {
    width: '75%',
    padding: 10,
  },
  legend: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  title: {textAlign: 'center', marginTop: 10, marginBottom: 20},
  flexEnd: {width: '100%', display: 'flex', justifyContent: 'flex-end'},
  datePicker: {
    borderRadius: '40px',
    height: 50,
    width: 'fit-content',
    border: 'solid 1px #d3d3d3',
    marginBottom: 20,
    marginLeft: 5,
  },
};

const ActiveUsersGraph = () => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [resultsTable, setResultsTable] = useState([]);
  const [resultsTemp, setResultsTemp] = useState([]);
  const [resultsMonth, setResultsMonth] = useState();
  const [, setKeysPeriod] = useState([]);
  const [, setResultsPeriod] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1)),
  );
  const [endDate, setEndDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [totalData, setTotalData] = useState(0);

  const GetData = async () => {
    setLoading(true);
    await dashboardService
      .getUsersActiveMonth({startDate, endDate})
      .then(({data}) => {
        var result = Object.entries(data).map(res => {
          return {...res[1]};
        });
        setResults(result);
      })
      .finally(() => setLoading(false));

    await dashboardService
      .getUsersActive(date.getFullYear())
      .then(({data}) => {
        var result = Object.entries(data)
          .map(res => {
            return {...res[1]};
          })
          .reduce((acc, item) => {
            return {
              ...acc,
              [item.userId]: [...(acc[item.userId] ?? []), item],
            };
          }, {});

        Object.entries(result)
          .map(res => {
            return res[1].reduce((acc, item) => {
              if (!acc[item.month]) {
                acc[item.month] = item;
              }
              return acc;
            }, {});
          })
          .map(res => {
            var result = Object.entries(res).map(res => {
              return {...res[1]};
            });
            resultsTemp.push(...result);
          });
      })
      .finally(() => setLoading(false));

    const totalValues = resultsTemp?.map(item => {
      return {...item, categories: getMonthName(item.month)};
    });

    const result = resultsTemp
      ?.map(item => {
        return {...item, categories: getMonthName(item.month)};
      })
      .reduce((acc, item) => {
        return {
          ...acc,
          [item.platform]: [...(acc[item.platform] ?? []), item],
        };
      }, {});

    setTotalData(totalValues.length);

    const formatedData = [
      {
        name: 'all',
        title: 'Total de usuários ativos',
        color: '#4472C4',
        data: returnTotalValues(totalValues),
        total: totalValues.length,
        textColor: '#fff',
        dataLabels: true,
      },
      {
        name: 'Android',
        title: 'Usuários ativos - Android',
        color: '#4472C4',
        data: returnTotalValues(result['android']),
        total: result['android']?.length,
        textColor: '#fff',
        dataLabels: true,
      },
      {
        name: 'IOS',
        title: 'Usuários ativos - IOS',
        color: '#ED7D31',
        data: returnTotalValues(result['ios']),
        total: result['ios']?.length,
        dataLabels: true,
      },
    ];
    setResultsMonth(formatedData);

    const response = formatedData.map(d => {
      const dataPerc = d.data.map((dd, index) => {
        const t = formatedData[0].data[index]
          ? ((dd * 100) / formatedData[0].data[index]).toFixed(2)
          : 0;
        return {dd: dd || 0, t};
      });
      return {...d, dataPerc};
    });
    setResultsTable(response);

    await dashboardService
      .getUsersActiveDay({
        year: date.getFullYear(),
        month: date.getMonth(),
        date,
        startDate,
        endDate,
      })
      .then(({data}) => {
        const resultsByDay = data?.reduce((acc, item) => {
          return {
            ...acc,
            [item.day]: [...(acc[item.day] ?? []), item],
          };
        }, {});

        if (resultsByDay) {
          let returnPer = [];

          Object.values(resultsByDay).map(key => {
            const groupedResults = key.reduce((acc, curr) => {
              if (!acc[curr.userId]) {
                acc[curr.userId] = curr;
              }
              return acc;
            }, {});

            Object.values(groupedResults).map(keyG => {
              return returnPer.push(keyG);
            });
          });

          const period = returnPer.reduce((acc, item) => {
            return {
              ...acc,
              [item.platform]: [...(acc[item.platform] ?? []), item],
            };
          }, {});

          const formatedPeriod = [
            {
              name: 'Android',
              title: 'Usuários ativos - Android',
              color: '#4472C4',
              data: returnValuesTotalBykeys(
                period['android'],
                Object.keys(resultsByDay),
              ),
              total:
                period['android']?.reduce(function(accumulator, value) {
                  return accumulator + value.count;
                }, 0) || 0,
              textColor: '#fff',
            },
            {
              name: 'IOS',
              title: 'Usuários ativos - IOS',
              color: '#ED7D31',
              data: returnValuesTotalBykeys(
                period['ios'],
                Object.keys(resultsByDay),
              ),
              total:
                period['ios']?.reduce(function(accumulator, value) {
                  return accumulator + value.count;
                }, 0) || 0,
            },
          ];

          setKeysPeriod(Object.keys(resultsByDay));
          setResultsPeriod(formatedPeriod);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setResults([]);
    setResultsTemp([]);
    setResultsMonth([]);
    setTotalData(0);
    GetData();
  }, [startDate, endDate, date]);

  return (
    <div style={styles.margin}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        style={styles.title}>
        Usuários ativos por plataforma
      </Typography>

      <div style={styles.flexEnd}>
        <div style={styles.datePicker}>
          <KeyboardDatePicker
            placeholder="Selecione data inicial"
            value={startDate}
            onChange={dateForm => {
              setStartDate(new Date(dateForm));
              setEndDate(
                new Date(
                  new Date(dateForm).setMonth(
                    new Date(dateForm).getMonth() + 1,
                  ),
                ),
              );
            }}
            format="DD/MM/YYYY"
          />
        </div>
        <div style={styles.datePicker}>
          <KeyboardDatePicker
            placeholder="Selecione data final"
            value={endDate}
            onChange={dateForm => {
              setEndDate(new Date(dateForm));
              setStartDate(
                new Date(
                  new Date(dateForm).setMonth(
                    new Date(dateForm).getMonth() - 1,
                  ),
                ),
              );
            }}
            format="DD/MM/YYYY"
          />
        </div>
      </div>
      {loading ? (
        <div className="flex-col center-a center-b">
          <CircularProgress size={100} />
        </div>
      ) : (
        <>
          <Carousel responsive={responsiveCards}>
            <DataCard
              title={<span>Total de usuários ativos</span>}
              value={results?.length || 0}
            />
            <DataCard
              title={<span>Usuários ativos - Android</span>}
              value={results?.filter(res => res.platform === 'ios').length || 0}
            />
            <DataCard
              title={<span>Usuários ativos - IOS</span>}
              value={
                results?.filter(res => res.platform === 'android').length || 0
              }
            />
          </Carousel>

          <div style={{marginTop: 10}} />

          <div style={styles.flexEnd}>
            <div style={styles.datePicker}>
              <KeyboardDatePicker
                placeholder="Selecione ano"
                value={date}
                onChange={dateForm => setDate(new Date(dateForm))}
                views={['year']}
                yearsOrder="desc"
                format=" YYYY"
              />
            </div>
          </div>

          {resultsMonth?.length > 0 && (
            <>
              <Grid container>
                <Grid container xs={12}>
                  {resultsMonth.map(item => (
                    <Grid item xs={6}>
                      <div id="chart" style={styles.containerChart}>
                        <Title fontWeight="normal" size="medium" align="center">
                          {item.title}
                        </Title>
                        <span style={styles.legend}>{date.getFullYear()}</span>

                        <Chart
                          options={{
                            colors: [item.color],
                            chart: {
                              type: 'bar',
                              height: 350,
                            },
                            plotOptions: {
                              bar: {
                                borderRadius: 4,
                                borderRadiusApplication: 'end',
                                horizontal: true,
                                ...(item.dataLabels && {
                                  dataLabels: {
                                    position: 'top',
                                  },
                                }),
                              },
                            },
                            ...(item.dataLabels
                              ? {
                                  dataLabels: {
                                    enabled: true,
                                    textAnchor: 'end',
                                    padding: 10,
                                    style: {
                                      fontSize: '12px',
                                      colors: [item.textColor],
                                    },
                                  },
                                }
                              : {
                                  dataLabels: {
                                    enabled: false,
                                  },
                                }),
                            tooltip: {
                              enabled: false,
                            },
                            stroke: {
                              enabled: false,
                            },
                            xaxis: {
                              categories: months,
                            },
                          }}
                          series={[
                            {
                              data: item.data,
                            },
                          ]}
                          type="bar"
                          height={350}
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <div style={{marginTop: 10}} />

              <Carousel responsive={responsiveMedium}>
                <div id="chart" style={styles.containerChart}>
                  <Title fontWeight="normal" size="medium" align="center">
                    Total de usuários ativos - Percentual
                  </Title>
                  <span style={styles.legend}>{date.getFullYear()}</span>
                  <Chart
                    options={{
                      chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        stackType: '100%',
                      },
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            legend: {
                              position: 'bottom',
                              offsetX: -10,
                              offsetY: 0,
                            },
                          },
                        },
                      ],
                      plotOptions: {
                        bar: {
                          horizontal: false,
                        },
                      },
                      xaxis: {
                        categories: months,
                      },
                    }}
                    series={resultsMonth.slice(1, 3)}
                    type="bar"
                    height={500}
                  />
                </div>
                <div id="chart" style={styles.containerChart}>
                  <Title fontWeight="normal" size="medium" align="center">
                    Total de usuários ativos - Quantidade
                  </Title>
                  <span style={styles.legend}>{date.getFullYear()}</span>
                  <Chart
                    options={{
                      chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                          enabled: false,
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        background: 'none',
                        textAnchor: 'end',
                      },
                      stroke: {
                        curve: 'straight',
                      },
                      xaxis: {
                        categories: months,
                      },
                    }}
                    series={resultsMonth.slice(1, 3)}
                    type="line"
                    height={500}
                  />
                </div>
              </Carousel>

              <div style={{marginTop: 15}} />

              <Title fontWeight="normal" size="medium" align="center">
                Total de usuários ativos - Percentual e Quantidade
              </Title>
              <span style={styles.legend}>{date.getFullYear()}</span>

              <div id="chart" style={styles.containerChartTable}>
                {DrawTableDash(
                  months,
                  resultsTable.map(item => {
                    return [
                      {dd: item.name === 'all' ? 'Total' : item.name},
                      {dd: item.total},
                      {
                        dd: `${
                          totalData > 0
                            ? ((item.total * 100) / totalData).toFixed(2)
                            : 0
                        }% `,
                      },
                      ...item.dataPerc,
                    ];
                  }),
                  'partners-to-xls',
                  'ativos',
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ActiveUsersGraph;
