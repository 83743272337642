import React, {useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import KeyWordIcon from '@material-ui/icons/Spellcheck';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FaceIcon from '@material-ui/icons/Face';

import LayersIcon from '@material-ui/icons/Layers';
import CampaignIcon from '@material-ui/icons/Layers';
import PostAddIcon from '@material-ui/icons/NoteAdd';
import CategoryIcon from '@material-ui/icons/Extension';
import MainCategoryIcon from '@material-ui/icons/Category';
import SubCategoryIcon from '@material-ui/icons/Class';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import SettingsIcon from '@material-ui/icons/Settings';
import UploadsIcon from '@material-ui/icons/Folder';
import PeopleAltIcon from '@material-ui/icons/People';
import ShareIcon from '@material-ui/icons/Share';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import ExitAppIcon from '@material-ui/icons/ExitToApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Timer from '@material-ui/icons/Timer';

import {withRouter} from 'react-router-dom';
import NestedItem from './NestedItem';
import {useAppContext} from '../components/singletons/AppContext';
import {useEffect} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import InputsFixedContext from '../components/singletons/InputsFixedContext';
import useReduxState from '../core/useReduxState';
import CountDetails from '../components/CountDetails';
import Noty from './Noty';
import api from '../core/api';
import {Business} from '@material-ui/icons';

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://material-ui.com/">
//         Medita
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {/* {". Built with "}
// 			<Link color="inherit" href="https://material-ui.com/">
// 				Material-UI.
// 			</Link> */}
//     </Typography>
//   );
// }

const drawerWidth = 240;

const displayHeight = window.innerHeight;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#61b7c3',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#61b7c3',
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    // paddingTop: 95
  },
  container: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: displayHeight - 70,
  },
  filters: {
    padding: '20px',
    justify: 'center',
  },
}));

// export default function Dashboard({ children })

const Dashboard = withRouter(
  ({
    history,
    children,
    fabButtonPath,
    fetchData,
    match,
    toolbarOpen = true,
    viewFilters = false,
  }) => {
    const {
      setAppState,
      search,
      searchFilter,
      searchFilterTypeOfAssign,
      onReloadData,
      path,
      loading,
    } = useAppContext();
    const classes = useStyles();
    const [label, setLabel] = useState('');
    const [showToolBar, setShowToolBar] = useState(toolbarOpen);
    const [showFilters, setFilters] = useState(viewFilters);
    const [open, setOpen] = React.useState(true);
    const [candidates, setCandidates] = React.useState([]);
    const [getState, setInputState] = useReduxState({
      premium: {},
      publication_date: {},
    });

    const idEmpresa = parseInt(localStorage.getItem('empresa_id'));
    const adminGestor = JSON.parse(localStorage.getItem('adminGestor'));

    const getCandidates = async () => {
      try {
        const {data: partners} = await api.get(`/partners`);
        const candidates = partners.filter(
          partner => partner.candidate_aproved !== true,
        );
        setCandidates(candidates);
      } catch (e) {
        // console.log('Ocorreu um erro');
      }
    };

    useEffect(() => {
      getCandidates();
    }, [path]);

    useEffect(() => {
      // console.log('PARTNERS', partners);
      // console.log(path);
      if (path.match(/edit|add|medias/) || path === '/') {
        setShowToolBar(false);
      } else {
        setShowToolBar(toolbarOpen);
      }
    }, [path]);

    useEffect(() => {
      // console.log(path);
      if (path === '/users') {
        setFilters(true);
      } else {
        setFilters(viewFilters);
      }
    }, [path]);

    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const handleDrawerClose = () => {
      setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const getNameRoute = () => {
      if (match.path.includes('/videos')) {
        return setLabel('Vídeos');
      }
      if (match.path.includes('/audios')) {
        return setLabel('Áudios');
      }
      if (match.path.includes('/articles')) {
        return setLabel('Matérias');
      }
      if (match.path.includes('/courses')) {
        return setLabel('Cursos');
      }
      if (match.path.includes('/categories')) {
        return setLabel('Categorias');
      }
      if (match.path.includes('/users')) {
        return setLabel('Usuários');
      }
      if (match.path.includes('/partners')) {
        return setLabel('Parceiros');
      }
      if (match.path.includes('/candidates')) {
        return setLabel('Candidatos');
      }
      if (match.path.includes('/empresas')) {
        return setLabel('Empresas');
      }
      if (match.path.includes('/specials')) {
        return setLabel('Specials');
      }
      if (match.path.includes('/plans')) {
        return setLabel('Planos');
      }
      if (match.path.includes('/campaign')) {
        return setLabel('Campanhas');
      }
      if (match.path.includes('/listwaiting')) {
        return setLabel('Beneficiados');
      }
      if (match.path.includes('/sub-categories')) {
        return setLabel('Subcategorias');
      }
      if (match.path.includes('/keywords')) {
        return setLabel('Palavras-Chave');
      }
      if (match.path.includes('/banners')) {
        return setLabel('Banners');
      }
      if (match.path.includes('/uploads')) {
        return setLabel('Uploads');
      }
      if (match.path.includes('/medias')) {
        return setLabel('Áudios e Vídeos');
      }
      if (match.path.includes('/audits')) {
        return setLabel('Auditorias');
      }
      return setLabel('Dashboard');
    };

    const menuItens = adminGestor
      ? [
          {
            icon: <DashboardIcon />,
            label: 'Dashboard',
            menu_open: 'dashboard',
            route: '/',
          },
          {
            icon: <ListAltIcon />,
            route: '/audits',
            label: 'Auditorias',
          },
          {
            icon: <AccountCircleIcon />,
            route: '/users',
            label: 'Usuários',
          },
          {
            icon: <Business />,
            route: '/plans',
            label: 'Planos',
          },
          // {
          //   icon: <GroupOutlined />,
          //   route: '/clients',
          //   label: 'Clientes',
          // },
          {
            icon: <FaceIcon />,
            route: '/partners',
            label: 'Parceiros',
          },

          {
            icon: <Business />,
            route: '/empresas',
            label: 'Empresas',
          },
          {
            icon: <Business />,
            route: '/specials',
            label: 'Tipos de Parcerias',
          },
          {
            icon: <Timer />,
            route: '/listwaiting',
            label: 'Beneficiados',
          },
          // {
          // 	icon: <PeopleIcon />,
          // 	label: "Usuários",
          // 	menu_open: "users",
          // 	list_item: [
          // 		{
          // 			icon: <AccountCircleIcon />,
          // 			route: "/users",
          // 			label: "Clientes"
          // 		},
          // 		{
          // 			icon: <FaceIcon />,
          // 			route: "/partners",
          // 			label: "Parceiros"
          // 		}
          // 	]
          // },

          {
            icon: <LayersIcon />,
            label: 'Conteúdos',
            menu_open: 'content',
            list_item: [
              {
                icon: <UploadsIcon />,
                route: '/uploads',
                label: 'Uploads',
              },
              {
                icon: <LibraryMusicIcon />,
                route: '/medias',
                label: 'Áudios e vídeos',
              },
              /*  {
            icon: <VideoLibrary />,
            route: '/videos',
            label: 'Vídeos',
          }, */
              {
                icon: <PostAddIcon />,
                route: '/articles',
                label: 'Matérias',
              },
              {
                icon: <LaptopChromebookIcon />,
                route: '/courses',
                label: 'Cursos',
              },
            ],
          },
          {
            icon: <CampaignIcon />,
            label: 'Campanhas',
            route: '/campaign',
          },
          {
            icon: <CategoryIcon />,
            label: 'Sessões',
            menu_open: 'category',
            list_item: [
              {
                icon: <MainCategoryIcon />,
                route: '/categories',
                label: 'Categorias',
              },
              {
                icon: <SubCategoryIcon />,
                route: '/sub-categories',
                label: 'Subcategorias',
              },
            ],
          },
          {
            icon: <KeyWordIcon />,
            route: '/keywords',
            label: 'Palavras-Chave',
            menu_open: 'keywords',
          },
          {
            icon: <ShareIcon />,
            label: 'Divulgação',
            menu_open: 'marketing',
            list_item: [
              {
                icon: <ViewCarouselIcon />,
                route: '/banners',
                label: 'Banner',
              },
            ],
          },
          {
            icon:
              candidates.length > 0 ? (
                <Noty
                  width={'25px'}
                  color={'#122C34'}
                  count={candidates.length}
                />
              ) : (
                // <BellIcon
                //   width="20"
                //   active={true}
                //   animate={true}
                //   color="#c0392b"
                //   CountDetails={10}
                // />
                <PeopleAltIcon />
              ),
            label: 'Candidatos',
            route: '/candidates',
          },
          {
            icon: <SettingsIcon />,
            label: 'Configurações',
            menu_open: 'config',
            list_item: [
              {
                icon: <PeopleIcon />,
                label: 'Usuários',
                route: '/users/admin',
              },

              // {
              // 	icon: <BuildIcon />,
              // 	route: "/modalities",
              // 	label: "Modalidades"
              // },
            ],
          },
          {
            icon: <ExitAppIcon />,
            route: '/login',
            label: 'Sair',
          },
        ]
      : [
          {
            icon: <DashboardIcon />,
            label: 'Dashboard',
            menu_open: 'dashboard',
            route: '/',
          },
          {
            icon: <AccountCircleIcon />,
            route: '/users',
            label: 'Clientes',
          },
          // {
          //   icon: <FaceIcon />,
          //   route: '/partners',
          //   label: 'Parceiros',
          // },

          // // {
          // //   icon: <Business />,
          // //   route: '/empresas',
          // //   label: 'Empresas',
          // // },

          {
            icon: <Timer />,
            route: '/listwaiting',
            label: 'Beneficiados',
          },
          // {
          //   icon:
          //     candidates.length > 0 ? (
          //       <Noty
          //         width={'25px'}
          //         color={'#122C34'}
          //         count={candidates.length}
          //       />
          //     ) : (
          //       // <BellIcon
          //       //   width="20"
          //       //   active={true}
          //       //   animate={true}
          //       //   color="#c0392b"
          //       //   CountDetails={10}
          //       // />
          //       <PeopleAltIcon />
          //     ),
          //   label: 'Candidatos',
          //   route: '/candidates',
          // },
          // {
          //   icon: <SettingsIcon />,
          //   label: 'Configurações',
          //   menu_open: 'config',
          //   list_item: [
          //     {
          //       icon: <PeopleIcon />,
          //       label: 'Usuários',
          //       route: '/users/admin',
          //     },

          //     // {
          //     // 	icon: <BuildIcon />,
          //     // 	route: "/modalities",
          //     // 	label: "Modalidades"
          //     // },
          //   ],
          // },
          {
            icon: <ExitAppIcon />,
            route: '/login',
            label: 'Sair',
          },
        ];

    const handleInput = e => {
      setAppState({search: e.target.value});
    };

    const handleInputFilter = e => {
      setAppState({searchFilter: e.target.value});
    };

    const handleInputFilterTypeOfAssign = e => {
      setAppState({searchFilterTypeOfAssign: e.target.value});
    };

    useEffect(() => {
      setAppState({search: ''});
      getNameRoute();
    }, []);

    useEffect(() => {
      setAppState({searchFilter: ''});
      getNameRoute();
    }, []);

    useEffect(() => {
      setAppState({searchFilterTypeOfAssign: ''});
      getNameRoute();
    }, []);

    useEffect(() => {
      setAppState({path: match.path});
    }, [match.path]);

    return (
      <InputsFixedContext.Provider value={{...getState, setInputState}}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden,
                )}>
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}>
                {label}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose,
              ),
            }}
            open={open}>
            <div className={classes.toolbarIcon}>
              <img
                width="175px"
                src={require('../assets/images/medita_logo_horizontal.png')}
              />
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              {menuItens.map((menuItem, i) => {
                return (
                  <NestedItem
                    keynext={i}
                    icon={menuItem.icon}
                    label={menuItem.label}
                    items={menuItem.list_item}
                    menu_open={menuItem.menu_open}
                    route={menuItem.route}
                  />
                );
              })}
            </List>
            <Divider />
          </Drawer>

          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div container justify="center" style={{padding: '5px 10px'}}>
              {showFilters && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: adminGestor ? 'space-between' : 'flex-end',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}>
                  {adminGestor && (
                    <>
                      <CountDetails />
                      {/* 
                      <FilterNewsLetter
                        search={searchFilterTypeOfAssign}
                        setSearch={handleInputFilter}
                      />
                      <FilterAssign
                        search={searchFilter}
                        setSearch={handleInputFilterTypeOfAssign}
                      /> */}
                    </>
                  )}
                  {/* <div>
                    {showToolBar && (
                      <FunctionalitiesListUser
                        labelRoute={label} //onde pega o label (design) do component user
                        fetchData={onReloadData} //
                        search={search} // para para esse component a pesquisa do usuario
                        setSearch={handleInput} //funçao q seta o search de acordo com a entrada do user
                      />
                    )}
                  </div> */}
                </div>
              )}

              <Grid item xs={12}>
                <List>
                  <Divider />
                </List>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                style={{
                  height: '100vh',
                  marginLeft: label !== 'Dashboard' ? '6rem' : '0px',
                  marginRight: label !== 'Dashboard' ? '6rem' : '0px',
                }}>
                <Scrollbars
                  style={{
                    height: 'calc(100vh - 180px)',
                  }}>
                  {children}
                </Scrollbars>
              </Grid>
            </div>
          </main>
        </div>
      </InputsFixedContext.Provider>
    );
  },
);
export default Dashboard;
