import React, { useEffect, useState } from "react"
import Dashboard from "../../layout/Dashboard"
import UsersContainer from "../../components/containers/UsersContainer"
import UserForm from "../../components/forms/UserForm"
import api from "../../core/api"

const EditAdminUser = ({ history, match }) => {
	const [user, setUser] = useState({})

	const fetchUser = async () => {
		try {
			await api.get(`/users/${match.params.userId}`).then(res => {
				const { data: user } = res
				setUser(user)
			})
		} catch (e) {
			alert(e)
		}
	}

	useEffect(() => {
		fetchUser()
	}, [])

	return (
		<Dashboard>
			{user.id && <UserForm history={history} user={user} />}
		</Dashboard>
	)
}

export default EditAdminUser
