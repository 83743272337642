import React from 'react'
import Dashboard from '../../layout/Dashboard'
import BannersContainer from '../../components/containers/BannersContainer'
import { List, Fab, Grid } from '@material-ui/core'
import BannerListItem from '../../components/BannerListItem'
import { useAppContext } from '../../components/singletons/AppContext'

export default function BannersPage({ history }) {
	const { search } = useAppContext()
	return (
		<Dashboard>
			<BannersContainer>
				{({ fetchData, banners }) => (
					<Grid item xs={12}>
						<List>
							{banners.map((banner) => {
								if (banner.title.toLowerCase().includes(search)) {
									return <BannerListItem item={banner} onDelete={fetchData} />
								}
							})}
						</List>
					</Grid>
				)}
			</BannersContainer>
		</Dashboard>
	)
}
