import React, {useState, useEffect} from 'react';
import {Grid, CircularProgress} from '@material-ui/core';
import api from '../../core/api';
import {useAppContext} from '../singletons/AppContext';

export default function VideosContainer({key, children}) {
  const [state, setState] = useState({
    videos: [],
    loading: true,
    error: null,
  });
  const {setAppState} = useAppContext();

  async function updateData(videos) {
    setState({...state, videos, loading: false});
  }

  async function fetchData() {
    setState({loading: true, error: null});
    try {
      const {data: videos} = await api.get(`/videos`);
      const {data: partners} = await api.get(`/partners`);
      const videosWithPartner = videos.map(video => {
        const partner = partners.find(partner => partner.id == video.partnerId);
        const name = partner.artisticName || partner.name;
        video.partnerName = name;
        return video;
      });
      setState({...state, videos: videosWithPartner, loading: false});
    } catch (e) {
      if (e.response)
        setState({
          ...state,
          error: {
            payload: e.response.data,
          },
        });
      else setState({...state, error: {}});
    }
  }

  useEffect(() => {
    fetchData();
    setAppState({onReloadData: () => fetchData()});
  }, []);

  if (state.error) {
    return (
      <div>
        <p style={{textAlign: 'center'}}>Ocorreu um erro ao obter os dados.</p>
        <p style={{textAlign: 'center'}}>
          <a href="javascript:void(0)" onClick={fetchData}>
            Tentar novamente
          </a>
        </p>
      </div>
    );
  }

  if (state.loading) {
    return (
      <div className="flex-col center-a center-b" style={{height: '50vh'}}>
        <span style={{paddingTop: '10em'}}>
          <CircularProgress size={100} />
          <p> Carregando... </p>
        </span>
      </div>
    );
  }

  return (
    <div style={{backgrounColor: 'red', width: '100%', height: 20}}>
      {children({
        videos: state.videos.sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          return 0;
        }),
        fetchData,
        updateData,
      })}
    </div>
  );
}
