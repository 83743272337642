import React, {useState, useEffect} from 'react';
import {makeStyles, CircularProgress} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import User from '../models/User';

const UserList = ({partner = {}}) => {
  const useStyles = makeStyles(theme => ({
    listUsersArea: {
      listStyle: 'none',
      padding: '30px 0px 10px 0px',
      margin: '0',
    },
    listUsersItem: {
      border: 'solid 1px #d3d3d3',
      height: '50px',
      padding: '0 20px',
      borderRadius: '40px',
      display: 'flex',
      marginBottom: '10px',
      alignItems: 'center',
    },
    listUsersLink: {
      fontSize: '1.1876rem',
      paddingLeft: '10px',
      color: '#585858',
    },
    listUsersText: {
      fontSize: '1.1876rem',
      color: '#b1a4aa',
    },
  }));

  const classes = useStyles();

  const [getUsers, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchApi = async () => {
    setLoading(true);
    try {
      const usersWithPartnerId = await User.findUserByPartnerId(partner);
      setUsers(usersWithPartnerId);
    } catch (e) {
      console.log({e});
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <div>
      {loading ? (
        <CircularProgress style={{margin: '30px 0px'}} />
      ) : (
        <ul className={classes.listUsersArea}>
          {getUsers.length ? (
            getUsers.map((user, index) => (
              <>
                <li key={index}>
                  <div className={classes.listUsersItem}>
                    <PersonIcon />
                    <a
                      className={classes.listUsersLink}
                      href={`/users/edit/${user.id}`}>
                      {user.name}
                    </a>
                  </div>
                </li>
              </>
            ))
          ) : (
            <div
              className={classes.listUsersItem}
              style={{justifyContent: 'center'}}>
              {' '}
              <p className={classes.listUsersText}>
                Nenhum usuário relacionado a este parceiro.
              </p>
            </div>
          )}
        </ul>
      )}
    </div>
  );
};

export default UserList;
