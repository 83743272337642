import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  circularProgress: {
    width: 50,
    height: 50,
  },
  uploadItem: {
    '&:hover': {},
  },
}));

export default useStyles;
