import React, { useState, useEffect } from "react"
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	DialogContentText,
	CircularProgress,
	FormControl,
	FormHelperText,
	withStyles,
	Grid,
	TextField,
	InputAdornment
} from "@material-ui/core"
import api from "../../core/api"
import { useSnackbar } from "notistack"
import { Formik } from "formik"
import { string, object } from "yup" // for only what you need
import LockIcon from "@material-ui/icons/Lock"

const ResetPasswordPage = ({ match, history }) => {
	const { enqueueSnackbar } = useSnackbar()
	const token = window.location.search.replace("?", "")
	const [form, setForm] = useState("")
	const [loading, setLoading] = useState(true)

	let password = ""

	const StyledDialog = withStyles({
		paper: { borderRadius: "1em 1em" }
	})(Dialog)

	const StyledButton = withStyles({
		root: {
			borderRadius: "40px",
			height: 60,
			background: "linear-gradient(21deg, #008f9d 0%,#68b43c 57%)"
		}
	})(Button)

	const StyledInput = withStyles({
		root: {
			borderRadius: "40px",
			height: 60,
			border: "solid 1px #d3d3d3",
			padding: "0 20px"
		}
	})(TextField)

	const onForgot = async form => {
		setLoading(true)
		try {
			await api.post(`/auth/reset`, form).then(res => {
				if (res.status === 200) {
					enqueueSnackbar("Senha Atualizada com Successo!")
					setTimeout(() => {
						window.location = "https://medita.com.br"
					}, 2000)
				}
			})
		} catch (e) {
			setLoading(false)
			return enqueueSnackbar("Erro, tente novamente mais tarde")
		}
	}

	const fetchEmail = async () => {
		setLoading(true)
		try {
			await api.get(`/auth/forgot/${token}`).then(res => {
				const { data: email } = res
				setForm({ ...form, email: email })
				setLoading(false)
			})
		} catch (e) {
			console.log({ e })

			// enqueueSnackbar(e)
			setLoading(false)
		}
	}

	const validationSchema = () =>
		object().shape({
			password: string()
				.min(6, "A senha deve ter no mínimo 6 caracteres")
				.required("Preencha o campo de senha"),
			newPassword: string().oneOf([password], "As senhas não conferem")
		})

	useEffect(() => {
		token && fetchEmail()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token])

	return (
		<Formik
			initialValues={{ password: "", newPassword: "" }}
			validationSchema={validationSchema}
			onSubmit={(values, actions) => {
				const postForm = {
					email: form.email,
					token: token,
					password: values.password
				}
				onForgot(postForm)
				setTimeout(() => {
					actions.setSubmitting(false)
				}, 1000)
			}}>
			{formikProps => {
				if (formikProps.values.password.length > 0) {
					password = formikProps.values.password
				}
				return (
					<StyledDialog open={true}>
						{loading ? (
							<DialogContent>
								<DialogContentText>
									Carregando, aguarde...
								</DialogContentText>
								<div
									className="flex-row center-b center-a"
									style={{ height: "15em" }}>
									<CircularProgress size={100} />
								</div>
							</DialogContent>
						) : (
							<>
								<DialogContent>
									<DialogContentText
										style={{ paddingTop: 20 }}>
										Olá, escolha uma nova senha para o
										MeditaBem.
									</DialogContentText>

									<FormControl
										style={{ paddingTop: 20 }}
										fullWidth
										error={formikProps.errors.password}>
										<StyledInput
											id="password"
											placeholder="Nova senha"
											type="password"
											value={formikProps.values.password}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<LockIcon />
													</InputAdornment>
												)
											}}
											onChange={e => {
												formikProps.setFieldValue(
													"password",
													e.target.value
												)
											}}
										/>

										<FormHelperText id="component-error-text">
											{formikProps.errors.password}
										</FormHelperText>
									</FormControl>

									<FormControl
										fullWidth
										style={{ paddingTop: 10 }}
										error={formikProps.errors.newPassword}>
										<StyledInput
											id="password"
											placeholder="Corfime a senha"
											type="password"
											value={
												formikProps.values.newPassword
											}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<LockIcon />
													</InputAdornment>
												)
											}}
											onChange={e => {
												formikProps.setFieldValue(
													"newPassword",
													e.target.value
												)
											}}
										/>

										<FormHelperText id="component-error-text">
											{formikProps.errors.newPassword}
										</FormHelperText>
									</FormControl>
								</DialogContent>
							</>
						)}
						<DialogActions>
							<Grid container justify="center">
								<Grid
									item
									xs={8}
									style={{
										paddingBottom: 20
									}}>
									<StyledButton
										disabled={loading}
										type="submit"
										fullWidth
										onClick={formikProps.handleSubmit}
										variant="contained"
										color="primary">
										Enviar
									</StyledButton>
								</Grid>
							</Grid>
						</DialogActions>
					</StyledDialog>
				)
			}}
		</Formik>
	)
}

export default ResetPasswordPage
