import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import FileContext from "../../../components/singletons/FileContext";
import Dashboard from "../../../layout/Dashboard";
import MultimidiaForm from "../../../components/forms/MultimidiaForm";
import { useAppContext } from "../../../components/singletons/AppContext";
import { withRouter } from "react-router-dom";
import useReduxState from "../../../core/useReduxState";
import api from "../../../core/api";

const e = (f) => f();

const MediasEdit = ({ history, match }) => {
  const [partners, setPartners] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetching = async () => {
      await fetchPartners();
      await fetchObjectives();
      await fetchCategories();
      await fetchVideo();
    };

    fetching();
  }, [history]);

  const [getFileState, setFileState] = useReduxState({
    src: null,
    crop: { unit: "%", width: 100, aspect: 3 / 3 },
    imageRef: null,
    croppedImageUrl: "",
    blobFile: "",
  });

  const [getState, setState] = useReduxState({
    video: null,
    loading: true,
  });

  const fetchVideo = async () => {
    await api.get(`/videos/${match.params.mediaId}`).then(({ data: video }) => {
      setState({
        video,
        loading: false,
      });
    });
  };

  const fetchObjectives = async () => {
    const objectivesRequest = await api.get("objectives");
    if (objectivesRequest.status === 200) {
      setObjectives(objectivesRequest.data);
    }
  };

  const fetchCategories = async () => {
    const categoriesRequest = await api.get("categories");
    if (categoriesRequest.status === 200) {
      setCategories(categoriesRequest.data);
    }
  };

  const fetchPartners = async () => {
    await api.get("/partners").then(({ data: partners }) => {
      const newPartners = partners
        .sort((a, b) => {
          if (a.artisticName > b.artisticName) {
            return 1;
          }
          if (a.artisticName < b.artisticName) {
            return -1;
          }
          return 0;
        });

      setPartners(newPartners);
    });
  };

  const { video, loading } = getState();

  const partner = partners.filter(
    (partner) => partner.id === (video && video.partnerId)
  );

  return (
    <FileContext.Provider value={{ ...getFileState(), setFileState }}>
      <Dashboard>
        {e(() => {
          if (loading) {
            return (
              <div
                className="flex-col center-a center-b"
                style={{ height: "50vh" }}
              >
                <span style={{ paddingTop: "10em" }}>
                  <CircularProgress size={100} />
                  <p> Carregando... </p>
                </span>
              </div>
            );
          }
          return (
            <MultimidiaForm
              midia={{
                ...video,
                partner: partner[0],
                author: partner[0].artisticName,
              }}
              partners={partners}
              categories={categories}
              objectives={objectives}
              type="video"
              history={history}
            />
          );
        })}
      </Dashboard>
    </FileContext.Provider>
  );
};

export default MediasEdit;
