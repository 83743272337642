import React, {useEffect, useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import UserItem from '../../components/UserItem';
import api from '../../core/api';
import {
  CircularProgress,
  List,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Button,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Empresas from '../../models/Empresas';
import styles from './styles.module.css';

import ListWaitingItem from '../../components/ListWaitingItem';

const ListWaiting = ({history}) => {
  const [users, setUsers] = useState([]);
  const [getEmpresas, setEmpresas] = useState([]);
  const [selectEmpresa, setSelectEmpresa] = useState('');
  const [firstLoading, setFirstLoading] = useState(true);
  const [isLoading, setIsFirstLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filterValue, setFilterValue] = useState('');
  const [onboarding, setOnboarding] = useState();
  const [error, setError] = useState(false);
  const adminGestor = JSON.parse(localStorage.getItem('adminGestor'));

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleFilterChange = event => {
    setFilterValue(event.target.value);
    setCurrentPage(1);
  };

  const handleOnboarding = event => {
    setOnboarding(event.target.value);
    setCurrentPage(1);
  };

  async function fetchUsers() {
    try {
      if (users.length < 1) {
        setFirstLoading(true);
      }
      setIsFirstLoading(true);

      const filters = {
        name: filterValue,
        onboarding: onboarding,
        company: selectEmpresa,
      };

      const {data} = await api.get('/waitinglist', {
        params: {
          page: currentPage,
          pageSize,
          filters,
          onboarding,
        },
      });
      setUsers(data.users);
      setTotalPages(data.totalUsers);
    } catch (err) {
      setError(true);
    } finally {
      setError(false);
      setFirstLoading(false);
      setIsFirstLoading(false);
    }
  }

  const fetchEmpresa = async () => {
    try {
      const allEmpresas = await Empresas.findAll();
      setEmpresas(allEmpresas);
    } catch (e) {
      // console.log({e});
    }
  };

  useEffect(() => {
    fetchEmpresa();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [currentPage, pageSize, filterValue, onboarding, selectEmpresa]);

  if (error) {
    return (
      <div>
        <p style={{textAlign: 'center'}}>Ocorreu um erro ao obter os dados.</p>
        <p style={{textAlign: 'center'}}>
          <a href="javascript:void(0)" onClick={fetchUsers}>
            Tentar novamente
          </a>
        </p>
      </div>
    );
  }

  return (
    <Dashboard>
      <div className={styles['filter-bar']}>
        <FormControl>
          <TextField
            variant="outlined"
            label="Buscar por"
            value={filterValue}
            onChange={handleFilterChange}
          />
        </FormControl>
        {adminGestor && (
          <FormControl>
            <InputLabel id="subscription-type-label">Onboarding?</InputLabel>
            <Select
              labelId="subscription-type-label"
              className={styles.select}
              variant="outlined"
              placeholder=""
              value={onboarding}
              onChange={handleOnboarding}>
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={true}>Sim</MenuItem>
              <MenuItem value={false}>Não</MenuItem>
            </Select>
          </FormControl>
        )}
        {adminGestor && (
          <FormControl>
            <InputLabel id="subscription-type-label">Empresa</InputLabel>
            <Select
              className={styles.select}
              variant="outlined"
              value={selectEmpresa}
              label="Selecione a empresa"
              onChange={e => setSelectEmpresa(e.target.value)}>
              {getEmpresas &&
                getEmpresas.map(e => {
                  return <MenuItem value={e.id}>{e.nomeFantasia}</MenuItem>;
                })}
            </Select>
          </FormControl>
        )}

        <div>
          {!window.location.pathname.match(/candidates/) && (
            <Button
              sx={{borderRadius: 10}}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => history.push(`${window.location.pathname}/add`)}>
              Adicionar Beneficiados
            </Button>
          )}
        </div>
      </div>
      {firstLoading ? (
        <div className="flex-col center-a center-b" style={{height: '50vh'}}>
          <span style={{paddingTop: '10em'}}>
            <CircularProgress size={100} />
            <p> Carregando... </p>
          </span>
        </div>
      ) : (
        <div>
          <TablePagination
            component="div"
            count={totalPages}
            page={currentPage - 1}
            labelRowsPerPage={'Itens por página:'}
            onChangePage={handleChangePage}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          {isLoading ? (
            <div
              className="flex-col center-a center-b"
              style={{height: '50vh'}}>
              <span style={{paddingTop: '10em'}}>
                <CircularProgress size={100} />
                <p> Carregando... </p>
              </span>
            </div>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>CPF</TableCell>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Categoria</TableCell>
                  {adminGestor && <TableCell>Onboarding</TableCell>}
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(user => (
                  <ListWaitingItem
                    key={user.id}
                    history={history}
                    user={user}
                    fetchUsers={fetchUsers}
                  />
                ))}
              </TableBody>
            </Table>
          )}
          <TablePagination
            component="div"
            count={totalPages}
            page={currentPage - 1}
            labelRowsPerPage={'Itens por página:'}
            onChangePage={handleChangePage}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
    </Dashboard>
  );
};

export default ListWaiting;
