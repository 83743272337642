import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Player, BigPlayButton} from 'video-react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';

import '../../node_modules/video-react/dist/video-react.css';
import api, {apiStatus} from '../core/api';
import {IconButton, Grid} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import request from 'superagent';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    margin: '0 auto',
  },
  media: {
    height: 200,
  },
  content: {
    maxHeight: 300,
  },
});

const VideoCard = ({video, history}) => {
  const classes = useStyles();

  const [thumbnailURI, setThumbnail] = useState('');
  const [videoURI, setVideo] = useState('');
  const [status, setStatus] = useState('');

  const deleteVideo = async () => {
    try {
      const {data} = await api.delete(`/videos/${video.id}`);
    } catch (e) {
      console.log('Upload Error');
      console.dir(e);
    }
  };

  const getVideo = async () => {
    try {
      Promise.all([
        api.get(`/videos/stream/public/${video.id}/thumbnail`).then(res => {
          setThumbnail(res.status);
        }),
        api.get(`/videos/stream/public/${video.id}`).then(res => {
          setVideo(res.status);
        }),
      ]);
    } catch (e) {
      console.log(e, 'ERRO');
    }
  };

  // const getStatus = async link => {
  // 	try {
  // 		alert(link)
  // 		await Axios.get(link).then(res => {
  // 			alert(JSON.stringify(res))
  // 			setStatus(res.status)
  // 		})
  // 	} catch (e) {}
  // }

  useEffect(() => {
    getVideo();
  }, []);

  // useEffect(() => {
  // 	getStatus(thumbnailURI)
  // }, [thumbnailURI])

  //
  return (
    <Grid xs={12}>
      <Card className={classes.card}>
        {/* {alert(JSON.stringify(video, null, 4))} */}
        {/* <CardActionArea> */}
        <CardMedia
          className={classes.media}
          //   image="/static/images/cards/contemplative-reptile.jpg"
          title="Contemplative Reptile">
          <Player
            playsInline
            poster={`https://api.meditabemestar.com.br/thumbnails/${video.thumbnail}`}
            src={`https://api.meditabemestar.com.br/videos/${video.filename}`}>
            <BigPlayButton position="center" />
          </Player>
        </CardMedia>

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {video.title}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {video.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Grid container alignContent="center" xs={12}>
            <Grid item justify="flex-start" xs={9}>
              <Button title="Capa">
                Capa
                {thumbnailURI === 200 ? (
                  <CheckCircleIcon style={{color: 'green'}} />
                ) : (
                  <ErrorIcon style={{color: 'red'}} />
                )}
              </Button>
            </Grid>
            <Grid item justify="flex-end">
              <Button aria-label="add to favorites">
                {videoURI === 200 ? (
                  <CheckCircleIcon style={{color: 'green'}} />
                ) : (
                  <ErrorIcon style={{color: 'red'}} />
                )}
                Vídeo
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default VideoCard;
