import React from 'react';
import Dashboard from '../../layout/Dashboard';
import UserForm from '../../components/forms/UserForm';

const UserAdminAdd = ({history}) => {
  const user = {
    name: '',
    nickName: '',
    email: '',
    password: '',
    confirmPassword: '',
    special: false,
    partner_id: '',
    author: '',
    empresa_id: '',
    nomeEmpresa: '',
    isAdmin: true,
    version_app: '',
    version_device: '',
  };

  return (
    <Dashboard>
      <UserForm history={history} user={user} />
    </Dashboard>
  );
};

export default UserAdminAdd;
