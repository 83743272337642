import React from "react"
import ReactPhoneInput from "react-phone-input-2"
//import "react-phone-input-2/dist/style.css"

const phoneInputOnChange = onChange => raw => onChange(raw)

const PhoneInput = ({ onChange, ...props }) => (
	<ReactPhoneInput
		onChange={phoneInputOnChange(onChange)}
		defaultCountry="br"
		// onlyCountries={["br"]}
		masks={{ br: "+.. (..) . ....-...." }}
		{...props}
	/>
)

export default PhoneInput
