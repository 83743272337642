import React from 'react';
import Dashboard from '../../layout/Dashboard';
import {Divider, Grid, Button, List} from '@material-ui/core';
import EmpresaContainer from '../../components/containers/EmpresasContainer';
import EmpresaItem from '../../components/EmpresaItem';
import AddIcon from '@material-ui/icons/Add';

import {useAppContext} from '../../components/singletons/AppContext';

const EmpresasPage = ({history}) => {
  const {user, search} = useAppContext();

  return (
    <Dashboard>
      <Grid container justify="flex-end">
        {!window.location.pathname.match(/candidates/) && (
          // <IconButton
          //   onClick={() =>
          //     history.push(
          //       window.location.pathname.includes('medias')
          //         ? '/uploads?startUpload=true'
          //         : `${window.location.pathname}/add`,
          //     )
          //   }>
          //   <AddIcon />
          // </IconButton>

          <Button
            sx={{borderRadius: 10}}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => history.push(`${window.location.pathname}/add`)}>
            Nova Empresa
          </Button>
        )}
      </Grid>
      <EmpresaContainer>
        {empresas =>
          empresas.map(user => {
            return <EmpresaItem history={history} user={user} />;
          })
        }
      </EmpresaContainer>
    </Dashboard>
  );
};

export default EmpresasPage;
