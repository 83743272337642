import React, { useEffect } from 'react'
import Dashboard from '../../layout/Dashboard'
import VideosContainer from '../../components/containers/VideosContainer'
import useReduxState from '../../core/useReduxState'
import api from '../../core/api'
import CourseForm from '../../components/forms/CourseForm'

const e = (f) => f()

const EditCourse = ({ key, match }) => {
	const [ getState, setState ] = useReduxState({
		article: null,
		loading: true
	})

	useEffect(
		() => {
			api.get(`/articles/${match.params.id}`).then(({ data: article }) => setState({ article, loading: false }))
		},
		[ key ]
	)

	const { article, loading } = getState()

	return (
		<Dashboard>
			{e(() => {
				if (loading) {
					return 'Carregando...'
				}
				return <CourseForm article={article} />
			})}
		</Dashboard>
	)
}

export default EditCourse
