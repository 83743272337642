import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core'

import Dashboard from "../../../layout/Dashboard"
import KeywordsForm from "../../../components/forms/KeywordsForm"
import api from '../../../core/api';

function KeywordsAddEdit({ history, match }) {
  const [categories, setCategories] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [loading, setLoading] = useState(true);

	const fetchApi = async () => {
    try {
      await api.get('/categories').then(({ data: categories }) => {
        setCategories(categories);
      });
      if(match.params.id){
        await api.get(`/keywords/${match.params.id}`).then(({ data: keyword }) => {
          setKeyword(keyword);
        });
      }
      setLoading(false);
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    fetchApi();
  }, []);


	return (
		<Dashboard>
      {loading ? 
        <div className="flex-col center-a center-b" style={{ height: '50vh' }}>
          <span style={{ paddingTop: '10em' }}>
            <CircularProgress size={100} />
            <p> Carregando... </p>
          </span>
        </div>
      : <KeywordsForm 
          categories={categories}
          keyword={keyword}
          history={history}
        />
      }
		</Dashboard>
	)
}

export default KeywordsAddEdit
