import React from "react"
import { Button, withStyles } from "@material-ui/core"

const ButtonMedita = ({ children, ...props }) => {
	const StyledButton = withStyles({
		root: {
			borderRadius: "40px",
			height: 60,
			background: "linear-gradient(21deg, #008f9d 0%,#68b43c 57%)",
			color: "#fff"
		}
	})(Button)

	return (
		<StyledButton {...props} fullWidth variant="contained">
			{children}
		</StyledButton>
	)
}

export default ButtonMedita
