import React from 'react';
import Dashboard from '../../layout/Dashboard';
import {Grid, Button} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SpecialContainer from '../../components/containers/SpecialsContainer';
import SpecialItem from '../../components/SpecialItem';

const SpecialPage = ({history}) => {
  return (
    <Dashboard>
      <Grid container justify="flex-end">
        {!window.location.pathname.match(/candidates/) && (
          <Button
            sx={{borderRadius: 10}}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => history.push(`${window.location.pathname}/add`)}>
            Novo tipo de parceria
          </Button>
        )}
      </Grid>
      <SpecialContainer>
        {special =>
          special.map(user => {
            return <SpecialItem history={history} user={user} />;
          })
        }
      </SpecialContainer>
    </Dashboard>
  );
};

export default SpecialPage;
