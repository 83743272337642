import React, {useState, useEffect} from 'react';
import {
  Grid,
  Avatar,
  Typography,
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Popover,
} from '@material-ui/core';
import {AccountBalance} from '@material-ui/icons';

import {Link} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/MoreVert';
import api from '../core/api';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {useSnackbar} from 'notistack';
import Confirm from './Confirm';
import {useAppContext} from './singletons/AppContext';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const EmpresaItem = ({user}) => {
  const empresa = {...user};
  const {enqueueSnackbar} = useSnackbar();
  const {onReloadData} = useAppContext();

  const onDeleteEmpresa = async () => {
    const res = await Confirm('Deseja realmente remover esta empresa?');
    if (res) {
      try {
        await api.delete(`/empresas/${empresa.id}`);
        onReloadData();
        enqueueSnackbar('Deletado com Sucesso!');
      } catch (e) {
        console.log(e);
      }
    }
  };

  const initialLetter = empresa.nomeFantasia || '';

  return (
    <React.Fragment>
      <ListItem alignItems="center">
        <ListItemAvatar>
          <Avatar
            src={empresa.thumbnailURL}
            alt="Remy Sharp"
            style={{textTransform: 'uppercase'}}>
            {empresa && initialLetter[0]}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={empresa.nomeFantasia}
          secondary={
            <Grid container>
              <Grid item xs={5}>
                <ListItemIcon>
                  <AccountBalance />
                  <Typography
                    style={{marginLeft: 8}}
                    variant="body2"
                    className="flex-row center-b"
                    color="textPrimary">
                    {empresa.razaoSocial}
                  </Typography>
                </ListItemIcon>
              </Grid>
              <Grid item xs={2}>
                <ListItemIcon>
                  <Typography
                    style={{marginLeft: 8}}
                    variant="body2"
                    className="flex-row center-b"
                    color="textPrimary">
                    CNPJ: {empresa.cnpj}
                  </Typography>
                </ListItemIcon>
              </Grid>
            </Grid>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            component={Link}
            to={`/empresas/edit/${empresa.id}`}
            edge="end">
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDeleteEmpresa} edge="end">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
export default EmpresaItem;
