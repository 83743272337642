import React, { useState, useEffect } from 'react'
import Dashboard from '../../layout/Dashboard'
import PartnerContainer from '../../components/containers/PartnerContainer'
import PartnerForm from '../../components/forms/PartnerForm'
import api from '../../core/api'
import useReduxState from '../../core/useReduxState'
import FileContext from '../../components/singletons/FileContext'

const e = (f) => f()

const EditCandidate = ({ history, match }) => {
	const [ getState, setState ] = useState({
		partner: null,
		loading: true
	})
	const [ getFileState, setFileState ] = useReduxState({
		src: null,
		crop: { unit: '%', width: 100, aspect: 3 / 3 },
		imageRef: null,
		croppedImageUrl: '',
		blobFile: ''
	})

	const fetchPartner = async () => {
		const { data } = await api.get(`/partners/${match.params.partnerId}`)
		console.log(data)
		//console.log(match)
		setState({ data, loading: false })
		//api
			//.get(`/partners/${match.params.partnerId}`)
			//.then(({ data: partner }) => setState({ partner, loading: false }))
	}

	useEffect(() => {
		fetchPartner()
	}, [])

	return (
		<FileContext.Provider value={{ ...getFileState(), setFileState }}>
			<Dashboard>
				{e(() => {
					if (getState.loading) {
						return 'Carregando...'
					}
					return <PartnerForm history={history} partner={getState.data} />
				})}
			</Dashboard>
		</FileContext.Provider>
	)
}

export default EditCandidate
