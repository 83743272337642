import React, {useState, useEffect} from 'react';
import api from '../../core/api';
import {List, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useAppContext} from '../singletons/AppContext';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));
export default function PlansContainer({key, children}) {
  const [state, setState] = useState({
    plans: [],
    loading: true,
    error: null,
  });
  const {setAppState} = useAppContext();

  const classes = useStyles();

  /**
   *
   * @param {import('axios').AxiosInstance} api
   */
  async function fetchData() {
    setState({
      plans: [],
      loading: true,
      error: null,
    });

    try {
      const {data: plans} = await api.get(`/plans`);
      setState({
        ...state,
        plans,
        loading: false,
      });
    } catch (e) {
      if (e.response)
        setState({
          ...state,
          error: {
            payload: e.response.data,
          },
        });
      else setState({...state, error: {}});
    }
  }

  useEffect(() => {
    fetchData();
    setAppState({onReloadData: () => fetchData()});
  }, []);

  if (state.error) {
    return (
      <div>
        <p style={{textAlign: 'center'}}>Ocorreu um erro ao obter os dados.</p>
        <p style={{textAlign: 'center'}}>
          <a href="javascript:void(0)" onClick={fetchData}>
            Tentar novamente
          </a>
        </p>
      </div>
    );
  }

  if (state.loading) {
    return (
      <div className="flex-col center-a center-b" style={{height: '50vh'}}>
        <span style={{paddingTop: '10em'}}>
          <CircularProgress size={100} />
          <p> Carregando... </p>
        </span>
      </div>
    );
  }

  return <List className={classes.root}>{children(state.plans)}</List>;
}
