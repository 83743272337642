import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import api from '../../core/api'

export default function BannersContainer({ key, children }) {
	const [ state, setState ] = useState({
		banners: [],
		loading: true,
		error: null
	})

	async function fetchData() {
		setState({ loading: true, error: null })
		try {
			// Estudos sobre error
			// if (!window.abc) {
			// 	throw new Error("erro")
			// 	window.abc = true
			// }
			const { data: banners } = await api.get(`/banners`)
			setState({ ...state, banners, loading: false })
		} catch (e) {
			if (e.response)
				setState({
					...state,
					error: {
						payload: e.response.data
					}
				})
			else setState({ ...state, error: {} })
		}
	}

	useEffect(
		() => {
			fetchData()
		},
		[ key ]
	)

	if (state.error) {
		return (
			<div>
				<p style={{ textAlign: 'center' }}>Ocorreu um erro ao obter os dados.</p>
				<p style={{ textAlign: 'center' }}>
					<a href="javascript:void(0)" onClick={fetchData}>
						Tentar novamente
					</a>
				</p>
			</div>
		)
	}

	if (state.loading) {
		return (
			<div className="flex-col center-a center-b" style={{ height: '50vh' }}>
				<span style={{ paddingTop: '10em' }}>
					<CircularProgress size={100} />
					<p> Carregando... </p>
				</span>
			</div>
		)
	}

	return (
		<Grid container xs={12}>
			{children({ banners: state.banners, fetchData })}
		</Grid>
	)
}
