import React, {useState, useEffect} from 'react';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

const DrawTableDash = (heads, entries, id, title) => {
  return (
    <Table id={id}>
      <TableHead>
        <TableRow>
          <TableCell style={centralize}>{title}</TableCell>
          <TableCell style={centralize}>Total</TableCell>
          <TableCell style={centralize}>%</TableCell>
          {heads.map(row => {
            return (
              <TableCell style={centralize} colSpan={2}>
                {row}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {entries.map((entry, index) => {
          return (
            <TableRow>
              {entry.map((row, index) => {
                return (
                  <>
                    <TableCell style={centralize}>{row?.dd || '-'}</TableCell>
                    {index > 2 && (
                      <TableCell style={centralize}>{`${row.t ||
                        0}%`}</TableCell>
                    )}
                  </>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const centralize = {
  textAlign: 'center',
  border: '1px solid #d3d3d3',
};

export default DrawTableDash;
