import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {Typography, Paper} from "@material-ui/core"

import api, { apiStatus } from "../core/api"
import { IconButton, Grid } from "@material-ui/core"

import request from "superagent"

const useStyles = makeStyles({
	userContainer: {
		margin: '20px',
	},
	titleContainer: {
		margin: '20px',

	},
	lineContainer: {
		margin: '5px',

	},
	title: {
		fontSize: 18,
	}
})

const AuditCard = ({ audit, partners, objectives, categories, users, date, history }) => {
	const classes = useStyles();

  const [partnerNewName, setPartnerNewName] = useState('');
	const [partnerOldName, setPartnerOldName] = useState('');
	const [categoryNew, setCategoryNew] = useState('');
	const [categoryOld, setCategoryOld] = useState('');
	const [user, setUser] = useState('');
	const [objectivesNew, setObjectivesNew] = useState('');
	const [objectivesOld, setObjectivesOld] = useState('');

	const durationNew = audit.duration_new ? JSON.parse(audit.duration_new) : null;
	const durationOld = audit.duration_old ?JSON.parse(audit.duration_old) : null;

	useEffect(()=>{
    const oldPartner = partners.find(partner => partner.id === audit.partnerId_old);
		const newPartner = partners.find(partner => partner.id === audit.partnerId_new);
		const alterUser = users.find(user => user.id === audit.user_id);
		const alterCategoryNew = categories.find(category => category.id == audit.categories_new);
		const alterCategoryOld = categories.find(category => category.id == audit.categories_old);
		
		const parsedObjectivesNew = JSON.parse(audit.objectives_new);
		let objectivesListNew = [];
		parsedObjectivesNew.map(objective => {
			const findObjective = objectives.find(obj => obj.id == objective);
			objectivesListNew.push(findObjective.name);
		});

		setObjectivesNew(objectivesListNew.join(', '));

		if(audit.objectives_old){
			const parsedObjectivesOld = JSON.parse(audit.objectives_old);
			let objectivesListOld = [];
			parsedObjectivesOld.map(objective => {
				const findObjective = objectives.find(obj => obj.id == objective);
				objectivesListOld.push(findObjective.name);
			});
	
			setObjectivesOld(objectivesListOld.join(', '));
		}


		
		setUser(alterUser?.name);
		setCategoryNew(alterCategoryNew?.name);
		setCategoryOld(alterCategoryOld?.name);

    if(newPartner){
      setPartnerNewName(newPartner.artisticName);
    }
    if(oldPartner){
      setPartnerOldName(oldPartner.artisticName);
    }

	},[]);
	
	return (
		<Grid container>
			<Grid container xs={12} className={classes.userContainer}>
				<Grid item xs={6}>
					<Typography inline variant="h5">Usuário Responsável</Typography> 
					{user}
				</Grid>
				<Grid item xs={6} align="flex-end">
					<Typography variant="h6" align="right">Data</Typography> 
					<Typography align="right">{date}</Typography>
				</Grid>
			</Grid>
			<Grid container xs={12} className={classes.titleContainer} justify="space-between">
				<Grid align="center" item xs={audit.alter_type == 'I' ? 12 : 6 }>
					<Typography variant="h5">Atual</Typography>
				</Grid>
				{ audit.alter_type == 'I' ? null : 
				<Grid align="center" item xs={6}>
					<Typography variant="h5">Anterior</Typography>
				</Grid>}
			</Grid>
			<Grid container xs={12} className={classes.lineContainer} >
				<Grid item xs={audit.alter_type == 'I' ? 12 : 6 }>
					<Typography variant="h6">Título</Typography> 
					<Typography>{audit.title_new}</Typography>
				</Grid>
				{ audit.alter_type == 'I' ? null : 
				<Grid item xs={6}>
					<Typography variant="h6">Título</Typography> 
					<Typography>{audit.title_old}</Typography>
				</Grid>}
			</Grid>
			<Grid container xs={12} className={classes.lineContainer}  >
				<Grid item xs={audit.alter_type == 'I' ? 12 : 6 }>
					<Typography variant="h6">Descrição</Typography> 
					<Typography>{audit.description_new}</Typography>
				</Grid>
				{ audit.alter_type == 'I' ? null : 
				<Grid item xs={6}>
					<Typography variant="h6">Descrição</Typography> 
					<Typography>{audit.description_old}</Typography>
				</Grid>}
			</Grid>
			<Grid container xs={12} className={classes.lineContainer}  >
				<Grid item xs={audit.alter_type == 'I' ? 12 : 6 }>
					<Typography variant="h6">Tipo</Typography>
					<Typography>
					 {audit.audio_new === null ? '' :
						audit.audio_new ? 'Áudio' : 'Vídeo'}
					</Typography>
				</Grid>
				{ audit.alter_type == 'I' ? null : 
				<Grid item xs={6}>
					<Typography variant="h6">Tipo</Typography> 
					<Typography>
						{audit.audio_old === null ? '' :
						audit.audio_old ? 'Áudio' : 'Vídeo'}
					</Typography>
				</Grid>}
			</Grid>
			<Grid container xs={12} className={classes.lineContainer}  >
				<Grid item xs={audit.alter_type == 'I' ? 12 : 6 }>
					<Typography variant="h6">Autor</Typography> 
					<Typography>{partnerNewName}</Typography>
				</Grid>
				{ audit.alter_type == 'I' ? null : 
				<Grid item xs={6}>
					<Typography variant="h6">Autor</Typography> 
					<Typography>{partnerOldName}</Typography>
				</Grid>}
			</Grid>
			<Grid container xs={12} className={classes.lineContainer}  >
				<Grid item xs={audit.alter_type == 'I' ? 12 : 6 }>
					<Typography variant="h6">Vídeo de Apresentação</Typography> 
					<Typography>
						{audit.isPartnerPresentationVideo_new === null ? '' : 
						audit.isPartnerPresentationVideo_new ? 'Sim' : 'Não'}
					</Typography>
				</Grid>
				{ audit.alter_type == 'I' ? null : 
				<Grid item xs={6}>
					<Typography variant="h6">Vídeo de Apresentação</Typography> 
					<Typography>
						{audit.isPartnerPresentationVideo_old === null ? '' :
						audit.isPartnerPresentationVideo_old ? 'Sim' : 'Não'}
					</Typography>
				</Grid>}
			</Grid>
			<Grid container xs={12} className={classes.lineContainer}  >
				<Grid item xs={audit.alter_type == 'I' ? 12 : 6 }>
					<Typography variant="h6">Tempo</Typography> 
					<Typography>
						{durationNew ? `${durationNew.minutes}min ${durationNew.seconds}s` : ''}
					</Typography>
				</Grid>
				{ audit.alter_type == 'I' ? null : 
				<Grid item xs={6}>
					<Typography variant="h6">Tempo</Typography>
					<Typography>
						{durationOld ? `${durationOld.minutes}min ${durationOld.seconds}s` : ''}
					</Typography>
				</Grid>}
			</Grid>
			<Grid container xs={12} className={classes.lineContainer}  >
				<Grid item xs={audit.alter_type == 'I' ? 12 : 6 }>
					<Typography variant="h6">Objetivos</Typography> 
					<Typography>{objectivesNew}</Typography>
				</Grid>
				{ audit.alter_type == 'I' ? null : 
				<Grid item xs={6}>
					<Typography variant="h6">Objetivos</Typography> 
					<Typography>{objectivesOld}</Typography>
				</Grid>}
			</Grid>
			<Grid container xs={12} className={classes.lineContainer}  >
				<Grid item xs={audit.alter_type == 'I' ? 12 : 6 }>
					<Typography variant="h6">Categorias</Typography> 
					<Typography>{categoryNew}</Typography>
				</Grid>
				{ audit.alter_type == 'I' ? null : 
				<Grid item xs={6}>
					<Typography variant="h6">Categorias</Typography> 
					<Typography>{categoryOld}</Typography>
				</Grid>}
			</Grid>
		</Grid>
	)
}

export default AuditCard
