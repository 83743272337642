import React, {useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import Grid from '@material-ui/core/Grid';
import PartnerItem from '../../components/PartnerItem';
import PartnerContainer from '../../components/containers/PartnerContainer';

import {useAppContext} from '../../components/singletons/AppContext';

const CandidatesPage = ({history}) => {
  const {user, search} = useAppContext();

  return (
    <Dashboard>
      <PartnerContainer>
        {({partners, fetchData}) => {
          if (
            partners.filter(partner => partner.candidate_aproved !== true)
              .length === 0
          ) {
            return (
              <div className="flex-row center-a center-b">
                Esta lista não possui nenhum candidato
              </div>
            );
          } else {
            return partners
              .filter(partner => partner.candidate_aproved !== true)
              .map((partner, i) => {
                if (
                  (partner.artisticName || '').toLowerCase().includes(search) ||
                  partner.name.toLowerCase().includes(search)
                ) {
                  return (
                    <PartnerItem
                      history={history}
                      user={partner}
                      fetchData={fetchData}
                    />
                  );
                }
              });
          }
        }}
      </PartnerContainer>
    </Dashboard>
  );
};

export default CandidatesPage;
