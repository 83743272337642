import React from 'react';
import {
  TextField,
  withStyles,
  FormControl,
  FormHelperText,
  InputAdornment,
  FormLabel,
} from '@material-ui/core';
import '../../layout/css/InputStyle.css';
import AnimatedView from '../AnimatedView';

const StyledInput = withStyles({
  root: {
    borderRadius: '40px',
    height: 50,
    border: 'solid 1px #d3d3d3',
    padding: '0 20px',
  },
})(TextField);

const InputText = ({label, errorMessage, leftIcon, rigthElement, ...props}) => (
  <FormControl fullWidth error={errorMessage}>
    {props.value ? (
      <AnimatedView>
        <FormLabel>{label}</FormLabel>
      </AnimatedView>
    ) : (
      <div id="box"></div>
    )}
    <StyledInput
      style={{
        ...props.style,
        height: props.id == 'description' && '100%',
      }}
      placeholder={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{color: '#d3d3d3'}}>
            {leftIcon}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" style={{color: '#d3d3d3'}}>
            {rigthElement}
          </InputAdornment>
        ),
      }}
      autoComplete={`${label.includes('E-mail') ? 'email' : 'off'}`}
      {...props}
    />

    {errorMessage && (
      <FormHelperText id="component-error-text">{errorMessage}</FormHelperText>
    )}
  </FormControl>
);

export default InputText;
