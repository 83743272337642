import React, {useState, useEffect} from 'react';
import {Grid, CircularProgress} from '@material-ui/core';
import api from '../../core/api';
import {useAppContext} from '../singletons/AppContext';

export default function AuditsContainer({key, children}) {
  const [state, setState] = useState({
    audits: [],
    partners: [],
    objectives: [],
    categories: [],
    users: [],
    loading: true,
    error: null,
  });
  const {setAppState} = useAppContext();

  async function fetchData() {
    setState({loading: true, error: null});
    try {
      const {data: audits} = await api.get(`/audit`);
      const {data: partners} = await api.get('/partners');
      const {data: objectives} = await api.get('/objectives');
      const {data: categories} = await api.get('/categories');
      const {data: users} = await api.get('/users');

      setState({
        ...state,
        audits,
        partners,
        objectives,
        categories,
        users,
        loading: false,
      });
    } catch (e) {
      if (e.response)
        setState({
          ...state,
          error: {
            payload: e.response.data,
          },
        });
      else setState({...state, error: {}});
    }
  }

  useEffect(() => {
    fetchData();
    setAppState({onReloadData: () => fetchData()});
  }, []);

  if (state.error) {
    return (
      <div>
        <p style={{textAlign: 'center'}}>Ocorreu um erro ao obter os dados.</p>
        <p style={{textAlign: 'center'}}>
          <a href="javascript:void(0)" onClick={fetchData}>
            Tentar novamente
          </a>
        </p>
      </div>
    );
  }

  if (state.loading) {
    return (
      <div className="flex-col center-a center-b" style={{height: '50vh'}}>
        <span style={{paddingTop: '10em'}}>
          <CircularProgress size={100} />
          <p> Carregando... </p>
        </span>
      </div>
    );
  }

  return (
    <Grid>
      {children({
        audits: state.audits,
        partners: state.partners,
        objectives: state.objectives,
        categories: state.categories,
        users: state.users,
        fetchData,
      })}
    </Grid>
  );
}
