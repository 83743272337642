import React from 'react';
import {makeStyles} from '@material-ui/core';
import SectionMain from '../../../components/SectionMain';
import Title from '../../../components/Title';
// import FooterSection from '../../../components/FooterSection';
// import banner from '../../../assets/images/banners/partner_desktop.jpg';
import logo from '../../../assets/images/medita_logo_horizontal.png';

const useStyles = makeStyles({
  root: {
    padding: theme =>
      theme.noPadding ? 0 : theme.miniPadding ? '10px 0' : '15px 0',
    justifyContent: theme => theme.position,
  },
  container: {
    padding: '20px 4vw',
    justifyContent: 'center',

    width: '80%',
    alignSelf: 'center',
  },
  banner: {
    background: '#f8f9fa',
    height: 400,
    justifyContent: 'space-between',
    padding: '10px 6vw',
  },
  leftSection: {padding: '25px 0', maxHeight: 400},
  rightSection: {justifyContent: 'center'},
  image: {width: '45vw', maxWidth: 400},
  title: {
    flexDirection: 'column',
    letterSpacing: 1,
    justifyContent: 'center',
    padding: '0 0 30px',
    fontSize: 25,
  },
  cursive: {
    color: '#008393',
    letterSpacing: 2,
    fontFamily: 'adobe-caslon-pro, serif',
    fontStyle: 'italic',
    fontSize: 38,
  },
  text: {
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 20,
    textAlign: 'justify',
  },
  subText: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 195,
    padding: '20px 4vw',
  },
  divImage: {
    background: '#EFEFEF',
    width: '100%',
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: '30px',
  },
  img: {cursor: 'pointer'},
  footer: {
    background: '#EFEFEF',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignSelf: 'center',
    padding: '30px',
  },
  contato: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
  },
});

const PrivacyPageDesktop = () => {
  const classes = useStyles();

  return (
    <SectionMain noPadding style={{display: 'flex', justifyContent: 'center'}}>
      <div className={classes.divImage}>
        <img
          alt="MeditaBem"
          src={logo}
          height="auto"
          width="400px"
          className={classes.img}
          onClick={() => {}}
        />
        <div>&nbsp;</div>
      </div>
      <SectionMain className={classes.container}>
        <SectionMain className={classes.title}>
          <Title
            fontWeight="800"
            size="large"
            align="center"
            capitalize="uppercase">
            {'POLÍTICA DE PRIVACIDADE'}
          </Title>
        </SectionMain>
        <SectionMain className={classes.container}>
          <SectionMain className={classes.title}>
            <Title
              fontWeight="800"
              size="medium"
              align="center"
              capitalize="uppercase">
              {'POLÍTICA DE PRIVACIDADE'}
            </Title>
          </SectionMain>
          <SectionMain className={classes.text}>
            <Title
              fontWeight="800"
              size="medium"
              align="left"
              capitalize="uppercase">
              Seja bem-vindo à política de privacidade do Medita Bem
            </Title>
            <p>
              O Medita Bem preocupa-se com sua privacidade e acreditamos que
              você deve estar no controle sobre seus dados pessoais. Por isso,
              preparamos essa Política de Privacidade para ajudar você a
              entender melhor como usamos seus dados pessoais em nossas páginas,
              na utilização de nossos serviços, sites, aplicativos, softwares,
              formulários físicos e demais plataformas que possam conter seus
              dados pessoais.
            </p>
            <Title
              fontWeight="800"
              size="medium"
              align="left"
              capitalize="uppercase">
              1. Para que serve esse documento
            </Title>
            <p>
              Criamos essa Política de Privacidade para ajudar você a entender
              quais são as práticas adotadas pelo Medita Bem ao realizar o
              tratamento de seus dados pessoais e o motivo pelo qual seus dados
              são solicitados ou coletados, demonstrando o nosso compromisso com
              a boa-fé, segurança, privacidade e transparência.
            </p>
            <p>
              Esta política é aplicável à todas as pessoas que acessarem o
              Aplicativo Medita Bem e poderá ser aplicável a outras formas de
              coleta de dados pelo Medita Bem, que permitam qualquer prestação
              de serviços ou fornecimento de produtos ou conteúdo.
            </p>
            <p>
              Ao utilizar as Plataformas Digitais nós disponibilizamos o acesso
              a esta política onde solicitamos a informação você que leu,
              compreendeu e aceita este documento, além de concordar em ficar
              vinculado por ele. Caso tenha dúvidas sobre o entendimento a
              respeito de qualquer disposição desta Política de Privacidade,
              entre em contato com o DPO (encarregado dos dados) por meio do
              canal oficial disponível somente para atender os titulares dos
              dados pessoais, aqui mencionado no final desta Política. Se você
              não concordar (ou não puder cumprir) com este documento, você não
              poderá usar o Aplicativo Medita Bem ou demais serviços que façam
              uso de seus dados pessoais conforme as diretrizes desta política.
            </p>
            <p>
              Alguns serviços oferecidos pelo Medita Bem podem estar sujeitos a
              termos e condições adicionais especificados pelo Medita Bem ou
              terceiros periodicamente, os quais serão incorporados nesta desta
              Política de Privacidade para os quais você também poderá ter de
              dar seu consentimento.
            </p>
            <Title
              fontWeight="800"
              size="large"
              align="left"
              capitalize="uppercase">
              2. Sobre seus dados pessoais
            </Title>
            <p>
              Neste item vamos falar um pouco sobre seus dados pessoais no
              Medita Bem, considerando todo o ciclo de tratamento desde a coleta
              até a eliminação dos dados.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              2.1. Como coletamos seus dados pessoais
            </Title>
            <p>
              Os seus dados pessoais poderão ser coletados ao realizar o seu
              cadastro no nosso Aplicativo mobile e nosso site, acessa quaisquer
              de nossos produtos ou serviços ou participa de eventos, campanhas
              e promoções conforme abaixo:
            </p>
            <ul>
              <li>Durante a navegação de nossos sites através de Cookies;</li>
              <li>
                Compartilhados através de terceiros, quando você autoriza em
                plataformas de terceiros o compartilhamento de seus dados
                pessoais;
              </li>
              <li>
                Ao efetuar a instalação de algum software ou aplicação
                desenvolvida pelo próprio Medita Bem ou de empresas parceiras,
                neste momento você será consultado sobre a permissão de acesso
                conforme o dispositivo utilizado;
              </li>
              <li>Eventos organizados ou com a participação do Medita Bem;</li>
              <li>
                Com a aquisição de algum produto ou serviço oferecido pelo
                Medita Bem ou empresas do Grupo;
              </li>
            </ul>
            <p>
              <b>Importante:</b> No momento da coleta o Medita Bem
              disponibilizará o acesso a esta política para leitura e respectivo
              aceite (exceto quando a coleta ocorrer em plataforma de
              terceiros).
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              2.2. Quais dados pessoais coletamos de você
            </Title>
            <p>
              Dados pessoais são todos aqueles que possam identificá-lo, com
              isso podem ser dados cadastrais (como nome, e-mail, endereço),
              assim como dados de registro de navegação (como IP de
              dispositivos, histórico de navegação e cookies), o mínimo
              necessário para oferecer a melhor experiência.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              2.3. Vamos detalhar um pouco os tipos dos dados?
            </Title>
            <p>
              <b>Dados cadastrais.</b> Poderão ser utilizados pelo Medita Bem
              diversos dados pessoais, os quais poderão incluir, mas não se
              limitam a:
            </p>
            <ul>
              <li>
                Dados cadastrais, tais como: nome, documentos de identificação,
                nacionalidade, endereço, data de nascimento, filiação, gênero,
                entre outros.
              </li>
              <li> Dados de contato tais como: telefone, e-mail e endereço.</li>
              <li> Dados sobre sua atividade, trabalho, profissão.</li>
              <li>
                Dados coletados de terceiros, incluindo os já acima mencionados,
                suas fotografias e demais documentos de identificação.
              </li>
            </ul>
            <p>
              <b>Dados de navegação.</b> Poderão ser utilizados pelo Medita Bem
              os seguintes dados de navegação, os quais poderão incluir, mas não
              se limitam a:
            </p>
            <ul>
              <li> Endereço de IP do dispositivo utilizado por você.</li>
              <li> Interações realizadas e uso de perfil.</li>
              <li>
                Dados técnicos, como informações de URL, conexão de rede, do
                provedor, do dispositivo.
              </li>
              <li> Histórico de navegação e interesses.</li>
              <li>
                Atributos de dispositivo, como ID, sistema operacional,
                navegador e modelo.
              </li>
              <li>
                Acesso a eventuais listas de contatos caso exista a ativação
                desta funcionalidade.
              </li>
              <li>
                Dados como cookies, pixels tags, beacons e local shared objects.
              </li>
              <li>
                {' '}
                Dados sobre as características dos dispositivos de acesso.
              </li>
              <li>
                Dados do navegador, incluindo IP (com data e hora) e origem do
                IP.
              </li>
              <li>
                Dados e informações sobre cliques, páginas acessadas, páginas
                seguintes acessadas após a saída das páginas anteriores, termos
                de buscas digitados em sites e nas Plataformas Digitais.
              </li>
            </ul>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              2.4. Para que usamos seus dados pessoais
            </Title>
            <p>
              Usamos seus dados pessoais para fornecer os nossos serviços,
              dentro e fora das Plataformas Digitais, da melhor forma possível,
              respeitando, sempre suas preferências de privacidade e a coleta
              mínima necessária. Podemos fazer uso para fins publicitários, de
              segurança e de personalização dos serviços.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              2.4.1. Finalidades que estão relacionadas diretamente ao seu
              interesse Fins de nossos serviços. 
            </Title>
            <p>
              O Medita Bem utiliza os seus dados para o estabelecimento de
              vínculo contratual ou ainda para gestão, administração, prestação,
              ampliação e melhoramento dos produtos, serviços, bem como do nosso
              Aplicativo adequando-as às suas preferências.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Fins publicitários do Medita Bem.
            </Title>
            <p>
              Os dados pessoais e demais informações coletadas também poderão
              ser utilizadas para fins publicitários do Medita Bem, como envio
              de informações de marcas, produtos, promoções e descontos, para a
              divulgação de eventos ou para a realização de pesquisas, sempre
              respeitando a transparência com o titular dos dados.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Fins de segurança.
            </Title>
            <p>
              Para operar nossos serviços, usamos alguns de seus dados, em
              especial dados de registro e de navegação, para assegurar seu
              desempenho seguro, confiável e robusto, bem como para garantir a
              segurança das contas e a devida autenticação.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              2.4.2. Finalidades que podemos solicitar o consentimento mesmo que
              atendam o seu interesse
            </Title>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Fins publicitários de terceiros.
            </Title>
            <p>
              Os dados pessoais e demais informações coletadas também poderão
              ser utilizadas para fins publicitários de terceiros ou empresas
              parceiras, como envio de informações de marcas, produtos,
              promoções e descontos, para a divulgação de eventos ou para a
              realização de pesquisas, sempre respeitando a transparência com o
              titular dos dados.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Fins de personalização.
            </Title>
            <p>
              Para personalizar nossos serviços, poderemos associar você a
              informações fornecidas por você ou por terceiros, por meio de
              inferências sobre temas sobre os quais você pode estar interessado
              e da customização do conteúdo que mostramos em nosso Aplicativo,
              inclusive em anúncios.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Demais finalidades.
            </Title>
            <p>
              Os dados pessoais obtidos pelo Medita Bem poderão também incluir
              as seguintes finalidades:
            </p>
            <ul>
              <li>
                Atendimento de suas solicitações e/ou esclarecimento de dúvidas.
              </li>
              <li>
                Contato por telefone, e-mail, SMS, aplicativo de mensagens,
                inclusive por meio de notificações ou push.
              </li>
              <li>
                Envio de comunicações em geral, incluindo atualizações desta
                Política de Privacidade bem como de demais termos aplicáveis.
              </li>
              <li>
                Exibição de publicidade no Aplicativo, redes sociais, inclusive
                em sites e plataformas de parceiros, assim como envio de
                ofertas, informações, novidades, atualizações de produtos,
                serviços, conteúdos, funcionalidades, notícias, eventos
                relevantes que possam ser de seu interesse e para manutenção de
                relacionamento com você.
              </li>
              <li>
                Prevenção e resolução de problemas técnicos e de segurança.
              </li>
              <li> Prevenção e combate a fraudes e demais atos ilícitos.</li>
              <li>
                Colaboração ou cumprimento de ordem judicial de autoridade
                competente ou órgão fiscalizador, assim como cumprimento de
                obrigação legal ou regulatória.
              </li>
              <li> Melhoria dos sistemas, produtos e serviços.</li>
              <li>
                Recomendações e operacionalização de novos serviços, produtos ou
                funcionalidades de aplicativos, softwares, plataformas, sites,
                inclusive de serviços oferecidos por parceiros.
              </li>
              <li>
                Geração de estatísticas, estudos, pesquisas e levantamentos
                pertinentes às atividades e comportamento no uso das Plataformas
                Digitais, produtos ou serviços.
              </li>
              <li> Dados de geolocalização, quando possível.</li>
            </ul>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              2.5. Compartilhamento dos seus dados pessoais
            </Title>
            <p>
              Seus dados pessoais e informações também poderão ser
              compartilhados com prestadores de serviços, sempre sujeito às
              obrigações consistentes com esta Política de Privacidade, tais
              como empresas provedoras de infraestrutura tecnológica e
              operacional, empresas de atendimento ao consumidor, empresas de
              comunicação de serviços, empresas intermediadoras de pagamento e
              empresas e provedores de serviço de armazenamento de informações e
              demais parceiros necessários para a completa atividade do Medita
              Bem. Esse compartilhamento é essencial para que você possa melhor
              utilizar nossos serviços.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Anúncios
            </Title>
            <p>
              Ao aceitar os termos disponibilizados em nosso Aplicativo, você
              concorda que o Medita Bem e seus parceiros podem utilizar parte de
              seus dados pessoais para disponibilizar anúncios que consideramos
              relevantes para você e seus interesses nas Plataformas Digitas ou
              em conexão com a exibição de conteúdo. Para o fornecimento de
              anúncios mais customizados e eficazes, nossos parceiros podem
              compartilhar conosco algumas de suas informações pessoais, em
              especial dados de navegação, localização e interesse, permitindo
              que os combinemos com dados pessoais já coletados. A receita
              oriunda de publicidade permite ao Medita Bem continuadamente
              melhore seus serviços.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Outros compartilhamentos
            </Title>
            <p>
              Poderemos usar e compartilhar dados pessoais ou outros dados de
              segurança se acreditarmos que são razoavelmente necessários para:
            </p>
            <ul>
              <li>
                Cumprimento de obrigação legal ou regulatória, incluindo ordem
                judicial ou requerimento de autoridades administrativas que
                detenham competência legal para tal requisição.
              </li>
              <li>
                Exercício regular do direito do Medita Bem, incluindo proteção
                dos interesses do Medita Bem em qualquer tipo de conflito,
                incluindo ações judiciais.
              </li>
              <li> Proteger a segurança de qualquer pessoa.</li>
              <li> Proteger a segurança ou integridade de nossa plataforma.</li>
              <li> Proteger você ou terceiros de fraude.</li>
              <li>
                Proteger direitos ou nossa propriedade ou de terceiros que usam
                nossos serviços.
              </li>
              <li>
                Casos de transações e alterações societárias envolvendo o Medita
                Bem, hipótese em que a transferência das informações será
                necessária para a continuidade dos serviços e utilização das
                Plataformas Digitais.
              </li>
              <li>
                Envio de notificações não obrigatórias por e-mail, aplicativos
                de mensagens, SMS e notificação push.
              </li>
              <li>
                Cumprir com o legítimo interesse do Medita Bem e das empresas
                que integrem o grupo empresarial do Medita Bem para os mesmos
                fins indicados na presente Política de Privacidade.
              </li>
              <li>
                Em caso de exceções não previstas nos itens acima, o Medita Bem
                aplicará o termo de consentimento.
              </li>
              <li>
                Consultar a veracidade dos dados através de um serviço de birô.
              </li>
            </ul>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              2.6. Coleta e uso de dados de menores de idade
            </Title>
            <p>
              Para realizar o cadastro em nossas Plataformas Digitais, o titular
              do dado precisa ter no mínimo 18 (dezoito) anos de idade. Caso o
              Medita Bem oferecer algum produto ou serviço destinado aos menores
              de idade, será obrigatório o consentimento formal de um dos pais
              ou responsável legal.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Idades mínimas
            </Title>
            <p>
              Para utilizar nossos serviços, você deve ter, no mínimo, 18
              (dezoito) anos de idade, assim como, para se cadastrar no
              aplicativo, você precisa ter, no mínimo, 18 (dezoito) anos de
              idade.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Dados coletados
            </Title>
            <p>
              Para garantir a máxima proteção à criança ou ao adolescente,
              somente serão solicitados os dados indispensáveis para a
              realização da inscrição específica, tais como nome do menor,
              idade, foto, entre outros e consentimento formal de um dos pais ou
              responsável legal. Ao realizar o cadastro de criança ou
              adolescente, você assume a veracidade dos dados fornecidos, bem
              como a condição de responsável legal do menor.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              2.7. Retenção e exclusão de seus dados pessoais
            </Title>
            <p>
              Excluiremos seus dados pessoais quando não mais forem necessários
              para a finalidade a eles estipuladas, quando a política de
              retenção assim o determinar ou quando você ou uma autoridade
              requerer. Lembre-se que, ainda assim, poderemos manter parte de
              seus dados, caso seja legalmente requerido ou permitido.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Exclusão dos dados.
            </Title>
            <p>
              Enquanto você mantiver relacionamento com o Medita Bem, bem como
              durante o uso do Aplicativo e de nossos de serviços, o Medita Bem
              armazenará seus dados em ambientes seguros e controlados, mas
              poderão ser excluídos:
            </p>
            <ul>
              <li>
                Quando os dados deixarem de ser necessários ou pertinentes à
                finalidade específica pela qual foram disponibilizados.
              </li>
              <li> Quando terminar o período do tratamento.</li>
              <li>
                Quando você solicitar que o Medita Bem bloqueie ou elimine esses
                dados.
              </li>
            </ul>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Retenção dos dados.
            </Title>
            <p>
              Mesmo em caso de requisição de exclusão de dados ou após o término
              do relacionamento com você, será respeitado o prazo de
              armazenamento mínimo de informações de usuários de aplicações de
              Internet, determinado pela legislação brasileira, resguardados os
              requisitos de tratamento previstos quando anonimizados os dados. O
              Medita Bem poderá armazenar seus dados por um período adicional de
              tempo para fins de auditoria, para cumprimento de obrigações
              legais ou regulatórias, para preservar os direitos do Medita Bem
              em eventuais discussões judiciais e para observar as bases legais
              que justifique a retenção dos dados, como, por exemplo, para
              cumprir eventual solicitação da Autoridade Nacional de Proteção de
              Dados (ANPD).
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Exclusão definitiva de conta.
            </Title>
            <p>
              É necessário que você esteja ciente de que a exclusão das
              informações essenciais para gestão de sua conta de acesso ao
              Aplicativo implicará o término de seu cadastro, com consequente
              cancelamento de seu acesso e potencial perda permanente dos dados
              até então coletados.
            </p>
            <Title
              fontWeight="800"
              size="large"
              align="left"
              capitalize="uppercase">
              3. Vamos falar um pouco sobre alguns produtos e serviços
            </Title>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              3.1. Site do Medita Bem
            </Title>
            <p>
              Durante a utilização de site Medita Bem o Usuário estará sujeito a
              fornecer dados pessoais para customização de conteúdo, publicidade
              personalizada, cadastro para ficar por dentro de campanhas e
              novidades envolvendo o aplicativo e campanhas envolvendo empresas
              parceiras.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              3.2. Tratamento de dados no Medita Bem
            </Title>
            <p>
              O Usuário poderá criar diversos perfis e no momento de criação,
              será solicitado nome e data de nascimento.
            </p>
            <p>
              Por meio do aceite desta Política o Usuário confirma e autoriza o
              envio de notificações automáticas sobre conteúdos considerados de
              interesse pelo Medita Bem (“push notification”) bem como de
              eventos a serem realizados. O aceite poderá ser solicitado
              novamente durante o uso do Medita Bem no dispositivo móvel,
              conforme regem as políticas de privacidade do ANDROID e IOS.
            </p>
            <Title
              fontWeight="800"
              size="large"
              align="left"
              capitalize="uppercase">
              4. Como mantemos as suas informações e seus dados pessoais seguros
            </Title>
            <p>
              Estamos comprometidos com a adoção de políticas e normas de boas
              práticas relativas à proteção dos dados pessoais. Implementamos as
              medidas técnicas e organizacionais pertinentes, considerando a
              tecnologia existente, para a segurança de dados, ainda que não
              possamos garantir que estejamos imunes a atos ilícitos de
              terceiros.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Segurança de sua informação.
            </Title>
            <p>
              Os sistemas e procedimentos do Medita Bem são estruturados de
              forma a atender aos requisitos de segurança, aos padrões de boas
              práticas e aos princípios gerais previstos na legislação aplicável
              e demais normas regulamentares. O Medita Bem armazenará suas
              informações e dados pessoais coletados no aplicativo em servidores
              próprios ou por nós contratados, utilizando os meios razoáveis de
              mercado e legalmente requeridos para preservar a privacidade sua
              privacidade, tais como:
            </p>
            <ul>
              <li>
                Métodos padrões e de mercado para criptografar e anonimizar os
                dados.
              </li>
              <li> Proteção contra acesso não autorizado a seus sistemas.</li>
              <li>
                Acesso restrito às pessoas previamente autorizadas ao local onde
                são armazenados os dados e as informações coletadas.
              </li>
              <li>
                Comprometimento e sigilo absoluto pelas pessoas que tiverem
                acesso aos dados e informações.
              </li>
              <li>
                Manutenção de inventário indicando momento, duração, identidade
                do funcionário, ou do responsável pelo acesso com base nos
                registros de conexão e de acesso a aplicações.
              </li>
            </ul>
            <p>
              <b>Importante.</b> É importante que você saiba que nenhum site é
              totalmente seguro. O Medita Bem adota as melhores práticas de
              segurança da informação, porém incentivamos você a tomar as
              medidas apropriadas para reforçar esta proteção, como, por
              exemplo, mantendo confidenciais todos os nomes de usuário e senhas
              bem como não transferindo os seus dados de acesso a quaisquer
              terceiros.
            </p>
            <Title
              fontWeight="800"
              size="large"
              align="left"
              capitalize="uppercase">
              5. Seus direitos como titular dos dados pessoais
            </Title>
            <p>
              Você poderá exercer seus direitos de titular de dado pessoal, tais
              como requerer acesso aos dados pessoais que coletamos, assim como
              requerendo retificação, atualização, portabilidade, revogação de
              consentimento e eliminação de seus dados pessoais.
            </p>
            <p>
              <b>Direitos de titular.</b> Enquanto titular de dados pessoais,
              você poderá exercer uma série de direitos. Disponibilizaremos
              mecanismos para que você possa entender de forma clara e
              transparente como poderá exercer seus direitos. O Medita Bem
              empreenderá todos os esforços para atender tais pedidos no menor
              espaço de tempo possível.
            </p>
            <p>
              <b>Acesso a dados. </b> Você poderá solicitar através do canal
              exclusivo para esta finalidade, a qualquer momento, que o Medita
              Bem lhe informe e confirme a existência de tratamento de dados em
              nossas Plataformas Digitais ou demais bases sob a responsabilidade
              da emissora, bem como forneça os dados que possuímos de você.
            </p>
            <p>
              <b>Retificação e atualização de dados.</b> Se você identificar que
              os seus dados pessoais necessitam de correções ou se encontrarem
              incompletos, inexatos ou desatualizados, você poderá, caso não
              haja possibilidade de fazê-lo diretamente em nosso aplicativo,
              encaminhar solicitação para o canal dedicado ao titular dos dados.
              Lembre-se que você pode ter de comprovar, inclusive por meio de
              documento, a veracidade desses dados.
            </p>
            <p>
              <b>Demais direitos.</b> O Medita Bem respeita e garante a você a
              possibilidade de apresentação de solicitações baseadas nos
              seguintes direitos:
            </p>
            <ul>
              <li>
                Anonimização, bloqueio ou eliminação de dados desnecessários,
                excessivos ou tratados em desconformidade.
              </li>
              <li>
                Portabilidade de seus dados a outro fornecedor de serviço ou
                produto.
              </li>
              <li> Eliminação dos dados tratados com o seu consentimento.</li>
              <li>
                Informação sobre a possibilidade de não fornecer o seu
                consentimento, bem como de ser informado sobre as consequências
                em caso de negativa.
              </li>
            </ul>
            <Title
              fontWeight="800"
              size="large"
              align="left"
              capitalize="uppercase">
              6. Coleta e revogação do consentimento
            </Title>
            <p>
              Poderemos pedir seu consentimento para alguns dos usos desses
              dados pessoais. Você tem o direito de não dar seu consentimento,
              assim como de revogá-lo, mas o Medita Bem poderá ainda fazer uso
              de parte desses dados pessoais, nas hipóteses previstas em lei.
            </p>
            <p>
              <b>Obtenção de consentimento. </b> <br /> Caso seu consentimento
              seja necessário para uso dos seus dados pessoais, de acordo com o
              disposto na lei aplicável, ele será coletado eletronicamente,
              momento em que serão informados os procedimentos e finalidades.
              Você possui o direito de não oferecer o seu consentimento ou de
              solicitar maiores esclarecimentos acerca desse consentimento.
            </p>
            <p>
              <b>Revogação do consentimento. </b> <br />
              Você poderá solicitar a revogação do consentimento que concedeu.
              Esta revogação poderá ocasionar a impossibilidade de uso ou acesso
              de funcionalidades dos sistemas e serviços do Medita Bem. É
              importante que você esteja ciente de que a revogação do seu
              consentimento não significa que seus dados pessoais serão
              eliminados, tendo em vista a possiblidade de conservação
              autorizada dos dados para as finalidades dispostas na lei
              aplicável.
            </p>
            <Title
              fontWeight="800"
              size="large"
              align="left"
              capitalize="uppercase">
              7. Informações sobre legislação.
            </Title>
            <p>
              A legislação brasileira é aplicável a esta Política de
              Privacidade, em especial a LGPD, e o foro é o da comarca da cidade
              de São Paulo/SP.
            </p>
            <p>
              Nulidade ou incompletude de cláusulas. Qualquer cláusula ou
              condição destes Termos que venha a ser reputada nula ou ineficaz
              por qualquer juízo ou tribunal, não afetará a validade das demais
              disposições, as quais permanecerão plenamente válidas e
              vinculantes. Nossa falha em exigir quaisquer direitos ou
              disposições dos presentes Termos não constituirá renúncia, podendo
              exercer regularmente o seu direito, dentro dos prazos legais.
            </p>
            <p>
              <b>Legislação aplicável e foro.</b> <br />
              Esta Política de Privacidade é regida, interpretada e executada de
              acordo com as Leis da República Federativa do Brasil,
              especialmente a Lei nº 13.709/2018, sendo competente o foro de
              Osasco para dirimir qualquer controvérsia decorrente desta
              Política de Privacidade.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              7.1. Pelo que o Medita Bem não se responsabiliza.
            </Title>
            <p>
              O Medita Bem será responsável apenas pelos danos diretos oriundos
              de nossas obrigações. Você continua responsável pelos dados que
              forneceu ao Medita Bem.
            </p>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              Responsabilidade do Medita Bem.
            </Title>
            <p>
              O Medita Bem será responsável apenas pelos danos diretos que
              comprovadamente vier a ocasionar, não assumindo responsabilidade:
            </p>
            <ul>
              <li>
                Por erros ou inconsistências na transmissão de dados que você
                apresentou ou que automaticamente forem coletados, incluindo
                dados imprecisos, inverídicos, incompletos.
              </li>
              <li>
                Por utilização do Aplicativo em desacordo com o disposto nesta
                Política de Privacidade.
              </li>
              <li>
                Por qualquer dano, ou prejuízo decorrente de utilização não
                autorizada ou indevida de seus dados.
              </li>
              <li>
                Pela violação de direitos de terceiros ou ainda pela utilização
                não autorizada de dados de terceiros.
              </li>
            </ul>
            <Title
              fontWeight="800"
              size="large"
              align="left"
              capitalize="uppercase">
              8. Entre em contato conosco
            </Title>
            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              8.1. Canal de Privacidade
            </Title>
            <p>
              Se você precisar sanar dúvidas sobre como seus dados pessoais
              estão sendo tratados no Aplicativo Medita Bem ou queira formalizar
              alguma solicitação prevista pela Lei Geral de Proteção de Dados,
              você deverá utilizar o nosso Canal de Privacidade.
            </p>
            <p>
              O encarregado pelo tratamento de dados pessoais no Medita Bem
              acompanhará todas as solicitações direcionadas ao canal de
              privacidade, assim mantendo a interação entre o titular dos dados
              pessoais e a emissora.
            </p>

            <p>
              <b>Encarregado (DPO):</b> Isabelle Gretillat <br />
              <b>E-mail: </b>
              <a href="mailto:belle@medita.com.br ">belle@medita.com.br </a>
              <br />
              <b>Encarregado (DPO):</b> Isabelle Gretillat <br />
            </p>

            <Title
              fontWeight="600"
              size="medium"
              align="left"
              capitalize="uppercase">
              8.2. SAC
            </Title>
            <p>
              Para tirar demais dúvidas sobre produtos ou serviços do Medita
              Bem, você deverá entrar em contato conosco por meio do e-mail 
              <a href="mailto:belle@medita.com.br ">belle@medita.com.br </a>
            </p>

            <p>
              <i>Última atualização em 01 de novembro de 2023</i>
            </p>
          </SectionMain>
        </SectionMain>
      </SectionMain>

      <br />
      <br />
      <div className={classes.footer}>
        <div className={classes.contato}>
          <b>Email:</b> belle@medita.com.br
          <br />
          <b>Telefone:</b> +55 11 995159152
          <br />
          <b>Endereço postal:</b> Rua Dr. Antonio Bento, 560 – Santo Amaro -
          CEP:04750-001 – São Paulo/SP
        </div>
        <img
          alt="MeditaBem"
          src={logo}
          height="auto"
          width="200px"
          className={classes.img}
          onClick={() => {}}
        />
        <div>&nbsp;</div>
      </div>
    </SectionMain>
  );
};

export default PrivacyPageDesktop;
