import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Chip from "@material-ui/core/Chip"
import Paper from "@material-ui/core/Paper"
import TagFacesIcon from "@material-ui/icons/TagFaces"
import InputText from "./forms/InputText"
import { Grid } from "@material-ui/core"
import useReduxState from "../core/useReduxState"
import api from "../core/api"

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
		padding: theme.spacing(0.5)
	},
	chip: {
		margin: theme.spacing(0.5)
	}
}))

const ChipsArray = ({ state, setState }) => {
	const classes = useStyles()
	const [word, setWord] = useState([])
	const [getStateCategories, setStateCategories] = useState([])
	const [chipData, setChipData] = useState([{ id: 0, name: "Meditações" }])

	const handleDelete = chipToDelete => () => {
		setChipData(chips =>
			chips.filter(chip => chip.key !== chipToDelete.key)
		)
	}

	const fetchDataApi = async () => {
		try {
			await api.get(`/categories`).then(({ data: categories }) => {
				const GeneralKeywordCategory = categories.filter(category => {
					if (category.name.toLowerCase() === "geral") {
						return category
					}
				})
				setStateCategories({
					category: { ...GeneralKeywordCategory[0] },
					key_words:
						(GeneralKeywordCategory.key_words &&
							JSON.parse(GeneralKeywordCategory.key_words)) ||
						[]
				})
			})
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		fetchDataApi()
	}, [])

	const { key_word } = getStateCategories

	console.log(getStateCategories)

	// const onSubmitCategories = async () => {
	// 	if (key_word && key_word.name) {
	// 		getStateCategories.key_words.push(key_word)
	// 	}
	// 	const form = {
	// 		...category,
	// 		key_words: JSON.stringify(key_words)
	// 	}

	// 	try {
	// 		await api.put(`/categories/${id}`, form)
	// 		fetchDataApi()
	// 		enqueueSnackbar("Dados Alterados com Sucesso!")
	// 	} catch (e) {
	// 		enqueueSnackbar("Preencha os campos!")

	// 		console.log(e)
	// 	}
	// }

	/**
	 *
	 * @param {React.FormEvent} ev
	 */
	function handleAdd() {
		const name = word

		setChipData(chips => chips.concat({ id: +new Date(), name }))
		setState({ ...state, key_words: { ...state.key_words, ...chipData } })
	}

	return (
		<Paper className={classes.root}>
			<form action="javascript:void(0)" onSubmit={handleAdd}>
				<Grid containter justify="center" xs={12}>
					{chipData.map(data => {
						return (
							<Chip
								key={data.key}
								label={data.name}
								onDelete={handleDelete(data)}
								className={classes.chip}
							/>
						)
					})}
					<Grid item>
						<InputText
							label="Palavra chave"
							name="keyword"
							onChange={e => setWord(e.target.value)}
						/>
					</Grid>
					<Grid item>
						<input style={{ display: "none" }} type="submit" />
					</Grid>
				</Grid>
			</form>
		</Paper>
	)
}

export default ChipsArray
