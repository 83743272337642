import React from "react"

const InputsFixedContext = React.createContext({
	premium: false,
	promo: false,
	publication_date: {}
})

export const useInputsFixedContext = () => React.useContext(InputsFixedContext)

export default InputsFixedContext
