import React, {useState} from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import useReduxState from '../../core/useReduxState';
import api from '../../core/api';
import ReturnButton from '../ReturnButton';
import {Formik} from 'formik';
import InputText from './InputText';
import ButtonSubmit from './ButtonSubmit';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  label: {
    paddingTop: 30,
  },
}));
const SpecialForm = ({
  special = {
    nome: '',
    descricao: '',
  },
  history,
}) => {
  const classes = useStyles();
  const editing = !!special.id;
  const [getUser, setUser] = useReduxState({
    loading: false,
  });

  const [open, setOpen] = useState('');
  const handleClickOpen = view => {
    setOpen(view);
  };
  const handleClose = () => {
    setOpen('');
  };

  const {enqueueSnackbar} = useSnackbar();

  const onSubmit = async form => {
    setUser({loading: true});

    try {
      if (editing) {
        // console.log(form);
        await api.put(`/specials/${form.id}`, form).then(res => {
          // console.log(res);
          if (res.status === 200) {
            enqueueSnackbar('Dados alterados com sucesso!');
            history.push('/specials');
          }
        });
      } else {
        await api.post(`/specials`, form).then(res => {
          // console.log(res);
          if (res.status === 200) {
            enqueueSnackbar('Nova categoria cadastrada com sucesso!');
            history.push('/specials');
          }
        });
      }
    } catch ({response}) {
      enqueueSnackbar(
        response.data.message ||
          'Ocorreu um erro ao cadastrar categoria. Tente novamente.',
      );
      setUser({loading: false});
    }
  };

  const validateForm = values => {
    const errors = {};
    let errorMessage = '';

    if (!values.nome) {
      errors.nome = 'Digite o nome da categoria';
      errorMessage += 'Nome Categoria\n';
    }

    if (!values.descricao) {
      errors.descricao = 'Digite a descrição da categoria';
      errorMessage += 'Descrição Categoria\n';
    }

    if (Object.keys(errors).length) {
      enqueueSnackbar(
        'Formulário incompleto, campos a serem preenchidos:\n' + errorMessage,
        {
          preventDuplicate: true,
          variant: 'error',
          style: {whiteSpace: 'pre-line'},
        },
      );
    }

    return errors;
  };

  return (
    <Formik
      initialValues={special}
      validate={validateForm}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        onSubmit(values);
      }}>
      {formikProps => (
        <Grid container xs={12}>
          <Grid item xs={12} alignContent="center">
            <Typography component="h1" variant="h5">
              <ReturnButton />
              {editing
                ? 'Editar Categoria Special'
                : 'Cadastrar Nova Categoria Special'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            {!getUser().loading ? (
              <form onSubmit={formikProps.handleSubmit}>
                <InputText
                  errorMessage={
                    formikProps.touched.nome && formikProps.errors.nome
                  }
                  id="nome"
                  label="Nome"
                  name="nome"
                  value={formikProps.values.nome}
                  autoComplete="nome"
                  onChange={e => {
                    formikProps.setFieldValue('nome', e.target.value);
                    formikProps.errors['nome'] = null;
                  }}
                />
                <InputText
                  errorMessage={
                    formikProps.touched.descricao &&
                    formikProps.errors.descricao
                  }
                  id="descricao"
                  label="Descrição"
                  name="descricao"
                  value={formikProps.values.descricao}
                  autoComplete="descricao"
                  onChange={e =>
                    formikProps.setFieldValue('descricao', e.target.value)
                  }
                />

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <ButtonSubmit
                    style={{
                      marginTop: 20,
                      width: '20vw',
                      alignSelf: 'center',
                    }}
                    type="submit"
                    variant="contained"
                    color="primary">
                    {editing ? 'Salvar' : 'Cadastrar'}
                  </ButtonSubmit>
                </div>
              </form>
            ) : (
              <div
                className="flex-col center-a center-b"
                style={{height: '50vh'}}>
                <CircularProgress size={100} />
                <p> Carregando... </p>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default SpecialForm;
