import React, { useState } from 'react'
import Dashboard from '../../layout/Dashboard'
import { Grid, InputAdornment, IconButton, List, Divider } from '@material-ui/core'
import ArticlesContainer from '../../components/containers/ArticlesContainer'
import ArticleList from '../../components/ArticleList'
import InputText from '../../components/forms/InputText'
import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useAppContext } from '../../components/singletons/AppContext'

const ArticlesPage = ({ history }) => {
	const { search } = useAppContext()

	return (
		<Dashboard>
			<ArticlesContainer>
				{({ fetchData, articles }) => (
					<Grid item xs={12} md={12} lg={12}>
						<List>
							{articles &&
								articles.map((article, i) => {
									if (
										(article.name || '').toLowerCase().includes(search) ||
										(article.description || '').toLowerCase().includes(search)
									) {
										return <ArticleList history={history} article={article} fetchData={fetchData} />
									}
								})}
						</List>
					</Grid>
				)}
			</ArticlesContainer>
		</Dashboard>
	)
}

export default ArticlesPage
