import React from 'react'
import { Fab } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'

const FloatButton = ({ history, route }) => (
	<div className="flex-row flex-end">
		<Fab onClick={() => history.push(route)} color="primary" aria-label="add">
			<AddIcon />
		</Fab>
	</div>
)

export default withRouter(FloatButton)
