import React from 'react';
import Dashboard from '../../layout/Dashboard';
import EmpresaForm from '../../components/forms/EmpresaForm';

const EmpresaAdd = ({history}) => {
  const empresa = {
    nomeFantasia: '',
    razaoSocial: '',
    cnpj: '',
    descritivo: '',
    postal_code: '',
    street: '',
    city: '',
    state: '',
    number: 0,
    // id: 1,
  };

  return (
    <Dashboard>
      <EmpresaForm
        history={history}
        empresa={empresa}
        namePage={'Cadastrar Nova Empresa'}
      />
    </Dashboard>
  );
};

export default EmpresaAdd;
