import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import AppMetrics from '../components/AppMetrics'

const styles = {
  dataStudioMetrics: {
    marginLeft: '0px',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: '20px'
  },
  formControl: {
    margin: '20px',
    minWidth: 200,
    marginLeft: '100px'
  },
}
const MetricsDataStudio = () => {

  return (
    <>
      <div style={styles.dataStudioMetrics}>
        <iframe width="100%" height="800" src="https://datastudio.google.com/embed/reporting/d2a56fae-efdb-4caa-a986-539a4aa44371/page/1M" frameborder="0" allowfullscreen></iframe>
      </div>
    </>
  );
};

const centralize = {
  textAlign: 'center',
};

export default MetricsDataStudio;
