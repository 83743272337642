import React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import PublicDatePicker from '../PublicDatePicker';

const InputsFixed = ({ formikProps }) => {
  return (
    <div
      className='flex-col'
      style={{
        alignItems: 'flex-end',
        width: 230,
      }}
    >
      <FormControlLabel
        labelPlacement='start'
        control={
          <Switch
            checked={formikProps.values.premium}
            onChange={(e) =>
              formikProps.setFieldValue('premium', e.target.checked)
            }
            value={formikProps.values.premium}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
          />
        }
        label='Premium'
      />
      <FormControlLabel
        labelPlacement='start'
        control={
          <Switch
            checked={formikProps.values.promo}
            onChange={(e) =>
              formikProps.setFieldValue('promo', e.target.checked)
            }
            value={formikProps.values.promo}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
          />
        }
        label='Promo'
      />
      <FormControlLabel
        labelPlacement='start'
        control={
          <Switch
            checked={formikProps.values.checkbox}
            onChange={(e) =>
              formikProps.setFieldValue('checkbox', e.target.checked)
            }
            value={formikProps.values.checkbox}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
          />
        }
        label='Data de publicação'
      />

      {formikProps.values.checkbox && (
        <PublicDatePicker formikProps={formikProps} />
      )}
    </div>
  );
};

export default InputsFixed;
