import React, { useState } from "react"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { Link, withRouter } from "react-router-dom"
import List from "@material-ui/core/List"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import Collapse from "@material-ui/core/Collapse"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core/styles"
import MainCategoryIcon from "@material-ui/icons/Category"
import SubCategoryIcon from "@material-ui/icons/Class"
import {
	ListItemSecondaryAction,
	IconButton,
	ListItemAvatar,
	Avatar,
	Typography
} from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import api from "../core/api"
import Confirm from "./Confirm"
import { useAppContext } from "./singletons/AppContext"

const KeywordItem = ({ categories, keyword, history }) => {
	const { onReloadData, setAppState } = useAppContext();

		const useStyles = makeStyles(() => ({
		nested: {
			paddingLeft: 60
		},
		listIcon: {
			paddingLeft: 5,
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-start"
		},
		// label: { textTransform: 'capitalize' },
		icon: {
			fontSize: 30,
			color: "#61b7c3"
		}
	}))

	const onDelete = async (idKeyword, description) => {
		const res = await Confirm(
			`Deseja realmente excluir a palavra-chave "${description}"?`
		)
		if (res) {
			try {
				const response = await api.delete(
					`keywords/${idKeyword}`
				)
			} catch (e) {
				alert(e)
			}
			return onReloadData();
		}
	}

	const classes = useStyles()

	return (
		<List>
			<ListItem
				className={classes.nested}
				button
				component={Link}>
				<ListItemAvatar>
					<Avatar>
						<SubCategoryIcon />
					</Avatar>
				</ListItemAvatar>

				<ListItemText
					className={classes.label}
					primary={<Typography component='h2'>{keyword.description}</Typography>}
					secondary={
            <Typography
              component='h4'
              variant='body2'
              color='textSecondary'
              style={{ maxWidth: '90%' }}
            >
              Categoria(s): {categories}
            </Typography>
          }
				/>
				<ListItemSecondaryAction>
					<IconButton
						component={Link}
						to={`/keywords/edit/${keyword.id}`}
						edge="end">
						<EditIcon />
					</IconButton>
					<IconButton
						edge="end"
						aria-label="delete"
						onClick={() => onDelete(keyword.id, keyword.description)}>
						<DeleteIcon />
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		</List>
	)
}

export default withRouter(KeywordItem)
