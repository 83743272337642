import React, {useEffect, useState} from 'react';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Button,
  Typography,
  Grid,
  Avatar,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import {useSnackbar} from 'notistack';
import {useFileContext} from '../singletons/FileContext';
import ImageCroper from '../ImageCroper';
import {onSelectFile, onSelectFileNotEditable} from '../../core/formHelpers';
import {toDataURL} from '../../core/toDataURL';
import api from '../../core/api';

const useStyles = makeStyles(() => ({
  imageInput: {
    border: '3px dashed #000000a1',
    borderRadius: '0.5em',
    padding: '10px',
  },
}));

const MidiaCard = ({title, description, thumbnailAvatar}) => {
  const {src} = useFileContext();
  return (
    <React.Fragment>
      <Grid
        container
        xs={12}
        justify="center"
        alignItems="flex-end"
        style={{
          borderRadius: 20,
          // maxHeight: "35vh",
          minHeight: '35vh',

          backgroundImage: `url('${src}')`,
          backgroundSize: 'cover',
          border: '1px solid #d3d3d3',
        }}>
        {!src && (
          <p style={{border: '1px solid #d3d3d3', color: 'red'}}>
            por favor, insira uma imagem de capa.
          </p>
        )}
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: '#f8f9fa87',
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            height: '50%',
          }}>
          <div className="flex-row center-b" style={{padding: '0 20px'}}>
            <div className="flex-row center-b" style={{height: 120}}>
              <Avatar
                src={thumbnailAvatar || null}
                style={{
                  minHeight: 80,
                  minWidth: 80,
                }}
              />
            </div>
            <div className="flex-row center-b" style={{padding: '0 10px'}}>
              <div
                style={{
                  maxHeight: 100,
                  overflow: 'hidden',
                  WebkitLineBreak: 'auto',
                }}>
                {description ? (
                  `Descrição: ${description}`
                ) : (
                  <p
                    style={{
                      border: '1px solid #d3d3d3',
                      color: 'red',
                    }}>
                    preencha o campo de descrição.
                  </p>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="flex-row" style={{padding: 20}}>
        <Typography component="h5" variant="h5">
          {title ? (
            `Título: ${title}`
          ) : (
            <p
              style={{
                border: '1px solid #d3d3d3',
                color: 'red',
              }}>
              preencha o campo de título
            </p>
          )}
        </Typography>
      </div>
    </React.Fragment>
  );
};

export const ModalEditThumbnail = ({
  formikProps,
  handleClose,
  open,
  notEditabled,
}) => {
  const classes = useStyles();
  const {src, crop, blobFile, croppedImageUrl, setFileState} = useFileContext();

  const {enqueueSnackbar} = useSnackbar();

  const onSaveThumbnail = async () => {
    try {
      if (blobFile) {
        const reader = new FileReader();
        await reader.addEventListener('load', () =>
          setFileState({src: reader.result, crop}),
        );
        reader.readAsDataURL(blobFile);
      }
    } catch (e) {
      enqueueSnackbar('Erro ao carregar imagem.');
    }
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open.includes('thumbnail')}>
      <DialogTitle onClose={handleClose}>Editor de Imagem</DialogTitle>
      <DialogContent>
        {!croppedImageUrl && !src && (
          <Dropzone
            onDrop={acceptedFiles => {
              if (notEditabled) {
                onSelectFileNotEditable(acceptedFiles, setFileState);
              } else {
                onSelectFile(acceptedFiles, setFileState);
              }

              formikProps.setFieldValue('thumbnail', acceptedFiles[0]);

              formikProps.errors.thumbnail = null;
            }}>
            {({getRootProps, getInputProps}) => {
              return (
                <div {...getRootProps()} className="flex-col center-a center-b">
                  <IconButton className={classes.imageInput}>
                    <input {...getInputProps()} accept="image/*" />
                    <p
                      style={{
                        cursor: 'pointer',
                      }}>
                      Arraste a imagem até aqui ou clique para selecionar.
                    </p>
                  </IconButton>
                </div>
              );
            }}
          </Dropzone>
        )}
        <img src={src} width={400} />
      </DialogContent>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <Button
          autoFocus
          onClick={() => {
            setFileState({
              src: null,
              croppedImageUrl: null,
              crop,
            });
            formikProps.setFieldValue('thumbnail', '');
          }}
          color="secondary">
          Limpar Imagem
        </Button>
        <Button autoFocus onClick={onSaveThumbnail} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ModalEditCampaignImage = ({
  setImage,
  image,
  handleClose,
  open,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [src, setSrc] = useState(null);
  const [loading, setLoading] = useState(false);

  const {enqueueSnackbar} = useSnackbar();

  const onUpdateFile = async file => {
    setLoading(true);
    try {
      if (file && file.length > 0) {
        setFile(file[0]);
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          toDataURL(
            reader.result,
            function(dataUrl) {
              // console.log('RESULT:', dataUrl)
              setSrc(dataUrl);
            },
            'image/jpeg',
          ),
        );
        reader.readAsDataURL(file[0]);
      }
    } catch (e) {
      enqueueSnackbar('Erro ao carregar imagem.');
    } finally {
      setLoading(false);
    }
  };

  const SaveImage = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      console.log('file', file);
      formData.append('file', file);
      const response = await api.post(`campaign/images`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
      setImage(response.data);
    } catch (error) {
      enqueueSnackbar('Erro ao carregar imagem.');
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle onClose={handleClose}>Editor de Imagem</DialogTitle>
      <DialogContent>
        {!src ? (
          <Dropzone
            onDrop={acceptedFiles => {
              onUpdateFile(acceptedFiles);
            }}>
            {({getRootProps, getInputProps}) => {
              return (
                <div {...getRootProps()} className="flex-col center-a center-b">
                  <IconButton className={classes.imageInput}>
                    <input {...getInputProps()} accept="image/*" />
                    <p
                      style={{
                        cursor: 'pointer',
                      }}>
                      Arraste a imagem até aqui ou clique para selecionar.
                    </p>
                  </IconButton>
                </div>
              );
            }}
          </Dropzone>
        ) : null}
        <img src={src} width={400} />
      </DialogContent>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <Button
          autoFocus
          loading={loading}
          disabled={loading}
          onClick={() => {
            setSrc(null);
          }}
          color="secondary">
          Limpar Imagem
        </Button>
        <Button
          autoFocus
          loading={loading}
          disabled={loading}
          onClick={SaveImage}
          color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ModalEditAudio = ({formikProps, handleClose, open}) => {
  const classes = useStyles();
  return (
    <Dialog onClose={handleClose} open={open.includes('audio')}>
      <DialogTitle onClose={handleClose}>Upload de Áudio</DialogTitle>
      <DialogContent>
        {formikProps.values.filename.length > 0 ? (
          <MidiaCard
            title={formikProps.values.title}
            description={formikProps.values.description}
            thumbnailAvatar={formikProps.values.partner.thumbnailURL}
          />
        ) : (
          <Dropzone
            onDrop={acceptedFiles => {
              formikProps.setFieldValue('video', acceptedFiles[0]);
              formikProps.setFieldValue('filename', acceptedFiles[0].name);
            }}>
            {({getRootProps, getInputProps}) => {
              return (
                <div {...getRootProps()} className="flex-col center-a center-b">
                  <IconButton className={classes.imageInput}>
                    <input {...getInputProps()} />
                    <p
                      style={{
                        cursor: 'pointer',
                      }}>
                      Arraste o áudio até aqui ou clique para selecionar.
                    </p>
                  </IconButton>
                </div>
              );
            }}
          </Dropzone>
        )}
        {/* <Typography style={{ opacity: 0.8 }} component="h5" variant="h5">
					{formikProps.values.filename}
				</Typography> */}
      </DialogContent>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <Button
          autoFocus
          onClick={() => {
            formikProps.setFieldValue('audio', '');
            formikProps.setFieldValue('filename', '');
          }}
          color="secondary">
          Limpar Áudio
        </Button>
        <Button autoFocus onClick={handleClose} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export const ModalEditVideo = ({formikProps, handleClose, open}) => {
  const classes = useStyles();
  return (
    <Dialog onClose={handleClose} open={open.includes('video')}>
      <DialogTitle onClose={handleClose}>Upload de Vídeo</DialogTitle>
      <DialogContent>
        {formikProps.values.filename.length > 0 ? (
          <MidiaCard
            title={formikProps.values.title}
            description={formikProps.values.description}
            thumbnailAvatar={formikProps.values.partner.thumbnailURL}
          />
        ) : (
          <Dropzone
            onDrop={acceptedFiles => {
              formikProps.setFieldValue('video', acceptedFiles[0]);
              formikProps.setFieldValue('filename', acceptedFiles[0].name);
            }}>
            {({getRootProps, getInputProps}) => {
              return (
                <div {...getRootProps()} className="flex-col center-a center-b">
                  <IconButton className={classes.imageInput}>
                    <input {...getInputProps()} />
                    <p
                      style={{
                        cursor: 'pointer',
                      }}>
                      Arraste o vídeo até aqui ou clique para selecionar.
                    </p>
                  </IconButton>
                </div>
              );
            }}
          </Dropzone>
        )}
      </DialogContent>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <Button
          autoFocus
          onClick={() => {
            formikProps.setFieldValue('video', '');
            formikProps.setFieldValue('filename', '');
          }}
          color="secondary">
          Limpar Vídeo
        </Button>
        <Button autoFocus onClick={handleClose} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
