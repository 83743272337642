import React from 'react';
import {
  Grid,
  Avatar,
  Typography,
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';

import {Link} from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const PlanItem = ({plan}) => {
  const initialLetter = plan.cycle || '';

  return (
    <React.Fragment>
      <ListItem alignItems="center">
        <ListItemAvatar>
          <Avatar
            src={plan.thumbnailURL}
            alt="Remy Sharp"
            style={{textTransform: 'uppercase'}}>
            {plan && initialLetter[0]}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={plan.description}
          secondary={
            <Grid container>
              <Grid item xs={5}>
                <ListItemIcon>
                  <Typography
                    variant="body2"
                    className="flex-row center-b"
                    color="textPrimary">
                    {plan.cycle}
                  </Typography>
                </ListItemIcon>
              </Grid>
            </Grid>
          }
        />
        <ListItemSecondaryAction>
          <IconButton component={Link} to={`/plans/edit/${plan.id}`} edge="end">
            <EditIcon />
          </IconButton>
          {/* <IconButton onClick={onDeleteplan} edge="end">
            <DeleteIcon />
          </IconButton> */}
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
export default PlanItem;
