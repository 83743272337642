import React, {useState, useEffect} from 'react';
import Chart from 'react-apexcharts';
import Title from '../../../components/Title';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {CircularProgress, Grid, Typography} from '@material-ui/core';
import dashboardService from '../service';
import {
  AnnotationsMonth,
  getMonthName,
  months,
  responsive,
  returnTotalValues,
  returnValues,
  returnValuesTotal,
} from '../service/utils';
import {KeyboardDatePicker} from '@material-ui/pickers';
import DrawTableDash from '../../../components/DrawTableDash';

const styles = {
  margin: {
    marginTop: 15,
    marginBottom: 15,
  },
  containerCards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: 15,
    marginBottom: 20,
  },
  viewCharts: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: 15,
  },
  legend: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  containerChart: {
    minWidth: '45%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    margin: '0 10px 10px 0',
  },
  containerChartTable: {
    width: '75%',
    padding: 10,
    marginTop: 20,
  },
  containerChartLine: {
    width: '75%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    marginTop: 20,
  },
  title: {textAlign: 'center', marginTop: 10, marginBottom: 10},
  flexEnd: {width: '100%', display: 'flex', justifyContent: 'flex-end'},
  datePicker: {
    borderRadius: '40px',
    height: 50,
    width: 'fit-content',
    border: 'solid 1px #d3d3d3',
    marginBottom: 20,
    marginLeft: 5,
  },
};

const UserFunnelChart = () => {
  const [loading, setLoading] = useState(false);
  const [resultsTemp, setResultsTemp] = useState([]);
  const [resultsTable, setResultsTable] = useState([]);
  const [results, setResults] = useState();
  const [date, setDate] = useState(new Date());
  const [totalData, setTotalData] = useState({
    total: {onboarding: 0, register: 0, active: 0},
    android: {onboarding: 0, register: 0, active: 0},
    ios: {onboarding: 0, register: 0, active: 0},
  });

  const GetData = async () => {
    setLoading(true);
    await dashboardService
      .getUsersActiveMonth({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        endDate: new Date(),
      })
      .then(({data}) => {
        var result = Object.entries(data).map(res => {
          return {...res[1]};
        });

        const totalMonth = result?.map(item => {
          return {...item, categories: getMonthName(item.month)};
        });

        const res = result
          ?.map(item => {
            return {...item, categories: getMonthName(item.month)};
          })
          .reduce((acc, item) => {
            return {
              ...acc,
              [item.platform]: [...(acc[item.platform] ?? []), item],
            };
          }, {});

        totalData.total.active = totalMonth.length;
        totalData.ios.active = res['ios']?.length;
        totalData.android.active = res['android']?.length;
      });

    await dashboardService.getUsersActive(date.getFullYear()).then(({data}) => {
      var result = Object.entries(data)
        .map(res => {
          return {...res[1]};
        })
        .reduce((acc, item) => {
          return {
            ...acc,
            [item.userId]: [...(acc[item.userId] ?? []), item],
          };
        }, {});

      Object.entries(result)
        .map(res => {
          return res[1].reduce((acc, item) => {
            if (!acc[item.month]) {
              acc[item.month] = item;
            }
            return acc;
          }, {});
        })
        .map(res => {
          var result = Object.entries(res).map(res => {
            return {...res[1]};
          });
          resultsTemp.push(...result);
        });
    });

    const result = resultsTemp
      ?.map(item => {
        return {...item, categories: getMonthName(item.month)};
      })
      .reduce((acc, item) => {
        return {
          ...acc,
          [item.platform]: [...(acc[item.platform] ?? []), item],
        };
      }, {});

    // pega total de cadastros e onboardingloads
    await dashboardService.getUsersFunnel(date.getFullYear()).then(({data}) => {
      const resRegister = data?.resultsByMonth?.map(item => {
        return {...item, categories: getMonthName(item.month)};
      });
      const resRegisterGroup = resRegister.reduce((acc, item) => {
        return {
          ...acc,
          [item.registration_platform]: [
            ...(acc[item.registration_platform] ?? []),
            item,
          ],
        };
      }, {});

      const resOnboarding = data?.resultsByMonthOn?.map(item => {
        return {...item, categories: getMonthName(item.month)};
      });
      const resOnboardingGroup = resOnboarding.reduce((acc, item) => {
        return {
          ...acc,
          [item.registration_platform]: [
            ...(acc[item.registration_platform] ?? []),
            item,
          ],
        };
      }, {});

      const totalValues = resultsTemp?.map(item => {
        return {...item, categories: getMonthName(item.month)};
      });

      const formatedData = [
        {
          name: 'Cadastrados',
          title: 'Número de usuários - Android',
          color: '#4472C4',
          data: returnValuesTotal(resRegister),
          total:
            data?.resultsByMonth?.reduce(function(accumulator, value) {
              return accumulator + value.count;
            }, 0) || 0,
          textColor: '#fff',
          dataLabels: true,
        },
        {
          name: 'Onboarding',
          title: 'Número de usuários cadastrados - IOS',
          color: '#ED7D31',
          data: returnValuesTotal(resOnboarding),
          total:
            data?.resultsByMonthOn?.reduce(function(accumulator, value) {
              return accumulator + value.count;
            }, 0) || 0,
          dataLabels: true,
        },
        {
          name: 'Ativos',
          title: 'Número de usuários Total - Total',
          color: '#A5A5A5',
          data: returnTotalValues(totalValues),
          total: totalValues.length || 0,
          textColor: '#fff',
          dataLabels: true,
        },
      ];
      const formatedDataAndroid = [
        {
          name: 'Cadastrados',
          title: 'Número de usuários - Android',
          color: '#4472C4',
          data: returnValues(resRegisterGroup['android']),
          total:
            resRegisterGroup['android']?.reduce(function(accumulator, value) {
              return accumulator + value.count;
            }, 0) || 0,
          textColor: '#fff',
          dataLabels: true,
        },
        {
          name: 'Onboarding',
          title: 'Número de usuários cadastrados - IOS',
          color: '#ED7D31',
          data: returnValues(resOnboardingGroup['android']),
          total:
            resOnboardingGroup['android']?.reduce(function(accumulator, value) {
              return accumulator + value.count;
            }, 0) || 0,
          dataLabels: true,
        },
        {
          name: 'Ativos',
          title: 'Número de usuários Total - Total',
          color: '#A5A5A5',
          data: returnTotalValues(result['android']),
          total: result['android']?.length,
          textColor: '#fff',
          dataLabels: true,
        },
      ];
      const formatedDataIOS = [
        {
          name: 'Cadastrados',
          title: 'Número de usuários - Android',
          color: '#4472C4',
          data: returnValues(resRegisterGroup['ios']),
          total:
            resRegisterGroup['ios']?.reduce(function(accumulator, value) {
              return accumulator + value.count;
            }, 0) || 0,
          textColor: '#fff',
          dataLabels: true,
        },
        {
          name: 'Onboarding',
          title: 'Número de usuários cadastrados - IOS',
          color: '#ED7D31',
          data: returnValues(resOnboardingGroup['ios']),
          total:
            resOnboardingGroup['ios']?.reduce(function(accumulator, value) {
              return accumulator + value.count;
            }, 0) || 0,
          dataLabels: true,
        },
        {
          name: 'Ativos',
          title: 'Número de usuários Total - Total',
          color: '#A5A5A5',
          data: returnTotalValues(result['ios']),
          total: result['ios']?.length,
          textColor: '#fff',
          dataLabels: true,
        },
      ];

      setResults([
        {title: 'Funil de usuários - Total', data: formatedData},
        {title: 'Funil de usuários - Android', data: formatedDataAndroid},
        {title: 'Funil de usuários - IOS', data: formatedDataIOS},
      ]);

      const response = formatedData.map(d => {
        const dataPerc = d.data.map((dd, index) => {
          const t = formatedData[0].data[index]
            ? ((dd * 100) / formatedData[0].data[index]).toFixed(2)
            : 0;
          return {dd: dd || 0, t};
        });
        return {...d, dataPerc};
      });
      setResultsTable(response);

      totalData.total.onboarding = data?.totalOnboarding;
      totalData.ios.onboarding = data?.formatResultsOn?.ios[0]?.count;
      totalData.android.onboarding = data?.formatResultsOn?.android[0]?.count;
      totalData.total.register = data?.total;
      totalData.ios.register = data?.formatResults?.ios[0]?.count;
      totalData.android.register = data?.formatResults?.android[0]?.count;
    });
    setLoading(false);
  };

  useEffect(() => {
    setResultsTemp([]);
    setResults([]);
    GetData();
  }, [date]);

  const dataBar = [
    {
      name: 'Total',
      color: '#4472C4',
      textColor: '#000',
      data: [
        totalData.total.register || 0,
        totalData.total.onboarding || 0,
        totalData.total.active || 0,
      ],
    },
    {
      name: 'Android',
      color: '#FFE699',
      textColor: '#000',
      data: [
        totalData.android.register || 0,
        totalData.android.onboarding || 0,
        totalData.android.active || 0,
      ],
    },
    {
      name: 'IOS',
      color: '#F8CBAD',
      textColor: '#000',
      data: [
        totalData.ios.register || 0,
        totalData.ios.onboarding || 0,
        totalData.ios.active || 0,
      ],
    },
  ];

  return (
    <div style={styles.margin}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        style={styles.title}>
        Funil de usuários
      </Typography>

      {loading ? (
        <div className="flex-col center-a center-b">
          <CircularProgress size={100} />
        </div>
      ) : (
        <>
          <Carousel responsive={responsive}>
            {dataBar.map(item => (
              <div id="chart" style={styles.containerChart}>
                <Title fontWeight="normal" size="medium" align="center">
                  Funil de usuários - {item.name}
                </Title>
                <Chart
                  options={{
                    colors: [item.color],
                    chart: {
                      type: 'bar',
                      height: 350,
                    },
                    plotOptions: {
                      bar: {
                        borderRadius: 0,
                        horizontal: true,
                        barHeight: '80%',
                        isFunnel: true,
                      },
                    },
                    dataLabels: {
                      enabled: true,

                      formatter: function(val, opt) {
                        return (
                          opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
                        );
                      },
                      dropShadow: {
                        enabled: true,
                      },
                      style: {
                        fontSize: '12px',
                        textShadow: 'none',
                        colors: [item.textColor],
                      },
                    },
                    xaxis: {
                      categories: ['Cadastros', 'Onboarding', 'Ativos'],
                      position: 'top',
                    },
                  }}
                  series={[
                    {
                      name: '',
                      data: item.data,
                    },
                  ]}
                  type="bar"
                  height={350}
                />
              </div>
            ))}
          </Carousel>

          <div style={styles.flexEnd}>
            <div style={styles.datePicker}>
              <KeyboardDatePicker
                placeholder="Selecione ano"
                value={date}
                minDate={new Date(new Date().setFullYear(2024))}
                onChange={dateForm => setDate(new Date(dateForm))}
                views={['year']}
                yearsOrder="desc"
                format="YYYY"
              />
            </div>
          </div>

          {results?.length > 0 && (
            <>
              <Grid container>
                <Grid container xs={12}>
                  {results.map(item => (
                    <Grid item xs={6}>
                      <div id="chart" style={styles.containerChart}>
                        <Title fontWeight="normal" size="medium" align="center">
                          {item.title}
                        </Title>
                        <span style={styles.legend}>{date.getFullYear()}</span>
                        <Chart
                          options={{
                            chart: {
                              type: 'bar',
                              height: 350,
                              stacked: true,
                            },
                            responsive: [
                              {
                                breakpoint: 480,
                                options: {
                                  legend: {
                                    position: 'bottom',
                                    offsetX: -10,
                                    offsetY: 0,
                                  },
                                },
                              },
                            ],
                            plotOptions: {
                              bar: {
                                horizontal: false,
                              },
                            },
                            xaxis: {
                              categories: months,
                            },
                            annotations: {
                              points: AnnotationsMonth(item.data),
                            },
                          }}
                          series={item.data}
                          type="bar"
                          height={350}
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <div style={{marginTop: 15}} />

              <Title fontWeight="normal" size="medium" align="center">
                Funil de usuários - Percentual e Quantidade
              </Title>
              <span style={styles.legend}>{date.getFullYear()}</span>

              <div id="chart" style={styles.containerChartTable}>
                {DrawTableDash(
                  months,
                  resultsTable.map(item => {
                    return [
                      {dd: item.name === 'all' ? 'Total' : item.name},
                      {dd: item.total},
                      {
                        dd: `${
                          totalData > 0
                            ? ((item.total * 100) / totalData).toFixed(2)
                            : 0
                        }% `,
                      },
                      ...item.dataPerc,
                    ];
                  }),
                  'partners-to-xls',
                  'Funil',
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UserFunnelChart;
