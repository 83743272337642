import React from 'react';
import Dashboard from '../../layout/Dashboard';
import CampaignForm from './components/CampaignForm';

const CampaignsAdd = ({history}) => {
  const campaign = {
    nome: '',
    descricao: '',
  };

  return (
    <Dashboard>
      <CampaignForm
        history={history}
        special={campaign}
        namePage={'Cadastrar Nova Campanha'}
      />
    </Dashboard>
  );
};

export default CampaignsAdd;
