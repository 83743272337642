import React, {useState, useEffect} from 'react';
import 'react-multi-carousel/lib/styles.css';
import {CircularProgress, Grid, Typography} from '@material-ui/core';
import dashboardService from '../service';
import Carousel from 'react-multi-carousel';
import Title from '../../../components/Title';
import Chart from 'react-apexcharts';
import {
  getMonthName,
  months,
  responsiveCards,
  responsiveMedium,
  returnValues,
  returnValuesBykeys,
  returnValuesTest,
} from '../service/utils';
import {KeyboardDatePicker} from '@material-ui/pickers';
import DataCard from './DataCard';
import DrawTableDash from '../../../components/DrawTableDash';

const styles = {
  margin: {
    marginTop: 15,
    marginBottom: 15,
  },
  viewCharts: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: 15,
  },
  containerChart: {
    minWidth: '45%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    margin: '0 10px 10px 0',
  },
  containerChartLine: {
    width: '75%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    marginTop: 20,
  },
  containerChartTable: {
    width: '75%',
    padding: 10,
  },
  legend: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  title: {textAlign: 'center', marginTop: 10, marginBottom: 20},
  flexEnd: {width: '100%', display: 'flex', justifyContent: 'flex-end'},
  datePicker: {
    borderRadius: '40px',
    height: 50,
    width: 'fit-content',
    border: 'solid 1px #d3d3d3',
    marginBottom: 20,
    marginLeft: 5,
  },
};

const RegisteredGraph = () => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [resultsTable, setResultsTable] = useState([]);
  const [, setKeysPeriod] = useState([]);
  const [, setResultsPeriod] = useState();
  const [resultsMonth, setResultsMonth] = useState({
    android: [
      {
        count: 0,
      },
    ],
    ios: [
      {
        count: 0,
      },
    ],
    site: [
      {
        count: 0,
      },
    ],
    none: [
      {
        count: 0,
      },
    ],
  });
  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(2015)),
  );
  const [endDate, setEndDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [totalData, setTotalData] = useState(0);
  const [totalDataMonth, setTotalDataMonth] = useState(0);
  const [totalDataPlataform, setTotalDataPlataform] = useState(0);

  const GetData = async () => {
    setLoading(true);
    dashboardService
      .getUsersRegistered({
        year: date.getFullYear(),
        month: date.getMonth(),
        date,
        startDate,
        endDate,
      })
      .then(async ({data}) => {
        const result = data?.results
          ?.map(item => {
            return {...item, categories: getMonthName(item.month)};
          })
          .reduce((acc, item) => {
            return {
              ...acc,
              [item.registration_platform]: [
                ...(acc[item.registration_platform] ?? []),
                item,
              ],
            };
          }, {});

        let test = [];

        Object.values(result).map(key => {
          return test.push(...key);
        });

        const formatedData = [
          {
            name: 'Total',
            title: 'Total de usuários cadastrados',
            color: '#70AD47',
            data: returnValuesTest(test),
            total: data?.total || 0,
            textColor: '#fff',
          },
          {
            name: 'Total com plataforma',
            title: 'Total de usuários cadastrados com plataforma',
            color: '#5B9BD5',
            data: returnValuesTest(test.filter(t => !!t.registration_platform)),
            total: data?.totalByPlataform || 0,
            textColor: '#fff',
          },
          {
            name: 'Android',
            title: 'Usuários cadastrados - Android',
            color: '#4472C4',
            data: returnValues(result['android']),
            total:
              result['android']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
            textColor: '#fff',
          },
          {
            name: 'IOS',
            title: 'Usuários cadastrados - IOS',
            color: '#ED7D31',
            data: returnValues(result['ios']),
            total:
              result['ios']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
          {
            name: 'Site',
            title: 'Usuários cadastrados - Site',
            color: '#A5A5A5',
            data: returnValues(result['site']),
            total:
              result['site']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
          {
            name: 'Sem plataforma',
            title: 'Usuários cadastrados sem plataforma',
            color: '#FFC000',
            data: returnValues(result['null']),
            total:
              result['null']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
        ];

        setTotalData(data?.total);
        setResults(formatedData);

        const response = formatedData.map(d => {
          const dataPerc = d.data.map((dd, index) => {
            const t = formatedData[0].data[index]
              ? ((dd * 100) / formatedData[0].data[index]).toFixed(2)
              : 0;
            return {dd: dd || 0, t};
          });
          return {...d, dataPerc};
        });
        setResultsTable(response);

        const resultsByDay = data?.resultsByDay.reduce((acc, item) => {
          return {
            ...acc,
            [item.day]: [...(acc[item.day] ?? []), item],
          };
        }, {});

        let returnPer = [];

        Object.values(resultsByDay).map(key => {
          return returnPer.push(...key);
        });

        const period = returnPer.reduce((acc, item) => {
          return {
            ...acc,
            [item.registration_platform]: [
              ...(acc[item.registration_platform] ?? []),
              item,
            ],
          };
        }, {});

        const formatedPeriod = [
          {
            name: 'Android',
            title: 'Número de usuários cadastrados - Android',
            color: '#4472C4',
            data: returnValuesBykeys(
              period['android'],
              Object.keys(resultsByDay),
            ),
            total:
              period['android']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
            textColor: '#fff',
          },
          {
            name: 'IOS',
            title: 'Número de usuários cadastrados - IOS',
            color: '#ED7D31',
            data: returnValuesBykeys(period['ios'], Object.keys(resultsByDay)),
            total:
              period['ios']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
          {
            name: 'Site',
            title: 'Número de usuários cadastrados - Site',
            color: '#FFC000',
            data: returnValuesBykeys(period['site'], Object.keys(resultsByDay)),
            total:
              period['site']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
        ];

        setKeysPeriod(Object.keys(resultsByDay));
        setResultsPeriod(formatedPeriod);

        const resultMonthR = data?.resultsByMonth.reduce((acc, item) => {
          return {
            ...acc,
            [item.registration_platform]: [
              ...(acc[item.registration_platform] ?? []),
              item,
            ],
          };
        }, {});
        setTotalDataMonth(data?.countByMonth);
        setTotalDataPlataform(data?.countByMonthByPlataform);

        setResultsMonth({
          android: [
            {
              count:
                resultMonthR['android'] && resultMonthR['android'][0]
                  ? resultMonthR['android'][0].count
                  : 0,
            },
          ],
          ios: [
            {
              count:
                resultMonthR['ios'] && resultMonthR['ios'][0]
                  ? resultMonthR['ios'][0].count
                  : 0,
            },
          ],
          site: [
            {
              count:
                resultMonthR['site'] && resultMonthR['site'][0]
                  ? resultMonthR['site'][0].count
                  : 0,
            },
          ],
          none: [
            {
              count:
                resultMonthR['null'] && resultMonthR['null'][0]
                  ? resultMonthR['null'][0].count
                  : 0,
            },
          ],
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    GetData();
  }, [startDate, endDate, date]);

  return (
    <div style={styles.margin}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        style={styles.title}>
        Usuários cadastrados por plataforma
      </Typography>

      {loading ? (
        <div className="flex-col center-a center-b">
          <CircularProgress size={100} />
        </div>
      ) : (
        <>
          <div style={styles.flexEnd}>
            <div style={styles.datePicker}>
              <KeyboardDatePicker
                placeholder="Selecione data inicial"
                value={startDate}
                onChange={dateForm => {
                  setStartDate(new Date(dateForm));
                }}
                format="DD/MM/YYYY"
              />
            </div>
            <div style={styles.datePicker}>
              <KeyboardDatePicker
                placeholder="Selecione data final"
                minDate={startDate}
                value={endDate}
                onChange={dateForm => {
                  setEndDate(new Date(dateForm));
                }}
                format="DD/MM/YYYY"
              />
            </div>
          </div>

          {resultsMonth && (
            <Carousel responsive={responsiveCards}>
              <DataCard
                title={<span>Total de usuários cadastrados</span>}
                value={totalDataMonth || 0}
              />
              <DataCard
                title={
                  <span>Total de usuários cadastrados com plataforma</span>
                }
                value={totalDataPlataform || 0}
              />
              <DataCard
                title={<span>Usuários cadastrados - Android</span>}
                value={resultsMonth['android'][0]?.count || 0}
              />
              <DataCard
                title={<span>Usuários cadastrados - IOS</span>}
                value={resultsMonth['ios'][0]?.count || 0}
              />
              <DataCard
                title={<span>Usuários cadastrados - Site</span>}
                value={resultsMonth['site'][0]?.count || 0}
              />
              <DataCard
                title={<span>Usuários cadastrados sem plataforma</span>}
                value={resultsMonth['none'][0]?.count || 0}
              />
            </Carousel>
          )}

          <div style={{marginTop: 10}} />

          <div style={styles.flexEnd}>
            <div style={styles.datePicker}>
              <KeyboardDatePicker
                placeholder="Selecione ano"
                value={date}
                onChange={dateForm => setDate(new Date(dateForm))}
                views={['year']}
                yearsOrder="desc"
                format=" YYYY"
              />
            </div>
          </div>

          {results?.length > 0 && (
            <>
              <Grid container>
                <Grid container xs={12}>
                  {results?.map(item => {
                    return (
                      <Grid item xs={6}>
                        <div id="chart" style={styles.containerChart}>
                          <Title
                            fontWeight="normal"
                            size="medium"
                            align="center">
                            {item.title}
                          </Title>
                          <span style={styles.legend}>
                            {date.getFullYear()}
                          </span>
                          <Chart
                            options={{
                              colors: [item.color],
                              chart: {
                                type: 'bar',
                                height: 350,
                              },
                              plotOptions: {
                                bar: {
                                  borderRadius: 4,
                                  borderRadiusApplication: 'end',
                                  horizontal: true,
                                  dataLabels: {
                                    position: 'top',
                                  },
                                },
                              },
                              dataLabels: {
                                enabled: true,
                                textAnchor: 'end',
                                padding: 10,
                                style: {
                                  fontSize: '12px',
                                  colors: [item.textColor],
                                },
                              },
                              tooltip: {
                                enabled: false,
                              },
                              stroke: {
                                enabled: false,
                              },
                              xaxis: {
                                categories: months,
                              },
                            }}
                            series={[
                              {
                                data: item.data,
                              },
                            ]}
                            type="bar"
                            height={350}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              <div style={{marginTop: 10}} />

              <Carousel responsive={responsiveMedium}>
                <div id="chart" style={styles.containerChart}>
                  <Title fontWeight="normal" size="medium" align="center">
                    Total de usuários cadastrados - Percentual
                  </Title>
                  <span style={styles.legend}>{date.getFullYear()}</span>

                  <Chart
                    options={{
                      chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        stackType: '100%',
                      },
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            legend: {
                              position: 'bottom',
                              offsetX: -10,
                              offsetY: 0,
                            },
                          },
                        },
                      ],
                      plotOptions: {
                        bar: {
                          horizontal: false,
                        },
                      },
                      xaxis: {
                        categories: months,
                      },
                    }}
                    series={results?.slice(2)}
                    type="bar"
                    height={500}
                  />
                </div>
                <div id="chart" style={styles.containerChart}>
                  <Title fontWeight="normal" size="medium" align="center">
                    Total de usuários cadastrados - Quantidade
                  </Title>
                  <span style={styles.legend}>{date.getFullYear()}</span>

                  <Chart
                    options={{
                      chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                          enabled: false,
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        background: 'none',
                        textAnchor: 'end',
                      },
                      stroke: {
                        curve: 'straight',
                      },
                      xaxis: {
                        categories: months,
                      },
                    }}
                    series={results?.slice(2)}
                    type="line"
                    height={500}
                  />
                </div>
              </Carousel>

              <div style={{marginTop: 15}} />

              <Title fontWeight="normal" size="medium" align="center">
                Total de usuários cadastrados - Percentual e Quantidade
              </Title>
              <span style={styles.legend}>{date.getFullYear()}</span>

              <div id="chart" style={styles.containerChartTable}>
                {DrawTableDash(
                  months,
                  resultsTable.map(item => {
                    return [
                      {dd: item.name === 'all' ? 'Total' : item.name},
                      {dd: item.total},
                      {
                        dd: `${
                          totalData > 0
                            ? ((item.total * 100) / totalData).toFixed(2)
                            : 0
                        }% `,
                      },
                      ...item.dataPerc,
                    ];
                  }),
                  'partners-to-xls',
                  'Cadastrados',
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default RegisteredGraph;
