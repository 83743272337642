import React, { useState, useEffect, useRef } from 'react';
import {
  IconButton,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import  queryString from 'query-string';
import { Prompt } from 'react-router';


import UploadIcon from '@material-ui/icons/CloudUpload';

import Dashboard from '../../layout/Dashboard';

import Promise from 'bluebird';

import useStyles from './styles';
import api from '../../core/api';
import Axios from 'axios';
import UploadItem from './UploadItem/UploadItem';

const Uploads = ({location}) => {
  const classes = useStyles();
  const [mediaItems, setMediaItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filesUploading, setFilesUploading] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [filesDuration, setFilesDuration] = useState([]);
  const [fileDuration, setFileDuration] = useState({});

  useEffect(()=>{
    if(fileDuration.name){
      setFilesDuration([...filesDuration, fileDuration]);
    }
  },[fileDuration])

  useEffect(() => {
    getUploadedFiles();
  }, []);

  useEffect(()=> {
    const parsed = queryString.parse(location.search);
    if(parsed.startUpload){
      fileInputRef.current.click();
    }
  },[location])

  const getUploadedFiles = async () => {
    const filesRequest = await api.get('/videos/uploads');
    if (filesRequest.status === 200) {
      const filesDurations = filesRequest.data.map((data, index) => 
          ({ name: data.name, duration: parseInt(data.duration)})
      )
      setFilesDuration(filesDurations);
      setMediaItems(filesRequest.data);
    } else {
      setMediaItems([]);
    }
    setIsLoading(false);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const fileItems = Object.values(files);
    
    fileItems.map((file, index) => {
      const video = document.createElement('audio');
      
      video.addEventListener('loadedmetadata', event => {
        setFileDuration({
          name: file.name, 
          duration: video.duration ? Math.round(video.duration) : -1,
        }); 
      });
      video.src = URL.createObjectURL(file);

    });    
    setMediaItems([...fileItems, ...mediaItems]);
  };

  const onUploading = (index, uploading) => {
    let files = filesUploading;
    if (uploading) {
      files.push(index);
    } else {
      const fileIndex = files.indexOf(index);
      if (fileIndex >= 0) {
        files.splice(fileIndex, 1);
      }
    }

    if (filesUploading.length > 0) {      
      setIsUploading(true);
    } else {
      setIsUploading(false);
    }
    
    setFilesUploading(files);
  };

  const handleDelete = (name) => {
    const filteredFilesDuration = filesDuration.filter(file => file.name !== name);
    const filteredMediaItems = mediaItems.filter(media => media.name !== name);
    setFilesDuration(filteredFilesDuration);
    setMediaItems(filteredMediaItems);
  }

  const handleUpload = (media) => {
    const updloadedMediaItems = mediaItems.map(mediaItem => {
      if(mediaItem.name === media.name){
        mediaItem.uploaded = true;
      }
      return mediaItem;
    });
    console.log(updloadedMediaItems);
    setMediaItems(updloadedMediaItems);
  }

  return (
    <Dashboard toolbarOpen={false}>
      <div className={classes.uploadsScreen}>
        <div className={classes.titleContainer}>
          <div>
            { isUploading && 
            <span className={classes.warningText}>
              <strong>Atenção</strong>: não mude de página enquanto houver conteúdo 
              sendo enviado para o servidor. Do contrário, o envio poderá ser cancelado.
            </span>
            }
          </div>
        <div className={classes.uploadBar}>
          <IconButton variant='contained' component='label'>
            <UploadIcon />
            <input
              type='file'
              ref={fileInputRef}
              className={classes.fileInput}
              onChange={handleFileUpload}
              multiple
              accept='video/*,audio/*'
            />
          </IconButton>
        </div>
        </div>
        <div className={classes.mediaItems}>
          {!isLoading ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Nome do Arquivo</TableCell>
                  <TableCell>Tamanho</TableCell>
                  <TableCell>Data do upload</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mediaItems.map((mediaItem, index) => {
                  const file = filesDuration.find(file => file.name === mediaItem.name);
                  return (
                    <UploadItem
                      mediaItem={mediaItem}
                      duration={file?.duration}
                      key={index}
                      onUploading={onUploading}
                      handleDelete={handleDelete}
                      handleUpload={handleUpload}
                    />
                  )
                })}
              </TableBody>
            </Table>
          ) : (
            <div className={classes.loadingArea}>
              <CircularProgress size={24} />
            </div>
          )}
        </div>
      </div>
    </Dashboard>
  );
};

export default Uploads;
