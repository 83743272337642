import React, {useEffect} from 'react';
import Dashboard from '../layout/Dashboard';
import {useAppContext} from '../components/singletons/AppContext';
import {makeStyles, Tabs, Tab} from '@material-ui/core';
import api from '../core/api';
import PropTypes from 'prop-types';
import MetricsDataStudio from './../components/MetricsDataStudio';

import PartnersMetrics from '../components/PartnersMetrics';
import ContentsMetrics from '../components/ContentsMetrics';
import NotificationIcon from '@material-ui/icons/Notifications';

const useStyles = makeStyles(styles => ({
  homeDiv: {
    marginTop: '3rem',
    width: '100%',
  },
  titleText: {
    fontSize: 48,
    color: '#61b7c3',
    marginTop: '10%',
  },
  tabDiv: {
    borderBottom: 'solid 0.5px rgba(0,0,0,0.3)',
  },
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const HomePage = () => {
  const {objectives, categories, partners, setAppState} = useAppContext();
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = index => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    if (!objectives || !objectives.length) {
      fetchObjectives();
    }
    if (!categories || !categories.length) {
      fetchCategories();
    }
    if (!partners || !partners.length) {
      fetchPartners();
    }
  }, []);

  const fetchObjectives = async () => {
    const objectivesRequest = await api.get('objectives');
    if (objectivesRequest.status === 200) {
      setAppState({
        objectives: objectivesRequest.data,
        categories,
        partners,
      });
    }
  };

  const fetchCategories = async () => {
    const categoriesRequest = await api.get('categories');
    if (categoriesRequest.status === 200) {
      setAppState({
        objectives,
        categories: categoriesRequest.data,
        partners,
      });
    }
  };

  const fetchPartners = async () => {
    const partnersRequest = await api.get('partners');
    if (partnersRequest.status === 200) {
      setAppState({
        objectives,
        categories,
        partners: partnersRequest.data,
      });
    }
  };

  return (
    <Dashboard>
      <div className={classes.homeDiv}>
        <div className={classes.tabDiv}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            aria-label="full width tabs example">
            <Tab label="Métricas" {...a11yProps(0)} />
            <Tab label="Exportar Parceiros" {...a11yProps(1)} />
            <Tab label="Exportar Conteúdos" {...a11yProps(2)} />
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <MetricsDataStudio />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PartnersMetrics />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ContentsMetrics />
        </TabPanel>
      </div>
    </Dashboard>
  );
};

export default HomePage;
