import React from 'react';
import SectionMain from '../../components/SectionMain';
import {Hidden} from '@material-ui/core';
import TermsPageDesktop from './Desktop';
import TermsPageMobile from './Mobile';

const PrivacyPage = () => {
  return (
    <SectionMain noPadding>
      <Hidden only={['xs', 'sm']}>
        <TermsPageDesktop />
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <TermsPageMobile />
      </Hidden>
    </SectionMain>
  );
};

export default PrivacyPage;
