import React, { useState, useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import api from '../../core/api'
import {
	TextField,
	Button,
	Grid,
	Typography,
	InputLabel,
	Select,
	FormControl,
	MenuItem,
	FormLabel
} from '@material-ui/core'
import ReturnButton from '../ReturnButton'
import InputText from './InputText'
import useReduxState from '../../core/useReduxState'
import AnimatedView from '../AnimatedView'
import { v4 } from "uuid";


const FormContext = React.createContext({
	form: {},
	setForm() {}
})

const typeDefs = {
	name: 'TEXT'
}

// const CategoryField = ({ name, ...props }) => {
// 	const { form, setForm } = useContext(FormContext)

// 	const update = (event) =>
// 		setForm({
// 			...form,
// 			[name]: event.target.value
// 		})

// 	switch (typeDefs[name]) {
// 		default:
// 			return <InputText label="Título" value={form[name]} name={name} onChange={update} {...props} />
// 	}
// }

function SubCategoryForm({ key, history, id, idSubCategory }) {
	const editing = !!id
	const [ getState, setState ] = useReduxState({
		subCategoryName: '',
		subCategoryArray: [],
		categories: [ 0 ],
		loadingInfo: editing,
		formErrors: {},
		error: null,
		sendError: null,
		loading: false
	})

	const { subCategoryName, loadingInfo, formErrors, error, sendError, loading, categories } = getState()

	const [ form, setForm ] = useState({
		name: ''
	})

	const fetchCategories = async () => {
		try {
			await api.get('/categories').then((res) => {
				const { data: categories } = res
				setState({ categories })
			})
		} catch (e) {
			alert(e)
		}
	}

	async function fetchInfo(id) {
		setState({ error: null, loadingInfo: true })
		try {
			await api.get(`categories/sub-categories/${id}`).then((res) => {
				const { data: arraySub } = res
				const [ filteredItem ] = arraySub.filter((item) => item.id == idSubCategory)
				setState({
					subCategoryArray: arraySub.filter((item) => item.id != idSubCategory),
					subCategoryName: filteredItem.name,
					categoryId: id,
					loadingInfo: false
				})
			})
		} catch (e) {
			alert(e)
			setState({ error: true })
		}
	}

	useEffect(() => {
		if (editing) {
			// extract id here from navigations params
			fetchInfo(id)
		}
		fetchCategories()
	}, [])

	/**
	 *
	 * @param {import('axios').AxiosInstance} api
	 */
	// async function onSubmit(api) {
	// 	setState({ loading: true, sendError: null })
	// 	try {
	// 		const { status, data } = !editing
	// 			? await api.post('/categories', form)
	// 			: await api.put(`/categories/${id}`, form)
	// 		if (status === 200) {
	// 			// handle success
	// 			history.push('/categories')
	// 		}
	// 	} catch (e) {
	// 		// handle error
	// 		setState({ loading: false, sendError: true })
	// 	}
	// }

	const handleSelectCategory = (e) => {
		const [ selectedCategory ] = categories.filter((category) => category.id === e.target.value)

		setState({ subCategoryArray: JSON.parse(selectedCategory.sub_category) || [] })
		setState({ categoryId: e.target.value })
	}

	const onSubmit = async () => {
		const sub_category = { id: idSubCategory ? String(idSubCategory) : v4().replace(/-/g, ''), name: subCategoryName }

		const newArraySubCategories = getState().subCategoryArray.concat(sub_category)
		setState({ subCategoryArray: newArraySubCategories })
		const form = { sub_category: JSON.stringify(newArraySubCategories) }
		try {
			await api.put(`/categories/${getState().categoryId}`, form).then((res) => {
				if (res.status === 200) {
					history.push('/sub-categories')
				}
			})
		} catch (e) {
			setState({ loading: false, sendError: true })
		}
	}

	const onUpdate = async () => {
		const sub_category = { id: idSubCategory, name: subCategoryName }
		const newArraySubCategories = getState().subCategoryArray.concat(sub_category)
		setState({ subCategoryArray: newArraySubCategories })
		const form = { sub_category: JSON.stringify(newArraySubCategories) }
		try {
			await api.put(`/categories/${getState().categoryId}`, form).then((res) => {
				if (res.status === 200) {
					history.push('/sub-categories')
				}
			})
		} catch (e) {
			setState({ loading: false, sendError: true })
		}
	}

	if (loadingInfo) {
		return (
			<div>
				<p> Carregando... </p>
			</div>
		)
	}

	if (error) {
		return (
			<div>
				<p style={{ textAlign: 'center' }}> Ocorreu um erro ao obter os dados. </p>
				<p style={{ textAlign: 'center' }}>
					<a href="javascript:void(0)" onClick={fetchInfo}>
						{' '}
						Tentar novamente{' '}
					</a>
				</p>
			</div>
		)
	}

	return (
		<FormContext.Provider value={{ form, setForm }}>
			<Grid lg={12}>
				<ReturnButton />
				<Typography component="h1" variant="h5">
					{!editing ? `Cadastrar Subcategoria` : `Editar Subcategoria`}
				</Typography>

				<form action="javascript:void(0)" onSubmit={() => onSubmit(api)}>
					{!editing && (
						<FormControl fullWidth>
							<AnimatedView>
								<FormLabel>Subcategoria</FormLabel>
							</AnimatedView>
							{/* <InputLabel style={{ backgroundColor: '#fff' }}>Categoria Principal</InputLabel> */}
							<Select
								defaultValue={25}
								style={{
									borderRadius: '40px',
									height: 60,
									border: 'solid 1px #d3d3d3',
									padding: '0 20px'
								}}
								onChange={handleSelectCategory}
								value={getState().categoryId}
							>
								{categories.map((category) => <MenuItem value={category.id}>{category.name}</MenuItem>)}
							</Select>
						</FormControl>
					)}
					<InputText
						label="Subcategoria"
						value={subCategoryName}
						onChange={(e) => setState({ subCategoryName: e.target.value })}
					/>
					<Button style={{ marginTop: 20 }} type="submit" fullWidth variant="contained" color="primary">
						Cadastrar
					</Button>
				</form>
			</Grid>
		</FormContext.Provider>
	)
}

export default withRouter(SubCategoryForm)
