import React, {useState} from 'react';
import {useSnackbar} from 'notistack';
import {
  makeStyles,
  IconButton,
  Typography,
  Grid,
  Avatar,
  Button,
} from '@material-ui/core';
import {useFileContext} from '../singletons/FileContext';
import {string, object, boolean} from 'yup'; // for only what you need
import {Formik} from 'formik';
import {toDataURL} from '../../core/toDataURL';
import Dropzone from 'react-dropzone';
import ReturnButton from '../ReturnButton';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AddVideoIcon from '@material-ui/icons/VideoCall';
import AddAudioIcon from '@material-ui/icons/MusicVideo';
import RenderInput from './RenderInput';
import SearchIcon from '@material-ui/icons/Search';
import {
  ModalEditThumbnail,
  ModalEditVideo,
  ModalEditAudio,
} from './ModalEditMidia';
import {
  toggleCheckbox,
  ModalPartner,
  ListKeywords,
  ListCategories,
  postMidiaUpload,
  putVideoMidiaUpload,
  putThumbnailMidiaUpload,
  ListObjectives,
  ChangeMidiaUpload,
} from '../../core/formHelpers';
import ButtonSubmit from './ButtonSubmit';
import UploadContext, {useUploadContext} from '../singletons/UploadContext';
import api from '../../core/api';
import AnimatedView from '../AnimatedView';
import CKEditor from '@ckeditor/ckeditor5-react';
import InputsFixed from './InputsFixed';

const useStyles = makeStyles(() => ({
  avatar: {
    paddingTop: 0,
    height: '40vh',
    width: '60vw',
    borderRadius: '20px',
    backgroundColor: '#61b7c3',
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  contentMidia: {
    height: 200,
    width: 200,
    backgroundColor: '#61b7c3',
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  iconButton: {
    paddingTop: 0,
    borderRadius: 0,
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  imageInput: {
    border: '3px dashed #000000a1',
    borderRadius: '0.5em',
    padding: '10px',
  },
  label: {
    padding: '15px 0',
  },
}));

const ArticleForm = ({
  article = {},
  categories = [],
  partners = [],
  type = 'article',
  objectives = [],
  history,
}) => {
  const classes = useStyles();
  const editing = !!article.id;

  const {src, crop, blobFile, croppedImageUrl, setFileState} = useFileContext();
  const [uploadState, setUploadState] = useState({
    videoFile: [],
    thumbnailFile: [],
    uploading: false,
    uploadProgress: 0,
    successfullUploaded: false,
  });
  const [open, setOpen] = useState('');

  const {enqueueSnackbar} = useSnackbar();

  const handleClickOpen = view => {
    setOpen(view);
  };
  const handleClose = () => {
    setOpen('');
  };

  const infoArticleInputs = [
    {name: 'name', label: 'Título', type: 'text'},
    {name: 'description', label: 'Descrição', type: 'description'},
    {
      name: 'author',
      label: 'Autor',
      type: 'text',
      leftIcon: (
        <IconButton onClick={() => handleClickOpen('partner')}>
          <SearchIcon />
        </IconButton>
      ),
      onSearch: () => void 0,
    },
    {
      name: 'type',
      label: 'Tipo de Matéria',
      type: 'radio',
      values: ['Áudio', 'Vídeo', 'Texto'],
    },
  ];

  const validationSchema = () =>
    object().shape({
      name: string().required('Preencha o campo, Título do conteúdo'),
      author: string().required('Informe o autor do conteúdo'),
    });

  let defaultValues = editing
    ? {
        ...article,
        sub_category_ids: JSON.parse(article.categories),
        selected_categories: [25],

        key_word_ids: JSON.parse(article.key_word_ids),
        // video: article.video_id,
        filename: article.video_id,
        objectives: article.objectives ? JSON.parse(article.objectives) : [],
        checkbox: article.publication_date ? true : false,
        type: article.video_id ? 'Vídeo' : 'Texto',
        publication_date: article.publication_date
          ? article.publication_date
          : new Date(),
      }
    : {
        name: '',
        filename: '',
        author: '',
        description: '',
        thumbnailURL: [],
        premium: false,

        video: [],
        publication_date: new Date(),
        sub_category_ids: [],
        key_word_ids: [],
        partnerId: '',
        selected_categories: [25],
        partner: {
          id: '',
          thumbnailURL: '',
          artisticName: '',
        },
        objectives: [],
        course: false,
        type: '',
        content: '',
        checkbox: false,
      };

  const onSubmit = async values => {
    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('content', values.content);
    formData.append('description', values.description);
    formData.append('thumbnailUrl', values.thumbnail);
    formData.append('premium', values.premium);
    formData.append('authorId', values.partner.id);
    formData.append('categories', JSON.stringify(values.sub_category_ids));
    formData.append('key_word_ids', JSON.stringify(values.key_word_ids));
    if (values.checkbox) {
      formData.append('publication_date', values.publication_date);
    } else {
      formData.append('publication_date', null);
    }

    formData.append('course', values.course);
    formData.append('objectives', JSON.stringify(values.objectives));
    formData.append('type', values.type);

    if (editing) {
      const form = {
        name: values.title,
        description: values.description,
        partnerId: values.partner.id,
        categories: JSON.stringify(values.sub_category_ids),
        premium: values.premium,
        sub_category_ids: JSON.stringify(values.sub_category_ids),
        key_word_ids: JSON.stringify(values.key_word_ids),
        publication_date:
          values.checkbox === true ? values.publication_date : null,
        objectives: JSON.stringify(values.objectives),
        type: values.type,
      };

      if (values.filename.includes('.mp4')) {
        await putVideoMidiaUpload(
          values.video,
          values.id,
          setUploadState,
          true,
        );
      }
      if (
        values.thumbnail &&
        values.thumbnail.name.toLowerCase().match(/.png|.jpg|.jpeg/)
      ) {
        await putThumbnailMidiaUpload(
          values.thumbnail,
          values.id,
          setUploadState,
        );
      }

      try {
        await api
          .put(`/articles/${values.id}`, form)
          .then(() => {
            enqueueSnackbar('Conteúdo Editado Com Sucesso!');
          })
          .then(() => history.push());
        window.location.pathname = `/${type}s`;
      } catch (e) {
        console.log({e});
      }
    } else {
      await postMidiaUpload(values.thumbnail, setUploadState).then(
        async filename => {
          if (filename) {
            formData.append('video_id', filename);
            try {
              // setState({ loading: true })
              await api
                .post('/articles', formData)
                .then(() => {
                  enqueueSnackbar('Vídeo Enviado Com Sucesso!');
                })
                .then(() => history.push(`/${type}s`));
            } catch (e) {
              console.log({e});
              // setState({ loading: false })
            }
          } else {
            alert('erro ao enviar');
          }
        },
      );
    }
  };

  return (
    <UploadContext.Provider>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSubmit(values);
        }}>
        {formikProps => {
          return (
            <div>
              <div
                className="flex-row center-b"
                style={{
                  justifyContent: 'space-between',
                  padding: '0 20px',
                }}>
                <ModalEditThumbnail
                  formikProps={formikProps}
                  handleClose={handleClose}
                  open={open}
                />
                <ModalEditAudio
                  formikProps={formikProps}
                  handleClose={handleClose}
                  open={open}
                />
                <ModalEditVideo
                  formikProps={formikProps}
                  handleClose={handleClose}
                  open={open}
                />
                <ModalPartner
                  formikProps={formikProps}
                  partners={partners}
                  open={open}
                  handleClose={handleClose}
                />

                <div className="flex-row center-b">
                  <ReturnButton />
                  <Typography component="h1" variant="h5">
                    {!editing ? 'Adicionar Matéria' : 'Editar Matéria'}
                  </Typography>
                </div>
                <InputsFixed formikProps={formikProps} />
              </div>
              <Grid container xs={12}>
                <Grid xs={12} item style={{padding: '20px'}}>
                  <div className="flex-col center-a center-b">
                    <IconButton
                      className={classes.avatar}
                      onClick={() => handleClickOpen('thumbnail')}>
                      <Avatar
                        className={classes.avatar}
                        src={croppedImageUrl || src || null}>
                        {!croppedImageUrl && !src && (
                          <AddAPhotoIcon
                            style={{fontSize: 60}}
                            // fontSize="large"
                          />
                        )}
                      </Avatar>
                    </IconButton>

                    <p>Selecione uma imagem de capa para este conteúdo.</p>
                  </div>

                  {src && (
                    <Grid xs={12} container justify="center">
                      <Button onClick={() => handleClickOpen('thumbnail')}>
                        Editar Imagem
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <form
                  style={{padding: '20px'}}
                  onSubmit={formikProps.handleSubmit}>
                  <Grid container xs={12}>
                    <Grid item xs={formikProps.values.type ? 6 : 12}>
                      <RenderInput
                        inputs={infoArticleInputs}
                        formikProps={formikProps}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      alignItems="center"
                      style={{paddingTop: 10}}>
                      <ChangeMidiaUpload
                        formikProps={formikProps}
                        handleClickOpen={handleClickOpen}
                        type={formikProps.values.type}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <Typography
                      // className={classes.label}
                      component="h5"
                      variant="h5">
                      Objetivos
                    </Typography>
                    <ListObjectives
                      objectives={objectives}
                      formikProps={formikProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      // className={classes.label}
                      component="h5"
                      variant="h5">
                      Categorias
                    </Typography>
                    <ListCategories
                      article
                      categories={categories}
                      formikProps={formikProps}
                      regValues={
                        /cursos|promoções|yoga|músicas|mantras|meditações|exercícios|minimedita/
                      }
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClickOpen('keyword')}>
                      {!editing
                        ? 'Adicionar Palavra Chave'
                        : 'Editar Palavra Chave'}
                    </Button>
                    <ListKeywords
                      categories={categories}
                      formikProps={formikProps}
                      open={open}
                      handleClose={handleClose}
                    />
                  </Grid>
                  <Grid alignItems="center">
                    <ButtonSubmit
                      // disabled={formikProps.isSubmitting}
                      style={{marginTop: 20}}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary">
                      Salvar
                    </ButtonSubmit>
                  </Grid>
                </form>
              </Grid>
            </div>
          );
        }}
      </Formik>
    </UploadContext.Provider>
  );
};

export default ArticleForm;
