import React, {useEffect, useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import useReduxState from '../../core/useReduxState';
import api from '../../core/api';
import ArticleForm from '../../components/forms/ArticleForm';
import FileContext from '../../components/singletons/FileContext';

const e = f => f();

const EditArticle = ({key, match, history}) => {
  const [getState, setState] = useReduxState({
    article: null,
    loading: true,
  });

  const [getFileState, setFileState] = useReduxState({
    src: null,
    crop: {unit: '%', width: 100, aspect: 3 / 3},
    imageRef: null,
    croppedImageUrl: '',
    blobFile: '',
  });

  const [categories, setCategories] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [partners, setPartners] = useState([]);

  const fetchApi = async () => {
    try {
      await api.get('/categories').then(({data: categories}) => {
        setCategories(categories);
      });

      await api.get('/objectives').then(({data: objectives}) => {
        setObjectives(objectives);
      });

      await api.get('/partners').then(({data: partners}) => {
        setPartners(partners);
      });
      await api.get(`/articles/${match.params.id}`).then(({data: article}) =>
        setState({
          article,
          loading: false,
        }),
      );
    } catch (e) {
      console.log({e}, 'Error');
    }
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const {article, loading} = getState();

  const [partner] = partners.filter(
    partner => partner.id === (article && article.partnerId),
  );

  return (
    <FileContext.Provider value={{...getFileState(), setFileState}}>
      <Dashboard>
        {e(() => {
          if (loading) {
            return 'Carregando...';
          }
          return (
            <ArticleForm
              partners={partners}
              categories={categories}
              objectives={objectives}
              type="article"
              article={{
                ...article,
                partner: partner.thumbnailURL,
                author: partner.artisticName,
              }}
              history={history}
            />
          );
        })}
      </Dashboard>
    </FileContext.Provider>
  );
};

export default EditArticle;
