import React from 'react';
import Dashboard from '../../layout/Dashboard';
import SpecialForm from '../../components/forms/SpecialForm';

const SpecialAdd = ({history}) => {
  const special = {
    nome: '',
    descricao: '',
  };

  return (
    <Dashboard>
      <SpecialForm
        history={history}
        special={special}
        namePage={'Cadastrar Nova Categoria'}
      />
    </Dashboard>
  );
};

export default SpecialAdd;
