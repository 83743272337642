import React from 'react'
import { ListItemAvatar, ListItem, Avatar, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import api from '../core/api'
import Confirm from './Confirm'

const BannerListItem = ({ item, onDelete }) => {
	const deleteBanner = async () => {
		const res = await Confirm('Deseja realmente excluir este Banner?')
		if (res) {
			try {
				await api.delete(`/banners/${item.id}`).then((res) => (res.status === 200 ? onDelete() : alert('Erro')))
			} catch (e) {
				console.dir(e)
			}
		}
	}

	return (
		<ListItem alignItems="center" button component={Link} to={`/banners/edit/${item.id}`}>
			<ListItemAvatar>
				<Avatar
					src={item.thumbnail}
					style={{
						width: 75,
						height: 50,
						borderRadius: '0.5em 0.5em'
					}}
					onError={(e)=>{console.log(e.nativeEvent)}}
				>
				{!item.thumbnail && (item.tittle?.toUpperCase() || '')[0]}
				</Avatar>
			</ListItemAvatar>
			<ListItemText style={{ marginLeft: 8 }} primary={item.title} />

			<ListItemSecondaryAction>
				<IconButton component={Link} to={`/banners/edit/${item.id}`} edge="end">
					<EditIcon />
				</IconButton>
				<IconButton edge="end" aria-label="delete" onClick={() => deleteBanner()}>
					<DeleteIcon />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	)
}

export default BannerListItem
