import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import api from '../../core/api'
import { useAppContext } from '../singletons/AppContext'

export default function KeywordsContainer({ key, children }) {
	const [ state, setState ] = useState({
		keywords: [],
		categories: [],
		loading: true,
		error: null
	})
	const { setAppState } = useAppContext()

	async function fetchData() {
		setState({ loading: true, error: null })
		try {
			const { data: keywords } = await api.get(`/keywords`)
			const { data: categories } = await api.get(`/categories`)
			setState({ ...state, keywords, categories, loading: false })
		} catch (e) {
			if (e.response)
				setState({
					...state,
					error: {
						payload: e.response.data
					}
				})
			else setState({ ...state, error: {} })
		}
	}

	useEffect(() => {
		setAppState({ onReloadData: () => fetchData() })
		fetchData()
	}, [])

	if (state.error) {
		return (
			<div>
				<p style={{ textAlign: 'center' }}>Ocorreu um erro ao obter os dados.</p>
				<p style={{ textAlign: 'center' }}>
					<a href="javascript:void(0)" onClick={fetchData}>
						Tentar novamente
					</a>
				</p>
			</div>
		)
	}

	if (state.loading) {
		return (
			<div className="flex-col center-a center-b" style={{ height: '50vh' }}>
				<span style={{ paddingTop: '10em' }}>
					<CircularProgress size={100} />
					<p> Carregando... </p>
				</span>
			</div>
		)
	}

	return (
		<Grid container xs={12}>
			{children({
				keywords: state.keywords.sort((a, b) => {
					if (a.description > b.description) {
						return 1
					}
					if (a.description < b.description) {
						return -1
					}
					return 0
				}),
				categories: state.categories
			})}
		</Grid>
	)
}
