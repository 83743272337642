import React, {useEffect, useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import UserItem from '../../components/UserItem';
// import UsersContainer from '../../components/containers/UsersContainer';
import {
  CircularProgress,
  List,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';

import styles from './styles.module.css';
import api from '../../core/api';
import AddNewUser from '../../components/AddUser';
import FloatButton from '../../components/FloatButton';

const UsersAdminPage = ({history}) => {
  const [users, setUsers] = useState([]);
  const [firstLoading, setFirstLoading] = useState(true);
  const [isLoading, setIsFirstLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filterValue, setFilterValue] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [newsletterType, setNewsletterType] = useState();
  const [error, setError] = useState(false);

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = event => {
    console.log(event.target.value);
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  async function fetchUsers() {
    try {
      if (users.length < 1) {
        setFirstLoading(true);
      }
      setIsFirstLoading(true);

      const filters = {
        name: filterValue,
        premium: subscriptionType,
        newsletter: newsletterType,
      };

      const {data} = await api.get('users', {
        params: {
          page: currentPage,
          pageSize,
          filters,
          subscriptionType,
        },
      });
      setUsers(data.users);
      setTotalPages(data.totalUsers);
    } catch (err) {
      setError(true);
    } finally {
      setError(false);
      setFirstLoading(false);
      setIsFirstLoading(false);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [currentPage, pageSize, filterValue, subscriptionType, newsletterType]);

  if (error) {
    return (
      <div>
        <p style={{textAlign: 'center'}}>Ocorreu um erro ao obter os dados.</p>
        <p style={{textAlign: 'center'}}>
          <a href="javascript:void(0)" onClick={fetchUsers}>
            Tentar novamente
          </a>
        </p>
      </div>
    );
  }

  return (
    <Dashboard>
      <FloatButton route={'/users/admin/add'} />
      {firstLoading ? (
        <div className="flex-col center-a center-b" style={{height: '50vh'}}>
          <span style={{paddingTop: '10em'}}>
            <CircularProgress size={100} />
            <p> Carregando... </p>
          </span>
        </div>
      ) : (
        <div>
          <TablePagination
            component="div"
            count={totalPages}
            page={currentPage - 1}
            labelRowsPerPage={'Itens por página:'}
            onChangePage={handleChangePage}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          {isLoading ? (
            <div
              className="flex-col center-a center-b"
              style={{height: '50vh'}}>
              <span style={{paddingTop: '10em'}}>
                <CircularProgress size={100} />
                <p> Carregando... </p>
              </span>
            </div>
          ) : (
            <List>
              {users.map(client => (
                <UserItem
                  key={client.id}
                  history={history}
                  user={client}
                  admin={true}
                />
              ))}
            </List>
          )}
          <TablePagination
            component="div"
            count={totalPages}
            page={currentPage - 1}
            labelRowsPerPage={'Itens por página:'}
            onChangePage={handleChangePage}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
    </Dashboard>
  );
};

export default UsersAdminPage;
