import React from "react"
import { createStore, combineReducers, applyMiddleware } from "redux"
import { Provider as StoreProvider, connect } from "react-redux"
// import { createLogger } from "redux-logger"
// import debounce from "debounce"

export const FormContext = React.createContext({
	dispatch: () => void 0,
	getState: () => ({}),
	getDefs: () => ({}),
	index: 0, // nested only
	remove: () => void 0 // nested only
})

const { Provider, Consumer } = FormContext

const identity = any => any

const copyObject = object => ({ ...object })

const isFunction = any => typeof any === "function"

const isObject = any => !!any && typeof any === "object"

const stringify = object =>
	JSON.stringify(object, (k, v) => (isFunction(v) ? "<Function>" : v), 4)

const deleteKey = (object, key) => {
	const new_object = { ...object }
	delete new_object[key]
	return new_object
}

const deleteArrayIndex = (array, index) => array.filter((_, i) => index !== i)

export const update = (key, value) => ({
	type: "UPDATE",
	key,
	value
})

const set = state => ({
	type: "SET",
	state
})

const setError = key => ({
	type: "SET_ERR",
	key
})

const setErrors = errors => ({
	type: "SET_ERRORS",
	errors
})

const register = (key, defs) => ({
	type: "REGISTER",
	key,
	defs
})

const annotateDollar = object => {
	const new_object = {}
	for (const [k, v] of Object.entries(object || {})) new_object["$" + k] = v
	return new_object
}

const formReducer = (form = {}, action) =>
	action.type === "UPDATE"
		? { ...form, [action.key]: action.value }
		: action.type === "SET"
		? action.state
		: form

const errorsReducer = (errors = {}, action) =>
	action.type === "UPDATE"
		? deleteKey(errors, action.key)
		: action.type === "SET_ERR"
		? { ...errors, [action.key]: true }
		: action.type === "SET_ERRORS"
		? action.errors
		: errors

const defsReducer = (defs = {}, action) =>
	action.type === "REGISTER"
		? { ...defs, [action.key]: action.defs || {} }
		: action.type === "SET_REG"
		? action.defs
		: defs

const stateReducer = combineReducers({
	form: formReducer,
	errors: errorsReducer,
	defs: defsReducer
})

const nestedReducer = combineReducers({
	form: formReducer,
	errors: errorsReducer
})

class Lifecycle extends React.PureComponent {
	constructor(props) {
		super(props)
		const { onInit = () => void 0, value } = props
		onInit(value)
	}

	render() {
		return this.props.children
	}
}

export const Field = ({ name, options = {}, children, defaultValue = "" }) => {
	const { serialize = identity, parse = identity } = options
	return (
		<Consumer>
			{({ dispatch, getState }) => (
				<Lifecycle
					value={dispatch}
					onInit={dispatch => {
						if (serialize && getState().form[name]) {
							return
						}
						dispatch(
							update(
								name,
								serialize(getState().form[name] || defaultValue)
							),
							true
						)
						dispatch(register(name, annotateDollar(options)), true)
					}}>
					{children({
						value: parse(getState().form[name]),
						update: value =>
							dispatch(update(name, serialize(value)))
					})}
				</Lifecycle>
			)}
		</Consumer>
	)
}
Field.Text = ({ name, options, ...props }) => (
	<Field name={name} options={options}>
		{({ value, update }) => (
			<input
				value={value || ""}
				onChange={event => update(event.target.value)}
				{...props}
			/>
		)}
	</Field>
)

export const Error = ({ name, children }) => (
	<Consumer>{({ getState }) => getState().errors[name] && children}</Consumer>
)

export const Group = ({ children, options, name }) => (
	<Consumer>
		{({ dispatch, getState, getDefs }) => (
			<Lifecycle
				value={dispatch}
				onInit={dispatch => {
					if (!getState().form[name])
						dispatch(update(name, nestedReducer({}, {})), true)
					if (!getDefs()[name])
						dispatch(register(name, annotateDollar(options)), true)
				}}>
				<Provider
					value={{
						dispatch: (action, preventDefault) => {
							if (action.type === "REGISTER") {
								dispatch(
									register(
										name,
										defsReducer(getDefs()[name], action)
									),
									preventDefault
								)
								return
							} else
								dispatch(
									update(
										name,
										nestedReducer(
											getState().form[name],
											action
										)
									),
									preventDefault
								)
						},
						getState: () => getState().form[name],
						getDefs: () => getDefs()[name] || {}
					}}>
					{children}
				</Provider>
			</Lifecycle>
		)}
	</Consumer>
)

export const Repeat = ({ children, options, name }) => (
	<Consumer>
		{({ dispatch, getState, getDefs }) => (
			<Lifecycle
				value={dispatch}
				onInit={dispatch => {
					if (!getState().form[name]) {
						const initialState = nestedReducer({}, {})
						const stateFormAsArray = nestedReducer(
							initialState,
							set([])
						)
						dispatch(update(name, stateFormAsArray), true)
					}
					dispatch(register(name, annotateDollar(options)), true)
				}}>
				{((getState().form[name] || {}).form || []).map(
					(object, index) => (
						<Provider
							key={`${name}-${index}`}
							value={{
								dispatch: (action, preventDefault) => {
									if (action.type === "REGISTER") {
										dispatch(
											register(
												name,
												defsReducer(
													getDefs()[name],
													action
												)
											),
											preventDefault
										)
										return
									}
									const arrayState = getState().form[name]
									const updatedArrayState = nestedReducer(
										arrayState,
										set(
											arrayState.form.map((obj, i) =>
												i !== index
													? obj
													: nestedReducer(obj, action)
											)
										)
									)
									dispatch(
										update(name, updatedArrayState),
										preventDefault
									)
								},
								getState: () => object,
								remove: () => {
									const arrayState = getState().form[name]
									const updatedArrayState = nestedReducer(
										arrayState,
										set(
											deleteArrayIndex(
												arrayState.form,
												index
											)
										)
									)
									dispatch(update(name, updatedArrayState))
								},
								getDefs: () => getDefs()[name] || {}
							}}>
							{children}
						</Provider>
					)
				)}
			</Lifecycle>
		)}
	</Consumer>
)

Repeat.Add = ({ children, name }) => (
	<Consumer>
		{({ dispatch, getState }) =>
			children(() => {
				const arrayState = getState().form[name]
				const updatedArrayState = nestedReducer(
					arrayState,
					set([...arrayState.form, nestedReducer({}, {})])
				)
				dispatch(update(name, updatedArrayState))
			})
		}
	</Consumer>
)

Repeat.Remove = ({ children }) => (
	<Consumer>{({ remove }) => children(remove)}</Consumer>
)

const mountInitialBatchedState = (batchedState, initialValue = {}, nested) => {
	const reducer = nested ? nestedReducer : stateReducer
	for (const [k, v] of Object.entries(initialValue)) {
		if (isObject(v)) {
			if (Array.isArray(v)) {
				if (v.length === 0 || isObject(v[0])) {
					const initialState = reducer({}, {})
					const stateFormAsArray = reducer(
						initialState,
						set(
							v.map(val =>
								mountInitialBatchedState(
									reducer({}, {}),
									val,
									true
								)
							)
						)
					)
					batchedState = reducer(
						batchedState,
						update(k, stateFormAsArray)
					)
				} else {
					batchedState = reducer(batchedState, update(k, v))
				}
				continue
			}
			const nestedState = nestedReducer({}, {})
			const updatedNestedState = mountInitialBatchedState(
				nestedState,
				v,
				true
			)
			batchedState = reducer(batchedState, update(k, updatedNestedState))
			continue
		}
		batchedState = reducer(batchedState, update(k, v))
	}
	return batchedState
}

const serializeStateAsForm = ({ form }) => {
	const serializable = {}

	for (const [k, v] of Object.entries(form)) {
		if (isObject(v)) {
			if (Array.isArray(v.form)) {
				// if (( v.form.length > 0 ? !!v[0].form[0].form : true ))
				serializable[k] = v.form.map(c => serializeStateAsForm(c))
			} else if (v.form) serializable[k] = serializeStateAsForm(v)
		} else serializable[k] = v
	}

	return serializable
}

const defaultHandleErrors = () =>
	setTimeout(() => {
		const errorElement = document.getElementsByClassName("error-msg")[0]
		if (!errorElement) {
			return
		}
		errorElement.scrollIntoView({ behavior: "smooth" })
	})

class FormContainer extends React.Component {
	static withFormUpdateContext = formRef => callback => async (...args) => {
		await callback(...args)
		setTimeout(() => formRef.current.forceUpdate(), 0)
	}

	constructor(props) {
		super(props)

		const { initialValue } = props
		// const sofftore = createStore(stateReducer, stateReducer({}, {}), applyMiddleware(createLogger()))
		const store = createStore(stateReducer)

		this.mounted = false
		this.store = store
		this.connector = connect(copyObject)
		this.formContainer = this.connector(({ children }) => {
			const { dispatch, getState } = this.getStoreMethods()
			return (
				<Provider
					value={{
						dispatch,
						getState,
						getDefs: () => getState().defs
					}}>
					<form
						style={this.props.style}
						onSubmit={this.onSubmit}
						autoComplete="new-password"
						action="javascript:void(0)">
						{children}
					</form>
					{this.props.debug && (
						<pre>
							form.state ={" "}
							{stringify(serializeStateAsForm(getState()))}
						</pre>
					)}
					{this.props.fullDebug && (
						<pre>form.state = {stringify(getState())}</pre>
					)}
				</Provider>
			)
		})

		if (!initialValue) {
			return
		}

		let state = stateReducer({}, {})

		state = mountInitialBatchedState(state, initialValue, false)
		// console.log("*** INIT ***", state)
		store.dispatch(set(state.form))
	}

	componentDidMount() {
		this.mounted = true
		const { getState } = this.getStoreMethods()
		const { onChange = () => void 0 } = this.props
		const state = getState()
		onChange(serializeStateAsForm(state), this.updateForm.bind(this))
	}

	updateForm(form) {
		if (isFunction(form)) {
			form = form(serializeStateAsForm(this.store.getState()))
			console.log("nextForm", form)
		}
		let state = stateReducer({}, {})
		state = mountInitialBatchedState(state, form, false)
		this.store.dispatch(set(state.form))
	}

	getStoreMethods() {
		const { onChange = () => void 0 } = this.props
		const store = this.store
		const getState = () => store.getState()
		const dispatch = (action, preventDefault) => {
			store.dispatch.call(store, action)
			if (!this.mounted || preventDefault) {
				return
			}
			const new_state = getState()
			onChange(
				serializeStateAsForm(new_state),
				this.updateForm.bind(this)
			)
		}

		return { dispatch, getState }
	}

	collectDataErrors = (getState, dispatch, defs) => {
		const { form } = getState()

		const data = {}
		const errors = {}
		const keys = new Set([
			...Object.keys(form || {}),
			...Object.keys(defs || {}).filter(key => !key.startsWith("$"))
		])

		console.log(keys)

		for (const key of keys) {
			data[key] = form[key]
			const { $validate: validate } = defs[key] || {}
			if (validate) {
				const isNotValid = Array.isArray((form[key] || {}).form)
					? !validate(
							form[key].form || [],
							serializeStateAsForm(this.store.getState()),
							this.props.injectValidation || {}
					  )
					: !validate(
							form[key] || "",
							serializeStateAsForm(this.store.getState()),
							this.props.injectValidation || {}
					  )

				if (isNotValid) {
					errors[key] = true
					dispatch(setError(key))
				}
				continue
			}
			if (Array.isArray((form[key] || {}).form)) {
				if (!defs[key]) {
					continue
				}
				const collectArray = form[key].form.map((_, index) =>
					this.collectDataErrors(
						() => getState().form[key].form[index],
						(action, preventDefault) => {
							const initialState = nestedReducer({}, {})
							const updatedState = nestedReducer(
								initialState,
								set(
									getState().form[key].form.map((object, i) =>
										i !== index
											? object
											: nestedReducer(object, action)
									)
								)
							)
							dispatch(update(key, updatedState), preventDefault)
						},
						defs[key]
					)
				)
				data[key] = collectArray.map(({ data }) => data)
				const errors = collectArray.map(
					({ errors }) => Object.keys(errors).length > 0 && errors
				)
				if (errors.some(Boolean)) {
					errors[key] = errors
				}
			} else if (isObject(form[key])) {
				if (!defs[key]) {
					continue
				}
				const {
					data: nestedData,
					errors: nestedErrors
				} = this.collectDataErrors(
					() => getState().form[key],
					(action, preventDefault) =>
						dispatch(
							update(
								key,
								nestedReducer(getState().form[key], action)
							),
							preventDefault
						),
					defs[key]
				)
				data[key] = nestedData
				if (Object.keys(nestedErrors).length > 0) {
					errors[key] = nestedErrors
				}
			}
		}
		return { data, errors }
	}

	onSubmit = e => {
		if (e) e.preventDefault()

		const store = this.store
		const { defs } = store.getState()
		const { getState, dispatch } = this.getStoreMethods()

		const { data, errors } = this.collectDataErrors(
			getState,
			action => dispatch(action, true),
			defs
		)

		const {
			onSubmit = () => void 0,
			handleErrors = defaultHandleErrors,
			ignoreValidation
		} = this.props

		const isValid = Object.keys(errors).length === 0

		console.log({ errors })

		if (ignoreValidation || isValid) onSubmit(data, isValid)
		else handleErrors(errors)
	}

	render() {
		const store = this.store
		return (
			<StoreProvider store={store}>
				<this.formContainer>{this.props.children}</this.formContainer>
			</StoreProvider>
		)
	}
}

export default FormContainer
