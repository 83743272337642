import React, {useState, useEffect} from 'react';
import Chart from 'react-apexcharts';
import Title from '../../../components/Title';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {CircularProgress, Grid, Typography} from '@material-ui/core';
import DataCard from './DataCard';
import dashboardService from '../service';
import {
  getMonthName,
  getPlanName,
  months,
  premiuns,
  returnValues,
  returnValuesPremium,
  responsiveCards,
  returnValuesPremiumTotal,
  returnValuesTotal,
  responsiveMedium,
  AnnotationsMonth,
  AnnotationsPremiums,
} from '../service/utils';
import DrawTableDash from '../../../components/DrawTableDash';
import {KeyboardDatePicker} from '@material-ui/pickers';

const styles = {
  margin: {
    marginTop: 15,
    marginBottom: 15,
  },
  containerCards: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 15,
    marginBottom: 20,
  },
  legend: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  viewCharts: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: 15,
  },
  containerChart: {
    minWidth: '25%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    margin: '0 10px 10px 0',
  },
  containerChartDonut: {
    width: '50%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    margin: '0 10px 10px 0',
  },
  containerChartTable: {
    width: '75%',
    padding: 10,
    marginTop: 20,
  },
  containerChartLine: {
    width: '75%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    marginTop: 20,
  },
  containerChartf: {
    minWidth: '25%',
    width: '40%',
    border: 'solid 1px #d3d3d3',
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
  },
  title: {textAlign: 'center', marginTop: 10, marginBottom: 10},
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexEnd: {width: '100%', display: 'flex', justifyContent: 'flex-end'},
  datePicker: {
    borderRadius: '40px',
    height: 50,
    width: 'fit-content',
    border: 'solid 1px #d3d3d3',
    marginBottom: 20,
    marginLeft: 5,
  },
};

const UserChartByPlan = () => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState({
    data: [],
    total: 0,
  });
  const [resultsByMonth, setResultsByMonth] = useState({
    formatedDataByMonth: [],
    total: 0,
  });
  const [resultsTableOne, setResultsTableOne] = useState([]);
  const [resultsTable, setResultsTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [date, setDate] = useState(new Date());
  const [resultsByPlataform, setResultsByPlataform] = useState();

  const GetData = async () => {
    setLoading(true);

    dashboardService
      .getUsersByPlan({year: date.getFullYear()})
      .then(({data}) => {
        const formatedData = {
          data: returnValuesPremium(
            data?.results?.map(item => {
              return {...item, categories: getPlanName(item.premium)};
            }),
          ),
        };

        const resultMap = data?.resultsByMonth?.map(item => {
          return {...item, categories: getMonthName(item.month)};
        });
        const result = resultMap.reduce((acc, item) => {
          return {
            ...acc,
            [item.premium]: [...(acc[item.premium] ?? []), item],
          };
        }, {});

        const formatedDataByMonth = [
          {
            name: 'Total',
            title: '',
            color: '#4472C4',
            data: returnValuesTotal(resultMap),
            total: data?.total,
          },
          {
            name: 'New',
            title: '',
            color: '#4472C4',
            data: returnValues(result['N']),
            total:
              result['N']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
          {
            name: 'Free',
            title: '',
            color: '#ED7D31',
            data: returnValues(result['F']),
            total:
              result['F']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
          {
            name: 'Mensal',
            title: ' ',
            color: '#A5A5A5',
            data: returnValues(result['M']),
            total:
              result['M']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
          {
            name: 'Anual',
            title: ' ',
            color: '#FFC000',
            data: returnValues(result['Y']),
            total:
              result['Y']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
          {
            name: 'Gift',
            title: ' ',
            color: '#5B9BD5',
            data: returnValues(result['G']),
            total:
              result['G']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
          {
            name: 'Especial',
            title: ' ',
            color: '#70AD47',
            data: returnValues(result['S']),
            total:
              result['S']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
        ];

        const resultPlataformMap = data?.resultsByPlataform?.map(item => {
          return {...item, categories: getPlanName(item.premium)};
        });

        const resultPlataform = resultPlataformMap.reduce((acc, item) => {
          return {
            ...acc,
            [item.registration_platform]: [
              ...(acc[item.registration_platform] ?? []),
              item,
            ],
          };
        }, {});

        const formatedDataByPlataform = [
          {
            name: 'Total',
            title: '',
            color: '#4472C4',
            data: returnValuesPremiumTotal(resultPlataformMap),
            total: 0,
          },
          {
            name: 'AppleStore',
            title: '',
            color: '#4472C4',
            data: returnValuesPremium(resultPlataform['ios']),
            total:
              resultPlataform['ios']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
          {
            name: 'PlayStore',
            title: '',
            color: '#ED7D31',
            data: returnValuesPremium(resultPlataform['android']),
            total:
              resultPlataform['android']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
          {
            name: 'Site',
            title: ' ',
            color: '#A5A5A5',
            data: returnValuesPremium(resultPlataform['site']),
            total:
              resultPlataform['site']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
          {
            name: 'Sem plataforma',
            title: ' ',
            color: '#FFC000',
            data: returnValuesPremium(resultPlataform['null']),
            total:
              resultPlataform['null']?.reduce(function(accumulator, value) {
                return accumulator + value.count;
              }, 0) || 0,
          },
        ];

        setResults({...formatedData, total: data?.total});
        setResultsByMonth({
          formatedDataByMonth: formatedDataByMonth.slice(1),
          total: data?.count,
        });

        const responseTableOne = formatedDataByMonth.map(d => {
          const dataPerc = d.data.map((dd, index) => {
            const t = formatedDataByMonth[0].data[index]
              ? ((dd * 100) / formatedDataByMonth[0].data[index]).toFixed(2)
              : 0;
            return {dd: dd || 0, t};
          });
          return {...d, dataPerc};
        });
        setResultsTableOne(responseTableOne);
        setResultsByPlataform(formatedDataByPlataform.slice(1));
        const responseTable = formatedDataByPlataform.map(d => {
          const dataPerc = d.data.map((dd, index) => {
            const t = formatedDataByPlataform[0].data[index]
              ? ((dd * 100) / formatedDataByPlataform[0].data[index]).toFixed(2)
              : 0;
            return {dd: dd || 0, t};
          });
          return {...d, dataPerc};
        });
        setResultsTable(responseTable);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    GetData();
  }, [date]);

  return (
    <div style={styles.margin}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        style={styles.title}>
        Usuários cadastrados por plano
      </Typography>

      {loading ? (
        <div className="flex-col center-a center-b">
          <CircularProgress size={100} />
        </div>
      ) : (
        <>
          <Carousel responsive={responsiveCards}>
            <DataCard
              title={<span>Número total de cadastros</span>}
              value={results?.total}
            />
            {premiuns?.map((plan, index) => (
              <DataCard
                title={<span>{plan}</span>}
                value={results?.data[index]}
              />
            ))}
          </Carousel>

          <div style={{marginTop: 15}} />

          <div style={styles.card}>
            <div id="chart" style={styles.containerChartDonut}>
              <Title fontWeight="normal" size="medium" align="center">
                Total de usuários cadastrados por plano - Quantidade
              </Title>
              <div>
                <div style={styles.card}>
                  <Grid xs={6}>
                    <Chart
                      options={{
                        colors: [
                          '#4472C4',
                          '#ED7D31',
                          '#A5A5A5',
                          '#FFC000',
                          '#5B9BD5',
                          '#70AD47',
                        ],
                        chart: {
                          type: 'donut',
                        },
                        responsive: [
                          {
                            breakpoint: 480,
                            options: {
                              chart: {
                                width: 200,
                              },
                              legend: {
                                position: 'bottom',
                              },
                            },
                          },
                        ],
                        dataLabels: {
                          formatter(_, index) {
                            return results?.data[index.seriesIndex];
                          },
                          color: '#000',
                        },
                        labels: premiuns,
                      }}
                      series={results?.data}
                      type="donut"
                    />
                  </Grid>
                </div>
              </div>
            </div>

            <div id="chart" style={styles.containerChartDonut}>
              <Title fontWeight="normal" size="medium" align="center">
                Total de usuários cadastrados por plano - Percentual
              </Title>
              <div style={styles.card}>
                <Grid xs={6}>
                  <Chart
                    options={{
                      colors: [
                        '#4472C4',
                        '#ED7D31',
                        '#A5A5A5',
                        '#FFC000',
                        '#5B9BD5',
                        '#70AD47',
                      ],
                      chart: {
                        type: 'donut',
                      },
                      labels: premiuns,
                    }}
                    series={results?.data}
                    type="donut"
                  />
                </Grid>
              </div>
            </div>
          </div>

          <div style={{marginTop: 10}} />

          <div style={styles.flexEnd}>
            <div style={styles.datePicker}>
              <KeyboardDatePicker
                placeholder="Selecione ano"
                value={date}
                onChange={dateForm => setDate(new Date(dateForm))}
                views={['year']}
                yearsOrder="desc"
                format=" YYYY"
              />
            </div>
          </div>

          {resultsByMonth?.formatedDataByMonth.length > 0 && (
            <>
              <Carousel responsive={responsiveMedium}>
                <div id="chart" style={styles.containerChart}>
                  <Title fontWeight="normal" size="medium" align="center">
                    Total de usuários cadastrados por plano
                  </Title>
                  <Chart
                    options={{
                      chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                      },

                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            legend: {
                              position: 'bottom',
                              offsetX: -10,
                              offsetY: 0,
                            },
                          },
                        },
                      ],
                      plotOptions: {
                        bar: {
                          horizontal: false,
                        },
                        dataLabels: {
                          position: 'top', // top, center, bottom
                        },
                      },
                      xaxis: {
                        categories: months,
                      },
                      annotations: {
                        points: AnnotationsMonth(
                          resultsByMonth?.formatedDataByMonth,
                        ),
                      },
                    }}
                    series={resultsByMonth?.formatedDataByMonth}
                    type="bar"
                    height={500}
                  />
                </div>
                <div id="chart" style={styles.containerChart}>
                  <Title fontWeight="normal" size="medium" align="center">
                    Distribuição de usuários cadastrados por plano
                  </Title>
                  <Chart
                    options={{
                      chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                          enabled: false,
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        background: 'none',
                        textAnchor: 'end',
                      },
                      stroke: {
                        curve: 'straight',
                      },
                      xaxis: {
                        categories: months,
                      },
                    }}
                    series={resultsByMonth?.formatedDataByMonth}
                    type="line"
                    height={500}
                  />
                </div>
              </Carousel>

              <div style={{marginTop: 15}} />

              <Title fontWeight="normal" size="medium" align="center">
                Total de usuários cadastrados - Percentual e Quantidade
              </Title>
              <span style={styles.legend}>{date.getFullYear()}</span>

              <div id="chart" style={styles.containerChartTable}>
                {DrawTableDash(
                  months,
                  resultsTableOne.map(item => {
                    return [
                      {dd: item.name === 'all' ? 'Total' : item.name},
                      {dd: item.total},
                      {
                        dd: `${
                          resultsByMonth.total > 0
                            ? (
                                (item.total * 100) /
                                resultsByMonth.total
                              ).toFixed(2)
                            : 0
                        }% `,
                      },
                      ...item.dataPerc,
                    ];
                  }),
                  'partners-to-xls',
                  'Planos',
                )}
              </div>
            </>
          )}
        </>
      )}

      <div style={{marginTop: 10}} />

      {resultsByPlataform && (
        <>
          <Carousel responsive={responsiveMedium}>
            <div id="chart" style={styles.containerChart}>
              <Title fontWeight="normal" size="medium" align="center">
                Total de usuários cadastrados - Planos X Lojas - Quantidade
              </Title>
              <Chart
                options={{
                  chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                  },
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        legend: {
                          position: 'bottom',
                          offsetX: -10,
                          offsetY: 0,
                        },
                      },
                    },
                  ],
                  plotOptions: {
                    bar: {
                      horizontal: false,
                    },
                  },
                  xaxis: {
                    categories: premiuns,
                  },
                  annotations: {
                    points: AnnotationsPremiums(
                      resultsByPlataform?.map(res => {
                        return {
                          name: res.name,
                          title: res.title,
                          color: res.color,
                          data: res.data,
                        };
                      }),
                    ),
                  },
                }}
                series={resultsByPlataform?.map(res => {
                  return {
                    name: res.name,
                    title: res.title,
                    color: res.color,
                    data: res.data,
                  };
                })}
                type="bar"
                height={500}
              />
            </div>
            <div id="chart" style={styles.containerChart}>
              <Title fontWeight="normal" size="medium" align="center">
                Total de usuários cadastrados - Planos X Lojas - Percentual
              </Title>
              <Chart
                options={{
                  chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%',
                  },
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        legend: {
                          position: 'bottom',
                          offsetX: -10,
                          offsetY: 0,
                        },
                      },
                    },
                  ],
                  plotOptions: {
                    bar: {
                      horizontal: false,
                    },
                  },
                  xaxis: {
                    categories: premiuns,
                  },
                }}
                series={resultsByPlataform?.map(res => {
                  return {
                    name: res.name,
                    title: res.title,
                    color: res.color,
                    data: res.data,
                  };
                })}
                type="bar"
                height={500}
              />
            </div>
          </Carousel>

          <div style={{marginTop: 15}} />

          <Title fontWeight="normal" size="medium" align="center">
            Total de usuários cadastrados - Planos X Lojas - Percentual e
            Quantidade
          </Title>
          <div id="chart" style={styles.containerChartTable}>
            {DrawTableDash(
              premiuns,
              resultsTable.map(item => {
                return [
                  {dd: item.name === 'all' ? 'Total' : item.name},
                  {dd: item.total},
                  {
                    dd: `${
                      totalData > 0
                        ? ((item.total * 100) / totalData).toFixed(2)
                        : 0
                    }% `,
                  },
                  ...item.dataPerc,
                ];
              }),
              'partners-to-xls',
              'Loja',
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserChartByPlan;
