import React, {useEffect} from 'react';
import {makeStyles, Tabs, Tab} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useAppContext} from '../../components/singletons/AppContext';
import api from '../../core/api';
import Dashboard from '../../layout/Dashboard';
import RegisteredGraph from './components/RegisteredGraph';
import ActiveUsersGraph from './components/ActiveUsersGraph';
import UserFunnelChart from './components/UserFunnelChart';
import UserChartByPlan from './components/UserChartByPlan';
import ActiveUserChartByPlan from './components/ActiveUserChartByPlan';
import OnboardingGraph from './components/OnboardingGraph';
import OnboardingUserChartByPlan from './components/OnboardingUserChartByPlan';

const useStyles = makeStyles(styles => ({
  homeDiv: {
    marginTop: '3rem',
    width: '100%',
  },
  titleText: {
    fontSize: 48,
    color: '#61b7c3',
    marginTop: '10%',
  },
  tabDiv: {
    borderBottom: 'solid 0.5px rgba(0,0,0,0.3)',
  },
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const DashboardPage = () => {
  const {objectives, categories, partners, setAppState} = useAppContext();
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = index => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    if (!objectives || !objectives.length) {
      fetchObjectives();
    }
    if (!categories || !categories.length) {
      fetchCategories();
    }
    if (!partners || !partners.length) {
      fetchPartners();
    }
  }, []);

  const fetchObjectives = async () => {
    const objectivesRequest = await api.get('objectives');
    if (objectivesRequest.status === 200) {
      setAppState({
        objectives: objectivesRequest.data,
        categories,
        partners,
      });
    }
  };

  const fetchCategories = async () => {
    const categoriesRequest = await api.get('categories');
    if (categoriesRequest.status === 200) {
      setAppState({
        objectives,
        categories: categoriesRequest.data,
        partners,
      });
    }
  };

  const fetchPartners = async () => {
    const partnersRequest = await api.get('partners');
    if (partnersRequest.status === 200) {
      setAppState({
        objectives,
        categories,
        partners: partnersRequest.data,
      });
    }
  };

  const tabs = [
    {
      index: 0,
      label: 'Cadastrados',
    },
    {
      index: 1,
      label: 'Onboarding',
    },
    {
      index: 2,
      label: 'Ativos',
    },
    {
      index: 3,
      label: 'Funil de usuários',
    },
    {
      index: 4,
      label: 'Cadastrados-plano',
    },
    {
      index: 5,
      label: 'Onboarding-plano',
    },
    {
      index: 6,
      label: 'Ativos-plano',
    },
    // {
    //   index: 6,
    //   label: 'Receita-competência',
    // },
    // {
    //   index: 7,
    //   label: 'Instalações e desinstalações',
    // },
  ];

  return (
    <Dashboard>
      <div className={classes.homeDiv}>
        <div className={classes.tabDiv}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            aria-label="full width tabs example">
            {tabs.map(item => (
              <Tab label={item.label} {...a11yProps(item.index)} />
            ))}
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <RegisteredGraph />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OnboardingGraph />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ActiveUsersGraph />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <UserFunnelChart />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <UserChartByPlan />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <OnboardingUserChartByPlan />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <ActiveUserChartByPlan />
        </TabPanel>
        {/* <TabPanel value={value} index={6}>
          <RevenueChart />
        </TabPanel> 
        <TabPanel value={value} index={7}>
          <InstallationChart />
        </TabPanel>*/}
      </div>
    </Dashboard>
  );
};

export default DashboardPage;
