import api from '../../../core/api';

const dashboardService = {
  getUsersRegistered: async body => {
    return await api.post('/dashboard/registered_users', body);
  },
  getUsersOnboarding: async body => {
    return await api.post('/dashboard/onboarding_users', body);
  },
  getUsersByPlan: async body => {
    return await api.post('/dashboard/users_by_premium', body);
  },
  getOnboardingByPlan: async body => {
    return await api.post('/dashboard/onboarding_by_premium', body);
  },
  getUsersActiveMonth: async ({startDate, endDate}) => {
    return await api.post('/dashboard/active_users_month', {
      startDate,
      endDate,
    });
  },
  getUsersActiveDay: async body => {
    return await api.post('/dashboard/active_users_day', body);
  },
  getUsersActive: async year => {
    return await api.post('/dashboard/active_users', {
      year,
    });
  },
  getUsersActiveByPlan: async body => {
    return await api.post('/dashboard/active_users_by_premium', body);
  },
  getUsersFunnel: async year => {
    return await api.post('/dashboard/users_funnel', {
      year,
    });
  },
};

export default dashboardService;
