import React from 'react'
import Dashboard from '../../layout/Dashboard'
import SubCategoryForm from '../../components/forms/SubCategoryForm'

function SubCategoryEdit({ match }) {
	return (
		<Dashboard>
			<SubCategoryForm id={match.params.category} idSubCategory={match.params.id} />
		</Dashboard>
	)
}

export default SubCategoryEdit
