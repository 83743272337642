import React from "react"
import Dashboard from "../../layout/Dashboard"
import PartnerForm from "../../components/forms/PartnerForm"
import FileContext from "../../components/singletons/FileContext"
import useReduxState from "../../core/useReduxState"

const PartnerAdd = ({ history }) => {
	const [getFileState, setFileState] = useReduxState({
		src: null,
		crop: { unit: "%", width: 100, aspect: 3 / 3 },
		imageRef: null,
		croppedImageUrl: "",
		blobFile: ""
	})

	return (
		<FileContext.Provider value={{ ...getFileState(), setFileState }}>
			<Dashboard>
				<PartnerForm history={history} />
			</Dashboard>
		</FileContext.Provider>
	)
}

export default PartnerAdd
