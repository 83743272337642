import React, { useState, useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import api from '../../core/api'
import {
	TextField,
	Button,
	Grid,
	Typography,
	makeStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Avatar,
	CircularProgress
} from '@material-ui/core'
import ReturnButton from '../ReturnButton'
import InputText from './InputText'
import Dropzone from 'react-dropzone'
import FileContext, { useFileContext } from '../singletons/FileContext'
import ImageCroper from '../ImageCroper'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(() => ({
	form: {
		minHeight: window.innerHeight - 300,
		height: '60vh',
		overflowY: 'auto'
	},
	selectInput: {
		paddingTop: '2.5vh'
	},
	selectInputMargin: { marginLeft: '10%' },
	avatar: {
		height: 200,
		width: 200,
		boxShadow: '0 10px 16px 0 rgba(0,0,0,0.2)'
	},
	imageInput: {
		border: '3px dashed #000000a1',
		borderRadius: '0.5em',
		padding: '10px'
	},
	label: {
		padding: '10px 0'
	}
}))

const FormContext = React.createContext({
	form: {},
	setForm() {}
})

const typeDefs = {
	name: 'TEXT'
}

const CategoryField = ({ name, ...props }) => {
	const { form, setForm } = useContext(FormContext)

	const update = (event) =>
		setForm({
			...form,
			[name]: event.target.value
		})

	switch (typeDefs[name]) {
		default:
			return <InputText label="Título" value={form[name]} name={name} onChange={update} {...props} />
	}
}

function CategoryFormContainer({ key, history, id }) {
	const classes = useStyles()
	const { src, crop, blobFile, croppedImageUrl, setFileState } = useFileContext()
	const editing = !!id

	const [ form, setForm ] = useState({
		name: '',
		thumbnail: null
	})

	const { enqueueSnackbar } = useSnackbar()

	const [ state, setState ] = useState({
		loadingInfo: editing,
		formErrors: {},
		error: null,
		sendError: null,
		loading: false
	})

	const [ open, setOpen ] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}

	async function fetchInfo(id) {
		setState({ ...state, error: null, loadingInfo: true })
		try {
			const { data } = await api.get(`/categories/${id}`)
			setForm(data)
			setState({ ...state, loadingInfo: false })
		} catch (e) {
			setState({ ...state, error: true })
		}
	}

	useEffect(() => {
		if (editing) {
			// extract id here from navigations params
			fetchInfo(id)
		}
	}, [])
	useEffect(
		() => {
			if (src) {
				setFileState({ crop: { unit: '%' } })
				setForm({ ...form, thumbnail: src })
			}
		},
		[ src ]
	)

	const onSelectFile = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				setFileState({ src: reader.result })
				// setForm({ ...form, thumbnail: `${reader.result}` })
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	/**
	 *
	 * @param {import('axios').AxiosInstance} api
	 */
	async function onSubmit() {
		setState({ ...state, loading: true, sendError: null })
		setState({ ...state, loadingInfo: true })
		if (blobFile) {
			const reader = new FileReader()
			await reader.addEventListener('load', async () => {
				await setForm({
					...form,
					thumbnail: reader.result
				})
			})
			reader.readAsDataURL(blobFile)
		}

		setTimeout(async () => {
			try {
				const { status, data } = !editing
					? await api.post('/categories', form)
					: await api.put(`/categories/${id}`, form)
				if (status === 200) {
					history.push('/categories')
					enqueueSnackbar('Dados alterados com sucesso!')
				}
				setState({ ...state, loadingInfo: false })
			} catch (e) {
				setState({ ...state, loadingInfo: false })
				if (e.response.status === 413) {
					enqueueSnackbar('Imagem muito grande, por favor, diminua a resolução!')
				}
				// handle error
				setState({ ...state, loading: false, sendError: true })
			}
		}, 800)
	}

	if (state.loadingInfo) {
		return (
			<div className="flex-col center-a center-b" style={{ height: '50vh' }}>
				<span style={{ paddingTop: '10em' }}>
					<CircularProgress size={100} />
					<p> Carregando... </p>
				</span>
			</div>
		)
	}

	if (state.error) {
		return (
			<div>
				<p style={{ textAlign: 'center' }}> Ocorreu um erro ao obter os dados. </p>
				<p style={{ textAlign: 'center' }}>
					<a href="javascript:void(0)" onClick={fetchInfo}>
						{' '}
						Tentar novamente{' '}
					</a>
				</p>
			</div>
		)
	}

	return (
		<FormContext.Provider value={{ form, setForm }}>
			<Dialog onClose={handleClose} open={open}>
				<DialogTitle onClose={handleClose}>Editor de Imagem</DialogTitle>
				<DialogContent>
					<ImageCroper circularCrop={false} />
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose} color="primary">
						Salvar
					</Button>
				</DialogActions>
			</Dialog>
			<Grid lg={12}>
				<ReturnButton />
				<Typography component="h1" variant="h5">
					{!editing ? `Cadastrar Categoria` : `Editar Categoria`}
				</Typography>

				<form action="javascript:void(0)" onSubmit={() => onSubmit(api)}>
					<CategoryField name="name" />
					{/* <Grid xs={12} md={4} lg={4} style={{ padding: "20px" }}>
						<Dropzone
							onDrop={acceptedFiles => {
								// alert(acceptedFiles[0])
								// onUploadImage(acceptedFiles[0])
							}}>
							{({ getRootProps, getInputProps }) => {
								return (
									<div
										{...getRootProps()}
										className={classes.imageInput}>
										<div className="flex-col center-a center-b">
											<input
												{...getInputProps()}
												onChange={onSelectFile}
											/>

											<Avatar
												style={{
													width: 325,
													height: 150,
													borderRadius: 20,
													backgroundSize: "cover"
												}}
												className={classes.avatar}
												src={
													croppedImageUrl ||
													src ||
													form.thumbnail ||
													null
												}
											/>

											<p>
												Arraste a imagem até aqui ou
												clique para selecionar.
											</p>
										</div>
									</div>
								)
							}}
						</Dropzone>
						<Grid xs={12} container justify="center">
							<Button onClick={handleClickOpen}>
								Editar Imagem
							</Button>
						</Grid>
					</Grid> */}
					<Button style={{ marginTop: 20 }} type="submit" fullWidth variant="contained" color="primary">
						Cadastrar
					</Button>
				</form>
			</Grid>
		</FormContext.Provider>
	)
}

export default withRouter(CategoryFormContainer)
