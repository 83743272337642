import React, { useState, useCallback } from "react"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { FormControl, withStyles } from "@material-ui/core"
import AnimatedView from "./AnimatedView"

const PublicDatePicker = ({ selectedDate, formikProps }) => {
	const StyledFormControl = withStyles({
		root: {
			borderRadius: "40px",
			height: 50,
			border: "solid 1px #d3d3d3",
			padding: "0 20px"
		}
	})(FormControl)

	return (
		<AnimatedView>
			<StyledFormControl
				key={"datePicker"}
				error={
					formikProps.touched.publication_date &&
					formikProps.errors.publication_date
				}>
				<KeyboardDatePicker
					clearable
					value={formikProps.values.publication_date}
					placeholder="Data de Publicação"
					minDateMessage=""
					onChange={date =>
						formikProps.setFieldValue("publication_date", date)
					}
					minDate={new Date()}
					format="DD/MM/YYYY"
				/>
			</StyledFormControl>
		</AnimatedView>
	)
}

export default PublicDatePicker
