import React from 'react'
import Dashboard from '../../layout/Dashboard'
import CategoriesContainer from '../../components/containers/CategoriesContainer'
import { List, Fab, Typography, Grid, ListSubheader } from '@material-ui/core'
import CategoryItem from '../../components/CategoryItem'
import FloatButton from '../../components/FloatButton'
import SubCategoryItem from '../../components/SubCategoryItem'
import ReturnButton from '../../components/ReturnButton'
import { useAppContext } from '../../components/singletons/AppContext'

export default function SubCategoriesPage({ history }) {
	const { search } = useAppContext()
	return (
		<Dashboard>
			{/* <div className="flex-row center-b" style={{ width: '100%' }}>
				<ReturnButton />
				<Typography component="h1" variant="h5">
					SubCategorias
				</Typography>
			</div> */}
			<CategoriesContainer>
				{({ categories }) =>
					categories ? (
						<Grid xs={12}>
							<List subheader={<li />}>
								{categories.map((category) => {
									const sub_categories = JSON.parse(category.sub_category) || []

									const filteredSub = sub_categories.filter((item) =>
										item.name.toLowerCase().includes(search)
									)

									return (
										<Grid key={`section-${category.id}`}>
											{search.length === 0 && (
												<ListSubheader style={{ backgroundColor: '#FFF' }}>
													<Typography
														component="h1"
														variant="h5"
													>{`Categoria: ${category.name}`}</Typography>
												</ListSubheader>
											)}
											<SubCategoryItem
												category={category}
												subCategories={search.length === 0 ? sub_categories : filteredSub}
											/>
										</Grid>
									)
								})}
							</List>
						</Grid>
					) : (
						<h1>Você precisa Adicionar uma categoria</h1>
					)}
			</CategoriesContainer>
			<FloatButton route={`/sub-categories/add/`} />
		</Dashboard>
	)
}
