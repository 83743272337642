import React from 'react';
import Dashboard from '../../layout/Dashboard';
import UserForm from '../../components/forms/UserForm';

const UserAdd = ({history}) => {
  const user = {
    name: '',
    nickName: '',
    email: '',
    password: '',
    confirmPassword: '',
    special: false,
    partner_id: '',
    author: '',
    empresa_id: '',
    nomeEmpresa: '',
    special_id: '',
    nomeSpecial: '',
    isAdmin: false,
    version_app: '',
    version_device: '',
  };

  return (
    <Dashboard>
      <UserForm history={history} user={user} />
    </Dashboard>
  );
};

export default UserAdd;
