import {TableBody, TableCell, TableRow} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import InputText from '../../components/forms/InputText';

const EditListItem = ({users, editable, setUsers}) => {
  const updateEmail = (id, value) => {
    setUsers(prevState => {
      return [
        ...prevState.map((a, index) => {
          if (index === id) {
            return {...a, Email: value};
          }
          return a;
        }),
      ];
    });
  };

  const updateNome = (id, value) => {
    setUsers(prevState => {
      return [
        ...prevState.map((a, index) => {
          if (index === id) {
            return {...a, Nome: value};
          }
          return a;
        }),
      ];
    });
  };

  const updateNickName = (id, value) => {
    setUsers(prevState => {
      return [
        ...prevState.map((a, index) => {
          if (index === id) {
            return {...a, NickName: value};
          }
          return a;
        }),
      ];
    });
  };

  const formatCpf = valor => {
    if (valor)
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5',
      );

    return '';
  };

  const updateCPF = (id, value) => {
    setUsers(prevState => {
      return [
        ...prevState.map((a, index) => {
          if (index === id) {
            return {...a, CPF: value};
          }
          return a;
        }),
      ];
    });
  };

  const deleteItem = id => {
    const usersFilter = users.filter((item, index) => index != id);
  };

  useEffect(() => {
    if (!editable) {
      const usersFilter = users.filter(
        item => item.CPF || item.NickName || item.Nome || item.Email,
      );
      setUsers(usersFilter);
    }
  }, [editable]);

  return (
    <TableBody>
      {users.length ? (
        editable ? (
          <>
            {users.map((user, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <InputText
                    label=""
                    value={users[index].Email}
                    onChange={e =>
                      updateEmail(index, e.target.value.toString())
                    }
                  />
                </TableCell>
                <TableCell>
                  <InputText
                    label=""
                    value={users[index].Nome}
                    onChange={e => updateNome(index, e.target.value.toString())}
                  />
                </TableCell>
                <TableCell>
                  <InputText
                    label=""
                    value={users[index].NickName}
                    onChange={e =>
                      updateNickName(index, e.target.value.toString())
                    }
                  />
                </TableCell>
                <TableCell>
                  <span className="badge bg-warning text-dark">
                    <InputText
                      label=""
                      value={users[index].CPF}
                      onChange={e =>
                        updateCPF(index, e.target.value.toString())
                      }
                    />
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </>
        ) : (
          users.map((user, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{user.Email}</TableCell>
              <TableCell>{user.Nome}</TableCell>
              <TableCell>{user.NickName}</TableCell>
              <TableCell>
                <span className="badge bg-warning text-dark">{user.CPF}</span>
              </TableCell>
              <TableCell
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => deleteItem(index)}>
                <DeleteIcon />
              </TableCell>
            </TableRow>
          ))
        )
      ) : (
        <TableRow>
          <td colSpan="5" className="text-center">
            Sem dados.
          </td>
        </TableRow>
      )}
    </TableBody>
  );
};

export default EditListItem;
