/* eslint-disable */
import React, { useState } from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from 'formik';
import ReturnButton from '../ReturnButton';
import RenderInput from './RenderInput';
import {
  ListAllCategories,
} from '../../core/formHelpers';
import ButtonSubmit from './ButtonSubmit';
import UploadContext from '../singletons/UploadContext';
import { useSnackbar } from 'notistack';
import api from '../../core/api';

const useStyles = makeStyles(() => ({
  label: {
    padding: '15px 0',
  },
  invalidLabel: {
    color: 'red',
  }
}));

const KeywordsForm = ({
  keyword = {},
  history,
  categories = [],
}) => {
  const classes = useStyles();
  const editing = !!keyword.id;
  const [uploadState, setUploadState] = useState({
    uploading: false,
    uploadProgress: 0,
    successfullUploaded: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  const infoMidiaInputs = [
    { name: 'description', label: 'Palavra-Chave', type: 'text' },
  ];

  const validateForm = (values) => {
    const errors = {};  
    let errorMessage = '';

		if(!values.description || !values.description.length){
      errors.description = 'Descrição inválido';
      errorMessage += 'Descrição\n';
    }
    if(!values.selected_categories.length){
      errors.selected_categories = 'Categoria inválida';
      errorMessage += 'Categoria\n';
    }
    
    
    if(Object.keys(errors).length){
      enqueueSnackbar('Formulário incompleto, campos a serem preenchidos:\n'+errorMessage, { 
        preventDuplicate: true,
        variant: 'error',
        style: { whiteSpace: 'pre-line' }
      });
    }
  
    return errors;
  }

  let defaultValues = editing
    ? {
        ...keyword,
        selected_categories: keyword.categories,
      }
    : {
        description: '',
        selected_categories: [],
      };

  const onSubmit = async (values) => {
    const formData = new FormData();

    formData.append('description', values.description);
		formData.append('categories', values.selected_categories.toString());
		    
    if (editing) {
      let form = {
        description: values.description,
        categories: values.selected_categories.toString(),
      };
      
      try {
        await api
          .put(`/keywords/${values.id}`, form)
          .then(() => {
            enqueueSnackbar('Palavra-Chave Editado Com Sucesso!');
          })
          history.push(`/keywords`);

      } catch (e) {
        console.log({ e });
      }
    } else {
			try {
				await api
					.post('/keywords', formData)
					.then(() => {
						enqueueSnackbar('Palavra-Chave Criada Com Sucesso!');
					}) 
					history.push(`/keywords`);
			} catch (e) {
				console.log({ e });
			}
    }
  };

	return 	(
    <UploadContext.Provider value={{ ...uploadState, setUploadState }}>
      <Formik
        initialValues={defaultValues}
        validate={validateForm}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        enableReinitialize={true}
      >
        {(formikProps) => {
          return (
            <div>
              <div
                className='flex-row center-b'
                style={{
                  justifyContent: 'space-between',
                  padding: '0 20px',
                }}
              >
                <div className='flex-row center-b'>
                  <ReturnButton />
                  <Typography component='h1' variant='h5'>
                    {!editing ? 'Adicionar Palavra-Chave' : 'Editar Palavra-Chave'}
                  </Typography>
                </div>
              </div>

              <Grid container xs={12}>
                <Grid xs={12} item>
                  <form
                    style={{ padding: '20px' }}
                    onSubmit={formikProps.handleSubmit}
                  >
                    <RenderInput
                      inputs={infoMidiaInputs}
                      formikProps={formikProps}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 50,
                      }}
                    ></div>
                    <Grid xs={12}>
                      <Typography
                        component='h5'
                        variant='h5'
                      >
                        Categorias
                      </Typography>
                      { formikProps.errors.selected_categories &&
                        <span className={classes.invalidLabel}>Categoria inválida</span>
                      }
                      <ListAllCategories
                        categories={categories}
                        formikProps={formikProps}
                        regValues={/cursos|matérias|promoções/}
                      />
                    </Grid>

                    <Grid alignItems='center'>
                      <ButtonSubmit
                        disabled={formikProps.isSubmitting}
                        style={{ marginTop: 20 }}
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                      >
                        {formikProps.isSubmitting ? <CircularProgress size={40} /> :
                        'Salvar'
                        }
                      </ButtonSubmit>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </div>
          );
        }}
      </Formik>
    </UploadContext.Provider>
  )
};

export default KeywordsForm;
