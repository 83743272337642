import React, {useEffect, useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import api from '../../core/api';
import PlanForm from '../../components/forms/PlanForm';

const EditPlan = ({history, match}) => {
  const [plan, setPlan] = useState({});

  const fetchPlan = async () => {
    try {
      const {data: plan} = await api.get(`/plans/${match?.params?.planId}`);
      const price = plan?.value?.toString()?.split('.');
      const value =
        price?.length === 1
          ? `${plan?.value},00`
          : price[1]?.length === 1
          ? `${plan?.value?.toString()?.replace('.', ',')}0`
          : `${plan?.value?.toString()?.replace('.', ',')}`;
      setPlan({...plan, value});
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  return (
    <Dashboard>
      {plan?.id && <PlanForm history={history} plan={plan} />}
    </Dashboard>
  );
};

export default EditPlan;
