import React from "react"
import { withRouter } from "react-router-dom"
import Dashboard from "../../layout/Dashboard"
import CategoryFormContainer from "../../components/forms/CategoryForm"
import FileContext from "../../components/singletons/FileContext"
import useReduxState from "../../core/useReduxState"

function CategoryAdd({ history }) {
	const [getFileState, setFileState] = useReduxState({
		src: null,
		crop: { unit: "%", width: 100, aspect: 3 / 3 },
		imageRef: null,
		croppedImageUrl: "",
		blobFile: ""
	})

	return (
		<FileContext.Provider value={{ ...getFileState(), setFileState }}>
			<Dashboard>
				<CategoryFormContainer />
			</Dashboard>
		</FileContext.Provider>
	)
}

export default withRouter(CategoryAdd)
