import React, { useState } from "react"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { Link, withRouter } from "react-router-dom"
import List from "@material-ui/core/List"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import Collapse from "@material-ui/core/Collapse"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core/styles"
import MainCategoryIcon from "@material-ui/icons/Category"
import SubCategoryIcon from "@material-ui/icons/Class"
import {
	ListItemSecondaryAction,
	IconButton,
	ListItemAvatar,
	Avatar
} from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import api from "../core/api"
import Confirm from "./Confirm"
import { useAppContext } from "./singletons/AppContext"

const SubCategoryItem = ({ category, subCategories, history }) => {
	const [open, setOpen] = useState(false)
	const { onReloadData } = useAppContext()
	// const sub_categories = JSON.parse(category.sub_category)
	// const key_words = JSON.parse(category.key_words)

	const handleClick = () => {
		setOpen(!open)
	}

	const useStyles = makeStyles(() => ({
		nested: {
			paddingLeft: 60
		},
		listIcon: {
			paddingLeft: 5,
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-start"
		},
		// label: { textTransform: 'capitalize' },
		icon: {
			fontSize: 30,
			color: "#61b7c3"
		}
	}))

	const onDelete = async idSubCategory => {
		const res = await Confirm("Deseja realmente excluir esta Subcategoria?")
		if (res) {
			try {
				const { data: subCategories } = await api.get(
					`categories/sub-categories/${category.id}`
				)
				const filteredItem = subCategories.filter(
					item => item.id != idSubCategory
				)
				const form = {
					sub_category: JSON.stringify(filteredItem || [])
				}

				api.put(`/categories/${category.id}`, form).then(res => {
					if (res.status === 200) {
						onReloadData()
					}
				})
			} catch (e) {
				alert(e)
			}
		}
	}

	const classes = useStyles()

	return (
		<List>
			{subCategories &&
				subCategories.map((item, i) => {
					return (
						<ListItem
							className={classes.nested}
							button
							key={i}
							component={Link}>
							<ListItemAvatar>
								<Avatar>
									<SubCategoryIcon />
								</Avatar>
							</ListItemAvatar>

							<ListItemText
								className={classes.label}
								primary={item.name}
							/>
							<ListItemSecondaryAction>
								<IconButton
									component={Link}
									to={`/sub-categories/edit/${item.id}/${category.id}`}
									edge="end">
									<EditIcon />
								</IconButton>
								<IconButton
									edge="end"
									aria-label="delete"
									onClick={() => onDelete(item.id)}>
									<DeleteIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					)
				})}
		</List>
	)
}

export default withRouter(SubCategoryItem)
