import React from 'react'
import Dashboard from '../../layout/Dashboard'
import CourseForm from '../../components/forms/CourseForm'

const CoursesAdd = ({ history }) => {
	return (
		<Dashboard>
			<CourseForm history={history} />
		</Dashboard>
	)
}

export default CoursesAdd
