import React, {useState, useEffect} from "react"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { useFileContext } from "./singletons/FileContext"

const ImageCroper = ({ ...props }) => {
	const { src, crop, imageRef, setFileState } = useFileContext();
	const [imgRef, setImgRef] = useState(null);
	let fileUrl = ""

	useEffect(()=> {
		if(imgRef){
			makeClientCrop(crop);
		}
	},[imgRef, crop])

	// If you setFileState the crop in here you should return false.
	const onImageLoaded = image => {
		setFileState({ imageRef: image });
		setImgRef(image);
	}

	const onCropComplete = crop => {
		makeClientCrop(crop)
	}

	const makeClientCrop = async crop => {
		if (imageRef && crop.width && crop.height) {
			const croppedImageUrl = await getCroppedImg(
				imageRef,
				crop,
				"newFile.jpeg"
			)
			setFileState({ croppedImageUrl })
		}
	}

	const getCroppedImg = (image, crop, fileName) => {
		const canvas = document.createElement("canvas")
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		canvas.width = crop.width
		canvas.height = crop.height
		const ctx = canvas.getContext("2d")

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		)

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error("Canvas is empty")
					return
				}

				setFileState({ blobFile: blob })
				blob.name = fileName
				window.URL.revokeObjectURL(fileUrl)
				fileUrl = window.URL.createObjectURL(blob)
				resolve(fileUrl)
			}, "image/jpeg")
		})
	}



	return (
		<>
			{src && (
				<ReactCrop
					circularCrop={true}
					src={src}
					crop={{...crop, aspect: crop.aspect}}
					ruleOfThirds
					onImageLoaded={onImageLoaded}
					onComplete={onCropComplete}
					onChange={newCrop => setFileState({ crop: newCrop })}
					{...props}
				/>
			)}
		</>
	)
}

export default ImageCroper
