import React, {useEffect, useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import api from '../../core/api';
import EmpresaForm from '../../components/forms/EmpresaForm';

const EditEmpresa = ({history, match}) => {
  const [empresa, setEmpresa] = useState({});
  // const idEmpresa = parseInt(localStorage.getItem('empresa_id'));

  const fetchEmpresa = async () => {
    try {
      const {data: empresa} = await api.get(
        `/empresas/${match.params.empresaId}`,
      );
      setEmpresa(empresa);
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    fetchEmpresa();
  }, []);

  return (
    <Dashboard>
      {empresa.id && <EmpresaForm history={history} empresa={empresa} />}
    </Dashboard>
  );
};

export default EditEmpresa;
