import MomentUtils from '@date-io/moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {createBrowserHistory} from 'history';
import moment from 'moment';
import 'moment/locale/pt-br';
import {SnackbarProvider} from 'notistack';
import React, {useEffect, useMemo} from 'react';
import {Router} from 'react-router-dom';
import '../src/layout/css/App.css';
import AppContext from './components/singletons/AppContext';
import UploadContext from './components/singletons/UploadContext';
import api from './core/api';
import useReduxState from './core/useReduxState';
// import SubCategoryEdit from './pages/sub-categories/editcategory'

//Reset Password page

import MeditaLogo from './assets/images/medita_logo_horizontal.png';
import AppRoutes from './routes';

const NO_AUTH_ROUTES = ['login', 'reset', 'forgot', 'categories'];
const NO_AUTH_RE = new RegExp(`^/[${NO_AUTH_ROUTES.join('|')}]`);

function App({key}) {
  const history = useMemo(() => createBrowserHistory(), []);
  const [getAppState, setAppState] = useReduxState({
    user: null,
    partners: [],
    loading: true,
    search: '',
    path: '',
    onReloadData: null,
    history: history,
  });
  const [getUploadState, setUploadState] = useReduxState({
    video: null,
    thumbnail: null,
    videoURL: '',
    previewURL: '',
  });

  const fetchUserInfo = async () => {
    const fullUrl = window.location.href;
    if (fullUrl.match(/politica-de-privacidade/)) {
      setAppState({loading: false});
      return;
    }
    if (
      fullUrl.match(/suporte.medita.com.br/) ||
      fullUrl.match(/suporte.meditabemestar.com.br/)
    ) {
      if (history.location.pathname.match(/reset-password/)) {
        setAppState({loading: false});
        return;
      } else {
        window.location = 'https://medita.com.br';
      }
    } else {
      if (history.location.pathname.match(NO_AUTH_RE)) {
        setAppState({loading: false});
        return;
      }
      try {
        const {data} = await api.get('/auth/me/admin');
        const response = await api.get('/partners/', {
          headers: {
            Authorization: `Bearer ${data.token || ''}`,
          },
        });
        setAppState({user: data, partners: response.data, loading: false});
      } catch (e) {
        history.replace('/login');
        setAppState({loading: false});
      }
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const {loading} = getAppState();

  if (loading) {
    return (
      <div className="flex-col center-a center-b" style={{height: '50vh'}}>
        <span style={{paddingTop: '10em'}}>
          <img src={MeditaLogo} />
          <div className="flex-col center-a center-b">
            <CircularProgress size={100} />
            <p>Carregando... </p>
          </div>
        </span>
      </div>
    );
  }

  const appState = getAppState();
  const uploadState = getUploadState();

  return (
    <AppContext.Provider value={{...appState, setAppState}}>
      <Router history={history}>
        <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
          <UploadContext.Provider value={{...uploadState, setUploadState}}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              libInstance={moment}
              locale="pt-br">
              <AppRoutes />
            </MuiPickersUtilsProvider>
          </UploadContext.Provider>
        </SnackbarProvider>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
