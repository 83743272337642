import React from 'react'
import Dashboard from '../../layout/Dashboard'
import VideoCard from '../../components/VideoCard'
import { Fab, Grid, GridList, GridListTile, List } from '@material-ui/core'
import FloatButton from '../../components/FloatButton'
import CoursesContainer from '../../components/containers/CoursesContainer'
import ArticleList from '../../components/ArticleList'
import { useAppContext } from '../../components/singletons/AppContext'

const ArticlesPage = ({ history }) => {
	const { search } = useAppContext()

	return (
		<Dashboard>
			<CoursesContainer>
				{({ fetchData, courses, onDelete }) => (
					<Grid item xs={12} md={12} lg={12}>
						{courses.map((article, i) => {
							if (
								(article.course && (article.name || '').toLowerCase().includes(search)) ||
								(article.description || '').toLowerCase().includes(search)
							) {
								return (
									<List>
										<ArticleList fetchData={fetchData} article={article} history={history} />
									</List>
								)
							}
						})}
					</Grid>
				)}
			</CoursesContainer>
		</Dashboard>
	)
}

export default ArticlesPage
