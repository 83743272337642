import React from 'react'
import { ListItemAvatar, ListItem, Avatar, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import api from '../core/api'
import Confirm from './Confirm'

const CategoryItem = ({ item, onDelete }) => {
	const deleteCategory = async () => {
		const res = await Confirm('Deseja realmente excluir esta Categoria?')
		if (res) {
			try {
				await api
					.delete(`/categories/${item.id}`)
					.then((res) => (res.status === 200 ? onDelete() : alert('Erro')))
			} catch (e) {
				console.log('Upload Error')
				console.dir(e)
			}
		}
	}

	return (
		<ListItem alignItems="center" button component={Link} to={`/categories/edit/${item.id}`}>
			<ListItemAvatar>
				<Avatar>{(item.name || '')[0].toUpperCase()}</Avatar>
			</ListItemAvatar>
			<ListItemText primary={item.name} />
			<ListItemSecondaryAction>
				<IconButton component={Link} to={`/categories/edit/${item.id}`} edge="end">
					<EditIcon />
				</IconButton>
				<IconButton edge="end" aria-label="delete" onClick={() => deleteCategory()}>
					<DeleteIcon />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	)
}

export default CategoryItem
