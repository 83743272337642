import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import api from '../core/api';

const CountDetails = () => {
  const [usersCount, setUsersCount] = useState();

  const handlerCountDatails = async () => {
    const count = await api.get('/users/count');
    setUsersCount(count.data);
  };

  useEffect(() => {
    handlerCountDatails();
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <div style={{minWidth: '100px'}}>
        <InputLabel htmlFor="age-native-helper">Total de usuários</InputLabel>
        <InputLabel style={{fontWeight: 'bold'}}>
          {usersCount?.users_total}
        </InputLabel>
      </div>
      <div style={{minWidth: '100px', marginLeft: 10}}>
        <InputLabel htmlFor="age-native-helper">Usuários Admin</InputLabel>
        <InputLabel style={{fontWeight: 'bold'}}>
          {usersCount?.users_admin}
        </InputLabel>
      </div>
      <div style={{minWidth: '100px', marginLeft: 10}}>
        <InputLabel htmlFor="age-native-helper">Usuários novos</InputLabel>
        <InputLabel style={{fontWeight: 'bold'}}>
          {usersCount?.users_new}
        </InputLabel>
      </div>
      <div style={{minWidth: '100px', marginLeft: 10}}>
        <InputLabel htmlFor="age-native-helper">Usuários Free</InputLabel>
        <InputLabel style={{fontWeight: 'bold'}}>
          {usersCount?.users_free}
        </InputLabel>
      </div>
      <div style={{minWidth: '100px', marginLeft: 10}}>
        <InputLabel htmlFor="age-native-helper">Usuário Ass./Mensal</InputLabel>
        <InputLabel style={{fontWeight: 'bold'}}>
          {usersCount?.users_monthly}
        </InputLabel>
      </div>
      <div style={{minWidth: '100px', marginLeft: 10}}>
        <InputLabel htmlFor="age-native-helper">Usuário Ass./Anual</InputLabel>
        <InputLabel style={{fontWeight: 'bold'}}>
          {usersCount?.users_yearly}
        </InputLabel>
      </div>
      <div style={{minWidth: '100px', marginLeft: 10}}>
        <InputLabel htmlFor="age-native-helper">Usuário Gift</InputLabel>
        <InputLabel style={{fontWeight: 'bold'}}>
          {usersCount?.users_gift}
        </InputLabel>
      </div>
      <div style={{minWidth: '100px', marginLeft: 10}}>
        <InputLabel htmlFor="age-native-helper">Fez onboarding</InputLabel>
        <InputLabel style={{fontWeight: 'bold'}}>
          {usersCount?.users_wish_onboarding}
        </InputLabel>
      </div>
      <div style={{minWidth: '100px', marginLeft: 10}}>
        <InputLabel htmlFor="age-native-helper">No onboarding</InputLabel>
        <InputLabel style={{fontWeight: 'bold'}}>
          {usersCount?.users_without_onboarding}
        </InputLabel>
      </div>
    </div>
  );
};

export default CountDetails;
