import React, { useState } from 'react';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  Divider,
} from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import MusicIcon from '@material-ui/icons/MusicNote';
import VideoIcon from '@material-ui/icons/PlayArrow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VideoCard from './VideoCard';

import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import api from '../core/api';
import Confirm from './Confirm';
import { useAppContext } from './singletons/AppContext';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'none',
    transition: 'width 1s, height 1s, opacity 1s 1s',
  },

  paperPropsMax: {
    padding: 10,
    width: 600,
    height: 500,
    transition: 'width 1s, height 1s, border-width 0.6s linear',
  },
}));

const VideoListItem = ({ video, history }) => {
  const [open, setOpen] = useState(false);
  const { onReloadData, loading, setAppState } = useAppContext();
  const classes = useStyles();

  //open dialog modal
  const handleClose = () => {
    setOpen(false);
  };
  //close dialog modal
  const handleOpen = () => {
    setOpen(true);
  };

  const onDelete = async (id) => {
    const res = await Confirm('Deseja realmente excluir este conteúdo');
    if (res) {
      try {
        await api.delete(`/videos/${id}`).then((res) => {
          if (res.status === 200) {
            setAppState({ loading: false });
            return onReloadData();
          }
        });
      } catch (e) {
        onReloadData();
        console.log('Upload Error');
        console.dir(e);
      }
    }
  };

  return (
    <React.Fragment>
      <ListItem
        alignItems='center'
        button
      >
        <ListItemAvatar>
          <Avatar>{video.audio ? <MusicIcon /> : <VideoIcon />}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Typography component='h2'>{video.title}</Typography>}
          secondary={
            <Typography
              component='h4'
              variant='body2'
              color='textSecondary'
              style={{ maxWidth: '90%' }}
            >
              {video.description ? video.description : null}
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            component={Link}
            to={`/medias/edit/${video.id}`}
            edge='end'
          >
            <EditIcon />
          </IconButton>
          <IconButton
            edge='end'
            aria-label='delete'
            onClick={() => onDelete(video.id)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
        <Dialog
          PaperProps={{
            classes: {
              root: classes.paperPropsMax,
              paper: classes.fixWidth,
            },
          }}
          onClose={handleClose}
          aria-labelledby='simple-dialog-title'
          open={open}
        >
          <DialogTitle id='simple-dialog-title'>
            <div
              className='flex-row center-b'
              style={{ justifyContent: 'space-between' }}
            >
              <div>Detalhes do vídeo</div>

              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <VideoCard video={video} />
        </Dialog>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};

export default VideoListItem;
