import React, { useState, useEffect } from 'react';
import Dashboard from '../../../layout/Dashboard';
import { Grid, List } from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import AuditListItem from '../../../components/AuditListItem';
import { withRouter } from 'react-router-dom';
import AuditsContainer from '../../../components/containers/AuditsContainer';
import { useAppContext } from '../../../components/singletons/AppContext';
import useStyles from './styles';

import "./styles.css";

const AuditList = ({ history }) => {
  const classes = useStyles();
  const { search } = useAppContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(9);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(0);
    setCurrentPage(0);
  }, [search])


  const handleChange = (e) => {
    setCurrentPage(e.selected);
    setOffset(e.selected * perPage);
  };

  return (
    <Dashboard>
      <AuditsContainer>
        {({ fetchData, audits, partners, objectives, categories, users }) => {
          const filteredAudits = audits.filter(audit => (
            audit.title_new?.toLowerCase().includes(search?.toLowerCase()) ||
            audit.description_new?.toLowerCase().includes(search?.toLowerCase()) ||
            audit.title_old?.toLowerCase().includes(search?.toLowerCase()) ||
            audit.description_old?.toLowerCase().includes(search?.toLowerCase())
          ));

          const slicedAudits = filteredAudits.slice(offset, offset + perPage);

          return (
            <Grid item xs={12} align="center">
              <List dense className={classes.list}>
                {slicedAudits &&
                  slicedAudits.map((audit, i) => {
                    return (
                      <AuditListItem
                        key={i}
                        audit={audit}
                        fetchData={fetchData}
                        history={history}
                        partners={partners}
                        objectives={objectives}
                        categories={categories}
                        users={users}
                      />
                    );
                  })}
              </List>
              <ReactPaginate
                previousLabel={"anterior"}
                nextLabel={"próxima"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(filteredAudits.length / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handleChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={currentPage}
              />

            </Grid>
          );
        }}

      </AuditsContainer>
    </Dashboard>
  );
}

export default withRouter(AuditList);
