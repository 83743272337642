import React from "react"
import { IconButton } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { withRouter } from "react-router-dom"

const ReturnButton = ({ history }) => {
	return (
		<IconButton onClick={() => history.goBack()} aria-label="Return">
			<ArrowBackIcon />
		</IconButton>
	)
}

export default withRouter(ReturnButton)
