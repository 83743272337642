import React, { useState, useEffect } from 'react';
import FileContext from '../../../components/singletons/FileContext';
import Dashboard from '../../../layout/Dashboard';
import MultimidiaForm from '../../../components/forms/MultimidiaForm';
import useReduxState from '../../../core/useReduxState';
import api from '../../../core/api';

const MediasAdd = ({ history, match }) => {
  const [categories, setCategories] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [partners, setPartners] = useState([]);
  const [video, setVideo] = useState({});

  const [getFileState, setFileState] = useReduxState({
    src: null,
    crop: { unit: '%', width: 100, aspect: 3 / 3 },
    imageRef: null,
    croppedImageUrl: '',
    blobFile: '',
  });

  useEffect(() => {
    const videoName = match.params.videoName;
    const videoType = match.params.videoType;
    const videoSize = match.params.videoSize;
    const videoDuration = match.params.videoDuration;
    setVideo({
      ...video,
      filename: videoName,
      size: videoSize,
      type: videoType,
      duration_seconds: videoDuration,
    });
  }, [match]);

  const fetchApi = async () => {
    try {
      await api.get('/categories').then(({ data: categories }) => {
        setCategories(categories);
      });

      await api.get('/objectives').then(({ data: objectives }) => {
        setObjectives(objectives);
      });

      await api.get('/partners').then(({ data: partners }) => {
        const newPartners = partners
          .sort((a, b) => {
            if (a.artisticName > b.artisticName) {
              return 1;
            }
            if (a.artisticName < b.artisticName) {
              return -1;
            }
            return 0;
          });

        setPartners(newPartners);
      });
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    fetchApi();
  }, []);
  return (
    <FileContext.Provider value={{ ...getFileState(), setFileState }}>
      <Dashboard>
        <MultimidiaForm
          midia={{
            ...video,
          }}
          type={video.type}
          size={video.size}
          history={history}
          partners={partners}
          objectives={objectives}
          categories={categories}
        />
      </Dashboard>
    </FileContext.Provider>
  );
};

export default MediasAdd;
