import React from 'react';
import {Route} from 'react-router-dom';
import LoginPage from '../pages/login';
import RegisterPage from '../pages/register';
import ResetPasswordPage from '../pages/reset-password';
import PrivacyPage from '../pages/PrivacyPolicy';

export function PublicRoutes() {
  return (
    <>
      <Route path="/login" exact component={LoginPage} />
      <Route path="/register" exact component={RegisterPage} />
      <Route path="/reset-password" exact component={ResetPasswordPage} />
      <Route path="/politica-de-privacidade" component={PrivacyPage} />
      {/* <Redirect to="/login" /> */}
    </>
  );
}
