import React, {useState, useEffect} from 'react';
import Chart from 'react-apexcharts';
import Title from '../../../components/Title';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {CircularProgress, Grid, Typography} from '@material-ui/core';
import DrawTable from '../../../components/DrawTable';
import DataCard from './DataCard';
import {
  AnnotationsMonth,
  AnnotationsPremiums,
  getMonthName,
  getPlanName,
  months,
  premiuns,
  responsive,
  responsiveCards,
  responsiveMedium,
  returnTotalValues,
  returnTotalValuesPremium,
  returnValuesPremium,
  returnValuesTotalPremium,
} from '../service/utils';
import dashboardService from '../service';
import {KeyboardDatePicker} from '@material-ui/pickers';
import DrawTableDash from '../../../components/DrawTableDash';

const styles = {
  margin: {
    marginTop: 15,
    marginBottom: 15,
  },
  containerCards: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 15,
    marginBottom: 20,
  },
  viewCharts: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: 15,
  },
  containerChart: {
    minWidth: '25%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    margin: '0 10px 10px 0',
  },
  containerChartDonut: {
    width: '50%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    margin: '0 10px 10px 0',
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerChartLine: {
    width: '75%',
    border: 'solid 1px #d3d3d3',
    padding: 10,
    marginTop: 20,
  },
  containerChartTable: {
    width: '75%',
    padding: 10,
    marginTop: 20,
  },
  containerChartf: {
    minWidth: '25%',
    width: '40%',
    border: 'solid 1px #d3d3d3',
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
  },
  title: {textAlign: 'center', marginTop: 10, marginBottom: 10},
  flexEnd: {width: '100%', display: 'flex', justifyContent: 'flex-end'},
  datePicker: {
    borderRadius: '40px',
    height: 50,
    width: 'fit-content',
    border: 'solid 1px #d3d3d3',
    marginBottom: 20,
    marginLeft: 5,
  },
  legend: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
};

const ActiveUserChartByPlan = () => {
  const colors = [
    '#4472C4',
    '#ED7D31',
    '#A5A5A5',
    '#FFC000',
    '#5B9BD5',
    '#70AD47',
  ];

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [resultsCards, setResultsCards] = useState({
    New: [],
    Free: [],
    Mensal: [],
    Anual: [],
    Gift: [],
    Especial: [],
  });
  const [resultsTable, setResultsTable] = useState([]);
  const [resultsTableOne, setResultsTableOne] = useState([]);
  const [resultsByPlataform, setResultsByPlataform] = useState();
  const [totalResults, setTotalResults] = useState();
  const [resultsMonth, setResultsMonth] = useState();
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1)),
  );
  const [endDate, setEndDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [totalMonth, setTotalMonth] = useState(0);
  const [totalData, setTotalData] = useState(0);

  const GetData = async () => {
    setLoading(true);

    await dashboardService
      .getUsersActiveByPlan({startDate, endDate, year: date.getFullYear()})
      .then(async ({data}) => {
        const formatedData = data?.resultsFilter
          ?.map(item => {
            return {...item, categories: getPlanName(item.premium)};
          })
          ?.reduce((acc, item) => {
            return {
              ...acc,
              [item.categories]: [...(acc[item.categories] ?? []), item],
            };
          }, {});

        setResultsCards(formatedData);
        setResults(returnTotalValuesPremium(formatedData));
        setTotalMonth(data?.resultsFilter?.length || 0);

        const resultMap = data?.results?.map(item => {
          return {...item, categories: getMonthName(item.month)};
        });
        const result = resultMap?.reduce((acc, item) => {
          return {
            ...acc,
            [item.premium]: [...(acc[item.premium] ?? []), item],
          };
        }, {});

        const formatedDataMonth = [
          {
            name: 'Total',
            title: '',
            color: '#4472C4',
            data: returnTotalValues(resultMap),
            total: 0,
          },
          {
            name: 'New',
            title: '',
            color: '#4472C4',
            data: returnTotalValues(result['N']),
            total: result['N']?.length || 0,
          },
          {
            name: 'Free',
            title: '',
            color: '#ED7D31',
            data: returnTotalValues(result['F']),
            total: result['F']?.length || 0,
          },
          {
            name: 'Mensal',
            title: '',
            color: '#A5A5A5',
            data: returnTotalValues(result['M']),
            total: result['M']?.length || 0,
          },
          {
            name: 'Anual',
            title: '',
            color: '#FFC000',
            data: returnTotalValues(result['Y']),
            total: result['Y']?.length || 0,
          },
          {
            name: 'Gift',
            title: '',
            color: '#5B9BD5',
            data: returnTotalValues(result['G']),
            total: result['G']?.length || 0,
          },
          {
            name: 'Especial',
            title: '',
            color: '#70AD47',
            data: returnTotalValues(result['S']),
            total: result['S']?.length || 0,
          },
        ];

        setResultsMonth(formatedDataMonth.slice(1));
        setTotalResults(
          formatedDataMonth.slice(1).map(form => {
            return {
              ...form,
              data: [form.total],
            };
          }),
        );
        setTotalData(
          formatedDataMonth.slice(1).reduce(function(accumulator, value) {
            return accumulator + value.total;
          }, 0) || 0,
        );

        const responseTable = formatedDataMonth.map(d => {
          const dataPerc = d.data.map((dd, index) => {
            const t = formatedDataMonth[0].data[index]
              ? ((dd * 100) / formatedDataMonth[0].data[index]).toFixed(2)
              : 0;
            return {dd: dd || 0, t};
          });
          return {...d, dataPerc};
        });
        setResultsTable(responseTable);

        const resultPlataformMap = Object.values(data?.resultsByPlatform)?.map(
          item => {
            return {...item, categories: getPlanName(item.premium)};
          },
        );
        const resultPlataform = resultPlataformMap.reduce((acc, item) => {
          return {
            ...acc,
            [item.platform]: [...(acc[item.platform] ?? []), item],
          };
        }, {});

        const formatedDataByPlataform = [
          {
            name: 'Total',
            title: '',
            color: '#4472C4',
            data: returnValuesTotalPremium(resultPlataformMap),
            total: 0,
          },
          {
            name: 'AppleStore',
            title: '',
            color: '#4472C4',
            data: returnValuesTotalPremium(resultPlataform['ios']),
            total: resultPlataform['ios']?.length || 0,
          },
          {
            name: 'PlayStore',
            title: '',
            color: '#ED7D31',
            data: returnValuesTotalPremium(resultPlataform['android']),
            total: resultPlataform['android']?.length || 0,
          },
        ];

        setResultsByPlataform(formatedDataByPlataform.slice(1));
        const responseTableOne = formatedDataByPlataform.map(d => {
          const dataPerc = d.data.map((dd, index) => {
            const t = formatedDataByPlataform[0].data[index]
              ? ((dd * 100) / formatedDataByPlataform[0].data[index]).toFixed(2)
              : 0;
            return {dd: dd || 0, t};
          });
          return {...d, dataPerc};
        });

        setResultsTableOne(responseTableOne);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setResults([]);
    setTotalResults();
    setResultsMonth();
    setTotalData(0);
    GetData();
  }, [startDate, endDate, date]);

  return (
    <div style={styles.margin}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        style={styles.title}>
        Usuários ativos por plano
      </Typography>

      <div style={styles.flexEnd}>
        <div style={styles.datePicker}>
          <KeyboardDatePicker
            placeholder="Selecione data inicial"
            value={startDate}
            onChange={dateForm => {
              setStartDate(new Date(dateForm));
              setEndDate(
                new Date(
                  new Date(dateForm).setMonth(
                    new Date(dateForm).getMonth() + 1,
                  ),
                ),
              );
            }}
            format="DD/MM/YYYY"
          />
        </div>
        <div style={styles.datePicker}>
          <KeyboardDatePicker
            placeholder="Selecione data final"
            value={endDate}
            onChange={dateForm => {
              setEndDate(new Date(dateForm));
              setStartDate(
                new Date(
                  new Date(dateForm).setMonth(
                    new Date(dateForm).getMonth() - 1,
                  ),
                ),
              );
            }}
            format="DD/MM/YYYY"
          />
        </div>
      </div>

      {loading ? (
        <div className="flex-col center-a center-b">
          <CircularProgress size={100} />
        </div>
      ) : (
        <>
          <Carousel responsive={responsiveCards}>
            <DataCard
              title={<span>Total de usuários ativos</span>}
              value={totalMonth}
            />
            {premiuns?.map(plan => (
              <DataCard
                title={<span>{plan}</span>}
                value={resultsCards[plan]?.length || 0}
              />
            ))}
          </Carousel>

          <div style={{marginTop: 15}} />

          {results?.length > 0 && (
            <div style={styles.card}>
              <div id="chart" style={styles.containerChartDonut}>
                <Title fontWeight="normal" size="medium" align="center">
                  Total de usuários ativos por plano - Quantidade
                </Title>
                <div>
                  <div style={styles.card}>
                    <Grid xs={6}>
                      <Chart
                        options={{
                          colors: [
                            '#4472C4',
                            '#ED7D31',
                            '#A5A5A5',
                            '#FFC000',
                            '#5B9BD5',
                            '#70AD47',
                          ],
                          chart: {
                            type: 'donut',
                          },
                          responsive: [
                            {
                              breakpoint: 480,
                              options: {
                                chart: {
                                  width: 200,
                                },
                                legend: {
                                  position: 'bottom',
                                },
                              },
                            },
                          ],
                          dataLabels: {
                            formatter(_, index) {
                              return results[index.seriesIndex];
                            },
                            color: '#000',
                          },
                          labels: premiuns,
                        }}
                        series={results}
                        type="donut"
                      />
                    </Grid>
                  </div>
                </div>
              </div>

              <div id="chart" style={styles.containerChartDonut}>
                <Title fontWeight="normal" size="medium" align="center">
                  Total de usuários ativos por plano - Percentual
                </Title>
                <div style={styles.card}>
                  <Grid xs={6}>
                    <Chart
                      options={{
                        colors: [
                          '#4472C4',
                          '#ED7D31',
                          '#A5A5A5',
                          '#FFC000',
                          '#5B9BD5',
                          '#70AD47',
                        ],
                        chart: {
                          type: 'donut',
                        },
                        labels: premiuns,
                      }}
                      series={results}
                      type="donut"
                    />
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <div style={{marginBottom: 10}} />

      <div style={styles.flexEnd}>
        <div style={styles.datePicker}>
          <KeyboardDatePicker
            placeholder="Selecione ano"
            value={date}
            onChange={dateForm => setDate(new Date(dateForm))}
            views={['year']}
            yearsOrder="desc"
            format="YYYY"
          />
        </div>
      </div>

      {resultsMonth && (
        <>
          <Carousel responsive={responsiveMedium}>
            <div id="chart" style={styles.containerChart}>
              <Title fontWeight="normal" size="medium" align="center">
                Total de usuários ativos por plano
              </Title>
              <Chart
                options={{
                  chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                  },
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        legend: {
                          position: 'bottom',
                          offsetX: -10,
                          offsetY: 0,
                        },
                      },
                    },
                  ],
                  plotOptions: {
                    bar: {
                      horizontal: false,
                    },
                  },
                  xaxis: {
                    categories: months,
                  },
                  annotations: {
                    points: AnnotationsMonth(resultsMonth),
                  },
                }}
                series={resultsMonth}
                type="bar"
                height={500}
              />
            </div>
            <div id="chart" style={styles.containerChart}>
              <Title fontWeight="normal" size="medium" align="center">
                Distribuição de usuários ativos por plano{' '}
              </Title>
              <Chart
                options={{
                  chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                      enabled: false,
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    background: 'none',
                    textAnchor: 'end',
                  },
                  stroke: {
                    curve: 'straight',
                  },
                  xaxis: {
                    categories: months,
                  },
                }}
                series={resultsMonth}
                type="line"
                height={500}
              />
            </div>
          </Carousel>

          <div style={{marginTop: 15}} />

          <Title fontWeight="normal" size="medium" align="center">
            Total de usuários ativos - Percentual e Quantidade
          </Title>
          <span style={styles.legend}>{date.getFullYear()}</span>

          <div id="chart" style={styles.containerChartTable}>
            {DrawTableDash(
              months,
              resultsTable.map(item => {
                return [
                  {dd: item.name === 'all' ? 'Total' : item.name},
                  {dd: item.total},
                  {
                    dd: `${
                      totalData > 0
                        ? ((item.total * 100) / totalData).toFixed(2)
                        : 0
                    }% `,
                  },
                  ...item.dataPerc,
                ];
              }),
              'partners-to-xls',
              'Planos',
            )}
          </div>

          <div style={{marginTop: 10}} />

          {resultsByPlataform && (
            <>
              <Carousel responsive={responsiveMedium}>
                <div id="chart" style={styles.containerChart}>
                  <Title fontWeight="normal" size="medium" align="center">
                    Total de usuários ativos - Planos X Lojas - Quantidade
                  </Title>
                  <Chart
                    options={{
                      chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                      },
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            legend: {
                              position: 'bottom',
                              offsetX: -10,
                              offsetY: 0,
                            },
                          },
                        },
                      ],
                      plotOptions: {
                        bar: {
                          horizontal: false,
                        },
                      },
                      xaxis: {
                        categories: premiuns,
                      },
                      annotations: {
                        points: AnnotationsPremiums(
                          resultsByPlataform?.map(res => {
                            return {
                              name: res.name,
                              title: res.title,
                              color: res.color,
                              data: res.data,
                            };
                          }),
                        ),
                      },
                    }}
                    series={resultsByPlataform?.map(res => {
                      return {
                        name: res.name,
                        title: res.title,
                        color: res.color,
                        data: res.data,
                      };
                    })}
                    type="bar"
                    height={500}
                  />
                </div>
                <div id="chart" style={styles.containerChart}>
                  <Title fontWeight="normal" size="medium" align="center">
                    Total de usuários ativos - Planos X Lojas - Percentual
                  </Title>
                  <Chart
                    options={{
                      chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        stackType: '100%',
                      },
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            legend: {
                              position: 'bottom',
                              offsetX: -10,
                              offsetY: 0,
                            },
                          },
                        },
                      ],
                      plotOptions: {
                        bar: {
                          horizontal: false,
                        },
                      },
                      xaxis: {
                        categories: premiuns,
                      },
                    }}
                    series={resultsByPlataform?.map(res => {
                      return {
                        name: res.name,
                        title: res.title,
                        color: res.color,
                        data: res.data,
                      };
                    })}
                    type="bar"
                    height={500}
                  />
                </div>
              </Carousel>

              <div style={{marginTop: 15}} />

              <Title fontWeight="normal" size="medium" align="center">
                Total de usuários ativos - Planos X Lojas - Percentual e
                Quantidade
              </Title>

              <div id="chart" style={styles.containerChartTable}>
                {DrawTableDash(
                  premiuns,
                  resultsTableOne.map(item => {
                    return [
                      {dd: item.name === 'all' ? 'Total' : item.name},
                      {dd: item.total},
                      {
                        dd: `${
                          totalData.total > 0
                            ? ((item.total * 100) / totalData.total).toFixed(2)
                            : 0
                        }% `,
                      },
                      ...item.dataPerc,
                    ];
                  }),
                  'partners-to-xls',
                  'Loja',
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ActiveUserChartByPlan;
