import React from "react"
import Dashboard from "../../layout/Dashboard"
import CategoryForm from "../../components/forms/CategoryForm"
import useReduxState from "../../core/useReduxState"
import FileContext from "../../components/singletons/FileContext"

function CategoryEdit({ match }) {
	const [getFileState, setFileState] = useReduxState({
		src: null,
		crop: { unit: "%", width: 100, aspect: 3 / 3 },
		imageRef: null,
		croppedImageUrl: "",
		blobFile: ""
	})

	return (
		<FileContext.Provider value={{ ...getFileState(), setFileState }}>
			<Dashboard>
				<CategoryForm id={match.params.id} />
			</Dashboard>
		</FileContext.Provider>
	)
}

export default CategoryEdit
