import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Divider,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  InputAdornment,
  ListSubheader,
  IconButton,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useReduxState from '../../core/useReduxState';
import api from '../../core/api';
import FormContainer from '../containers/FormContainer';
import {Text} from './FormInputs';
import request from 'superagent';
import Dropzone from 'react-dropzone';
import InputText from './InputText';
import ReturnButton from '../ReturnButton';
import {useSnackbar} from 'notistack';
import PublicDatePicker from '../PublicDatePicker';
import ChipsArray from '../ChipsArray';
import VideoListItem from '../VideoListItem';
import UploadContent from '../UploadContent';
import {useUploadContext} from '../singletons/UploadContext';

const useStyles = makeStyles(() => ({
  selectInput: {
    paddingTop: '2.5vh',
  },
  selectInputMargin: {marginRight: 10},
  avatar: {
    height: 200,
    width: 200,
    boxShadow: '0 10px 16px 0 rgba(0,0,0,0.2)',
  },
  imageInput: {
    border: '3px dashed #000000a1',
    borderRadius: '0.5em',
    padding: '10px',
    minHeight: 133,
  },
  label: {
    padding: '10px 0',
  },
  section: {
    marginTop: 10,
  },
}));

const CourseForm = ({article = '', history}) => {
  const classes = useStyles();
  const [getState, setState] = useReduxState({
    article: '',
    thumbnailUrl: '',
    title: '',
    authorId: '',
    videoId: '',
    videoTitle: '',
    cardDescription: '',
    filename: '',
    authorName: '',
    categories: [],
    sub_categories: [],
    key_words: [],
    checkbox: false,
    premium: false,
    thumbnail: null,
  });
  const {enqueueSnackbar} = useSnackbar();

  const [categories, setCategories] = useState([]);
  const [partners, setPartners] = useState([]);
  const [promotion, setPromotion] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedDate, handleDateChange] = useState(null);
  const [open, setOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [videoUpload, setVideo] = useState({
    file: [],
    uploading: false,
    uploadProgress: 0,
    successfullUploaded: false,
  });
  const [editingThumbnail, setEditingThumbnail] = useState(false);
  const [editingVideo, setEditingVideo] = useState(false);

  const videoFile = useUploadContext().video;
  const thumbnailFile = useUploadContext().thumbnail;

  const editing = !!article.id;
  const {checkbox} = getState();

  useEffect(() => {
    fetchDataApi()
      .then(fetchPartnerId)
      .then(fetchVideoId);
  }, []);

  // useEffect(() => {
  // 	fetchPartnerId().then()
  // }, [])

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = value => {
    setOpen(value);
  };

  const fetchPartnerId = async () => {
    if (editing) {
      try {
        await api
          .get(`/partners/${article.partnerId}`)
          .then(({data: partner}) => {
            setState({authorName: partner.artisticName});
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const fetchVideoId = async () => {
    if (editing) {
      try {
        await api.get(`/videos/${article.video_id}`).then(({data: video}) => {
          setState({videoTitle: video.title});
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const fetchDataApi = async () => {
    try {
      await api.get('/categories').then(({data: categories}) => {
        const newCategories = categories.filter(item => {
          if (item.name.toLowerCase().includes('cursos')) return item;
        });
        const newPromotion = categories.filter(item => {
          if (item.name.toLowerCase().includes('promoções')) return item;
        });
        setCategories(newCategories);
        setPromotion(JSON.parse(newPromotion[0].sub_category));
      });
      await api.get('/videos').then(({data: videos}) => {
        setVideos(
          videos.filter(video =>
            video.description.toLowerCase().includes('cursos'),
          ),
        );
      });
      await api.get('/partners').then(({data: partners}) => {
        const newPartners = partners.sort((a, b) => {
          if (a.artisticName > b.artisticName) {
            return 1;
          }
          if (a.artisticName < b.artisticName) {
            return -1;
          }
          return 0;
        });

        setPartners(newPartners);
      });

      if (editing) {
        setState({
          cardDescription: article.card_description,
          title: article.name,
          article: article.content,
          videoId: article.video_id,
          thumbnailUrl: article.thumbnailUrl,
          sub_categories: JSON.parse(article.categories),
          key_words: JSON.parse(article.key_word_ids),
          checkbox: article.publication_date ? true : false,
        });
        handleDateChange(article.publication_date || new Date());
      }
    } catch (e) {
      console.log(e);
    }
  };

  const toggleSubCategory = id => {
    const {sub_categories} = getState();

    if (sub_categories.includes(id)) {
      setState({
        sub_categories: sub_categories.filter(catId => catId !== id),
      });
    } else {
      setState({
        sub_categories: sub_categories.concat(id),
      });
    }
  };

  const toggleKeyWords = id => {
    const {key_words} = getState();

    if (key_words.includes(id)) {
      setState({
        key_words: key_words.filter(catId => catId !== id),
      });
    } else {
      setState({
        key_words: key_words.concat(id),
      });
    }
  };

  const uploadVideo = () => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          setVideo({
            uploadProgress: (event.loaded / event.total) * 100,
          });
        }
      });
      const formData = new FormData();
      formData.append('filename', videoFile.name);
      formData.append('content', videoFile);
      req.open(
        'POST',
        `${process.env.REACT_APP_API_URL}${
          article.videoId
            ? `/articles/edit-video/${article.id}`
            : '/videos/upload'
        }`,
      );
      req.setRequestHeader(
        'Authorization',
        `Bearer ${localStorage.getItem('token') || ''}`,
      );
      req.onreadystatechange = () => {
        if (req.readyState == XMLHttpRequest.DONE) {
          setState({videoId: req.response});

          resolve(200);
        }
      };
      if (videoFile) {
        req.send(formData);
      } else {
        enqueueSnackbar('Por favor, Insira um vídeo!');
      }
    });
  };

  const editThumbnailUpload = () => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      const formData = new FormData();

      formData.append('thumbnail', thumbnailFile);
      req.open(
        'POST',
        `${process.env.REACT_APP_API_URL}/videos/edit-thumbnail/${article.id}`,
      );
      req.setRequestHeader(
        'Authorization',
        `Bearer ${localStorage.getItem('token') || ''}`,
      );
      req.onreadystatechange = () => {
        if (req.readyState == XMLHttpRequest.DONE) {
          // setState({ filename: thumbnailFile.name })
          resolve(200);
        }
      };
      if (thumbnailFile) {
        req.send(formData);
      } else {
        alert('Por favor, Insira uma imagem de capa.');
      }
    });
  };

  const onUpdate = async () => {
    if (thumbnailFile) {
      editThumbnailUpload();
    }
    if (videoFile) {
      uploadVideo();
    }

    const form = {
      name: getState().title,
      content: getState().article,
      thumbnailUrl: getState().thumbnailUrl,
      authorId: getState().authorId,
      card_description: getState().cardDescription,
      video_id: getState().videoId,
      premium: getState().premium,
      publication_date: checkbox === true ? selectedDate || new Date() : null,
      categories: JSON.stringify(getState().sub_categories),
      key_word_ids: JSON.stringify(getState().key_words),
    };

    try {
      await api.put(`/articles/${article.id}`, form).then(res => {
        if (res.status === 200) {
          history.push('/courses');
        }
      });
      enqueueSnackbar('Curso Alterada Com Sucesso!');
    } catch (e) {
      alert(e);
    }
  };

  const onPosting = async () => {
    const form = {
      name: getState().title,
      content: getState().article,
      thumbnailUrl: thumbnailFile,
      premium: getState().premium,
      authorId: getState().authorId.toString(),
      card_description: getState().cardDescription,
      //categoria principal Matérias
      categories: JSON.stringify(getState().sub_categories),
      key_word_ids: JSON.stringify(getState().key_words),
      publication_date: checkbox === true ? selectedDate || new Date() : null,
      video_id: getState().videoId,
      course: true,
    };

    const formData = new FormData();
    formData.append('name', form.name);
    formData.append('content', form.content);
    formData.append('thumbnailUrl', form.thumbnailUrl);
    formData.append('premium', form.premium);
    formData.append('authorId', form.authorId);
    formData.append('card_description', form.card_description);
    formData.append('categories', form.categories);
    formData.append('key_word_ids', form.key_word_ids);
    formData.append('publication_date', form.publication_date);
    formData.append('video_id', form.video_id);
    formData.append('course', form.course);

    if (thumbnailFile) {
      try {
        await api
          .post(`/articles`, formData)
          .then(() => {
            enqueueSnackbar('Curso Cadastrado Com Sucesso!');
          })
          .then(() => history.push('/courses'));
      } catch (e) {
        alert(e);
      }
    } else {
      enqueueSnackbar('Por favor, insira uma imagem de capa.');
    }
  };

  const onSubmit = async () => {
    if (getState().title) {
      if (getState().authorId) {
        if (videoFile) {
          await uploadVideo().then(async res => {
            if (res === 200) {
              onPosting();
            }
          });
        } else {
          onPosting();
        }
      } else {
        enqueueSnackbar('Por favor insira um autor!');
      }
    } else {
      enqueueSnackbar('Por favor insira um título!');
    }
  };

  const fetchParter = value => {
    const name = value;
    if (value.length > 0) {
      return partners.map((partner, i) => {
        return (
          partner.artisticName?.toLowerCase().includes(name) && (
            <span onClick={handleClose}>
              <ListItem
                button
                onClick={() =>
                  setState({
                    authorId: partner.id,
                    authorName: partner.artisticName,
                  })
                }
                key={i}>
                <ListItemAvatar>
                  <Avatar>{partner.artisticName[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={partner.artisticName} />
              </ListItem>
            </span>
          )
        );
      });
    } else {
      return false;
    }
  };
  // alert(editing)

  return (
    <Grid xs={12} md={12} lg={12}>
      <div className="flex-row center-b">
        <ReturnButton />
        <div className="flex-row " style={{width: '100%'}}>
          <Typography component="h1" variant="h5">
            {!editing ? 'Adicionar Curso' : 'Editar Curso'}
          </Typography>
        </div>
        <div style={{width: '100%'}} className="flex-row flex-end">
          <Grid xs={6} justify="flex-end">
            <Grid item>
              <FormControlLabel
                control={<Checkbox checked={!getState().premium} />}
                onChange={e => setState({premium: !getState().premium})}
                label="Conteúdo Free"
              />
              <FormControlLabel
                label="Ativar Data de Publicação"
                onChange={e => setState({checkbox: !getState().checkbox})}
                control={
                  <Checkbox
                    checked={checkbox}
                    value="primary"
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                }
              />
            </Grid>
            <Grid item>
              {checkbox && (
                <PublicDatePicker
                  selectedDate={selectedDate}
                  handleDateChange={handleDateChange}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <form
        action={'javascript:void(0)'}
        onSubmit={!editing ? onSubmit : onUpdate}>
        <InputText
          label="Título"
          value={getState().title}
          onChange={e =>
            setState({
              title: e.target.value,
            })
          }
        />
        <Grid xs={12}>
          <InputText
            label="Autor"
            value={getState().authorName}
            onChange={e =>
              setState({
                authorName: e.target.value,
              })
            }
            leftIcon={
              <IconButton onClick={() => setOpen('partner')} size="large">
                <SearchIcon fontSize="inherit" />
              </IconButton>
            }
          />
        </Grid>

        <Grid container className={classes.section} xs={12}>
          <UploadContent
            editing={editing}
            getVideoURL={`https://api.meditabemestar.com.br/videos/${
              getState().videoId
            }`}
            getThumbnailURL={article.thumbnailUrl}
          />
        </Grid>
        <InputText
          id="description"
          label="Descrição do Card"
          multiline
          rows="4"
          value={getState().cardDescription}
          onChange={e => setState({cardDescription: e.target.value})}
        />

        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open === 'partner' ? true : false}>
          <DialogTitle id="simple-dialog-title">Escolha o Autor</DialogTitle>
          <List>
            {fetchParter(getState().authorName.toLowerCase(), partners)}
          </List>
        </Dialog>

        <div className={classes.section}>
          <CKEditor
            editor={ClassicEditor}
            data={getState().article}
            onInit={editor => {
              // You can store the "editor" and use when it is needed.
              console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setState({article: data});
            }}
            onBlur={(event, editor) => {
              console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              console.log('Focus.', editor);
            }}
          />
        </div>
        <Grid style={{marginTop: 40}} xs={12}>
          <Grid item>
            <Typography className={classes.section} component="h1" variant="h5">
              Promoções
            </Typography>
          </Grid>
        </Grid>
        <div
          className="flex-col"
          style={{
            // overflowY: 'auto',
            overflowX: 'hidden',
            padding: '1em',
          }}>
          {promotion.map(promo => {
            return (
              <div>
                <FormControlLabel
                  key={`sub-${promo.id}`}
                  control={
                    <Checkbox
                      checked={getState().sub_categories.includes(promo.id)}
                    />
                  }
                  onChange={() => toggleSubCategory(promo.id)}
                  label={promo.name}
                />
              </div>
            );
          })}
        </div>
        <Grid container justify="space-between" style={{marginTop: 40}} xs={12}>
          <Typography className={classes.section} component="h1" variant="h5">
            Categorias
          </Typography>
        </Grid>
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open === 'keyword' ? true : false}>
          <DialogTitle id="simple-dialog-title">
            <Grid xs={12} container alignItems="center" justify="space-between">
              <Grid item>Escolha as palavras chave</Grid>
              <Grid item>
                <InputText
                  variant="standard"
                  label="Palavra Chave"
                  value={inputSearch}
                  onChange={e => setInputSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="inherit" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <List
            style={{
              width: 600,
              // maxWidth: 600,
              paddingLeft: 8,
              top: 0,

              position: 'relative',
              overflow: 'auto',
              height: 600,
            }}>
            <Grid xs={12} container>
              {categories.map(category => {
                return (
                  <Grid xs={12} key={`c-${category.id}`} item>
                    <ListSubheader
                      style={{
                        backgroundColor: '#fff',
                        top: '-14px',
                      }}>
                      <Grid
                        xs={12}
                        container
                        alignItems="center"
                        justify="space-between">
                        <Grid item>{`Categoria: ${category.name}`}</Grid>
                      </Grid>
                    </ListSubheader>

                    {category.keywords &&
                      category.keywords.map(key_word => {
                        if (
                          key_word.description.includes(
                            inputSearch.toLowerCase(),
                          )
                        ) {
                          return (
                            <ListItem key={`sub-${key_word.id}`}>
                              <FormControlLabel
                                style={{
                                  textTransform: 'capitalize',
                                }}
                                key={`sub-${key_word.id}`}
                                control={
                                  <Checkbox
                                    checked={getState().key_words.includes(
                                      key_word.id,
                                    )}
                                  />
                                }
                                onChange={() => toggleKeyWords(key_word.id)}
                                label={key_word.description}
                              />
                              <Divider />
                            </ListItem>
                          );
                        } else {
                          return '';
                          // <ListItem
                          // 	key={`sub-${key_word.id}`}>
                          // 	<FormControlLabel
                          // 		style={{
                          // 			textTransform:
                          // 				"capitalize"
                          // 		}}
                          // 		key={`sub-${key_word.id}`}
                          // 		control={
                          // 			<Checkbox
                          // 				checked={getState().key_words.includes(
                          // 					key_word.id
                          // 				)}
                          // 			/>
                          // 		}
                          // 		onChange={() =>
                          // 			toggleKeyWords(
                          // 				key_word.id
                          // 			)
                          // 		}
                          // 		label={
                          // 			key_word.name
                          // 		}
                          // 	/>
                          // 	<Divider />
                          // </ListItem>
                        }
                      })}
                  </Grid>
                );
              })}
            </Grid>
          </List>
        </Dialog>
        <div
          className="flex-col"
          style={{
            // overflowY: 'auto',
            overflowX: 'hidden',
            padding: '1em',
          }}>
          {categories.map(category => {
            const sub_categories = JSON.parse(category.sub_category);

            return (
              <div
                style={{
                  textTransform: 'capitalize',
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <FormControlLabel
                    key={`c-${category.id}`}
                    control={<Checkbox checked={true} />}
                    label={category.name}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <div className="flex-col">
                    {sub_categories &&
                      sub_categories.map(sub_category => {
                        return (
                          <div
                            style={{
                              paddingLeft: '1em',
                            }}>
                            <FormControlLabel
                              key={`sub-${sub_category.id}`}
                              control={
                                <Checkbox
                                  checked={getState().sub_categories.includes(
                                    sub_category.id,
                                  )}
                                />
                              }
                              onChange={() =>
                                toggleSubCategory(sub_category.id)
                              }
                              label={sub_category.name}
                            />
                            <Divider />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Grid container>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen('keyword')}>
            {!editing ? 'Adicionar Palavra Chave' : 'Editar Palavra Chave'}
          </Button>
        </Grid>
        <Grid alignItems="center">
          <Button
            style={{marginTop: 20}}
            type="submit"
            fullWidth
            variant="contained"
            color="primary">
            {videoUpload.uploadProgress !== 0
              ? `Carregando ${parseInt(videoUpload.uploadProgress, 10)}%`
              : 'Salvar Curso'}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default CourseForm;
