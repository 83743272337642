import React, { useContext, useEffect } from "react"
import PhoneInput from "../PhoneInput"
import { Field, Error, FormContext, update } from "../containers/FormContainer"
import InputMask from "react-text-mask"
import CurrencyInput from "react-currency-input"
// import Input from "antd/lib/input"
import useMergeState from "../../utils/useMergeState"
import { InputLabel, TextField, MenuItem, Select } from "@material-ui/core"

// import AntSelect from "antd/lib/select"
// import AntCheckbox from "antd/lib/checkbox"
// import Col from "antd/lib/col"
// import AutoCompleteSelect from "../AutoCompleteSelect"
// import DatePicker from "antd/lib/date-picker"
// import moment from "moment"
// import AppContext, { useAppContext } from "../AppContextCheckout"
// import IntlContext from "../IntlContext"
// import { primary } from "../../layout/color"

const isFunction = any => typeof any === "function"

// const faIcon = {
// 	color: primary,
// 	fontSize: "small",
// 	cursor: "pointer"
// }

const generateMask = mask =>
	mask
		.split("")
		.map(c =>
			c === "9" ? /\d/ : c === " " ? "\u2000" : c === "?" ? /\d?/ : c
		)

class WrapCurrency extends React.Component {
	v = ""

	constructor(props, context) {
		super(props, context)

		this.v = props.value / 100
	}

	componentWillReceiveProps({ value }) {
		this.v = value / 100
	}

	onChangeEvent = event => {
		let value = event.target.value
		value = (value.match(/\d/g) || []).join("") || 0
		value = parseInt(value)
		this.props.onChangeEvent(value)
	}

	render() {
		return (
			<CurrencyInput
				{...this.props}
				onChangeEvent={this.onChangeEvent}
				value={this.v}
			/>
		)
	}
}

// export const Iamge input de image

export const Text = ({
	name,
	options,
	icon,
	label,
	type,
	onDisabled,
	defaultValue,
	placeholder,
	placeholderChar,
	max_length,
	onChange = () => void 0,
	mask,
	...props
}) => {
	mask = type === "date" ? "99/99/9999" : mask

	const { getState, dispatch } = useContext(FormContext)

	if (isFunction(mask)) {
		mask = mask(getState().form)
	}

	const updateCurrentContext = (key, value) => {
		dispatch(update(key, value))
	}

	return (
		<Field name={name} options={options} defaultValue={defaultValue}>
			{({ value, update }) =>
				mask ? (
					<InputMask
						autoComplete="new-password"
						disabled={onDisabled}
						mask={generateMask(mask)}
						className="ant-input"
						value={value || ""}
						placeholder={placeholder}
						placeholderChar={placeholderChar || "_"}
						onChange={event => {
							update(event.target.value)
							onChange(event.target.value, updateCurrentContext)
						}}
						maxLength={max_length || 40}
						{...props}
					/>
				) : type === "currency" ? (
					<WrapCurrency
						className="ant-input"
						value={value}
						onChangeEvent={value => update(value)}
					/>
				) : (
					<TextField
					{...props}
						defaultValue={defaultValue}
						disabled={onDisabled}
						variant="outlined"
						margin="normal"
						fullWidth
						label={label}
						name={name}
						value={value || ""}
						onChange={event => update(event.target.value)}
						maxLength={max_length || 40}
					/>
				)
			}
		</Field>
	)
}

export const Phone = ({ name, options, defaultValue, onDisabled }) => (
	<Field name={name} options={options} defaultValue={defaultValue}>
		{({ value, update }) => (
			<>
				{onDisabled ? (
					<input disabled={onDisabled} value={value || ""} />
				) : (
					<PhoneInput
						masks={{ br: "+.. (..) . ....-...." }}
						value={value}
						onChange={value => update(value)}
					/>
				)}
			</>
		)}
	</Field>
)

// export const Switch = ({
// 	name,
// 	options,
// 	defaultValue = false,
// 	label,
// 	onDisabled,
// 	...props
// }) => (
// 	<Field name={name} options={options} defaultValue={defaultValue}>
// 		{({ value, update }) => (
// 			<AntCheckbox
// 				disabled={onDisabled}
// 				style={{ margin: "6px 16px 6px 0" }}
// 				checked={value}
// 				onChange={event => update(event.target.checked)}
// 				{...props}
// 			/>
// 		)}
// 	</Field>
// )

export const SelectInput = ({
	name,
	options: { values, ...options } = {},
	label,
	children,
	defaultValue,
	onDisabled,
	onSelect = () => void 0,
	placeholder,
	...props
}) => {
	const [
		{ shouldFetchValues, selectValues, loading },
		setState
	] = useMergeState({
		shouldFetchValues: isFunction(values),
		selectValues: [],
		loading: isFunction(values)
	})

	placeholder = placeholder || " Selecione"

	const { getState, dispatch } = useContext(FormContext)

	useEffect(() => {
		if (values) {
			if (shouldFetchValues) {
				;(async () => {
					const selectValues = await values()
					console.log({ address_form: getState().form })
					console.log(
						`select - [${name}]`,
						"got select values. value as of now:",
						getState().form[name]
					)
					if (!getState().form[name]) {
						dispatch(
							update(name, (selectValues[0] || {}).value || "")
						)
						onSelect(selectValues[0] || { props: {} })
					}
					setState({
						loading: false,
						selectValues,
						shouldFetchValues: false
					})
				})()
			}
		}
	}, [])

	return (
		<Field name={name} options={options} defaultValue={defaultValue}>
			{({ value, update }) => (
				<>
					<InputLabel>{label}</InputLabel>
					<Select
						fullWidth
						value={value || defaultValue || ""}
						onChange={nextValue => {
							onSelect(
								selectValues.find(
									({ value }) => nextValue === value
								) || nextValue
							)
							update(nextValue)
						}}
						{...props}>
						{/* {placeholder && (
							<Select.Option value="" disabled>
								{placeholder}
							</Select.Option>
						)} */}

						{children}
					</Select>
				</>
			)}
		</Field>
	)
}

// export const MultipleCheckBox = ({
// 	name,
// 	options: { optValues = [], ...options },
// 	label,
// 	children,
// 	defaultValue,
// 	id,
// 	checked,
// 	...props
// }) => {
// 	// passing array as value to formcontainer will simply break it

// 	const serialize = value => JSON.stringify(value || []) || "[]"
// 	const parse = value => {
// 		try {
// 			return JSON.parse(value) || []
// 		} catch (e) {
// 			return []
// 		}
// 	}

// 	const toggleElem = (array, elem) =>
// 		array.indexOf(elem) === -1
// 			? array.concat(elem)
// 			: array.filter(e => e !== elem)

// 	return (
// 		<Field
// 			name={name}
// 			options={{ serialize, parse, ...options }}
// 			defaultValue={defaultValue}>
// 			{({ value = [], update }) => (
// 				<div>
// 					{optValues.map(({ label, value: option }, i) => (
// 						<Col key={`opt-${i}`}>
// 							<AntCheckbox
// 								checked={value.indexOf(option) !== -1}
// 								onChange={() =>
// 									update(toggleElem(value || [], option))
// 								}
// 								{...props}
// 							/>
// 							<label
// 								style={{
// 									marginLeft: "16px",
// 									marginTop: "10px"
// 								}}
// 								htmlFor={id}>
// 								{label}
// 							</label>
// 						</Col>
// 					))}
// 				</div>
// 			)}
// 		</Field>
// 	)
// }

export const TextList = ({ name, options, children }) => {
	const serialize = value => JSON.stringify(value || []) || "[]"
	const parse = value => {
		try {
			return JSON.parse(value) || []
		} catch (e) {
			return []
		}
	}
	const inputs = (emails, update) =>
		emails.map((text, i) => ({
			index: i,
			value: text,
			update: value =>
				update(emails.map((_, j) => (i !== j ? _ : value))),
			remove: () => update(emails.filter((_, j) => i !== j))
		}))
	return (
		<Field name={name} options={{ serialize, parse, ...options }}>
			{({ value, update }) =>
				children({
					add: () => update(value.concat("")),
					inputs: inputs(value, update)
				})
			}
		</Field>
	)
}

export const InputError = ({ name, children, ...props }) => (
	<Error name={name} {...props}>
		<span className="error-msg" style={{ color: "#F00" }}>
			{children}
		</span>
	</Error>
)

// export const AutoComplete = ({
// 	name,
// 	options: { fetch, renderSuggestion, ...options }
// }) => (
// 	<Field name={name} options={options}>
// 		{({ value, update }) => (
// 			<AutoCompleteSelect
// 				value={value}
// 				fetchInitialSuggestions
// 				fetchFunction={fetch}
// 				renderSuggestion={renderSuggestion}
// 				onChange={nextValue => update(nextValue)}
// 			/>
// 		)}
// 	</Field>
// )

// export const DateInput = ({ name, placeholder, options = {}, ...props }) => (
// 	<Field name={name} options={options}>
// 		{({ value, update }) => (
// 			<DatePicker
// 				placeholder={placeholder}
// 				format="DD/MM/YYYY"
// 				value={value && moment(value)}
// 				onChange={date => update(date && date.format("YYYY-MM-DD"))}
// 				{...props}
// 			/>
// 		)}
// 	</Field>
// )

SelectInput.Option = MenuItem
