import React from 'react'
import { Animated } from 'react-web-animation'

const animate = {
	fadeIn(iterations = 1) {
		const keyframes = [ { opacity: '0', offset: 0 }, { opacity: '1', offset: 1 } ]
		const timing = { duration: 400, iterations }
		return { keyframes, timing }
	}
}

const AnimatedView = ({ children }) => {
	return (
		<Animated.div
			keyframes={animate.fadeIn().keyframes}
			timing={animate.fadeIn().timing}
			style={{
				paddingLeft: '1em',
				paddingTop: 20,
				width: '100%'
			}}
		>
			{children}
		</Animated.div>
	)
}

export default AnimatedView
