import React, {useState, useEffect} from 'react';
import {
  Grid,
  Avatar,
  Typography,
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Popover,
} from '@material-ui/core';
import {AccountBalance} from '@material-ui/icons';

import {Link} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/MoreVert';
import api from '../core/api';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {useSnackbar} from 'notistack';
import Confirm from './Confirm';
import {useAppContext} from './singletons/AppContext';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const SpecialItem = ({user}) => {
  const special = {...user};
  const {enqueueSnackbar} = useSnackbar();
  const {onReloadData} = useAppContext();

  const onDeleteSpecial = async () => {
    const res = await Confirm(
      'Deseja realmente remover esta Categoria Special?',
    );
    if (res)
      try {
        await api.delete(`/specials/${special.id}`);
        onReloadData();
        enqueueSnackbar('Deletado com Sucesso!');
      } catch (e) {
        console.log(e);
      }
  };

  const initialLetter = special.nome || '';

  return (
    <React.Fragment>
      <ListItem alignItems="center">
        <ListItemAvatar>
          <Avatar
            src={special.thumbnailURL}
            alt="Remy Sharp"
            style={{textTransform: 'uppercase'}}>
            {special && initialLetter[0]}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={special.nome}
          secondary={
            <Grid container>
              <Grid item xs={5}>
                <ListItemIcon>
                  <Typography
                    variant="body2"
                    className="flex-row center-b"
                    color="textPrimary">
                    {special.descricao}
                  </Typography>
                </ListItemIcon>
              </Grid>
            </Grid>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            component={Link}
            to={`/specials/edit/${special.id}`}
            edge="end">
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDeleteSpecial} edge="end">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
export default SpecialItem;
