import api from '../core/api';

const findAll = async () => {
  const result = await api.get('/partners');
  const partners = result.data;
  // console.log(partners);
  const sortedPartners = partners.sort((a, b) => {
    if (a.artisticName > b.artisticName) {
      return 1;
    }
    if (a.artisticName < b.artisticName) {
      return -1;
    }
    return 0;
  });
  return sortedPartners;
};

export default {findAll};
