import React, {useEffect, useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import api from '../../core/api';
import CampaignForm from './components/CampaignForm';
import moment from 'moment';

const EditCampaigns = ({history, match}) => {
  const [campaigns, setCampaign] = useState({});

  const fetchCampaign = async () => {
    try {
      const {data: campaigns} = await api.get(
        `/campaign/${match.params.campaignId}`,
      );
      const bodyFormat = campaigns.body ? JSON.parse(campaigns.body) : {};
      setCampaign({
        ...campaigns,
        body: bodyFormat.body || '',
        button: bodyFormat.button || '',
        link: bodyFormat.link || '',
        startDate: moment.utc(campaigns.startDate).format('DD/MM/YYYY'),
        endDate: campaigns.endDate
          ? moment.utc(campaigns.endDate).format('DD/MM/YYYY')
          : campaigns.endDate,
      });
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    fetchCampaign();
  }, []);

  return (
    <Dashboard>
      {campaigns.id && <CampaignForm history={history} campaign={campaigns} />}
    </Dashboard>
  );
};

export default EditCampaigns;
