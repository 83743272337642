import React from 'react'

const UploadContext = React.createContext({
	src: {},
	setUploadState() {}
})

export const useUploadContext = () => React.useContext(UploadContext)

export default UploadContext
