import React, {useState} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link} from 'react-router-dom';
import List from '@material-ui/core/List';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core/styles';
import Confirm from '../components/Confirm';
import {Button} from '@material-ui/core';

const NestedItem = ({label, icon, items, keynext, route, ...props}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const onLogout = async () => {
    const res = await Confirm(
      'Tem certeza que deseja sair do Painel de Controle?',
    );
    if (res) {
      try {
        localStorage.setItem('token', '');
        localStorage.setItem('empresa_id', '');
        localStorage.setItem('adminGestor', '');
        window.location.pathname = '/login';
      } catch (e) {
        console.log({e});
      }
    }
  };

  const useStyles = makeStyles(() => ({
    nested: {
      paddingLeft: open ? 40 : 20,
    },
    listIcon: {
      paddingLeft: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    icon: {color: '#61b7c3'},
  }));

  const classes = useStyles();

  return (
    <div>
      {!items ? (
        <>
          {' '}
          {route.includes('/login') ? (
            <ListItem
              button
              key={keynext}
              component={Link}
              style={{
                paddingLeft: 30,
              }}
              // to={route}
              onClick={onLogout}>
              <Tooltip title={label} placement={open ? 'top' : 'right'}>
                <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
              </Tooltip>
              <ListItemText primary={label} />
            </ListItem>
          ) : (
            <ListItem
              button
              key={keynext}
              component={Link}
              style={{
                paddingLeft: 30,
              }}
              to={route}>
              <Tooltip title={label} placement={open ? 'top' : 'right'}>
                <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
              </Tooltip>
              <ListItemText primary={label} />
            </ListItem>
          )}
        </>
      ) : (
        <div>
          <ListItem
            button
            className={classes.listIcon}
            key={keynext}
            onClick={handleClick}>
            {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            <Tooltip title={label} placement={open ? 'top' : 'right'}>
              <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            </Tooltip>
            <ListItemText primary={label} />
            {/* <Button
              type="button"
              style={{
                display: 'block',
                position: 'absolute',
                top: '-100%',
                right: 0,
                margin: 'auto',
                width: '1px',
                height: '1px',
                border: '1px solid red',
              }}
            /> */}
          </ListItem>
          <Collapse in={open ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {items &&
                items.map((item, i) => {
                  return (
                    <ListItem
                      className={classes.nested}
                      button
                      key={i}
                      component={Link}
                      to={item.route}>
                      <Tooltip
                        className={classes.tooltip}
                        title={item.label}
                        placement={open ? 'top' : 'right'}>
                        <ListItemIcon className={classes.icon}>
                          {item.icon}
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={item.label} />
                    </ListItem>
                  );
                })}
            </List>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default NestedItem;
