import React, { useState, useEffect } from 'react'
import Dashboard from '../../layout/Dashboard'
import PartnerContainer from '../../components/containers/PartnerContainer'
import PartnerForm from '../../components/forms/PartnerForm'
import api from '../../core/api'
import useReduxState from '../../core/useReduxState'
import FileContext from '../../components/singletons/FileContext'

const e = (f) => f()

const EditPartner = ({ history, match }) => {
	const [getState, setState] = useState({
		partner: null,
		loading: true
	})
	const [getFileState, setFileState] = useReduxState({
		src: null,
		crop: { unit: '%', width: 100, aspect: 3 / 3 },
		imageRef: null,
		croppedImageUrl: '',
		blobFile: ''
	})

	useEffect(() => {
		api
			.get(`/partners/${match.params.partnerId}`)
			.then(({ data: partner }) => {
				console.log(partner);
				const parsedPartner = {
					...partner,
					social_midia: partner.social_midia ? JSON.parse(partner.social_midia) : null,
					address: partner.address ? partner.address : null,
					document: partner.document ? JSON.parse(partner.document) : null,
				}
				setState({ partner: parsedPartner, loading: false })
			})
	}, [])

	return (
		<FileContext.Provider value={{ ...getFileState(), setFileState }}>
			<Dashboard>
				{e(() => {
					if (getState.loading) {
						return 'Carregando...'
					}
					return <PartnerForm history={history} partner={getState.partner} />
				})}
			</Dashboard>
		</FileContext.Provider>
	)
}

export default EditPartner
