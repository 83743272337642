import React from 'react'
import { List, Grid } from '@material-ui/core'

import Dashboard from '../../../layout/Dashboard'
import KeywordsContainer from '../../../components/containers/KeywordsContainer'
import KeywordItem from '../../../components/KeywordItem'
import { useAppContext } from '../../../components/singletons/AppContext'

export default function KeywordsPage({ history }) {
	const { search } = useAppContext()
	return (
		<Dashboard>
			<KeywordsContainer>
				{({ keywords, categories }) => {
					return keywords ? (
						<Grid item xs={12}>
							<List subheader={<li />}>
								{keywords.map((keyword) => {

									const keywordCategories = categories.filter(category => 
										keyword.categories.includes(category.id) 
									).map(category => category.name).join(', ');

									const filteredKeywords = search.length === 0 ? keyword : 
										(keyword.description.includes(search) || 
										 keywordCategories.includes(search)) ? keyword : null
										keywords.filter((item) =>
											item.description.toLowerCase().includes(search)
										)

									
									return filteredKeywords && (
										<Grid key={`section-${filteredKeywords.id}`}>
											<KeywordItem
												categories={keywordCategories}
												keyword={filteredKeywords}
											/>
										</Grid>
									)
								})}
							</List>
						</Grid>
					) : (
						<h1>Você precisa Adicionar uma palavra-chave</h1>
					)
				}}
			</KeywordsContainer>
		</Dashboard>
	)
}
