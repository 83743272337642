/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  DialogTitle,
  List,
  Grid,
  FormControlLabel,
  Checkbox,
  Divider,
  ListSubheader,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AddVideoIcon from '@material-ui/icons/VideoCall';
import AddAudioIcon from '@material-ui/icons/MusicVideo';

import InputText from '../components/forms/InputText';
import SearchIcon from '@material-ui/icons/Search';
import {useUploadContext} from '../components/singletons/UploadContext';
import {toDataURL} from './toDataURL';
import AnimatedView from '../components/AnimatedView';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Axios from 'axios';

export const toggleCheckbox = (id = '', formikProps, objectKey) => {
  const array = formikProps.values[`${objectKey}`];
  if (array.includes(id)) {
    formikProps.setFieldValue(
      `${objectKey}`,
      array.filter(catId => catId !== id),
    );
  } else {
    formikProps.setFieldValue(`${objectKey}`, array.concat(id));
  }
};

export const ModalPartner = ({
  formikProps,
  partners = [],
  open = '',
  handleClose = () => void 0,
}) => {
  const search =
    (formikProps.values.author && formikProps.values.author.toLowerCase()) ||
    '';
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open === 'partner' ? true : false}>
      <DialogTitle id="simple-dialog-title">Escolha o Autor</DialogTitle>
      <List>
        <HandlePartner
          formikProps={formikProps}
          search={search}
          partners={partners}
          handleClose={handleClose}
        />
      </List>
    </Dialog>
  );
};
export const HandlePartner = ({
  formikProps,
  search = '',
  partners = [],
  handleClose = () => void 0,
}) => {
  if (search.length > 0) {
    return partners.map((partner, i) => {
      return (
        partner.artisticName?.toLowerCase().includes(search) && (
          <span onClick={handleClose}>
            <ListItem
              button
              onClick={() => {
                formikProps.errors.author = null;
                formikProps.setFieldValue('partner', {
                  id: partner.id,
                  authorName: partner.artisticName,
                  thumbnailURL: partner.thumbnail,
                });
                formikProps.setFieldValue('author', partner.artisticName);
                formikProps.setFieldValue('partnerId', partner.id);
              }}
              key={i}>
              <ListItemAvatar>
                <Avatar src={partner.thumbnail || null}>
                  {!partner.thumbnail && <p>{partner.artisticName[0]}</p>}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={partner.artisticName} />
            </ListItem>
          </span>
        )
      );
    });
  } else {
    return false;
  }
};

export const ModalEmpresa = ({
  formikProps,
  empresas = [],
  open = '',
  handleClose = () => void 0,
}) => {
  const search =
    (formikProps.values.nomeEmpresa &&
      formikProps.values.nomeEmpresa.toLowerCase()) ||
    '';

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open === 'empresa' ? true : false}>
      <DialogTitle id="simple-dialog-title">Escolha a empresa</DialogTitle>
      <List>
        <HandleEmpresa
          formikProps={formikProps}
          search={search}
          empresas={empresas}
          handleClose={handleClose}
        />
      </List>
    </Dialog>
  );
};

export const HandleEmpresa = ({
  formikProps,
  search = '',
  empresas = [],
  handleClose = () => void 0,
}) => {
  if (search.length > 0) {
    return empresas.map((empresa, i) => {
      return (
        empresa.nomeFantasia?.toLowerCase().includes(search) && (
          <span onClick={handleClose}>
            <ListItem
              button
              onClick={() => {
                // formikProps.setFieldValue('empresa', {
                //   id: empresa.id,
                //   nomeFantasia: empresa.nomeFantasia,
                //   razaoSocial: empresa.razaoSocial,
                // });
                formikProps.setFieldValue('nomeEmpresa', empresa.nomeFantasia);
                formikProps.setFieldValue('empresa_id', empresa.id);
              }}
              key={i}>
              <ListItemAvatar>
                <Avatar>{<p>{empresa.nomeFantasia[0]}</p>}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={empresa.razaoSocial}
                secondary={empresa.nomeFantasia}
              />
            </ListItem>
          </span>
        )
      );
    });
  } else {
    return empresas.map((empresa, i) => {
      return (
        <span onClick={handleClose}>
          <ListItem
            button
            onClick={() => {
              // formikProps.setFieldValue('empresa', {
              //   id: empresa.id,
              //   nomeFantasia: empresa.nomeFantasia,
              //   razaoSocial: empresa.razaoSocial,
              // });
              formikProps.setFieldValue('nomeEmpresa', empresa.nomeFantasia);
              formikProps.setFieldValue('empresa_id', empresa.id);
            }}
            key={i}>
            <ListItemAvatar>
              <Avatar>{<p>{empresa.nomeFantasia[0]}</p>}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={empresa.razaoSocial}
              secondary={empresa.nomeFantasia}
            />
          </ListItem>
        </span>
      );
    });
  }
};

export const ModalSpecial = ({
  formikProps,
  specials = [],
  open = '',
  handleClose = () => void 0,
}) => {
  const search =
    (formikProps.values.nomeSpecial &&
      formikProps.values.nomeSpecial.toLowerCase()) ||
    '';
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open === 'special' ? true : false}>
      <DialogTitle id="simple-dialog-title">Escolha a categoria</DialogTitle>
      <List>
        <HandleSpecial
          formikProps={formikProps}
          search={search}
          specials={specials}
          handleClose={handleClose}
        />
      </List>
    </Dialog>
  );
};

export const HandleSpecial = ({
  formikProps,
  search = '',
  specials = [],
  handleClose = () => void 0,
}) => {
  if (search.length > 0) {
    return specials.map((special, i) => {
      return (
        special.nome?.toLowerCase().includes(search) && (
          <span onClick={handleClose}>
            <ListItem
              button
              onClick={() => {
                // formikProps.setFieldValue('special', {
                //   id: special.id,
                //   nomeFantasia: special.nomeFantasia,
                //   razaoSocial: special.razaoSocial,
                // });
                formikProps.setFieldValue('nomeSpecial', special.nome);
                formikProps.setFieldValue('special_id', special.id);
              }}
              key={i}>
              <ListItemAvatar>
                <Avatar>{<p>{special.nome[0]}</p>}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={special.nome}
                secondary={special.descricao}
              />
            </ListItem>
          </span>
        )
      );
    });
  } else {
    return specials.map((special, i) => {
      return (
        <span onClick={handleClose}>
          <ListItem
            button
            onClick={() => {
              // formikProps.setFieldValue('special', {
              //   id: special.id,
              //   nomeFantasia: special.nomeFantasia,
              //   razaoSocial: special.razaoSocial,
              // });
              formikProps.setFieldValue('nomeSpecial', special.nome);
              formikProps.setFieldValue('special_id', special.id);
            }}
            key={i}>
            <ListItemAvatar>
              <Avatar>{<p>{special.nome[0]}</p>}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={special.nome}
              secondary={special.descricao}
            />
          </ListItem>
        </span>
      );
    });
  }
};

export const ListCategories = ({
  formikProps,
  regValues,
  categories = [],
  article,
}) => {
  const selected_sub_categories = formikProps.values.sub_category_ids;
  const selected_categories = formikProps.values.selected_categories;

  const exception = (string = '', regValues) => {
    return string.toLowerCase().match(regValues);
  };

  return (
    <Grid
      xs={12}
      container
      style={{
        overflowX: 'hidden',
        padding: '1em',
      }}>
      <Grid item xs={12}>
        {categories.map(category => {
          const renderSubCategories = JSON.parse(category.sub_category);

          if (!exception(category.name, regValues))
            return (
              <div
                style={{
                  textTransform: 'capitalize',
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <FormControlLabel
                    key={`c-${category.id}`}
                    control={
                      <Checkbox
                        checked={
                          article === true
                            ? true
                            : selected_categories.includes(category.id)
                        }
                      />
                    }
                    onChange={() => {
                      formikProps.errors.selected_categories = null;
                      toggleCheckbox(
                        category.id,
                        formikProps,
                        'selected_categories',
                      );
                    }}
                    label={category.name}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <div className="flex-col" style={{width: '100%'}}>
                    {renderSubCategories &&
                      renderSubCategories.map(sub_category => {
                        if (
                          article === true
                            ? true
                            : selected_categories.includes(category.id)
                        ) {
                          return (
                            <Grid xs={12} contianer>
                              <AnimatedView>
                                <FormControlLabel
                                  key={`sub-${sub_category.id}`}
                                  control={
                                    <Checkbox
                                      checked={selected_sub_categories.includes(
                                        sub_category.id,
                                      )}
                                    />
                                  }
                                  onChange={() =>
                                    toggleCheckbox(
                                      sub_category.id.toString(),
                                      formikProps,
                                      'sub_category_ids',
                                    )
                                  }
                                  label={sub_category.name}
                                />
                                <Divider />
                              </AnimatedView>
                            </Grid>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            );
        })}
      </Grid>
    </Grid>
  );
};

export const ListAllCategories = ({formikProps, categories = []}) => {
  const selected_categories = formikProps.values.selected_categories;

  return (
    <Grid
      xs={12}
      container
      style={{
        overflowX: 'hidden',
        padding: '1em',
      }}>
      <Grid item xs={12}>
        {categories.map(category => {
          return (
            <div
              style={{
                textTransform: 'capitalize',
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <FormControlLabel
                  key={`c-${category.id}`}
                  control={
                    <Checkbox
                      checked={selected_categories.includes(category.id)}
                    />
                  }
                  onChange={() => {
                    formikProps.errors.selected_categories = null;
                    toggleCheckbox(
                      category.id,
                      formikProps,
                      'selected_categories',
                    );
                  }}
                  label={category.name}
                />
              </div>
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};

export const ListObjectives = ({formikProps, regValues, objectives = []}) => {
  return (
    <Grid
      xs={12}
      container
      style={{
        overflowX: 'hidden',
        padding: '1em',
      }}>
      <Grid item xs={12}>
        {objectives.map(objective => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <FormControlLabel
                key={objective.id}
                control={
                  <Checkbox
                    checked={formikProps.values.objectives.includes(
                      objective.id,
                    )}
                  />
                }
                onChange={() => {
                  formikProps.errors.objectives = null;
                  toggleCheckbox(objective.id, formikProps, 'objectives');
                }}
                label={objective.name}
              />
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};

export const ListKeywords = ({
  formikProps,
  regValues,
  categories = [],
  open = '',
  handleClose = () => void 0,
}) => {
  const [inputSearch, setInputSearch] = useState('');

  const selected_key_word_ids = formikProps.values.key_word_ids;
  const selected_categories = formikProps.values.selected_categories;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open === 'keyword' ? true : false}>
      <DialogTitle id="simple-dialog-title">
        <Grid xs={12} container alignItems="center" justify="space-between">
          <Grid item style={{paddingTop: 40}}>
            Escolha as palavras chave
          </Grid>
          <Grid item>
            <InputText
              variant="standard"
              label="Palavra Chave"
              value={inputSearch}
              onChange={e => setInputSearch(e.target.value)}
              leftIcon={<SearchIcon fontSize="inherit" />}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <List
        style={{
          width: 600,

          paddingLeft: 8,
          top: 0,

          position: 'relative',
          overflow: 'auto',
          height: 600,
        }}>
        <Grid xs={12} container>
          {categories.map(category => {
            const keywords = category.keywords;
            //JSON.parse(category.key_words)

            if (selected_categories.includes(category.id))
              return (
                <Grid xs={12} key={`c-${category.id}`} item>
                  <ListSubheader
                    style={{
                      backgroundColor: '#fff',
                      top: '-14px',
                    }}>
                    <Grid
                      xs={12}
                      container
                      alignItems="center"
                      justify="space-between">
                      <Grid item>{`Categoria: ${category.name}`}</Grid>
                    </Grid>
                  </ListSubheader>

                  {keywords &&
                    keywords.map(key_word => {
                      if (selected_categories.includes(category.id)) {
                        if (
                          key_word.description
                            .toLowerCase()
                            .includes(inputSearch.toLowerCase())
                        ) {
                          return (
                            <ListItem key={`sub-${key_word.id}`}>
                              <FormControlLabel
                                style={{
                                  textTransform: 'capitalize',
                                }}
                                key={`sub-${key_word.id}`}
                                control={
                                  <Checkbox
                                    checked={selected_key_word_ids.includes(
                                      key_word.id,
                                    )}
                                  />
                                }
                                onChange={() =>
                                  toggleCheckbox(
                                    key_word.id,
                                    formikProps,
                                    'key_word_ids',
                                  )
                                }
                                label={key_word.description}
                              />
                              <Divider />
                            </ListItem>
                          );
                        } else {
                          return '';
                        }
                      }
                    })}
                </Grid>
              );
          })}
        </Grid>
      </List>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  avatar: {
    paddingTop: 0,
    height: '40vh',
    width: '60vw',
    borderRadius: '20px',
    backgroundColor: '#61b7c3',
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  contentMidia: {
    height: 200,
    width: 200,
    backgroundColor: '#61b7c3',
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  iconButton: {
    borderRadius: 0,
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  imageInput: {
    border: '3px dashed #000000a1',
    borderRadius: '0.5em',
    padding: '10px',
  },
  label: {
    padding: '15px 0',
  },
}));

export const ChangeMidiaUpload = ({type, formikProps, handleClickOpen}) => {
  const classes = useStyles();
  switch (type) {
    case 'Áudio':
      return (
        <AnimatedView>
          <div
            className="flex-col center-a "
            style={{
              opacity: type.includes('Áudio') ? 1 : 0.5,
            }}>
            <IconButton
              className={classes.iconButton}
              disabled={!type.includes('Áudio')}
              onClick={() => handleClickOpen('audio')}>
              <Avatar
                className={classes.contentMidia}
                style={{
                  backgroundColor:
                    type.includes('audio') &&
                    formikProps.values.filename.length > 0 &&
                    '#008000ba',
                }}>
                <AddAudioIcon
                  style={{
                    fontSize: 60,
                  }}
                  // fontSize="large"
                />
              </Avatar>
            </IconButton>
            <div className="flex-row center-a">
              {type.includes('Áudio') &&
              formikProps.values.filename.length > 0 ? (
                <p>{formikProps.values.filename}</p>
              ) : (
                <p>Selecione uma áudio para este conteúdo.</p>
              )}
            </div>
          </div>
        </AnimatedView>
      );
      break;
    case 'Vídeo':
      return (
        <AnimatedView>
          <div
            className="flex-col center-a "
            style={{
              opacity: type.includes('Vídeo') ? 1 : 0.5,
            }}>
            <IconButton
              className={classes.iconButton}
              disabled={!type.includes('Vídeo')}
              onClick={() => handleClickOpen('video')}>
              <Avatar
                className={classes.contentMidia}
                style={{
                  backgroundColor:
                    type.includes('audio') &&
                    formikProps.values.filename.length > 0 &&
                    '#008000ba',
                }}>
                <AddVideoIcon
                  style={{
                    fontSize: 60,
                  }}
                  // fontSize="large"
                />
              </Avatar>
            </IconButton>
            <div className="flex-row center-a">
              {type.includes('Vídeo') &&
              formikProps.values.filename.length > 0 ? (
                <p>{formikProps.values.filename}</p>
              ) : (
                <p>Selecione uma vídeo para este conteúdo.</p>
              )}
            </div>
          </div>
        </AnimatedView>
      );
      break;
    case 'Texto':
      return (
        <AnimatedView>
          <CKEditor
            editor={ClassicEditor}
            data={formikProps.values.content}
            onInit={editor => {
              // You can store the "editor" and use when it is needed.
              console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              formikProps.setFieldValue('content', data);
            }}
            onBlur={(event, editor) => {
              console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              console.log('Focus.', editor);
            }}
          />
        </AnimatedView>
      );
      break;

    default:
      return '';
      break;
  }
};

export const postMidiaUpload = (videoFile, setUploadState) => {
  try {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          setUploadState({
            uploadProgress: (event.loaded / event.total) * 100,
          });
        }
      });
      const formData = new FormData();

      formData.append('filename', videoFile.name);
      formData.append('content', videoFile);
      req.open('POST', `${process.env.REACT_APP_API_URL}/videos/upload`);
      req.setRequestHeader(
        'Authorization',
        `Bearer ${localStorage.getItem('token') || ''}`,
      );
      req.onreadystatechange = () => {
        if (req.readyState == XMLHttpRequest.DONE) {
          resolve(req.response);
        }
      };
      if (videoFile) {
        req.send(formData);
      } else {
        alert('Por favor, Insira um vídeo!');
      }
    });
  } catch (e) {
    console.log({e});
  }
};

export const putVideoMidiaUpload = (
  videoFile,
  id,
  setUploadState,
  articleRoute,
) => {
  try {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          setUploadState({
            uploadProgress: (event.loaded / event.total) * 100,
          });
        }
      });
      const formData = new FormData();

      formData.append('filename', videoFile.name);
      formData.append('content', videoFile);
      req.open(
        'POST',
        `${process.env.REACT_APP_API_URL}${
          articleRoute ? '/articles/edit-video/' : '/videos/edit-video/'
        }${id}`,
      );
      req.setRequestHeader(
        'Authorization',
        `Bearer ${localStorage.getItem('token') || ''}`,
      );
      req.onreadystatechange = () => {
        if (req.readyState == XMLHttpRequest.DONE) {
          resolve(req.response);
        }
      };
      if (videoFile) {
        req.send(formData);
      } else {
        alert('Por favor, Insira um vídeo!');
      }
    });
  } catch (e) {
    console.log({e});
  }
};

export const putThumbnailMidiaUpload = (thumbnailFile, id, setUploadState) => {
  try {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          setUploadState({
            uploadProgress: (event.loaded / event.total) * 100,
          });
        }
      });
      const formData = new FormData();
      formData.append('thumbnail', thumbnailFile);
      req.open(
        'POST',
        `${process.env.REACT_APP_API_URL}/videos/edit-thumbnail/${id}`,
      );
      req.setRequestHeader(
        'Authorization',
        `Bearer ${localStorage.getItem('token') || ''}`,
      );
      req.onreadystatechange = () => {
        if (req.readyState == XMLHttpRequest.DONE) {
          resolve(req.response);
        }
      };
      if (thumbnailFile) {
        req.send(formData);
      } else {
        alert('Por favor, Insira um thumbnail!');
      }
    });
  } catch (e) {
    console.log({e});
  }
};

export const putThumbnailUpload = async (thumbnailFile, route, filename) => {
  try {
    const url = `${process.env.REACT_APP_BUCKET_THUMBNAIL_URL}/${route}/${filename}`;
    const options = {
      headers: {
        'Content-Type': thumbnailFile.type ?? 'image/png',
        'x-amz-acl': 'public-read',
      },
    };

    // console.log(`URL: ${url}`);
    // console.log(thumbnailFile)
    const response = await Axios.put(url, thumbnailFile, options);
    console.log(response);
  } catch (e) {
    console.log({e});
  }
};

export const onSelectFile = (file, setFileState) => {
  if (file && file.length > 0) {
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      toDataURL(
        reader.result,
        function(dataUrl) {
          // console.log('RESULT:', dataUrl)
          setFileState({src: dataUrl});
        },
        'image/jpeg',
      ),
    );
    reader.readAsDataURL(file[0]);
  }
};

export const onUpdateFile = (file, setFileState) => {
  if (file && file.length > 0) {
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      toDataURL(
        reader.result,
        function(dataUrl) {
          // console.log('RESULT:', dataUrl)
          setFileState(dataUrl);
        },
        'image/jpeg',
      ),
    );
    reader.readAsDataURL(file[0]);
  }
};

export const onSelectFileNotEditable = (file, setFileState) => {
  if (file && file.length > 0) {
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      toDataURL(
        reader.result,
        function(dataUrl) {
          // console.log('RESULT:', dataUrl)
          setFileState({src: dataUrl});
        },
        'image/jpeg',
      ),
    );
    reader.readAsDataURL(file[0]);
  }
};

export const stringGen = len => {
  var text = '';

  var charset = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < len; i++)
    text += charset.charAt(Math.floor(Math.random() * charset.length));

  return text;
};
