import React from 'react';

const styles = {
  view: {
    height: '150px',
    width: '95%',
    backgroundColor: '#61b7c3',
    borderRadius: 15,
    border: 'solid 2px #000',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
  },
  divTitle: {
    color: '#fff',
    width: '80%',
    textAlign: 'center',
    padding: 10,
  },
  divValues: {
    color: '#fff',
    width: '60%',
    textAlign: 'center',
    marginTop: 10,
    padding: 10,
    fontSize: 25,
    fontWeight: 'bold',
  },
};

const DataCard = ({title, value}) => {
  return (
    <div style={styles.view}>
      <div style={styles.divTitle}>{title}</div>
      <div style={styles.divValues}>{value}</div>
    </div>
  );
};

export default DataCard;
