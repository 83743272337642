import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import {SnackbarProvider, useSnackbar} from 'notistack';
import useReduxState from '../../core/useReduxState';
import api from '../../core/api';
import ReturnButton from '../ReturnButton';
import {Formik} from 'formik';
import InputText from './InputText';
import ButtonSubmit from './ButtonSubmit';
import RenderInput from './RenderInput';
import SearchIcon from '@material-ui/icons/Search';
import {ModalPartner} from '../../core/formHelpers';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  label: {
    paddingTop: 30,
  },
}));
const EmpresaForm = ({
  empresa = {
    nomeFantasia: '',
    razaoSocial: '',
    cnpj: '',
    descritivo: '',
    colaborador_1: '',
    departamento_1: '',
    email_1: '',
    telefone_1: '',
    colaborador_2: '',
    departamento_2: '',
    email_2: '',
    telefone_2: '',
    colaborador_3: '',
    departamento_3: '',
    email_3: '',
    telefone_3: '',
    postal_code: '',
    street: '',
    city: '',
    state: '',
    number: '',
  },
  history,
}) => {
  const classes = useStyles();
  const editing = !!empresa.id;
  const [getUser, setUser] = useReduxState({
    loading: false,
  });

  const [open, setOpen] = useState('');
  const handleClickOpen = view => {
    setOpen(view);
  };
  const handleClose = () => {
    setOpen('');
  };

  const addressInputs = [
    {name: 'postal_code', label: 'CEP', type: 'number'},
    {name: 'street', label: 'Endereço', type: 'text'},
    {name: 'number', label: 'Número', type: 'number'},
    {name: 'state', label: 'Estado', type: 'text'},
    {name: 'city', label: 'Cidade', type: 'text'},
  ];

  const {enqueueSnackbar} = useSnackbar();

  const onSubmit = async form => {
    setUser({loading: true});

    try {
      if (editing) {
        // console.log(form);
        await api.put(`/empresas/${form.id}`, form).then(res => {
          // console.log(res);
          if (res.status === 200) {
            enqueueSnackbar('Dados alterados com sucesso!');
            history.push('/empresas');
          }
        });
      } else {
        await api.post(`/empresas`, form).then(res => {
          // console.log(res);
          if (res.status === 200) {
            enqueueSnackbar('Nova empresa cadastrada com sucesso!');
            history.push('/empresas');
          }
        });
      }
    } catch ({response}) {
      enqueueSnackbar(
        response.data.message ||
          'Ocorreu um erro ao cadastrar empresa. Tente novamente.',
      );
      setUser({loading: false});
    }
  };

  const validateForm = values => {
    const errors = {};
    let errorMessage = '';

    if (!values.nomeFantasia) {
      errors.nomeFantasia = 'Digite o nome fantasia da empresa';
      errorMessage += 'Nome Fantasia\n';
    }

    if (!values.razaoSocial) {
      errors.razaoSocial = 'Digite a razão social da empresa';
      errorMessage += 'Razão Social\n';
    }

    if (!values.cnpj) {
      errors.cnpj = 'Digite o cnpj da empresa';
      errorMessage += 'CNPJ\n';
    }

    if (!values.descritivo) {
      errors.descritivo = 'Adicione uma descrição';
      errorMessage += 'Descrição\n';
    }

    // if (!values.colaborador) {
    //   errors.colaborador = 'Adicione um colaborador';
    //   errorMessage += 'Colaborador\n';
    // }

    // if (!values.departamento) {
    //   errors.departamento = 'Adicione um departamento';
    //   errorMessage += 'Departamento\n';
    // }

    // if (!values.email) {
    //   errors.email = 'Adicione um email do colaborador';
    //   errorMessage += 'Colaborador\n';
    // }

    if (!values.postal_code) {
      errors.postal_code = 'Digite um cep';
      errorMessage += 'CEP\n';
    }

    if (!values.street) {
      errors.street = 'Digite um endereco';
      errorMessage += 'Endereço\n';
    }

    if (!values.city) {
      errors.city = 'Digite uma cidade';
      errorMessage += 'Cidade\n';
    }

    if (!values.state) {
      errors.state = 'Digite um estado';
      errorMessage += 'Estado\n';
    }

    if (Object.keys(errors).length) {
      enqueueSnackbar(
        'Formulário incompleto, campos a serem preenchidos:\n' + errorMessage,
        {
          preventDuplicate: true,
          variant: 'error',
          style: {whiteSpace: 'pre-line'},
        },
      );
    }

    return errors;
  };

  return (
    <Formik
      initialValues={empresa}
      validate={validateForm}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        onSubmit(values);
      }}>
      {formikProps => (
        <Grid container xs={12}>
          <Grid item xs={12} alignContent="center">
            <Typography component="h1" variant="h5">
              <ReturnButton />
              {editing ? 'Editar Empresa' : 'Cadastrar Nova Empresa'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            {!getUser().loading ? (
              <form onSubmit={formikProps.handleSubmit}>
                <TextField
                  label="Nome Fantasia"
                  name="nomeFantasia"
                  value={formikProps.values.nomeFantasia}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.nomeFantasia &&
                    Boolean(formikProps.errors.nomeFantasia)
                  }
                  helperText={
                    formikProps.touched.nomeFantasia &&
                    formikProps.errors.nomeFantasia
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="nomeFantasia"
                />

                <TextField
                  label="Razão Social"
                  name="razaoSocial"
                  value={formikProps.values.razaoSocial}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.razaoSocial &&
                    Boolean(formikProps.errors.razaoSocial)
                  }
                  helperText={
                    formikProps.touched.razaoSocial &&
                    formikProps.errors.razaoSocial
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="razaoSocial"
                />

                <TextField
                  label="CNPJ"
                  name="cnpj"
                  value={formikProps.values.cnpj}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.cnpj && Boolean(formikProps.errors.cnpj)
                  }
                  helperText={
                    formikProps.touched.cnpj && formikProps.errors.cnpj
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="cnpj"
                />

                <TextField
                  label="Descrição"
                  name="descritivo"
                  type="description"
                  value={formikProps.values.descritivo}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.descritivo &&
                    Boolean(formikProps.errors.descritivo)
                  }
                  helperText={
                    formikProps.touched.descritivo &&
                    formikProps.errors.descritivo
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="descritivo"
                />

                <Typography
                  className={classes.label}
                  component="h5"
                  variant="h5">
                  Colaboradores
                </Typography>

                <Typography
                  className={classes.label}
                  component="h6"
                  variant="h6">
                  Colaborador 1
                </Typography>
                <TextField
                  label="Nome do colaborador(a)"
                  name="colaborador_1"
                  type="description"
                  value={formikProps.values.colaborador_1}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.colaborador_1 &&
                    Boolean(formikProps.errors.colaborador_1)
                  }
                  helperText={
                    formikProps.touched.colaborador_1 &&
                    formikProps.errors.colaborador_1
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="colaborador_1"
                />

                <FormControl
                  variant="outlined"
                  style={{width: '100%', marginTop: '1rem'}}>
                  <InputLabel id="departamento_1">Departamento</InputLabel>
                  <Select
                    style={{width: '100%'}}
                    labelId="departamento-label"
                    id="departamento_1"
                    name="departamento_1"
                    value={formikProps.values.departamento_1}
                    onChange={e => {
                      formikProps.setFieldValue(
                        'departamento_1',
                        e.target.value,
                      );
                      formikProps.errors['departamento_1'] = null;
                    }}
                    label="Departamento">
                    <MenuItem value="rh">Recursos Humanos</MenuItem>
                    <MenuItem value="financeiro">Financeiro</MenuItem>
                    <MenuItem value="esg">ESG</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label="Telefone"
                  name="telefone_1"
                  type="description"
                  value={formikProps.values.telefone_1}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.telefone_1 &&
                    Boolean(formikProps.errors.telefone_1)
                  }
                  helperText={
                    formikProps.touched.telefone_1 &&
                    formikProps.errors.telefone_1
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="telefone_1"
                />

                <TextField
                  label="E-mail"
                  name="email_1"
                  type="description"
                  value={formikProps.values.email_1}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.email_1 &&
                    Boolean(formikProps.errors.email_1)
                  }
                  helperText={
                    formikProps.touched.email_1 && formikProps.errors.email_1
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="email_1"
                />

                <Typography
                  className={classes.label}
                  component="h6"
                  variant="h6">
                  Colaborador 2
                </Typography>
                <TextField
                  label="Nome do colaborador(a)"
                  name="colaborador_2"
                  type="description"
                  value={formikProps.values.colaborador_2}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.colaborador_2 &&
                    Boolean(formikProps.errors.colaborador_2)
                  }
                  helperText={
                    formikProps.touched.colaborador_2 &&
                    formikProps.errors.colaborador_2
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="colaborador_2"
                />

                <FormControl
                  variant="outlined"
                  style={{width: '100%', marginTop: '1rem'}}>
                  <InputLabel id="departamento_2">Departamento</InputLabel>
                  <Select
                    style={{width: '100%'}}
                    labelId="departamento-label"
                    id="departamento_2"
                    name="departamento_2"
                    value={formikProps.values.departamento_2}
                    onChange={e => {
                      formikProps.setFieldValue(
                        'departamento_2',
                        e.target.value,
                      );
                      formikProps.errors['departamento_2'] = null;
                    }}
                    label="Departamento">
                    <MenuItem value="rh">Recursos Humanos</MenuItem>
                    <MenuItem value="financeiro">Financeiro</MenuItem>
                    <MenuItem value="esg">ESG</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label="Telefone"
                  name="telefone_2"
                  type="description"
                  value={formikProps.values.telefone_2}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.telefone_2 &&
                    Boolean(formikProps.errors.telefone_2)
                  }
                  helperText={
                    formikProps.touched.telefone_2 &&
                    formikProps.errors.telefone_2
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="telefone_2"
                />

                <TextField
                  label="E-mail"
                  name="email_2"
                  type="description"
                  value={formikProps.values.email_2}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.email_2 &&
                    Boolean(formikProps.errors.email_2)
                  }
                  helperText={
                    formikProps.touched.email_2 && formikProps.errors.email_2
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="email_2"
                />

                <Typography
                  className={classes.label}
                  component="h6"
                  variant="h6">
                  Colaborador 3
                </Typography>
                <TextField
                  label="Nome do colaborador(a)"
                  name="colaborador_3"
                  type="description"
                  value={formikProps.values.colaborador_3}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.colaborador_3 &&
                    Boolean(formikProps.errors.colaborador_3)
                  }
                  helperText={
                    formikProps.touched.colaborador_3 &&
                    formikProps.errors.colaborador_3
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="colaborador_3"
                />

                <FormControl
                  variant="outlined"
                  style={{width: '100%', marginTop: '1rem'}}>
                  <InputLabel id="departamento_3">Departamento</InputLabel>
                  <Select
                    style={{width: '100%'}}
                    labelId="departamento-label"
                    id="departamento_3"
                    name="departamento_3"
                    value={formikProps.values.departamento_3}
                    onChange={e => {
                      formikProps.setFieldValue(
                        'departamento_3',
                        e.target.value,
                      );
                      formikProps.errors['departamento_3'] = null;
                    }}
                    label="Departamento">
                    <MenuItem value="rh">Recursos Humanos</MenuItem>
                    <MenuItem value="financeiro">Financeiro</MenuItem>
                    <MenuItem value="esg">ESG</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label="Telefone"
                  name="telefone_3"
                  type="description"
                  value={formikProps.values.telefone_3}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.telefone_3 &&
                    Boolean(formikProps.errors.telefone_3)
                  }
                  helperText={
                    formikProps.touched.telefone_3 &&
                    formikProps.errors.telefone_3
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="telefone_3"
                />

                <TextField
                  label="E-mail"
                  name="email_3"
                  type="description"
                  value={formikProps.values.email_3}
                  onChange={formikProps.handleChange}
                  error={
                    formikProps.touched.email_3 &&
                    Boolean(formikProps.errors.email_3)
                  }
                  helperText={
                    formikProps.touched.email_3 && formikProps.errors.email_3
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="email_3"
                />

                <Typography
                  className={classes.label}
                  component="h5"
                  variant="h5">
                  Endereço
                </Typography>
                <RenderInput inputs={addressInputs} formikProps={formikProps} />

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <ButtonSubmit
                    style={{
                      marginTop: 20,
                      width: '20vw',
                      alignSelf: 'center',
                    }}
                    type="submit"
                    variant="contained"
                    color="primary">
                    {editing ? 'Salvar' : 'Cadastrar'}
                  </ButtonSubmit>
                </div>
              </form>
            ) : (
              <div
                className="flex-col center-a center-b"
                style={{height: '50vh'}}>
                <CircularProgress size={100} />
                <p> Carregando... </p>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default EmpresaForm;
