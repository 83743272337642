import React from "react"
import Dashboard from "../../layout/Dashboard"
import BannerForm from "../../components/forms/BannerForm"
import useReduxState from "../../core/useReduxState"
import FileContext from "../../components/singletons/FileContext"

function BannerAdd({ match, history }) {
	const [getFileState, setFileState] = useReduxState({
		src: null,
		crop: { unit: "px", width: 100, aspect: 320 / 120 },
		imageRef: null,
		croppedImageUrl: "",
		blobFile: ""
	})
	return (
		<FileContext.Provider value={{ ...getFileState(), setFileState }}>
			<Dashboard>
				<BannerForm id={match.params.id} history={history} />
			</Dashboard>
		</FileContext.Provider>
	)
}

export default BannerAdd
