import React, { Component } from "react";
import ReactDOM from "react-dom";
import Button from "@material-ui/core/Button";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

require("dotenv").config();

// ReactDOM.render(<App />, document.getElementById("root"))

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()

const onUpdate = (param) => {
  const event = new Event("newContentAvailable");
  window.dispatchEvent(event);
};

class NewApp extends Component {
  render() {
    return <ReloadModal />;
  }
}

class ReloadModal extends Component {
  state = {
    show: false,
  };
  componentDidMount() {
    // Handle global event.
    window.addEventListener("newContentAvailable", () => {
      this.setState({
        show: true,
      });
    });
  }
  onClick = () => {
    // Reload when modal click.
    window.location.reload(window.location.href);
  };
  render() {
    if (!this.state.show) {
      return <App />;
    }
    // <Modal> is common fixed component.
    return (
      <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
        <img src="" />
        <h3>Uma nova versão está disponível!!!</h3>
        <h3>Por favor, atualize</h3>

        <Button variant="contained" color="primary" onClick={this.onClick}>
          Atualizar
        </Button>
      </div>
    );
  }
}

ReactDOM.render(<NewApp />, document.getElementById("root"));

serviceWorker.register({ onUpdate: onUpdate });
