import React, { useState } from "react"
import {
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	IconButton,
	ListItemSecondaryAction,
	Grid,
	Dialog,
	DialogTitle,
	DialogContentText,
	Typography,
	DialogContent
} from "@material-ui/core"
import TextFieldsIcon from "@material-ui/icons/TextFields"
import DeleteIcon from "@material-ui/icons/Delete"
import CloseIcon from "@material-ui/icons/Close"

import EditIcon from "@material-ui/icons/Edit"
// import VideoCard from "./VideoCard"
import { makeStyles } from "@material-ui/styles"
import { Link } from "react-router-dom"
import api from "../core/api"
import Confirm from "./Confirm"

const useStyles = makeStyles(theme => ({
	root: {
		// flexGrow: 1,
		overflow: "none",
		transition: "width 1s, height 1s, opacity 1s 1s"
	},

	paperPropsMax: {
		padding: 10,
		width: 600,
		height: 500,
		transition: "width 1s, height 1s, border-width 0.6s linear"
		// minWidth: "450px !important"
	}
}))

const ArticleList = ({ article, fetchData, history }) => {
	const [open, setOpen] = useState(false)
	const classes = useStyles()

	//open dialog modal
	const handleClose = () => {
		setOpen(false)
	}
	//close dialog modal
	const handleOpen = () => {
		setOpen(true)
	}

	function createMarkup() {
		return { __html: article.content }
	}

	const onDelete = async id => {
		const res = await Confirm("Deseja realmente excluir este conteúdo")
		if (res) {
			try {
				await api
					.delete(`/articles/${id}`)
					.then(res => res.status === 200 && fetchData())

				// setState({ loading: false })
			} catch (e) {
				// setState({ loading: false })

				console.log("Upload Error")
				console.dir(e)
			}
		}
	}

	return (
		<ListItem alignItems="center" button>
			<ListItemAvatar>
				<Avatar>
					<TextFieldsIcon />
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={<Typography component="h2">{article.name}</Typography>}

				// secondary={article.description ? article.description : null}
			/>
			<ListItemSecondaryAction>
				<IconButton
					component={Link}
					to={
						history.location.pathname === "/articles"
							? `/articles/edit/${article.id}`
							: `/courses/edit/${article.id}`
					}
					edge="end">
					<EditIcon />
				</IconButton>
				<IconButton
					edge="end"
					aria-label="delete"
					onClick={() => onDelete(article.id)}>
					<DeleteIcon />
				</IconButton>
			</ListItemSecondaryAction>
			<Dialog
				PaperProps={{
					classes: {
						root: classes.paperPropsMax,
						paper: classes.fixWidth
					}
				}}
				onClose={handleClose}
				aria-labelledby="simple-dialog-title"
				open={open}>
				<DialogTitle id="simple-dialog-title">
					<div
						className="flex-row center-b"
						style={{ justifyContent: "space-between" }}>
						Detalhes da Matéria
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</div>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="article">
						<div dangerouslySetInnerHTML={createMarkup()} />
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</ListItem>
	)
}

export default ArticleList
