import React, {useState, useEffect} from 'react';
import api from '../../core/api';
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  CircularProgress,
  Avatar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import ReturnButton from '../ReturnButton';
import ImageCroper from '../ImageCroper';
import {useFileContext} from '../singletons/FileContext';
import {Formik} from 'formik';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import WifiIcon from '@material-ui/icons/Wifi';
import {string, object} from 'yup'; // for only what you need
import ButtonSubmit from './ButtonSubmit';
import RenderInput from './RenderInput';
import {useSnackbar} from 'notistack';
import {toDataURL} from '../../core/toDataURL';
import {putThumbnailUpload} from '../../core/formHelpers';
import {v4} from 'uuid';
import UserList from '../UserList';

const useStyles = makeStyles(() => ({
  avatar: {
    height: 200,
    width: 200,
    backgroundColor: '#61b7c3',
  },
  imageInput: {
    border: '3px dashed #000000a1',
    borderRadius: '0.5em',
    padding: '10px',
  },
  label: {
    paddingTop: 30,
  },
}));

const PartnerForm = ({
  history,
  partner = {
    name: '',
    email: '',
    artisticName: '',
    phone_first: '',
    phone_second: '',
    socialName: '',
    //occupation: '',
    // expertise: "",
    description: '',
    message: '',
    thumbnail: {},
    url_callback: '',
    document: {type: 'CPF', number: ''},
    document_identity: {type: 'RG', number: ''},
    postal_code: '',
    street: '',
    complement: '',
    number: '',
    state: '',
    city: '',
    country: '',
    facebook: '',
    instagram: '',
    linkedIn: '',
    telegram: '',
    whatsapp: '',
    youtube: '',
    others: '',
    show_email: false,
    show_facebook: false,
    show_instagram: false,
    show_linkedIn: false,
    show_telegram: false,
    show_whatsapp: false,
    show_youtube: false,
    show_site: false,
    show_phone_first: false,
    show_phone_second: false,
  },
}) => {
  const classes = useStyles();
  const editing = !!partner.id;
  const [getState, setState] = useState({sending: false});
  const {src, crop, blobFile, croppedImageUrl, setFileState} = useFileContext();
  const [open, setOpen] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const address =
    partner.address && typeof partner.address !== 'object'
      ? JSON.parse(partner.address)
      : partner.address;
  const social_midia =
    partner.social_midia && typeof partner.social_midia !== 'object'
      ? JSON.parse(partner.social_midia)
      : partner.social_midia;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
  };

  // useEffect(() => console.log(editing, partner));

  const onSaveThumbnail = async formikProps => {
    try {
      if (blobFile) {
        const reader = new FileReader();
        await reader.addEventListener('load', () =>
          setFileState({src: reader.result}),
        );
        reader.readAsDataURL(blobFile);

        formikProps.setFieldValue('thumbnail', blobFile);
        formikProps.errors.thumbnail = null;
      }
    } catch (e) {
      enqueueSnackbar('Erro ao carregar imagem.');
    }
    setOpen(false);
  };

  //inputs de dados pessoais
  const textInputs = [
    {name: 'artisticName', label: 'Nome Artístico', type: 'text'},
    {name: 'name', label: 'Nome Completo', type: 'text'},
    {name: 'socialName', label: 'Razão Social', type: 'text'},
    {name: 'expertise', label: 'Especialidade', type: 'text'},
    {name: 'description', label: 'Descrição', type: 'description'},
    {name: 'message', label: 'Mensagem', type: 'description'},

    //{
    //name: 'document',
    //label: 'Número',
    //type: 'select',
    //values: ['CPF', 'CNPJ'],
    //},
    //{
    //name: 'document_identity',
    //label: 'Número',
    //type: 'select',
    //values: ['RG', 'IE'],
    //},

    {name: 'email', label: 'E-mail', type: 'text', show: 'show_email'},
    {name: 'phone_first', label: 'Telefone 1', type: 'number'},
    {name: 'phone_second', label: 'Telefone 2', type: 'number'},
    {
      name: 'url_callback',
      label: 'Site - www.sitedoparceiro.com',
      type: 'text',
      show: 'show_site',
    },
  ];

  const addressInputs = [
    {name: 'postal_code', label: 'CEP', type: 'number'},
    {name: 'street', label: 'Endereço', type: 'text'},
    {name: 'complement', label: 'Complemento', type: 'text'},
    {name: 'number', label: 'Número', type: 'number'},
    {name: 'state', label: 'Estado', type: 'text'},
    {name: 'city', label: 'Cidade', type: 'text'},
    {name: 'country', label: 'País', type: 'text'},
  ];

  //inputs sobre as redes sociais do parceiro
  const socialMidiaInputs = [
    {
      name: 'facebook',
      label: 'Facebook',
      type: 'social',
      leftIcon: <FacebookIcon />,
    },
    {
      name: 'instagram',
      label: 'Instagram',
      type: 'social',
      leftIcon: <InstagramIcon />,
    },
    {
      name: 'youtube',
      label: 'Youtube',
      type: 'social',
      leftIcon: <YoutubeIcon />,
    },
    {
      name: 'linkedIn',
      label: 'LinkedIn',
      type: 'social',
      leftIcon: <LinkedInIcon />,
    },
    {
      name: 'telegram',
      label: 'Telegram',
      type: 'social',
      leftIcon: <TelegramIcon />,
    },
    {
      name: 'whatsapp',
      label: 'Whatsapp',
      type: 'social',
      leftIcon: <WhatsAppIcon />,
    },
    {
      name: 'others',
      label: 'Outros',
      type: 'social',
      leftIcon: <WifiIcon />,
    },
  ];

  const advancedOptionsCheckboxes = [
    {name: 'is_private', label: 'Ativo', type: 'checkbox'},
    {name: 'vip', label: 'Especialista', type: 'checkbox'},
  ];

  const onSelectFile = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setFileState({src: reader.result}));
      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  const validationSchema = () =>
    object().shape({
      artisticName: string().required('Preencha o campo, Nome Artístico'),
      name: string().required('Preencha o campo, Nome'),
      // socialName: string().required("Preencha o campo, Nome Social"),
      // expertise: string().required("Preencha o campo, Especialidade"),
      description: string().required('Preencha o campo, Descrição'),
      // document: string().required('Preencha o campo'),
      // document_identity: string().required('Preencha o campo'),
      // is_private: boolean().required("Preencha o campo, senha"),
      // vip: boolean().required("Preencha o campo, senha"),
      //occupation: string().required('Preencha o campo, Profissão'),
      email: string()
        .email('Digite um e-mail válido')
        .required('Preencha o campo, E-mail'),
      phone_first: string().required('Preencha o campo, Telefone 1'),
      // phone_second: string().required("Preencha o campo, Telefone 2"),
      // postal_code: string().required('Preencha o campo, CEP'),
      // street: string().required('Preencha o campo, Endereço'),
      // number: string().required('Preencha o campo, Número'),
      // state: string().required('Preencha o campo, Estado'),
      // city: string().required('Preencha o campo, Cidade'),
      // country: string().required('Preencha o campo, País')
      // facebook: string().required("Preencha o campo, senha"),
      // instagram: string().required("Preencha o campo, senha"),
      // youtube: string().required("Preencha o campo, senha"),
      // linkedIn: string().required("Preencha o campo, senha"),
      // telegram: string().required("Preencha o campo, senha"),
      // whatsapp: string().required("Preencha o campo, senha")
    });

  const defaultValues = editing
    ? {
        ...partner,
        show_linkedIn: partner.show_linkedin,
        postal_code: address?.postal_code || null,
        street: address?.street || null,
        complement: partner.complement?.complement || null,
        number: address?.number || null,
        state: address?.state || null,
        city: address?.city || null,
        country: address?.country || null,
        facebook: social_midia?.facebook || null,
        instagram: social_midia?.instagram || null,
        linkedIn: social_midia?.linkedIn || null,
        telegram: social_midia?.telegram || null,
        whatsapp: social_midia?.whatsapp || null,
        youtube: social_midia?.youtube || null,
        social_midia: social_midia,
        document: partner.document
          ? typeof partner.document !== 'object'
            ? JSON.parse(partner.document)
            : partner.document
          : null,
        document_identity: partner.document_identity
          ? typeof partner.document_identity !== 'object'
            ? JSON.parse(partner.document_identity)
            : partner.document_identity
          : null,
        show_phone_first: partner.show_phone_1,
        show_phone_second: partner.show_phone_2,
      }
    : {
        name: '',
        email: '',
        artisticName: '',
        phone_first: '',
        phone_second: '',
        socialName: '',
        //occupation: '',
        // expertise: "",
        description: '',
        message: '',
        thumbnail: {},
        url_callback: '',
        document: {type: 'CPF', number: ''},
        document_identity: {type: 'RG', number: ''},
        postal_code: '',
        street: '',
        complement: '',
        number: '',
        state: '',
        city: '',
        country: '',
        facebook: '',
        instagram: '',
        linkedIn: '',
        telegram: '',
        whatsapp: '',
        youtube: '',
        others: '',
        show_email: false,
        show_facebook: false,
        show_instagram: false,
        show_linkedIn: false,
        show_telegram: false,
        show_whatsapp: false,
        show_youtube: false,
        show_others: false,
        show_site: false,
        show_phone_first: false,
        show_phone_second: false,
      };

  // console.log(defaultValues);

  const onSubmit = async (values = {}) => {
    const form = {
      ...values,
      show_linkedin: values.show_linkedIn,
      show_site: values.show_site,
      show_phone_1: values.show_phone_first,
      show_phone_2: values.show_phone_second,
      document: JSON.stringify(values.document),
      document_identity: JSON.stringify(values.document_identity),
      address: JSON.stringify({
        postal_code: values.postal_code,
        street: values.street,
        number: values.number,
        complement: values.complement,
        state: values.state,
        city: values.city,
        country: values.country,
      }),
      social_midia: JSON.stringify({
        facebook: values.facebook,
        instagram: values.instagram,
        linkedIn: values.linkedIn,
        telegram: values.telegram,
        whatsapp: values.whatsapp,
        youtube: values.youtube,
      }),
      candidate_aproved: true,
    };

    try {
      if (editing) {
        if (
          !(typeof values.thumbnail === 'string') &&
          values.thumbnail?.name &&
          values.thumbnail?.name.toLowerCase().match(/.png|.jpg|.jpeg|.svg/)
        ) {
          const filename = partner?.thumbnail.split('/').pop();
          await putThumbnailUpload(values?.thumbnail, 'partners', filename);
        }

        delete form?.thumbnail;
        await api
          .put(`/partners/${partner.id}`, form)
          .then(() => {
            enqueueSnackbar('Dados Alterados com Sucesso!');
          })
          .catch(error => {
            enqueueSnackbar(`${error.response.data.message}`);
          });
      } else {
        const rndString = v4().replace(/-/g, '');
        form.thumbnail = `upload_${rndString}`;
        await api.post(`/partners`, form).then(async res => {
          if (res.status === 200) {
            await putThumbnailUpload(
              values.thumbnail,
              'partners',
              form.thumbnail,
            );

            enqueueSnackbar('Dados Cadastrados com Sucesso!');
          }
        });
      }
      if (window.location.pathname.includes('/candidates')) {
        history.push('/candidates');
      } else {
        history.push('/partners');
      }
    } catch (e) {
      console.log({e});
      if (e.response.status === 413) {
        enqueueSnackbar('Por favor, recorte a imagem para um tamanho menor!');
      } else {
        enqueueSnackbar('Ocorreu um erro de conexão, tente novamente!');
      }
    }
  };

  useEffect(() => {
    if (
      editing &&
      defaultValues.thumbnail &&
      defaultValues.thumbnail.includes('https')
    ) {
      toDataURL(
        `${defaultValues.thumbnail}?random=${Math.random()
          .toString(36)
          .substring(7)}`,
        function(dataUrl) {
          // console.log('RESULT:', dataUrl)
          setFileState({src: dataUrl});
        },
        'image/jpeg',
      );
    } else {
      setFileState({src: ''});
    }
  }, []);

  if (getState.sending) {
    return (
      <div className="flex-col center-a center-b" style={{height: '50vh'}}>
        <CircularProgress size={100} />
        <p> Enviando Aguarde... </p>
      </div>
    );
  }

  return (
    <Formik
      initialValues={defaultValues}
      // validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => onSubmit(values)}>
      {formikProps => (
        <div>
          <div className="flex-row center-b">
            <Dialog onClose={handleClose} open={open}>
              <DialogTitle onClose={handleClose}>Editor de Imagem</DialogTitle>
              <DialogContent>
                {!croppedImageUrl && !src && (
                  <Dropzone
                    onDrop={(acceptedFiles, ...rest) => {
                      onSelectFile(acceptedFiles);

                      formikProps.setFieldValue('thumbnail', acceptedFiles[0]);

                      formikProps.errors.thumbnail = null;
                    }}>
                    {({getRootProps, getInputProps}) => {
                      return (
                        <div
                          {...getRootProps()}
                          className="flex-col center-a center-b">
                          <IconButton className={classes.imageInput}>
                            <input {...getInputProps()} />
                            <p
                              style={{
                                cursor: 'pointer',
                              }}>
                              Arraste a imagem até aqui ou clique para
                              selecionar.
                            </p>
                          </IconButton>
                        </div>
                      );
                    }}
                  </Dropzone>
                )}
                <ImageCroper />
              </DialogContent>
              <DialogActions style={{justifyContent: 'space-between'}}>
                <Button
                  autoFocus
                  onClick={() =>
                    setFileState({
                      src: null,
                      croppedImageUrl: null,
                    })
                  }
                  color="secondary">
                  Limpar Imagem
                </Button>
                <Button
                  autoFocus
                  onClick={() => onSaveThumbnail(formikProps)}
                  color="primary">
                  Salvar
                </Button>
              </DialogActions>
            </Dialog>
            <ReturnButton />
            <Typography component="h1" variant="h5">
              {!editing
                ? 'Adicionar Parceiro'
                : window.location.pathname.includes('/candidates')
                ? 'Editar Candidato'
                : 'Editar Parceiro'}
            </Typography>
          </div>
          <Grid container xs={12}>
            <Grid xs={12} style={{padding: '20px'}}>
              <div className="flex-col center-a center-b">
                <IconButton onClick={handleClickOpen}>
                  <Avatar
                    className={classes.avatar}
                    src={croppedImageUrl || src || null}>
                    {!croppedImageUrl && !src && (
                      <AddAPhotoIcon style={{fontSize: 60}} />
                    )}
                  </Avatar>
                </IconButton>

                <p>Selecione uma imagem de parceiro.</p>
              </div>

              {src && (
                <Grid xs={12} container justify="center">
                  <Button onClick={handleClickOpen}>Editar Imagem</Button>
                </Grid>
              )}
            </Grid>
            <form style={{padding: '20px'}} onSubmit={formikProps.handleSubmit}>
              <Typography className={classes.label} component="h5" variant="h5">
                Dados Cadastrais
              </Typography>
              <RenderInput inputs={textInputs} formikProps={formikProps} />
              <Typography className={classes.label} component="h5" variant="h5">
                Endereço
              </Typography>
              <RenderInput inputs={addressInputs} formikProps={formikProps} />
              <Typography className={classes.label} component="h5" variant="h5">
                Mídias Sociais
              </Typography>
              <RenderInput
                inputs={socialMidiaInputs}
                formikProps={formikProps}
              />
              <Typography className={classes.label} component="h5" variant="h5">
                Mostrar no App
              </Typography>
              <RenderInput
                inputs={advancedOptionsCheckboxes}
                formikProps={formikProps}
              />
              <Typography className={classes.label} component="h5" variant="h5">
                Usuários Relacionados ao Parceiro
              </Typography>
              <UserList partner={partner.id} />
              <Grid alignItems="center">
                <ButtonSubmit
                  disabled={formikProps.isSubmitting}
                  style={{marginTop: 20}}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary">
                  {window.location.pathname.includes('/candidates')
                    ? 'Aprovar Candidato'
                    : 'Salvar'}
                </ButtonSubmit>
              </Grid>
            </form>
          </Grid>
        </div>
      )}
    </Formik>
  );
};

export default PartnerForm;
