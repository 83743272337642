import React, {useState, useEffect} from 'react';
import api from '../../core/api';
import {List, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useAppContext} from '../singletons/AppContext';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));
export default function EmpresaContainer({key, children}) {
  const [state, setState] = useState({
    empresas: [],
    loading: true,
    error: null,
  });
  const {setAppState} = useAppContext();
  const idEmpresa = parseInt(localStorage.getItem('empresa_id'));
  const adminGestor = JSON.parse(localStorage.getItem('adminGestor'));

  const classes = useStyles();

  /**
   *
   * @param {import('axios').AxiosInstance} api
   */
  async function fetchData() {
    setState({
      empresas: [],
      loading: true,
      error: null,
    });

    try {
      const {data: empresas} = await api.get(`/empresas`);

      // const empresas = [
      //   {
      //     id: 7,
      //     razaoSocial: 'teste razão',
      //     nomeFantasia: 'a nome fantasia',
      //     cnpj: '12345649877502',
      //     postal_code: '0',
      //     city: 'Planalto',
      //     state: 'bahia',
      //     street: 'rua teste',
      //     number: 0,
      //     descritivo: 'descritivo teste',
      //     createdAt: '2020-01-09T11:26:10.000Z',
      //     updatedAt: '2023-05-05T16:56:17.000Z',
      //   },
      //   {
      //     id: 3,
      //     razaoSocial: 'teste razão',
      //     nomeFantasia: 'teste nome fantasia',
      //     cnpj: '12345649877500',
      //     postal_code: '0',
      //     city: 'Planalto',
      //     state: 'bahia',
      //     street: 'rua teste',
      //     number: 0,
      //     descritivo: 'descritivo teste',
      //     createdAt: '2020-01-09T11:26:10.000Z',
      //     updatedAt: '2020-07-05T20:06:37.000Z',
      //   },
      //   {
      //     id: 5,
      //     razaoSocial: 'teste update',
      //     nomeFantasia: 'teste nome up',
      //     cnpj: '12345649877503',
      //     postal_code: '45190000',
      //     city: 'Poções',
      //     state: 'ceará',
      //     street: 'rua teste api',
      //     number: 15,
      //     descritivo: 'descritivo teste api',
      //     createdAt: '2023-05-03T19:27:16.000Z',
      //     updatedAt: '2023-05-04T17:32:35.000Z',
      //   },
      //   {
      //     id: 6,
      //     razaoSocial: 'nova Razão 1',
      //     nomeFantasia: 'nova empresa 6 ed',
      //     cnpj: '12316565499845',
      //     postal_code: '75110-150',
      //     city: 'GO',
      //     state: 'Anápolis',
      //     street: 'Rua Cônego Ramos',
      //     number: 110,
      //     descritivo: 'teste de descrição',
      //     createdAt: '2023-05-04T00:38:10.000Z',
      //     updatedAt: '2023-05-05T16:03:21.000Z',
      //   },
      // ];

      const filteredEmpresas = empresas
        .filter(e => {
          if (!adminGestor) {
            // console.log('verificando empresa');
            return e.id === idEmpresa;
          }
          return e;
        })
        .sort(function(a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

      setState({
        ...state,
        empresas: filteredEmpresas,
        loading: false,
      });
    } catch (e) {
      if (e.response)
        setState({
          ...state,
          error: {
            payload: e.response.data,
          },
        });
      else setState({...state, error: {}});
    }
  }

  useEffect(() => {
    fetchData();
    setAppState({onReloadData: () => fetchData()});
  }, []);

  if (state.error) {
    return (
      <div>
        <p style={{textAlign: 'center'}}>Ocorreu um erro ao obter os dados.</p>
        <p style={{textAlign: 'center'}}>
          <a href="javascript:void(0)" onClick={fetchData}>
            Tentar novamente
          </a>
        </p>
      </div>
    );
  }

  if (state.loading) {
    return (
      <div className="flex-col center-a center-b" style={{height: '50vh'}}>
        <span style={{paddingTop: '10em'}}>
          <CircularProgress size={100} />
          <p> Carregando... </p>
        </span>
      </div>
    );
  }

  return <List className={classes.root}>{children(state.empresas)}</List>;
}
